import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";
import { useSnapshot } from "valtio";

import MySelectionsContext from "@pages/MySelections/context";

import store, {
    setFilteredFranchises,
    setSearchedElementInputValue,
} from "@pages/MySelections/store";

export default function SelectFranchiseFormGroupWrapper({
    customSelectStyles,
}: any) {
    const { t } = useTranslation();

    const {
        setIsFranchiseChosen,
        selectedFranchiseValue,
        setSelectedFranchiseValue,
        setSelectedSelectionValue,
    } = React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    const franchisesOptions = MySelectionsStore.allFranchises.map(function (
        franchise: FranchisesDataType
    ) {
        return { value: franchise.id, label: franchise.name };
    });

    franchisesOptions.push({ value: "all", label: t("All franchises") });

    function handleChooseFranchiseSelectOnChangeEvent(
        selectedFranchiseValue: any,
        action: any
    ) {
        setSelectedFranchiseValue!(selectedFranchiseValue);
        setSearchedElementInputValue!("");
        setIsFranchiseChosen!(true);
        if (action.action === "clear") {
            setFilteredFranchises(MySelectionsStore.allFranchises);
        } else {
            let localChosenFranchise = MySelectionsStore.allFranchises!.filter(
                (franchise: any) => {
                    return franchise.id === selectedFranchiseValue.value;
                }
            );
            setFilteredFranchises!(
                selectedFranchiseValue.value === "all"
                    ? MySelectionsStore.allFranchises
                    : localChosenFranchise
            );
            setSelectedSelectionValue!([]);
        }
    }

    return (
        <FormGroup className="w-100 w-xs-100 franchises-font-size-xl__clz">
            {t("Franchise")}
            <Select
                isSearchable={true}
                isClearable={true}
                value={selectedFranchiseValue}
                onChange={handleChooseFranchiseSelectOnChangeEvent}
                options={franchisesOptions}
                placeholder={t("All franchises")}
                styles={customSelectStyles}
                noOptionsMessage={() => t("no option found")}
                className="franchises-font-size-xl__clz mt-select__clz"
                menuPosition="fixed"
            />
        </FormGroup>
    );
}
