import React from "react";
import { Card, CardBody, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";
import {
    setAllHistogram,
    setTop10Categories,
    setTop10Histogram,
} from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getSalesSupportQueryString,
    getUntranslatedString,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsSalesSupportCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    const { salesSupportSelection } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [salesSupportData, setSalesSupportData] = React.useState<any[]>([]);

    const {
        resourceData: statisticsArticlesSalesSupportData,
        error: statisticsArticlesSalesSupportDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/sales-support?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesSupportQueryString(
                salesSupportSelection!.map(getUntranslatedString)
            ) !== ""
                ? `&sales-support=${getSalesSupportQueryString(
                      salesSupportSelection!.map(getUntranslatedString)
                  ).toUpperCase()}`
                : ""
        }`
    );

    const {
        resourceData: { data },
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/all/sales-support?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesSupportQueryString(
                salesSupportSelection!.map(getUntranslatedString)
            ) !== ""
                ? `&sales-support=${getSalesSupportQueryString(
                      salesSupportSelection!.map(getUntranslatedString)
                  ).toUpperCase()}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setTop10Categories(
            getFormattedCategoriesByPeriod(period, advancedDate),
            "salesSupport"
        );
    }, [period, advancedDate]);

    React.useEffect(() => {
        setTop10Histogram(
            statisticsArticlesSalesSupportData.data,
            "salesSupport"
        );
        setSalesSupportData(
            getStatisticsData(
                statisticsArticlesSalesSupportData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsArticlesSalesSupportData,
        categories,
        period,
        advancedDate,
        t,
    ]);

    React.useEffect(() => {
        setAllHistogram(data, "salesSupport");
    }, [data]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "families",
                    value: salesSupportData.map((product) => product.name),
                },
                {
                    designation: "sales_support",
                    value: salesSupportSelection!
                        .filter(
                            (mode: string) => mode !== t("All sales supports")
                        )
                        .map((item: string) => {
                            return getUntranslatedString(item).toUpperCase();
                        }),
                },
            ],
        };

        setAdvancedLegend(t("Products"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm">
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="pb-0 chart-order chart-order-sm chart-order-md chart-order-lg chart-order-xl apex-charts apex-chart__clz">
                    {statisticsArticlesSalesSupportDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={salesSupportData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
