import React from "react";
import { Col, Card, CardBody } from "reactstrap";

import { formatCurrency } from "@helpers/general";

import { MyBestShopSvgIcon } from "@components/Common/SvgIcons";
import { useTranslation } from "react-i18next";

type PropsType = { shop: MyBestShopType };

export function CustomFirstCol({ shop }: PropsType) {
    const { t } = useTranslation();

    return (
        <Col key={shop.designation} md="3" className="px-0">
            <Card
                className="card-block__clz my-best-shop-card__clz mr-2"
                style={{
                    background:
                        "transparent linear-gradient(180deg, #EAC600 0%, #FFB103 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <CardBody
                    className="text-white d-grid__clz py-2 px-3 mb-3"
                    style={{
                        gridTemplateColumns: "0.1fr 0.9fr",
                        height: "100%",
                    }}
                >
                    <div className="d-flex justify-content-center align-items-center">
                        <MyBestShopSvgIcon />
                    </div>
                    <div className="d-grid__clz ml-3">
                        <span
                            style={{
                                font: "normal normal bold 17px/22px Poppins",
                                letterSpacing: "0px",
                            }}
                        >
                            {t(shop.designation)}
                        </span>

                        <table
                            style={{
                                font: "normal normal  13px/18px Poppins",
                            }}
                        >
                            <tr>
                                <td>{t("Incl-tax")}</td>
                                <td align="right">
                                    {formatCurrency(shop?.inclTax as number)}
                                </td>
                            </tr>
                            <tr>
                                <td>PM</td>
                                <td align="right">
                                    {formatCurrency(shop?.avgBasket as number)}
                                </td>
                            </tr>
                            <tr>
                                <td>CMD</td>
                                <td align="right">{shop?.tickets}</td>
                            </tr>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}
