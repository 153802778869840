import React from "react";
import { Card, CardBody, CardTitle, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ChartWrapper from "./ChartWrapper";

export default function ChartCard({ type }: any) {
    const { t } = useTranslation();

    return (
        <Card className="active border border-dark order-card-mb order-xs-card-mb">
            <CardBody className="pl-0 pb-1">
                <CardTitle className="mb-6 pl-4 float-sm-left">
                    {type}
                </CardTitle>
                <Nav
                    pills
                    className="navtab-bg"
                    style={{
                        float: "right",
                    }}
                >
                    <Link
                        to="/coming-soon"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <CardBody className="p-0">
                    <div className="p-3">
                        <ChartWrapper />
                    </div>
                </CardBody>
            </CardBody>
        </Card>
    );
}
