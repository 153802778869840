import React from "react";
import { Spinner, Card, CardBody } from "reactstrap";

export default function SpinnerWrapper() {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="text-center m-5">
                        <Spinner type="grow" className="mr-2" color="primary" />
                        <Spinner
                            type="grow"
                            className="mr-2"
                            color="secondary"
                        />
                        <Spinner type="grow" className="mr-2" color="success" />
                        <Spinner type="grow" className="mr-2" color="danger" />
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}
