import TitleRow from "@components/Common/TitleRow";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

import { setType } from "./RegulationModesColumn/store";

import { RegulationModesColumn } from "./RegulationModesColumn";
import { StyledButtonWrapper } from "./StyledButtonWrapper";

export function RegulationModesCol() {
    const { t } = useTranslation();

    return (
        <Col md="12">
            <TitleRow title={t("Payment methods").toUpperCase()}>
                <StyledButtonWrapper onClick={() => setType("TTC")}>
                    {t("Turnover incl-tax")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("Tickets")}>
                    {t("Tickets")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("Panier moyen")}>
                    {t("Average basket")}
                </StyledButtonWrapper>
            </TitleRow>
            <RegulationModesColumn />
        </Col>
    );
}
