import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { setSalesModeCards } from "@pages/Statistics/store";

import { getCurrentLanguageItemTranslation } from "@helpers/general";

type LocalOptionsType = {
    label: string | number;
    value: string | number;
};

export default function CustomSelectGroup({
    amounts,
    options,
}: {
    amounts: number[];
    options: any[];
}) {
    const { t, i18n } = useTranslation();

    let localOptions: LocalOptionsType[] = options.map((option: string) => ({
        label: option,
        value: option,
    }));

    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        localOptions[options.length - 1],
    ]);

    function handleSelectOnChangeEvent(selectedValue: any, actionType: any) {
        let allItemExistInSelectedValue: boolean =
            selectedValue?.find(
                (element: LocalOptionsType) =>
                    element.value === localOptions[options.length - 1].value
            ) !== undefined;

        if (allItemExistInSelectedValue) {
            if (
                ["Tous les modes de vente", "All sales methods"].includes(
                    actionType.option.label
                )
            ) {
                setSelectedValueState([localOptions[options.length - 1]]);
            } else {
                setSelectedValueState(
                    selectedValue.filter(
                        (element: any) =>
                            ![
                                "Tous les modes de vente",
                                "All sales methods",
                            ].includes(element.label)
                    )
                );
            }
        } else {
            let selectedValueSize = Object.keys(
                selectedValue === null ? {} : selectedValue
            )?.length;

            if (selectedValueSize === 0) {
                setSelectedValueState([localOptions[options.length - 1]]);
            } else {
                setSelectedValueState(selectedValue);
            }
        }
    }

    React.useEffect(() => {
        let selectedModes: string[] = selectedValueState?.map(
            (el: any) => el?.label
        );

        let localModesAmounts: number[] = [];

        selectedModes.forEach((mode: string) => {
            options?.forEach((option: string, index: number) => {
                if (mode === option) {
                    localModesAmounts.splice(
                        selectedModes.indexOf(option),
                        0,
                        amounts[index]
                    );
                }
            });
        });

        setSalesModeCards({
            modes: selectedModes,
            amounts: localModesAmounts,
        });
    }, [selectedValueState, amounts, options]);

    function MultiValueRemove() {
        return false;
    }

    const components: any = { MultiValueRemove };

    i18n.on("languageChanged", () => {
        setSelectedValueState((prevState: any) => {
            let newState: typeof prevState = [];

            newState = getCurrentLanguageItemTranslation(
                t("All sales methods")
            ).map((el: string) => ({
                label: el,
                value: el,
            }));

            return newState;
        });
    });

    return (
        <Select
            options={localOptions}
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={handleSelectOnChangeEvent}
            isMulti
            isClearable={
                selectedValueState &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState).includes(el.label)
                ).length === 0
            }
            components={
                selectedValueState &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState)?.includes(el.label)
                ).length > 0
                    ? components
                    : undefined
            }
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    minWidth: 240,
                    margin: 1,
                    borderRadius: "5px",
                    padding: "0px",
                }),
            }}
            theme={(theme: any) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "neutral10",
                },
            })}
        />
    );
}
