import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem } from "reactstrap";
import { CSVLink } from "react-csv";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

export function DownloadTop10CsvDropdownItem({ filename }: DropdownItemProps) {
    const { t } = useTranslation();

    const snapshot = useSnapshot(store);

    const data = snapshot[snapshot.section]["top10"].csv;

    return (
        <DropdownItem>
            <CSVLink className="btn p-0" data={data} filename={filename}>
                {t("Download top 10 in CSV format")}
            </CSVLink>
        </DropdownItem>
    );
}
