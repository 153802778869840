import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useResource } from "@hooks/useResource";

import { getTypeParam, getAdvancedPeriodStringParam } from "@helpers/general";

import BarChartCardWrapper from "../BarChartCardWrapper";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import ErrorPage from "@components/Common/ErrorPage";

export function KioskEmployeesRow({
    type,
    period,
    designation,
}: StatisticsChartsRowsPropsType) {
    const { t } = useTranslation();
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);
    const [shops, setShops] = React.useState<string[]>([]);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/kiosk/employees?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            designation && designation !== "my_favorites"
                ? `&selected=${designation}`
                : ""
        }&employee=cashier`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];
        let localShops: string[] = [];

        resourceData.data?.forEach((item: any) => {
            localLabels.push(item.designation);
            localData.push(item.value);
            localShops.push(item.shop);
        });

        setData(localData);
        setLabels(localLabels);
        setShops(localShops);
    }, [resourceData]);

    const employeeInitData: SeriesDataType[] = [
        {
            name: type,
            data: [...data, ...Array(10 - data.length).fill(0)],
        },
    ];

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            {error ? (
                <ErrorPage />
            ) : (
                <BarChartCardWrapper
                    title={t("Kiosk")}
                    series={employeeInitData}
                    type={type}
                    categories={[
                        ...labels,
                        ...Array(10 - labels.length).fill(""),
                    ]}
                    shops={shops}
                />
            )}
        </React.Suspense>
    );
}
