import React from "react";
import { useTranslation } from "react-i18next";

import { capitalize, formatExcelData } from "@helpers/general";

import ExportCsvToExcel from "@components/Common/ExportCsvToExcel";

import "./index.css";

type PropsType = {
    chartData: SerieType[];
    categories: string[];
};

export default function DownloadButton({ chartData, categories }: PropsType) {
    const { t } = useTranslation();

    const [excelData, setExcelData] = React.useState<
        {
            columns: any[];
            data: any[];
        }[]
    >([]);

    React.useEffect(() => {
        let headers: any[] = [t("Categories")];
        let array: any[] = [];
        let total: any[] = [];
        let averageLine: any[] = [];

        chartData?.forEach((datum: any) => {
            headers.push(t(capitalize(datum.name.toLowerCase())));
            let localTotal =
                datum.data.length > 0
                    ? datum.data.reduce(
                          (previousValue: any, currentValue: any) =>
                              previousValue + currentValue
                      )
                    : 0;
            total.push(localTotal);
            averageLine.push((localTotal / datum.data.length).toFixed(2));
        });

        categories.forEach((category: string, index: number) => {
            let localArray: any[] = [];
            chartData?.forEach((datum: any) => {
                localArray.push(datum.data[index]);
            });
            array.push([category, ...localArray]);
        });

        setExcelData([
            formatExcelData(headers, array, total, averageLine, t("Tickets")),
        ]);
    }, [chartData, categories, t]);

    return (
        <div
            className="float-right"
            style={{ zIndex: "1", position: "inherit" }}
        >
            <span className="ml-2" style={{ cursor: "pointer" }}>
                <ExportCsvToExcel data={excelData} filename={t("Transactions")}>
                    <i
                        className="bx bx-download"
                        style={{ color: "#495057", fontSize: "20px" }}
                    ></i>
                </ExportCsvToExcel>
            </span>
        </div>
    );
}
