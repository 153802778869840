import React from "react";
import { useDispatch } from "react-redux";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import "../PeriodModalWrapper/index.css";

type PropsType = {
    selectedItem: string;
    items: string[];
    isDropdownOpened: boolean;
    setIsDropdownOpened: Function;
    changePeriodAction: Function;
};

export default function TypeDropdownWrapper({
    selectedItem,
    isDropdownOpened,
    setIsDropdownOpened,
    items = [],
    changePeriodAction,
}: PropsType) {
    const dispatch = useDispatch();

    return (
        <Dropdown
            onMouseOver={() => {
                setIsDropdownOpened(true);
            }}
            onMouseLeave={() => {
                setIsDropdownOpened(false);
            }}
            isOpen={isDropdownOpened}
            toggle={() => false}
        >
            <DropdownToggle className="bg-transparent border-0 py-0">
                <div className="font-size-16 text-dark">
                    <h5
                        style={{
                            textDecoration: "underline",
                        }}
                        className="white-space-nowrap"
                    >
                        {selectedItem}
                    </h5>
                </div>
            </DropdownToggle>
            <DropdownMenu className="mt-0">
                {items.map((item: string, index: number) => {
                    return (
                        <DropdownItem key={`item__${index}`}>
                            <div
                                onClick={(e: any) => {
                                    setIsDropdownOpened(false);
                                    dispatch(
                                        changePeriodAction(
                                            e.currentTarget.textContent
                                        )
                                    );
                                }}
                            >
                                {item}
                            </div>
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
}
