import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { DownloadTop10CsvDropdownItem } from "./DownloadTop10CsvDropdownItem";
import { DownloadTop10ExcelDropdownItem } from "./DownloadTop10ExcelDropdownItem";
import { DownloadAllCsvDropdownItem } from "./DownloadAllCsvDropdownItem";
import { DownloadAllExcelDropdownItem } from "./DownloadAllExcelDropdownItem";

type PropsType = {
    filename?: string;
};

export function DownloadDropdown({ filename }: PropsType) {
    const { t } = useTranslation();

    const [isDropdownOpened, setIsDropdownOpened] = React.useState<boolean>(
        false
    );

    return (
        <Dropdown
            isOpen={isDropdownOpened}
            tag="div"
            toggle={() => setIsDropdownOpened(!isDropdownOpened)}
            className="p-1"
        >
            <DropdownToggle className="d-flex">
                <i className="bx bx-menu font-size-20" />
            </DropdownToggle>
            <DropdownMenu right={true}>
                <DownloadTop10CsvDropdownItem
                    filename={`${
                        filename !== undefined ? filename : t("Statistics")
                    }.csv`}
                />
                <DownloadTop10ExcelDropdownItem
                    filename={
                        filename !== undefined ? filename : t("Statistics")
                    }
                />
                <DownloadAllCsvDropdownItem
                    filename={`${
                        filename !== undefined
                            ? filename.replace("Top-10", "all")
                            : t("Statistics")
                    }.csv`}
                />
                <DownloadAllExcelDropdownItem
                    filename={
                        filename !== undefined
                            ? filename.replace("Top-10", "all")
                            : t("Statistics")
                    }
                />
            </DropdownMenu>
        </Dropdown>
    );
}
