import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import i18n from "i18next";

import Breadcrumb from "@components/Common/GlobalBreadcrumb";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import PageTitle from "@components/Common/PageTitle";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import Cards from "./Cards";
import SalesSupportRow from "./SalesSupportRow";
import RegulationsRow from "./RegulationsRow";
import SalesModeRow from "./SalesModeRow";
import ServicesRow from "./ServicesRow";
import Top10FamiliesRow from "./Top10FamiliesRow";
import Top10ArticlesRow from "./Top10ArticlesRow";
import SelectFilter from "./SelectFilter";
import CardSpinnerWrapper from "@components/Common/CardSpinnerWrapper";

export default function SalesSupport() {
    const { t } = useTranslation();
    const type = useSelector((state: any) => state.type);

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Sales support")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Sales support")}
                />
                <React.Suspense fallback={<CardSpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <SelectFilter />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <Cards />
                    </CustomErrorBoundary>
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesSupportRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesModeRow />
                </React.Suspense>

                {type === t("Turnover incl-tax") && (
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <RegulationsRow />
                    </React.Suspense>
                )}

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <ServicesRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10FamiliesRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10ArticlesRow />
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
