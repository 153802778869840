import React from "react";

export default function Menu(props: any) {
    const shadow = "hsla(218, 50%, 10%, 0.1)";

    return (
        <div
            className="bg-light menu__clz text-left"
            style={{
                borderRadius: "4px",
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                position: "absolute",
                zIndex: 12,
                right: "100px",
            }}
            {...props}
        />
    );
}
