import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { SavedSelectionModalWrapper } from "./SavedSelectionModalWrapper";

export function SavedSelectionButtonWrapper() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);

    const [
        isSavedSelectionModalOpened,
        setIsSavedSelectionModalOpened,
    ] = React.useState<boolean>(false);

    function toggleOpeningIsSaveMySelectionModal() {
        setIsSavedSelectionModalOpened((prevState) => !prevState);
    }

    return (
        <React.Fragment>
            <Button
                type="button"
                color={mainColor === null ? "secondary" : ""}
                outline={true}
                className="w-xs-100 w-sm-100 mt-2 mt-md-0 franchises-font-size-xl__clz"
                style={{ borderColor: mainColor, color: mainColor }}
                onClick={toggleOpeningIsSaveMySelectionModal}
            >
                {t("My saved selections")}
            </Button>
            {isSavedSelectionModalOpened === true ? (
                <SavedSelectionModalWrapper
                    toggleOpeningModal={toggleOpeningIsSaveMySelectionModal}
                />
            ) : null}
        </React.Fragment>
    );
}
