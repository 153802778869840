import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useRowSelect,
    useTable,
    useGlobalFilter,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { Button } from "reactstrap";
import { useSnapshot } from "valtio";
import { useStep } from "react-hooks-helper";
import { useSelector } from "react-redux";

import { store as vStore } from "@vStore";
import { store as salesSupportStore, setSalesSupportList } from "./store";
import store, {
    addSalesSupportSelectionItem,
} from "@pages/Statistics/Transactions/store";

import { useResource } from "@hooks/useResource";

import { capitalize } from "@helpers/general";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import AddSvgIcon from "@components/Common/SvgIcons/AddSvgIcon";

import Pagination from "@components/Common/Table/Pagination";
import MySelection from "./MySelection";
import { Styles } from "../Styles";
import { TopContentWrapper } from "./TopContentWrapper";

export default function SalesSupport() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const advancedStore = useSnapshot(store);
    const { salesSupportList } = useSnapshot(salesSupportStore);
    const { selections } = useSelector((state: any) => state);

    const { index, navigation } = useStep({ steps: 2 });

    const {
        resourceData: salesSupport,
        error: salesSupportError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/transactions/sales-support${
            selections.designation && selections.designation !== "my_favorites"
                ? `?selected=${selections.designation}`
                : ""
        }`
    );

    const handleAddingSalesSupportButtonOnClickEvent = React.useCallback(
        (salesSupport: any) => {
            addSalesSupportSelectionItem(salesSupport);

            setSalesSupportList(
                salesSupportList.filter((datum: any) => {
                    return salesSupport.toLowerCase() !== datum.toLowerCase();
                })
            );
        },
        [salesSupportList]
    );

    const columns: AdvancedTableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("Designation"),
                accessor: (salesSupport: string): string =>
                    t(capitalize(salesSupport)),
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (salesSupport: any) {
                    return (
                        <div>
                            <Button
                                type="button"
                                outline={true}
                                block={true}
                                color={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block p-1 white-space-nowrap"
                                style={{
                                    width: "150px",
                                    float: "right",
                                    color: mainColor,
                                    borderColor: mainColor,
                                }}
                                onClick={() =>
                                    handleAddingSalesSupportButtonOnClickEvent(
                                        salesSupport
                                    )
                                }
                            >
                                {t("Add")}
                            </Button>
                            <button
                                className="border-0 d-block d-sm-block d-md-none p-1 bg-transparent__clz icon-color__clz"
                                onClick={() =>
                                    handleAddingSalesSupportButtonOnClickEvent(
                                        salesSupport
                                    )
                                }
                            >
                                <AddSvgIcon />
                            </button>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingSalesSupportButtonOnClickEvent, mainColor]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t(rest.title) };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        selectedFlatRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: salesSupportList,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    React.useEffect(() => {
        setSalesSupportList(
            advancedStore.salesSupportSelection.length > 0
                ? salesSupport.data.filter((saleSupport: any) => {
                      return !advancedStore.salesSupportSelection
                          .map((s) => s.toLowerCase())
                          .includes(saleSupport.toLowerCase());
                  })
                : salesSupport.data
        );
    }, [salesSupport, advancedStore]);

    return (
        <React.Fragment>
            {index === 0 && (
                <React.Fragment>
                    <TopContentWrapper
                        selectedFlatRows={selectedFlatRows}
                        setGlobalFilter={setGlobalFilter}
                        navigation={navigation}
                    />
                    {salesSupportError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <Styles>
                                <BTable responsive {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (column) => {
                                                        return (
                                                            <th
                                                                {...column.getHeaderProps()}
                                                            >
                                                                {column.render(
                                                                    "Header"
                                                                )}
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td
                                                                className="white-space-nowrap"
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </BTable>
                            </Styles>
                            {salesSupportList.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </CustomErrorBoundary>
                    )}
                </React.Fragment>
            )}
            {index === 1 && (
                <MySelection
                    {...navigation}
                    data={advancedStore.salesSupportSelection}
                />
            )}
        </React.Fragment>
    );
}
