import React from "react";

export default function Svg(props: any) {
    return (
        <svg
            className="svg__clz"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            focusable="false"
            role="presentation"
            {...props}
        />
    );
}
