import { proxy } from "valtio";

type ProductType = {
    designation: string;
    family: string;
    image: string;
    reference: string;
    subFamily: string;
};

type StoreType = {
    groupName: string;
    groupedRegulations: any[];
    regulations: ProductType[];
    filteredRegulations: ProductType[];
    families: string[];
    subFamilies: string[];
    globalFilterValue: string;
};

export const store = proxy<StoreType>({
    groupName: "",
    groupedRegulations: [],
    regulations: [],
    filteredRegulations: [],
    families: [],
    subFamilies: [],
    globalFilterValue: "",
});

export async function getRegulations() {
    const url = `${process.env.REACT_APP_API_V1_URL}/settings/regulations/all`;
    const response = await fetch(url, {
        headers: {
            accept: "application/json",
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
    });

    const rawData = await response.json();

    if (rawData.error === false) {
        store.regulations = rawData.data;
        store.filteredRegulations = store.regulations;
    }
}

export function setGroupName(groupName: string) {
    store.groupName = groupName;
}

export function setGroupedRegulations(groupedRegulations: any[]) {
    store.groupedRegulations = groupedRegulations;
}

export function setGlobalFilterValue(value: string) {
    store.globalFilterValue = value;
}

export function setFilteredRegulations(regulations: ProductType[]) {
    store.filteredRegulations = regulations;
}
