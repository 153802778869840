import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store as vStore } from "@vStore";
import store from "@pages/Statistics/Transactions/store";

type PropsType = {
    title?: string;
    next?: any;
};

export default function TitleRowButtons({ title, next }: PropsType) {
    const { t } = useTranslation();

    const {
        salesModeSelection,
        salesSupportSelection,
        regulationModesSelection,
        productsSelection,
        customersCitiesSelection,
    } = useSnapshot(store);
    const { mainColor } = useSnapshot(vStore);

    const [selection, setSelection] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (title === t("Sales mode")) {
            setSelection(salesModeSelection);
        } else if (title === t("Sales support")) {
            setSelection(salesSupportSelection);
        } else if (title === t("Payment methods")) {
            setSelection(regulationModesSelection);
        } else if (title === t("Products")) {
            setSelection(productsSelection);
        } else if (title === t("Order city")) {
            setSelection(customersCitiesSelection);
        }
    }, [
        title,
        salesModeSelection,
        salesSupportSelection,
        regulationModesSelection,
        productsSelection,
        customersCitiesSelection,
        t,
    ]);

    return (
        <StyledButton
            rounded={true}
            outline={true}
            variant={mainColor === null ? "secondary" : ""}
            disabled={selection.length === 0}
            className="white-space-nowrap"
            onClick={() => next()}
            style={{
                borderColor: mainColor,
                color: mainColor,
                marginRight: title === t("Products") ? "0.5rem" : "unset",
            }}
        >
            {t("My selection")} {`(${selection.length})`}
        </StyledButton>
    );
}
