import React from "react";
import { Row } from "reactstrap";

import { useResource } from "@hooks/useResource";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import { CustomFirstCol } from "./CustomFirstCol";
import { CustomCol } from "./CustomCol";

type PropsType = { url: string };

export function MyBestShopRowWrapper({ url }: PropsType) {
    const {
        resourceData: { data: myBestShop },
        error,
    } = useResource(url);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <Row className="flex-row flex-nowrap overflow-auto mt-2">
                    <CustomErrorBoundary>
                        {myBestShop?.map(
                            (shop: ClientsMyBestShopType, index: number) =>
                                index === 0 ? (
                                    <CustomFirstCol
                                        key={`${shop.designation
                                            .split(" ")
                                            .join("_")}_${index}`}
                                        shop={shop}
                                    />
                                ) : (
                                    <CustomCol
                                        key={`${shop.designation
                                            .split(" ")
                                            .join("_")}_${index}`}
                                        shop={shop}
                                    />
                                )
                        )}
                    </CustomErrorBoundary>
                </Row>
            )}
        </React.Fragment>
    );
}
