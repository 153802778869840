import React from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store, toggle } from "../store";

export function NavWrapper() {
    const { t } = useTranslation();
    const { activeTab } = useSnapshot(store);

    return (
        <Nav tabs>
            <NavItem>
                <NavLink
                    className={classNames({
                        "active active-tab__clz": activeTab === 1,
                    })}
                    onClick={() => {
                        toggle(1);
                    }}
                    style={{ color: "black" }}
                >
                    {t("The regulations")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classNames({
                        "active active-tab__clz": activeTab === 2,
                    })}
                    onClick={() => {
                        toggle(2);
                    }}
                    style={{ color: "black" }}
                >
                    {t("Families")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classNames({
                        "active active-tab__clz": activeTab === 3,
                    })}
                    onClick={() => {
                        toggle(3);
                    }}
                    style={{ color: "black" }}
                >
                    {t("Sub-families")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classNames({
                        "active active-tab__clz": activeTab === 4,
                    })}
                    onClick={() => {
                        toggle(4);
                    }}
                    style={{ color: "black" }}
                >
                    {t("Products")}
                </NavLink>
            </NavItem>
        </Nav>
    );
}
