import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Nav, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { uid } from "react-uid";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { formatCurrency, formatDecimalNumber } from "@helpers/general";

type DonutChartPropsType = {
    data: any;
    title: string;
    type: string;
};

export default function DonutChart({ data, title, type }: DonutChartPropsType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    const series = data.data;

    const options = {
        dataLabels: {
            enabled: true,
            textAnchor: "start",
            distributed: true,
            offsetX: 0,
            offsetY: 0,
            background: {
                enabled: true,
                foreColor: "#fff",
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: "#fff",
                opacity: 0,
                dropShadow: {
                    enabled: true,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: "#000",
                    opacity: 0,
                },
            },
            dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                color: "#000",
                opacity: 0.45,
            },
            formatter: function (val: any, opts: any) {
                if (
                    opts !== undefined &&
                    opts!.w!.globals!.seriesPercent![opts!.seriesIndex!]! ===
                        undefined
                ) {
                    return `${formatDecimalNumber(
                        opts!.w!.globals!.seriesPercent![opts!.seriesIndex!]![0]
                    )}%`;
                } else {
                    return `${formatDecimalNumber(val)}%`;
                }
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            y: {
                formatter: (seriesName: any) =>
                    type !== t("Tickets")
                        ? formatCurrency(seriesName)
                        : seriesName,
                title: {
                    formatter: (seriesName: any) => seriesName,
                },
            },
        },
        labels: data.labels,
        colors: data.colors,
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            offsetX: 0,
            offsetY: -10,
            formatter: function (seriesName: any, opts: any) {
                if (
                    opts!.w!.globals!.seriesPercent![opts!.seriesIndex!]! ===
                    undefined
                ) {
                    return `${seriesName}`;
                } else {
                    return `${seriesName} (${
                        type !== t("Tickets")
                            ? formatCurrency(
                                  opts!.w!.globals!.seriesTotals![
                                      opts!.seriesIndex!
                                  ]
                              )
                            : opts!.w!.globals!.seriesTotals![
                                  opts!.seriesIndex!
                              ]
                    }/${formatDecimalNumber(
                        opts!.w!.globals!.seriesPercent![opts!.seriesIndex!]![0]
                    )}%)`;
                }
            },
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };

    return (
        <Card className="mb-0">
            <CardTitle
                className={classNames("rounded-top", {
                    "dark-bg__clz": mainColor === null,
                })}
                style={{ background: mainColor }}
            >
                <Nav
                    pills
                    className="navtab-bg d-none"
                    style={{ float: "right" }}
                >
                    <Link
                        to="/turnover-detail"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <h4 className="card-title my-2 mx-3 text-white">{title}</h4>
            </CardTitle>
            <CardBody className="p-1">
                <div className="dount">
                    <ReactApexChart
                        key={uid(`apex-chart__${type}`)}
                        options={options}
                        series={series}
                        type="donut"
                        height="400px"
                    />
                </div>
            </CardBody>
        </Card>
    );
}
