import { ADVANCED_DATE } from "./actions-types";

type ChangeDateActionType = {
    type: any;
    payload: any;
};

type InitStateType = {
    startDate: string;
    endDate: string;
};

const INIT_STATE: InitStateType = { startDate: "", endDate: "" };

type ReturnedType = {
    startDate: string;
    endDate: string;
} | null;

export default function advancedDate(
    state = INIT_STATE,
    action: ChangeDateActionType
): ReturnedType {
    if (action.type === ADVANCED_DATE) {
        return action.payload;
    } else {
        return state !== undefined ? state : null;
    }
}
