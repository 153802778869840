import React from "react";

export default function AllSalesMethodsSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8790">
                <g data-name="Groupe 8792">
                    <g data-name="Groupe 8785">
                        <g data-name="Mask group">
                            <path
                                data-name="icon bg"
                                d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                                fill="#333"
                            />
                            <g
                                data-name="Mask group"
                                clipPath="url(#prefix__a)"
                            >
                                <path
                                    data-name="Rectangle 2"
                                    d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                    fill="rgba(255,255,255,0.1)"
                                />
                            </g>
                        </g>
                    </g>
                    <g data-name="bag (1)">
                        <g data-name="Groupe 8458">
                            <g data-name="Groupe 8457">
                                <path
                                    data-name="Trac\xE9 8605"
                                    d="M27.406 16.75H24.5v-1.937a5.813 5.813 0 10-11.625 0v1.937H9.969a.969.969 0 00-.969.969v18.406A3.879 3.879 0 0012.875 40h6.781a.969.969 0 000-1.938h-6.781a1.939 1.939 0 01-1.938-1.938V18.688h1.938v.969a.969.969 0 101.938 0v-.969h7.75v.969a.969.969 0 101.938 0v-.969h1.938v.969a.969.969 0 101.938 0v-1.938a.969.969 0 00-.971-.969zm-4.844 0h-7.749v-1.937a3.875 3.875 0 117.75 0z"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                        <g data-name="Groupe 8460">
                            <g data-name="Groupe 8459">
                                <path
                                    data-name="Trac\xE9 8606"
                                    d="M39.032 24.5h-3.005a4.843 4.843 0 00-9.49 0h-3.005a.969.969 0 00-.969.969v10.656A3.879 3.879 0 0026.438 40h9.688a3.879 3.879 0 003.875-3.875V25.469a.969.969 0 00-.969-.969zm-7.75-1.938A2.9 2.9 0 0134.01 24.5h-5.456a2.9 2.9 0 012.728-1.937zm6.781 13.563a1.939 1.939 0 01-1.938 1.938h-9.687a1.939 1.939 0 01-1.937-1.938v-9.688h1.937v.969a.969.969 0 101.938 0v-.969h5.813v.969a.969.969 0 101.937 0v-.969h1.937z"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
