import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { setType } from "./store";

export default function SelectType() {
    const { t } = useTranslation();
    
    let localOptions: LocalOptionsType[] = [t("Shop")].map(
        (option: string) => ({
            label: option,
            value: option,
        })
    );
    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        localOptions[0],
    ]);

    function handleSelectOnChangeEvent(selectedValue: any) {
        setSelectedValueState(selectedValue);
        setType(selectedValue.value);
    }

    return (
        <Select
            isSearchable
            options={localOptions}
            value={selectedValueState}
            onChange={handleSelectOnChangeEvent}
            placeholder={t("Select a country")}
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                    borderRadius: "0px",
                    borderColor: "unset",
                    boxShadow: "unset",
                }),
                indicatorSeparator: (provided: any) => ({
                    ...provided,
                    display: "none",
                }),
                placeholder: (provider: any) => ({
                    ...provider,
                    fontSize: "0.8125rem",
                }),
                valueContainer: (provider: any) => ({
                    ...provider,
                    fontSize: "15px",
                    paddingLeft: 0,
                    paddingRight: 0,
                }),
                singleValue: (provider: any) => ({
                    ...provider,
                    color: "black",
                    paddingLeft: "0.75rem",
                }),
                option: (provider: any) => ({
                    ...provider,
                    fontSize: "15px",
                }),
            }}
        />
    );
}
