export async function fetcherWithToken(url: string) {
    const token = localStorage.getItem("jwt");
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const response = await fetch(url, {
        headers: {
            authorization: `Bearer ${token}`,
            "Accept-Language": i18nextLng,
        },
    });

    let text = await response.text();

    const data = text ? JSON.parse(text) : null;

    if (response.ok === true) {
        return data;
    } else {
        const error: any = new Error(
            "An error occurred while fetching the data."
        );
        // Attach extra info to the error object.
        error.info = data.message;
        error.status = response.status;
        throw error;
    }
}
