import React from "react";
import { Card, CardBody } from "reactstrap";
import { DynamicTable } from "@aures/custom-react-table";
import { useSnapshot } from "valtio";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { store } from "../store";

import {
    getAdvancedPeriodStringParam,
    getSelectionQueryStringFromSelectedGroups,
    getSelectionQueryStringFromSelectedShops,
} from "@helpers/general";

import { ExportExcelButton } from "./ExportExcelButton";

export function MyClientsCardWrapper() {
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const { selectedShops, selectedGroups } = useSnapshot(store);
    const { period, advancedDate } = useSelector((state: any) => state);

    const selectionQueryString = getSelectionQueryStringFromSelectedShops(
        selectedShops
    );
    const groupsSelectionQueryString = getSelectionQueryStringFromSelectedGroups(
        selectedGroups
    );
    const token = localStorage.getItem("jwt");

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [, setSelectedRows] = React.useState<SelectedRowsType>([]);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [data, setData] = React.useState<{ data: any[] }>({ data: [] });
    const [key, setKey] = React.useState(uuid());

    React.useEffect(() => {
        setDataIsUpdated(["fr", "en"].includes(i18nextLng));
        setKey(uuid());
    }, [i18nextLng]);

    return (
        <Card>
            <CardBody key={key} className="card-body__clz">
                <DynamicTable
                    url={`${
                        process.env.REACT_APP_API_V1_URL
                    }/statistics/client/clients?${getAdvancedPeriodStringParam(
                        period,
                        advancedDate
                    )}${selectionQueryString}${groupsSelectionQueryString}`}
                    requestHeader={{
                        "Accept-Language": i18nextLng,
                        Authorization: `Bearer ${token}`,
                    }}
                    setData={setData}
                    canSort={true}
                    canResize={true}
                    canSelect={false}
                    setSelectedRows={setSelectedRows}
                    customSelect={false}
                    showGlobalFilter={true}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    showFilter={true}
                    filterActive={filterActive}
                    setLocalFilterActive={setLocalFilterActive}
                    customJsxDir="left"
                    customJsxSideFilterButton={
                        <ExportExcelButton data={data.data} />
                    }
                    elevationTable={8}
                    minHeight="300px"
                    defaultPaginationValues={[10]}
                />
            </CardBody>
        </Card>
    );
}
