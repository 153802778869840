import React from "react";
import { Container, Row } from "reactstrap";
import { SelectedShopWrapper } from "../SelectedShopWrapper";
import { SelectedGroupWrapper } from "../SelectedGroupWrapper";
import { useTranslation } from "react-i18next";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";

import { store } from "@pages/Statistics/Shops/store";

export function ContainerWrapper() {
    const { t } = useTranslation();
    const { selectedShops, selectedGroups } = useSnapshot(store);

    return (
        <Container
            className="bg-white mb-2"
            fluid={true}
            style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #F2F2F2",
            }}
        >
            <Row className="p-2 mb-3 flex-row flex-nowrap overflow-auto">
                {[...selectedShops, ...selectedGroups].length > 0 ? (
                    <React.Fragment>
                        {selectedShops.map((shop) => (
                            <SelectedShopWrapper
                                key={uid(`${shop.franchiseId}__${shop.shopId}`)}
                                shop={shop}
                            />
                        ))}
                        {selectedGroups.map((group) => (
                            <SelectedGroupWrapper
                                key={uid(group)}
                                group={group}
                            />
                        ))}
                    </React.Fragment>
                ) : (
                    t("No data")
                )}
            </Row>
        </Container>
    );
}
