import React from "react";
import { isEqual } from "lodash";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store as vStore } from "@vStore";
import { store, setSelectedShops } from "@pages/Statistics/Shops/store";

type PropsType = { shop: MyShopsType };

export function SelectedShopWrapper({ shop }: PropsType) {
    const { selectedShops } = useSnapshot(store);
    const { mainColor } = useSnapshot(vStore);

    function handleRemoveShopFromSelectedShops(shop: MyShopsType) {
        const newSelectedShops = selectedShops.filter((selectedShop) => {
            return isEqual(selectedShop, shop) === false;
        });
        setSelectedShops(newSelectedShops);
    }

    return (
        <div
            className={classNames("mr-1 mb-1 p-2 badge", {
                "badge-dark": mainColor === null,
                "text-white": mainColor !== null,
            })}
            style={{ background: mainColor }}
        >
            <span>{shop.shopName}</span>
            <span
                className="uppercase-text ml-1"
                onClick={() => handleRemoveShopFromSelectedShops(shop)}
                style={{ cursor: "pointer" }}
            >
                X
            </span>
        </div>
    );
}
