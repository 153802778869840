import React from "react";
import { useTranslation } from "react-i18next";

import { useTop10ArticlesData, useTopAllArticlesData } from "../hooks";

import TitleRow from "@components/Common/TitleRow";
import { DownloadDropdown } from "@pages/Statistics/NewProducts/DownloadDropdown";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import PercentageTop10ArticlesCol from "./PercentageTop10ArticlesCol";
import RecapCol from "./RecapCol";

export default function Top10ArticlesRow() {
    const { t } = useTranslation();

    useTop10ArticlesData();
    useTopAllArticlesData();

    return (
        <React.Fragment>
            <TitleRow title={t("Top 10 products")}>
                <DownloadDropdown />
            </TitleRow>
            <div
                className="d-grid__clz 
                                grid-gap__clz 
                                grid-xl-statistics-products-details__clz 
                                grid-lg-statistics-template-details__clz 
                                grid-md-statistics-template-details__clz 
                                grid-sm-template-col__clz grid-xs-template-col__clz 
                                pr-0"
            >
                <CustomErrorBoundary>
                    <PercentageTop10ArticlesCol />
                </CustomErrorBoundary>
                <CustomErrorBoundary>
                    <RecapCol />
                </CustomErrorBoundary>
            </div>
        </React.Fragment>
    );
}
