import { createContext } from "react";

type MySelectionsContextType = {
    setSearchedFranchises?: Function;
    searchedFranchises?: any;
    setIsFranchiseChosen?: Function;
    isFranchiseChosen?: boolean;
    setExpandedRows?: Function;
    expandedRows?: any[];
    setSelectedSelectionValue?: Function;
    selectedSelectionValue?: any[];
    selectedSelection?: any;
    setSelectedSelection?: Function;
    selection?: string;
    setSelection?: Function;
    setSelectedFranchiseValue?: Function;
    selectedFranchiseValue?: any[];
};

export default createContext<MySelectionsContextType>({});
