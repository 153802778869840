import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import i18n from "i18next";

import { StatisticsContext } from "@pages/Statistics/context";
import { useResource } from "@hooks/useResource";

import { capitalize } from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/OldDownloadDropdown";

import SalesModeCol from "./SalesModeCol";
import StatisticsSalesModeCol from "./StatisticsSalesModeCol";
import ErrorPage from "@components/Common/ErrorPage";
import GeneralCustomSelectGroup from "@components/Common/GeneralCustomSelectGroup";

export default function SalesModeRow() {
    const { t } = useTranslation();
    moment.locale(i18n.language);

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);
    const [salesModesSelection, setSalesModesSelection] = React.useState<
        string[]
    >([t("All sales methods")]);

    const [salesModeListData, setSalesModeListData] = React.useState<string[]>(
        []
    );

    const {
        resourceData: salesModeList,
        error: salesModeListError,
    } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/statistics/sales-mode-list?${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSalesModeList: string[] = [];

        salesModeList.data?.forEach((el: string) => {
            localSalesModeList.push(t(capitalize(el)));
        });

        setSalesModeListData(localSalesModeList);
    }, [salesModeList, t]);

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                    percentageData,
                    setPercentageData,
                    histogramData,
                    setHistogramData,
                    categoriesData,
                    setCategoriesData,
                    salesModesSelection,
                    setSalesModesSelection,
                }}
            >
                <TitleRow title={t("Sub-families per sale mode")}>
                    {salesModeListError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <GeneralCustomSelectGroup
                                options={[
                                    t("All sales methods"),
                                    ...salesModeListData,
                                ]}
                            />
                        </CustomErrorBoundary>
                    )}
                    <DownloadDropdown />
                </TitleRow>

                <div
                    className="
                d-grid__clz 
                grid-gap__clz 
                grid-xl-statistics-families-and-products-details__clz 
                grid-lg-statistics-template-details__clz 
                grid-md-statistics-template-details__clz
                grid-sm-template-col__clz grid-xs-template-col__clz 
                pr-0"
                >
                    <CustomErrorBoundary>
                        <SalesModeCol />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <StatisticsSalesModeCol />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
