import {
    ADD_REGULATION_MODE,
    CLEAR_REGULATION_MODES,
    INIT_REGULATION_MODES,
    SELECT_REGULATION_MODES,
} from "./actions-types";

export const selectRegulationModes = (
    regulationModes: string
): {
    type: string;
    payload: string;
} => {
    return {
        type: SELECT_REGULATION_MODES,
        payload: regulationModes,
    };
};

export const addRegulationMode = (
    regulationModes: string
): {
    type: string;
    payload: string;
} => {
    return {
        type: ADD_REGULATION_MODE,
        payload: regulationModes,
    };
};

export const clearRegulationModes = (): {
    type: string;
    payload: [];
} => {
    return {
        type: CLEAR_REGULATION_MODES,
        payload: [],
    };
};

export function initRegulationModes(
    regulationsModes: string[]
): { type: string; payload: string[] } {
    return {
        type: INIT_REGULATION_MODES,
        payload: regulationsModes,
    };
}
