import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { addFavorite, cleanFavorites } from "@store/actions";

import PageTitle from "@components/Common/PageTitle";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import { default as Breadcrumb } from "@components/Common/GlobalBreadcrumb";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import MainChart from "./MainChart";
import StatisticsCharts from "./StatisticsCharts";
import Orders from "./Orders";

import "./ApexChartDnoutTypeText.css";
import "@assets/swal.css";
import "@assets/theme.css";

export default function Dashboard() {
    const { t } = useTranslation();

    const selections = useSelector((state: any) => state.selections);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (selections.designation === "my_favorites") {
            dispatch(cleanFavorites());

            selections.selection?.forEach((item: any) => {
                item.shops_id?.forEach((shop_id: any) => {
                    dispatch(
                        addFavorite({
                            franchise_id: item.franchise_id,
                            shop_id,
                        })
                    );
                });
            });
        }
    }, [dispatch, selections]);

    return (
        <React.Fragment>
            <PageTitle title={t("Dashboard")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Dashboard")}
                    pageTitle={t("Dashboard")}
                />
                <div className="grid-container">
                    <div className="d-grid__clz">
                        <React.Suspense fallback={<SpinnerWrapper />}>
                            <CustomErrorBoundary>
                                <MainChart />
                            </CustomErrorBoundary>
                        </React.Suspense>
                    </div>
                    <div className="grid-order grid-xs-order mb-2 mb-sm-0">
                        <React.Suspense fallback={<SpinnerWrapper />}>
                            <CustomErrorBoundary>
                                <Orders />
                            </CustomErrorBoundary>
                        </React.Suspense>
                    </div>
                </div>
                <StatisticsCharts />
            </div>
        </React.Fragment>
    );
}
