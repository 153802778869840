import React from "react";
import { useTranslation } from "react-i18next";

import PageTitle from "@components/Common/PageTitle";

import Breadcrumb from "@components/Common/GlobalBreadcrumb";

import Cards from "./Cards";
import Top10OffersAndSalesRow from "./Top10OffersAndSalesRow";
import SalesModeRow from "./SalesModeRow";
import SalesSupportRow from "./SalesSupportRow";
import ServicesRow from "./ServicesRow";
import Top10FamiliesRow from "./Top10FamiliesRow";
import Top10ProductsRow from "./Top10ProductsRow";

export default function AdditionalOffersAndSales() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PageTitle title={t("Add. Offers and sale")} />

            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Add. Offers and sale")}
                />
                <Cards />
                <Top10OffersAndSalesRow />
                <SalesModeRow />
                <SalesSupportRow />
                <ServicesRow />
                <Top10FamiliesRow />
                <Top10ProductsRow />
            </div>
        </React.Fragment>
    );
}
