import React from "react";

export default function PortalLogoSvgIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={415.001}
            height={112}
            fill="#ffff"
            {...props}
        >
            <g data-name="Groupe 9896">
                <g data-name="Groupe 9809">
                    <g data-name="Groupe 9781">
                        <path
                            data-name="Trac\xE9 9359"
                            d="M0 84.001v-60h44.49v10.148H12.115v13.3H42.24v10.112H12.115v16.332h33.522v10.108z"
                        />
                        <path
                            data-name="Trac\xE9 9360"
                            d="M69.414 84.001V34.149h-17.8V24.001h47.68v10.148H81.532v49.852z"
                        />
                        <path
                            data-name="Trac\xE9 9361"
                            d="M107.274 84.001v-60h12.115v26.644l24.475-26.644h16.289l-22.592 23.37 23.82 36.628h-15.676l-16.494-28.158-9.822 10.029v18.131z"
                        />
                    </g>
                    <path
                        data-name="Rectangle 4460"
                        d="M174.047 22.807h2.253v60.822h-2.253z"
                    />
                    <text
                        transform="translate(188.001 84)"
                        fontSize={80}
                        fontFamily="Poppins-Light, Poppins"
                        fontWeight={300}
                    >
                        <tspan x={0} y={0}>
                            {"Portal"}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}
