import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { useSnapshot } from "valtio";
import {
    AddIcon,
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
} from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import store, { setGroupedFamilies } from "./store";

import { useResource } from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import { NoImagePlaceholderIcon } from "../components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";

import "@assets/widths.css";

export default function SecondStep({ _, go }: any) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const { groupedFamilies, groupName } = useSnapshot(store);

    const [families, setFamilies] = React.useState<any[]>([]);
    const [filteredFamilies, setFilteredFamilies] = React.useState<any[]>([]);
    const [selectedFamilies, setSelectedFamilies] = React.useState<any[]>([]);

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/families/families-list`
    );

    React.useEffect(() => {
        let localFamilies: any[] = resourceData.data!.filter((family: any) => {
            return !groupedFamilies
                .map((familyGroup: any) => familyGroup.designation)
                .includes(family.designation);
        });
        setFamilies(localFamilies);
        setFilteredFamilies(localFamilies);
    }, [resourceData, groupedFamilies]);

    const handleAddingFamilyButtonOnClickEvent = React.useCallback(
        (family: any) => {
            let localSelectedFamilies: any[] = [...selectedFamilies, family];
            setSelectedFamilies(localSelectedFamilies);
            let localFamilies: any[] = (filteredFamilies.length > 0
                ? filteredFamilies
                : families
            ).filter((family) => {
                return !localSelectedFamilies
                    .map((selectedFamily): string =>
                        `${selectedFamily.designation}|${selectedFamily.franchise_schema}|${selectedFamily.shop_id}`.toLowerCase()
                    )
                    .includes(
                        `${family.designation}|${family.franchise_schema}|${family.shop_id}`.toLowerCase()
                    );
            });
            setFilteredFamilies(localFamilies);
        },
        [selectedFamilies, filteredFamilies, families]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Family"),
                accessor: "designation",
            },
            {
                Header: t("Picture"),
                accessor: function (family: any) {
                    return (
                        <div>
                            {family.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                family.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (family: any) {
                    return (
                        <div>
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleAddingFamilyButtonOnClickEvent(family)
                                }
                            >
                                {t("Add")}
                            </StyledButton>
                            <StyledIconButton
                                icon="PlusIcon"
                                className="d-block d-sm-block d-md-none m-0"
                                onClick={() =>
                                    handleAddingFamilyButtonOnClickEvent(family)
                                }
                            >
                                <AddIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingFamilyButtonOnClickEvent, mainColor]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredFamilies,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    function handleMultipleAddingFamiliesButtonOnClickEvent() {
        let localSelectedFamilies: any[] = [
            ...selectedFamilies,
            ...selectedFlatRows.map((row: any) => row.original),
        ];
        setSelectedFamilies(localSelectedFamilies);
        let localFamilies: any[] = filteredFamilies.filter((family) => {
            return !localSelectedFamilies
                .map((selectedFamily) =>
                    `${selectedFamily.designation}|${selectedFamily.franchise_schema}|${selectedFamily.shop_id}`.toLowerCase()
                )
                .includes(
                    `${family.designation}|${family.franchise_schema}|${family.shop_id}`.toLowerCase()
                );
        });
        setFilteredFamilies(localFamilies);
    }

    function handleSelectionListButtonOnClickEvent() {
        setGroupedFamilies([...groupedFamilies, ...selectedFamilies]);
        go(3);
    }

    React.useEffect(() => {
        setPageSize(5);
    }, [setPageSize]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div className="d-md-flex justify-content-md-between">
                        <div className="d-md-flex align-items-md-center my-2">
                            <span className="mb-0 ml-2 mr-0 h5 white-space-nowrap">
                                {groupName}
                            </span>
                            <span
                                className="divider mt-0 mx-4 d-none d-md-block"
                                style={{
                                    backgroundColor: "#808080a6",
                                    height: "2.25rem",
                                    width: "2px",
                                }}
                            />
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                            />
                        </div>
                        <div className="d-md-flex mt-2 mx-2 mb-2">
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                disabled={selectedFlatRows.length === 0}
                                onClick={
                                    handleMultipleAddingFamiliesButtonOnClickEvent
                                }
                            >
                                {t("Add families")}
                            </StyledButton>
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                disabled={
                                    selectedFamilies.length +
                                        groupedFamilies.length ===
                                    0
                                }
                                onClick={handleSelectionListButtonOnClickEvent}
                            >
                                {`${t("My selection")} (${
                                    selectedFamilies.length +
                                    groupedFamilies.length
                                })`}
                            </StyledButton>
                        </div>
                    </div>
                    {error ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {filteredFamilies.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </CustomErrorBoundary>
                    )}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
