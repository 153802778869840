import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getPhysicalRegulations,
    getNumericRegulations,
} from "@helpers/general";
import getRegulationsDataHelper from "@pages/Dashboard/StatisticsCharts/RegulationsRow/getRegulationDataHelper";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function RegulationsCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const regulationType = useSelector(
        (state: RegulationTypeType) => state.regulationType
    );

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: regulationData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/regulations/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        const { amounts, labels } = getRegulationsDataHelper(
            regulationType === t("All regulation methods")
                ? regulationData.data
                : regulationType === t("Physical Payment Method")
                ? getPhysicalRegulations(regulationData.data)
                : getNumericRegulations(regulationData.data)
        );

        setData(amounts);
        setLabels(labels);
        setPercentageData!(amounts);
    }, [regulationType, regulationData, t, setPercentageData]);

    const donutChartRegulationsData: SeriesDataType = {
        data: data,
        labels:
            labels.length > 0
                ? labels.map((label) => t(label))
                : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Payment types")}
                        data={donutChartRegulationsData}
                    >
                        <LegendPopoverWrapper
                            title={t("Payment types")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
