import React from "react";

import { TitleWrapper } from "@pages/Statistics/Shops/TopContentWrapper/FiltersWrapper/TitleWrapper";
import { MainContentWrapper } from "@pages/Statistics/Shops/TopContentWrapper/FiltersWrapper/MainContentWrapper";

export function FiltersWrapper() {
    return (
        <div
            className="d-xl-flex align-items-center px-4 py-1"
            style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #F2F2F2",
            }}
        >
            <div className="d-md-flex align-items-center">
                <TitleWrapper />
                <MainContentWrapper />
            </div>
        </div>
    );
}
