import React from "react";
import { useTranslation } from "react-i18next";

import PageTitle from "@components/Common/PageTitle";
import { GlobalBreadcrumb } from "./GlobalBreadcrumb";
import { MyBestShopRow } from "./MyBestShopRow";
import { MyClientsCardWrapper } from "./MyClientsCardWrapper";

export function Clients() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PageTitle title={t("Customers")} />
            <div className="page-content">
                <GlobalBreadcrumb />
                <MyBestShopRow />
                <MyClientsCardWrapper />
            </div>
        </React.Fragment>
    );
}
