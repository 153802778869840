import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, CardBody } from "reactstrap";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "@vStore";

import { formatCurrency } from "@helpers/general";

import {
    TurnoverSvgIcon,
    OnTheSpotSvgIcon,
    ToTakeSvgIcon,
    DriveSvgIcon,
    KioskSvgIcon,
    BoxSvgIcon,
    ClickAndCollectSvgIcon,
    WebsiteSvgIcon,
    DeliverySvgIcon,
} from "@components/Common/SvgIcons";

type PropsType = { shop: MyBestShopType };

export function CustomCol({ shop }: PropsType) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);

    return (
        <Col key={shop.designation} md="3" className="px-0">
            <Card
                className={classNames(
                    "card-block__clz  my-best-shop-card__clz mr-2",
                    {
                        "dark-bg__clz": mainColor === null,
                    }
                )}
                style={{ background: mainColor }}
            >
                <CardBody
                    className="text-white d-grid__clz py-2 px-3 mb-3"
                    style={{
                        gridTemplateColumns: "0.2fr 0.8fr",
                        gap: "20px",
                    }}
                >
                    <div className="d-flex justify-content-center align-items-center">
                        {shop.designation === "Turnover" ? (
                            <TurnoverSvgIcon />
                        ) : null}
                        {shop.designation === "On the spot" ? (
                            <OnTheSpotSvgIcon />
                        ) : null}
                        {shop.designation === "To take" ? (
                            <ToTakeSvgIcon />
                        ) : null}
                        {shop.designation === "Drive" ? <DriveSvgIcon /> : null}
                        {shop.designation === "Thick headed" ? (
                            <KioskSvgIcon />
                        ) : null}
                        {shop.designation === "Box" ? <BoxSvgIcon /> : null}
                        {shop.designation === "Application" ? (
                            <ClickAndCollectSvgIcon />
                        ) : null}
                        {shop.designation === "Web" ? <WebsiteSvgIcon /> : null}
                        {shop.designation === "Delivery" ? (
                            <DeliverySvgIcon />
                        ) : null}
                    </div>
                    <div className="d-grid__clz">
                        <span
                            style={{
                                font: "normal normal bold 17px/22px Poppins",
                                letterSpacing: "0px",
                            }}
                        >
                            {t(shop.designation)}
                        </span>
                        <table
                            style={{
                                font: "normal normal  13px/18px Poppins",
                            }}
                        >
                            <tr>
                                <td>{t("Incl-tax")}</td>
                                <td align="right">
                                    {formatCurrency(shop.inclTax)}
                                </td>
                            </tr>
                            <tr>
                                <td>PM</td>
                                <td align="right">
                                    {formatCurrency(shop.avgBasket)}
                                </td>
                            </tr>
                            <tr>
                                <td>CMD</td>
                                <td align="right">{shop.tickets}</td>
                            </tr>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}
