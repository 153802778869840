import React from "react";

export default function DigitalPaymentMethodSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8769">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="credit-card (2)" fill="#fff">
                    <path
                        data-name="Trac\xE9 8558"
                        d="M39.6 25.443l-2.4-13.625a3.413 3.413 0 00-3.949-2.765L12.64 12.685a3.413 3.413 0 00-2.766 3.949l.177 1A3.414 3.414 0 008 20.762v13.5a3.413 3.413 0 003.409 3.409h20.455a3.413 3.413 0 003.409-3.409v-4.59l1.559-.275a3.409 3.409 0 002.768-3.954zm-2.088 1.481a1.54 1.54 0 01-1 .637l-1.236.218v-7.017a3.413 3.413 0 00-3.409-3.409H11.889l-.184-1.041a1.55 1.55 0 011.257-1.8l20.606-3.633a1.549 1.549 0 011.8 1.257l2.4 13.625a1.54 1.54 0 01-.259 1.162zm-5.645 8.883H11.409a1.551 1.551 0 01-1.55-1.55v-6.609h23.555v6.609a1.551 1.551 0 01-1.55 1.55zM11.409 19.212h20.455a1.551 1.551 0 011.55 1.55v.809H9.86v-.809a1.551 1.551 0 011.549-1.55zm22 4.218v2.359H9.86v-2.355z"
                    />
                    <path
                        data-name="Trac\xE9 8559"
                        d="M16.948 32.068H12.73a.93.93 0 100 1.86h4.218a.93.93 0 100-1.86z"
                    />
                </g>
            </g>
        </svg>
    );
}
