import React from "react";
import { Button, ModalFooter, ModalBody, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { uid } from "react-uid";
import moment from "moment";

import { useResource } from "@hooks/useResource";

import { formatCurrency, capitalize } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";

import "./ModalContent.css";

type ModalContentType = {
    order: OrderType;
    toggleOrderModal: () => void;
};

export default function ModalContent({
    order,
    toggleOrderModal,
}: ModalContentType) {
    const { t } = useTranslation();

    const [data, setData] = React.useState<any>({});

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/transactions/order-details?${
            order.franchise ? `&franchise=${order.franchise}` : ""
        }${order.id ? `&command_id=${order.id}` : ""}${
            order.shop_id ? `&shop_id=${order.shop_id}` : ""
        }`
    );

    React.useEffect(() => {
        if (resourceData.data) {
            setData(resourceData.data);
        }
    }, [resourceData]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <div key={uid(order)}>
                    <div className="modal-header border-0">
                        <div className="d-flex w-100 justify-content-center">
                            <h4 className="modal-title" id="exampleModalLabel">
                                <strong> {t("Order details")}</strong>
                            </h4>
                        </div>

                        <Button
                            onClick={toggleOrderModal}
                            className="text-light"
                            color="danger"
                        >
                            <span aria-hidden="true">&times;</span>
                        </Button>
                    </div>
                    <div className="scroll-modal__clz">
                        <ModalBody>
                            <div className="custom-modal-body__clz">
                                <Card className="card wish-list border py-2 px-1">
                                    <CardBody className="p-0">
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div className="py-2 px-1">
                                                <h6>
                                                    <strong>
                                                        {t("Order number")}:{" "}
                                                    </strong>{" "}
                                                    {order.order_number}
                                                </h6>
                                                <h6>
                                                    <strong>
                                                        {t("Shop")}:{" "}
                                                    </strong>{" "}
                                                    {capitalize(
                                                        order.shop_name
                                                    )}
                                                </h6>
                                                <h6>
                                                    <strong>
                                                        {t("Creation date")}:{" "}
                                                    </strong>{" "}
                                                    {moment(
                                                        order.creation_date
                                                    ).format(
                                                        "hh:mm - DD/MM/YYYY"
                                                    )}
                                                </h6>
                                                <h6>
                                                    <strong>
                                                        {t("Withdrawal date")}:{" "}
                                                    </strong>{" "}
                                                    {data.retirementDate
                                                        ? moment(
                                                              data.retirementDate
                                                          ).format(
                                                              "hh:mm - DD/MM/YYYY"
                                                          )
                                                        : ""}
                                                </h6>
                                                <h6>
                                                    <strong>
                                                        {t("Withdrawal")}:
                                                    </strong>{" "}
                                                    {t(
                                                        capitalize(
                                                            order.sale_mode
                                                        )
                                                    )}
                                                </h6>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            {order.is_detail_exist ? (
                                data.articles?.map((article: any) => (
                                    <div
                                        key={uid(article)}
                                        className="custom-modal-body__clz"
                                    >
                                        <Card className="wish-list border py-3 px-2">
                                            <CardBody className="p-0">
                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                    <div>
                                                        <h6 className="mb-2 font-weight-bold">
                                                            {article.quantity}X{" "}
                                                            {article.product}
                                                        </h6>
                                                    </div>
                                                    <h6 className="mb-2 font-weight-bold">
                                                        {formatCurrency(
                                                            Number(
                                                                article.price
                                                            )
                                                        )}
                                                    </h6>
                                                </div>
                                            </CardBody>
                                            {article.components.map(
                                                (component: any) => (
                                                    <CardBody
                                                        key={uid(component)}
                                                        className="p-0"
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <div>
                                                                <h6 className="mb-1">
                                                                    {
                                                                        component.quantity
                                                                    }
                                                                    X{" "}
                                                                    {
                                                                        component.name
                                                                    }
                                                                </h6>
                                                            </div>
                                                            <h6 className="mb-1">
                                                                {formatCurrency(
                                                                    Number(
                                                                        component.price
                                                                    )
                                                                )}
                                                            </h6>
                                                        </div>
                                                    </CardBody>
                                                )
                                            )}

                                            {article.remark ? (
                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                    <div>
                                                        <span
                                                            style={{
                                                                color:
                                                                    "#B1B1B1",
                                                            }}
                                                        >
                                                            {t("remark")} :
                                                            {article.remark}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </Card>
                                    </div>
                                ))
                            ) : (
                                <Card className="wish-list border py-3 px-2">
                                    <CardBody className="p-0">
                                        <div className=" d-flex  justify-content-center">
                                            <h5 className="">
                                                {t("No data available")}
                                            </h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </ModalBody>
                        {order.is_detail_exist ? (
                            <ModalFooter>
                                {data.subTotal ? (
                                    <Card className="wish-list border w-100 py-2 px-1 mt-2">
                                        <CardBody className="p-0">
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <div>
                                                    <h6 className="mb-1  font-weight-bold">
                                                        {t("Sub-total")}
                                                    </h6>
                                                </div>
                                                <h6 className="mb-1">
                                                    {formatCurrency(
                                                        Number(data.subTotal)
                                                    )}
                                                </h6>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ) : null}
                                {data.shippingCost ? (
                                    <Card className="wish-list border w-100 py-2 px-1 mt-2">
                                        <CardBody className="p-0">
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <div>
                                                    <h6 className="mb-1  font-weight-bold">
                                                        {t("Shipping cost")}
                                                    </h6>
                                                </div>
                                                <h6 className="font-weight-bold mb-1">
                                                    {formatCurrency(
                                                        Number(
                                                            data.shippingCost
                                                        )
                                                    )}
                                                </h6>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ) : null}
                                <Card className="wish-list border w-100 py-2 px-1 mt-2">
                                    <CardBody className="p-0 py-2 px-1">
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <div>
                                                    <h6 className="mb-1  font-weight-bold">
                                                        {t("Total")}
                                                    </h6>
                                                </div>
                                                <h6 className="font-weight-bold mb-1">
                                                    {formatCurrency(
                                                        Number(data.total)
                                                    )}
                                                </h6>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </ModalFooter>
                        ) : null}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
