import React from "react";
import { useSnapshot } from "valtio";

import store, { setAvgBasketRange } from "../../store";

import { formatCurrency } from "@helpers/general";

import { StyledRange } from "./StyledRange";

export default function ReactSliderWrapper() {
    const advancedStore = useSnapshot(store);

    const [defaultValue] = React.useState([0, advancedStore.maxAvgBasket]);

    return (
        <StyledRange
            defaultValue={
                advancedStore.avgBasketRange.length > 0
                    ? advancedStore.avgBasketRange
                    : defaultValue
            }
            min={0}
            max={advancedStore.maxAvgBasket}
            tipFormatter={(value: number) => formatCurrency(value, 0)}
            onChange={(range: number[]) => {
                setAvgBasketRange(range);
            }}
        />
    );
}
