import React from "react";
import { Row, Col } from "reactstrap";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import MiniCard from "@components/Common/MiniCard";

import "../SalesSupport.css";

export default function Cards() {
    const statisticsStore = useSnapshot(store);

    return (
        <React.Fragment>
            <Row>
                {statisticsStore.salesSupportCards.modes?.map(
                    (title: string, index: number) => {
                        return (
                            <Col key={`${title}_${index}`}>
                                <MiniCard
                                    title={title}
                                    amount={
                                        statisticsStore.salesSupportCards
                                            .amounts[index] || 0
                                    }
                                />
                            </Col>
                        );
                    }
                )}
            </Row>
        </React.Fragment>
    );
}
