import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import i18n from "i18next";

import { changeType } from "@store/dashboard/actions";

import GlobalBreadcrumb from "@components/Common/GlobalBreadcrumb";
import PageTitle from "@components/Common/PageTitle";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import StatisticsChart from "../StatisticsChart";
import SalesSupportRow from "./SalesSupportRow";
import RegulationsRow from "./RegulationsRow";
import SalesModeRow from "./SalesModeRow";
import ServicesRow from "./ServicesRow";
import Top10Employees from "./Top10EmployeesRow";
import Top10ArticlesRow from "./Top10ArticlesRow";
import Top10FamiliesRow from "./Top10FamiliesRow";

import "@assets/grid.css";

export default function StatisticsTurnover() {
    const { t } = useTranslation();
    const type = useSelector((state: any) => state.type);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeType(t("Incl-tax")));
    }, [t, dispatch]);

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Turnover")} />
            <div className="page-content">
                <GlobalBreadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Turnover")}
                />
                <CustomErrorBoundary>
                    <StatisticsChart
                        title={t("Turnover")}
                        iconClass="bx-euro"
                    />
                </CustomErrorBoundary>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesModeRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesSupportRow />
                </React.Suspense>

                {type === t("Incl-tax") && (
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <RegulationsRow />
                    </React.Suspense>
                )}

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <ServicesRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10FamiliesRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10ArticlesRow />
                </React.Suspense>

                {type === t("Incl-tax") && (
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <Top10Employees />
                    </React.Suspense>
                )}
            </div>
        </React.Fragment>
    );
}
