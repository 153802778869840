import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useResource } from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import CustomSelectFilter from "@pages/Statistics/NewProducts/CustomSelectFilter";
import { DownloadDropdown } from "@pages/Statistics/NewProducts/DownloadDropdown";
import ErrorPage from "@components/Common/ErrorPage";
import TitleRow from "@components/Common/TitleRow";

export function TitleRowWrapper() {
    const { t } = useTranslation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    const [subFamilies, setSubFamilies] = React.useState<string[]>([
        t("Top 10 sub-families"),
    ]);

    const {
        resourceData: { data: statisticsSubFamiliesList },
        error: statisticsSubFamiliesListError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/sub-families-list?${getAdvancedPeriodStringParam(
            period,
            advancedDate
        )}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${type === t("Average turnover") ? "" : `&type=${getTypeParam(type)}`}`
    );

    React.useEffect(() => {
        setSubFamilies([
            t("Top 10 sub-families"),
            ...statisticsSubFamiliesList,
        ]);
    }, [statisticsSubFamiliesList, t]);

    return (
        <TitleRow title={t("Articles per top 10 sub-families")}>
            {statisticsSubFamiliesListError ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <CustomSelectFilter options={subFamilies} />
                </CustomErrorBoundary>
            )}
            <DownloadDropdown />
        </TitleRow>
    );
}
