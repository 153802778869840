import React from "react";

export const MyBestShopSvgIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={63.85}
        height={54.851}
        viewBox="0 0 342 342"
        {...props}
    >
        <defs>
            <filter
                id="Rectangle_13563"
                x={0}
                y={0}
                width={342}
                height={342}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.161} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g
            id="Groupe_1025"
            data-name="Groupe 1025"
            transform="translate(-5727 6583)"
        >
            <g
                transform="matrix(1, 0, 0, 1, 5727, -6583)"
                filter="url(#Rectangle_13563)"
            >
                <rect
                    id="Rectangle_13563-2"
                    data-name="Rectangle 13563"
                    width={324}
                    height={324}
                    rx={50}
                    transform="translate(9 6)"
                    fill="#f8f8f6"
                />
            </g>
            <g
                id="Groupe_1007"
                data-name="Groupe 1007"
                transform="translate(5811.878 -6537.926)"
            >
                <g id="trophy" transform="translate(3.508 29.307)">
                    <path
                        id="Trac\xE9_710"
                        data-name="Trac\xE9 710"
                        d="M231.738,322.74H191.576a5.486,5.486,0,0,1-.512-10.947c2.214-.208,3.751-4.836,4.45-9.1a84.055,84.055,0,0,0,.475-18.1,5.486,5.486,0,0,1,6.713-5.736,41.479,41.479,0,0,0,17.916-.021,5.485,5.485,0,0,1,6.733,5.7c-1.083,16.442,1.705,26.949,4.91,27.255a5.485,5.485,0,0,1-.522,10.946Z"
                        transform="translate(-129.044 -176.775)"
                        fill="#ffb500"
                    />
                    <path
                        id="Trac\xE9_711"
                        data-name="Trac\xE9 711"
                        d="M276.655,311.794c-3.2-.306-5.993-10.813-4.91-27.255a5.485,5.485,0,0,0-6.733-5.7,39.279,39.279,0,0,1-8.931.989V322.74h20.052a5.485,5.485,0,0,0,.521-10.946Z"
                        transform="translate(-173.439 -176.775)"
                        fill="#ff7403"
                    />
                    <path
                        id="Trac\xE9_712"
                        data-name="Trac\xE9 712"
                        d="M79.838,129.923a5.463,5.463,0,0,1-3.216-1.045L61.514,117.92A76.387,76.387,0,0,1,30.1,56.247v-.494a5.486,5.486,0,0,1,5.485-5.485H64.035a5.485,5.485,0,1,1,0,10.971H41.259a65.39,65.39,0,0,0,26.7,47.8L83.064,120a5.486,5.486,0,0,1-3.225,9.926Z"
                        transform="translate(-30.096 -31.884)"
                        fill="#ffc12e"
                    />
                    <path
                        id="Trac\xE9_713"
                        data-name="Trac\xE9 713"
                        d="M336.138,129.923a5.486,5.486,0,0,1-3.221-9.929l15.14-10.961a65.381,65.381,0,0,0,26.74-47.8H351.614a5.485,5.485,0,1,1,0-10.971h28.864a5.486,5.486,0,0,1,5.486,5.485v.455A76.377,76.377,0,0,1,354.49,117.92l-15.14,10.961A5.461,5.461,0,0,1,336.138,129.923Z"
                        transform="translate(-220.737 -31.884)"
                        fill="#ff7403"
                    />
                    <path
                        id="Trac\xE9_714"
                        data-name="Trac\xE9 714"
                        d="M153.156,123.538c-20.807,0-35.709-11.048-44.294-32.836-6.833-17.342-8.409-38.316-9.56-53.631-.47-6.246-1.039-13.821-1.879-16.959A5.488,5.488,0,0,1,93.928,15h118.54a5.488,5.488,0,0,1-3.82,5.228c-.931,3.213-1.541,11.2-2.044,17.785-1.164,15.24-2.757,36.112-9.541,53.21C188.557,112.665,173.784,123.538,153.156,123.538Z"
                        transform="translate(-70.585 -9.514)"
                        fill="#ffe645"
                    />
                    <path
                        id="Trac\xE9_715"
                        data-name="Trac\xE9 715"
                        d="M256.082,123.537c20.591-.025,35.34-10.9,43.837-32.316,6.783-17.1,8.377-37.97,9.541-53.21.5-6.581,1.113-14.572,2.044-17.785A5.487,5.487,0,0,0,315.323,15H256.082Z"
                        transform="translate(-173.439 -9.513)"
                        fill="#ffc12e"
                    />
                    <path
                        id="Trac\xE9_716"
                        data-name="Trac\xE9 716"
                        d="M201.825,10.971H80.212A5.485,5.485,0,1,1,80.212,0H201.825a5.485,5.485,0,0,1,0,10.971Z"
                        transform="translate(-58.406)"
                        fill="#ffc12e"
                    />
                    <path
                        id="Trac\xE9_717"
                        data-name="Trac\xE9 717"
                        d="M316.859,0H256.082V10.971h60.777a5.485,5.485,0,1,0,0-10.971Z"
                        transform="translate(-173.439)"
                        fill="#ff9300"
                    />
                    <path
                        id="Trac\xE9_718"
                        data-name="Trac\xE9 718"
                        d="M225.332,399.373H167.726a5.486,5.486,0,0,1-5.485-5.485V381.939a12.814,12.814,0,0,1,12.8-12.8h42.978a12.814,12.814,0,0,1,12.8,12.8v11.948A5.486,5.486,0,0,1,225.332,399.373Z"
                        transform="translate(-113.916 -234.146)"
                        fill="#ffe645"
                    />
                    <path
                        id="Trac\xE9_719"
                        data-name="Trac\xE9 719"
                        d="M277.542,369.14h-21.46v30.233h28.774a5.486,5.486,0,0,0,5.485-5.485V381.939A12.814,12.814,0,0,0,277.542,369.14Z"
                        transform="translate(-173.439 -234.146)"
                        fill="#ffc12e"
                    />
                    <path
                        id="Trac\xE9_720"
                        data-name="Trac\xE9 720"
                        d="M212.816,454.917H121.129a5.486,5.486,0,0,1-5.485-5.486V427.49A5.486,5.486,0,0,1,121.129,422h91.687a5.486,5.486,0,0,1,5.485,5.486v21.942A5.485,5.485,0,0,1,212.816,454.917Z"
                        transform="translate(-84.359 -267.678)"
                        fill="#ff3d4a"
                    />
                    <path
                        id="Trac\xE9_721"
                        data-name="Trac\xE9 721"
                        d="M301.9,422H256.082v32.913H301.9a5.486,5.486,0,0,0,5.485-5.486V427.489A5.486,5.486,0,0,0,301.9,422Z"
                        transform="translate(-173.439 -267.678)"
                        fill="#c20020"
                    />
                </g>
                <text
                    id="_1"
                    data-name={1}
                    transform="translate(67.395 105.056)"
                    fill="#ff3d4a"
                    fontSize={60}
                    fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                    fontWeight={700}
                >
                    <tspan x={0} y={0}>
                        {"1"}
                    </tspan>
                </text>
            </g>
        </g>
    </svg>
);
