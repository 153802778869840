import i18next from "i18next";

import { CHANGE_TYPE, CLEAR_TYPE, INIT_INCL_TAX_TYPE } from "./actionsType";

const INIT_STATE: InitStateType | null = i18next.t("Turnover incl-tax") || null;

export default function changeType(
    state = INIT_STATE,
    action: changeTypeActionType
): string | null {
    switch (action.type) {
        case CHANGE_TYPE:
            return action.payload;
        case CLEAR_TYPE:
            return action.payload !== undefined ? action.payload : null;
        case INIT_INCL_TAX_TYPE:
            return action.payload;
        default:
            return state !== undefined ? state : null;
    }
}
