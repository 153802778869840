import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownToggle, Dropdown } from "reactstrap";

import getItemIcon from "@helpers/getIconItem";

import MiniCard from "./MiniCard";
import CustomDropdown from "@components/Common/SelectWrapper/Dropdown";
import ChevronDown from "@components/Common/SelectWrapper/ChevronDown";
import { ArrowDownSvgIcon } from "@components/Common/SvgIcons";

import {
    shop,
    clickCollect,
    digitalPaymentMethod,
    physicalPaymentMethod,
    platform,
    service,
    salesMode,
} from "./data";

export default function DropdownWrapper({ options }: any) {
    const { t } = useTranslation();
    const [isDropdownOpened, setIsDropdownOpened] = React.useState<boolean>(
        false
    );
    const labelsArray: typeof options = options.map(
        (option: string | number) => option
    );

    const [selectedValue] = React.useState(labelsArray[0]);
    const [
        isPlatformDropdownSelected,
        setIsPlatformDropdownSelected,
    ] = React.useState(false);
    const [isShopDropDownSelected, setIsShopDropDownSelected] = React.useState(
        false
    );
    const [
        isClickCollectDropDownSelected,
        setIsClickCollectDropDownSelected,
    ] = React.useState(false);
    const [
        isPhysicalPaymentMethodmDropDownSelected,
        setIsPhysicalPaymentMethodmDropDownSelected,
    ] = React.useState(false);
    const [
        isDigitalPaymentMethodDropDownSelected,
        setIsDigitalPaymentMethodDropDownSelected,
    ] = React.useState(false);

    function toggleOpen() {
        setIsDropdownOpened(!isDropdownOpened);
    }

    function checkTablesIntersection(firstFable: any[], secondTable: any[]) {
        let intersection = false;
        const filteredArray = firstFable.filter((value: any) =>
            secondTable.includes(value)
        );
        if (filteredArray.length > 0) {
            return true;
        }
        return intersection;
    }

    function getTablesIntersection(firstTable: any[], secondTable: any[]) {
        return firstTable.filter((value: any) => secondTable.includes(value));
    }

    function getSelectedMenuStyle(selected: boolean) {
        return {
            backgroundColor: selected ? "rgb(200, 204, 208)" : "",
            width: selected ? "325px" : "",
            marginBottom: selected ? "5px" : "",
            paddingBottom: selected ? "1px" : "",
            borderRadius: selected ? "10px" : "",
        };
    }

    return (
        <CustomDropdown
            isOpen={isDropdownOpened}
            onClose={toggleOpen}
            target={
                <div onClick={toggleOpen}>
                    <span className="pr-4">
                        {selectedValue
                            ? `${selectedValue}`
                            : t("Select a State")}
                    </span>
                    <ChevronDown />
                </div>
            }
        >
            <Dropdown isOpen={isDropdownOpened} toggle={toggleOpen}>
                <DropdownToggle
                    style={{
                        width: "330px",
                        height: "auto",
                    }}
                    className="bg-transparent p-1 mr-1 border-0 width-sm"
                >
                    <MiniCard
                        itemIcon={getItemIcon(labelsArray[0])}
                        title={t(labelsArray[0])}
                    />

                    {checkTablesIntersection(labelsArray, shop) && (
                        <div
                            onMouseOver={() => setIsShopDropDownSelected(true)}
                            onMouseLeave={() =>
                                setIsShopDropDownSelected(false)
                            }
                            style={getSelectedMenuStyle(isShopDropDownSelected)}
                        >
                            <MiniCard
                                color={isShopDropDownSelected}
                                itemIcon={
                                    !isShopDropDownSelected ? (
                                        getItemIcon(t("Shop"))
                                    ) : (
                                        <ArrowDownSvgIcon />
                                    )
                                }
                                title={t("Shop")}
                            />
                            {isShopDropDownSelected &&
                                getTablesIntersection(labelsArray, shop).map(
                                    (item: any) => (
                                        <div key={`${item}_mini-card`}>
                                            <MiniCard
                                                margin={true}
                                                itemIcon={getItemIcon(item)}
                                                title={t(item)}
                                            />
                                        </div>
                                    )
                                )}
                        </div>
                    )}

                    {checkTablesIntersection(labelsArray, clickCollect) && (
                        <div
                            onMouseLeave={() =>
                                setIsClickCollectDropDownSelected(false)
                            }
                            onMouseOver={() =>
                                setIsClickCollectDropDownSelected(true)
                            }
                            style={getSelectedMenuStyle(
                                isClickCollectDropDownSelected
                            )}
                        >
                            <MiniCard
                                color={isClickCollectDropDownSelected}
                                itemIcon={
                                    !isClickCollectDropDownSelected ? (
                                        getItemIcon(t("Click & collect"))
                                    ) : (
                                        <ArrowDownSvgIcon />
                                    )
                                }
                                title={t("Click & collect")}
                            />
                            {isClickCollectDropDownSelected &&
                                getTablesIntersection(
                                    labelsArray,
                                    clickCollect
                                ).map((item: any) => (
                                    <div key={`${item}_mini-card`}>
                                        <MiniCard
                                            margin={true}
                                            itemIcon={getItemIcon(item)}
                                            title={t(item)}
                                        />
                                    </div>
                                ))}
                        </div>
                    )}

                    {checkTablesIntersection(labelsArray, platform) && (
                        <div
                            onMouseOver={() =>
                                setIsPlatformDropdownSelected(true)
                            }
                            onMouseLeave={() =>
                                setIsPlatformDropdownSelected(false)
                            }
                            style={getSelectedMenuStyle(
                                isPlatformDropdownSelected
                            )}
                        >
                            <MiniCard
                                color={isPlatformDropdownSelected}
                                itemIcon={
                                    !isPlatformDropdownSelected ? (
                                        getItemIcon(t("Platform"))
                                    ) : (
                                        <ArrowDownSvgIcon />
                                    )
                                }
                                title={t("Platform")}
                            />
                            {isPlatformDropdownSelected &&
                                getTablesIntersection(
                                    labelsArray,
                                    platform
                                ).map((item: any) => (
                                    <div key={`${item}_mini-card`}>
                                        <MiniCard
                                            margin={true}
                                            itemIcon={getItemIcon(item)}
                                            title={t(item)}
                                        />
                                    </div>
                                ))}
                        </div>
                    )}

                    {checkTablesIntersection(
                        labelsArray,
                        physicalPaymentMethod
                    ) && (
                        <div
                            onMouseOver={() =>
                                setIsPhysicalPaymentMethodmDropDownSelected(
                                    true
                                )
                            }
                            onMouseLeave={() =>
                                setIsPhysicalPaymentMethodmDropDownSelected(
                                    false
                                )
                            }
                            style={getSelectedMenuStyle(
                                isPhysicalPaymentMethodmDropDownSelected
                            )}
                        >
                            <MiniCard
                                padding={true}
                                color={isPhysicalPaymentMethodmDropDownSelected}
                                itemIcon={
                                    !isPhysicalPaymentMethodmDropDownSelected ? (
                                        getItemIcon(
                                            t("Physical Payment Method")
                                        )
                                    ) : (
                                        <ArrowDownSvgIcon />
                                    )
                                }
                                title={t("Physical Payment Method")}
                            />
                            {isPhysicalPaymentMethodmDropDownSelected &&
                                getTablesIntersection(
                                    labelsArray,
                                    physicalPaymentMethod
                                ).map((item: any) => (
                                    <div key={`${item}_mini-card`}>
                                        <MiniCard
                                            margin={true}
                                            itemIcon={getItemIcon(item)}
                                            title={t(item)}
                                        />
                                    </div>
                                ))}
                        </div>
                    )}

                    {checkTablesIntersection(
                        labelsArray,
                        digitalPaymentMethod
                    ) && (
                        <div
                            onMouseOver={() =>
                                setIsDigitalPaymentMethodDropDownSelected(true)
                            }
                            onMouseLeave={() =>
                                setIsDigitalPaymentMethodDropDownSelected(false)
                            }
                            style={getSelectedMenuStyle(
                                isDigitalPaymentMethodDropDownSelected
                            )}
                        >
                            <MiniCard
                                padding={true}
                                color={isDigitalPaymentMethodDropDownSelected}
                                itemIcon={
                                    !isDigitalPaymentMethodDropDownSelected ? (
                                        getItemIcon(t("Digital Payment Method"))
                                    ) : (
                                        <ArrowDownSvgIcon />
                                    )
                                }
                                title={t("Digital Payment Method")}
                            />
                            {isDigitalPaymentMethodDropDownSelected &&
                                getTablesIntersection(
                                    labelsArray,
                                    digitalPaymentMethod
                                ).map((item: any) => (
                                    <div key={`${item}_mini-card`}>
                                        <MiniCard
                                            margin={true}
                                            itemIcon={getItemIcon(item)}
                                            title={t(item)}
                                        />
                                    </div>
                                ))}
                        </div>
                    )}

                    {checkTablesIntersection(labelsArray, service) && (
                        <div>
                            {getTablesIntersection(labelsArray, service).map(
                                (item: any) => (
                                    <div key={`${item}_mini-card`}>
                                        <MiniCard
                                            itemIcon={getItemIcon(item)}
                                            title={t(item)}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    )}

                    {checkTablesIntersection(labelsArray, salesMode) && (
                        <div>
                            {getTablesIntersection(labelsArray, salesMode).map(
                                (item: any) => (
                                    <div key={`${item}_mini-card`}>
                                        <MiniCard
                                            itemIcon={getItemIcon(item)}
                                            title={t(item)}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </DropdownToggle>
            </Dropdown>
        </CustomDropdown>
    );
}
