import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { useSnapshot } from "valtio";

import { store, setSelectedGroups } from "@pages/Statistics/Shops/store";
import { fetchGroups } from "@pages/Statistics/Shops/store";

import { capitalize } from "@helpers/general";

type LocalOptionsType = {
    label: string | number;
    value: string | number;
};

type GroupedOptionsType = {
    options: LocalOptionsType[];
};

export function GroupedGroupsCustomSelect() {
    const { t } = useTranslation();

    const styles = {
        control: (provided: any) => ({
            ...provided,
            minWidth: 240,
            minHeight: 40,
            height: 40,
            margin: 1,
            borderRadius: "5px",
            padding: "0px",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            width: "100%",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }),
        option: (provided: any) => {
            return {
                ...provided,
                color: "#495057",
            };
        },
        menu: (provided: any) => {
            return {
                ...provided,
                zIndex: 99999999,
            };
        },
    };

    const { groups, selectedGroups } = useSnapshot(store);

    const [localOptions, setLocalOptions] = React.useState<any[]>([
        { label: t("All selections"), value: t("All selections") },
    ]);
    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        localOptions[0],
    ]);

    const isClearableProps =
        selectedValueState &&
        localOptions.filter((el: any) =>
            Object.values(selectedValueState).includes(el.label)
        ).length === 0;

    const [options, setOptions] = React.useState<SelectFilterType[]>([
        {
            options: [t("All selections")],
            label: t("All selections"),
        },
    ]);

    const [groupedOptions, setGroupedOptions] = React.useState<
        GroupedOptionsType[]
    >([{ options: [] }]);

    function handleSelectOnChangeEvent(selectedValue: any, actionType: any) {
        let allItemExistInSelectedValue: boolean =
            selectedValue?.find((element: LocalOptionsType) => {
                return element.value === localOptions[0].value;
            }) !== undefined;

        let selectedValueSize = selectedValue?.length;

        let localSelectedValue = [...selectedValueState];

        if (allItemExistInSelectedValue === true) {
            if ([t("All selections")].includes(actionType.option.label)) {
                localSelectedValue = [localOptions[0]];
            } else {
                let opts: string[] = [];
                options.forEach((element: any) => {
                    if (![t("All selections")].includes(element.label)) {
                        opts.push(...element.options);
                    }
                });

                opts = opts.filter(
                    (opt: string) => opt !== actionType.option.label
                );
                localSelectedValue =
                    opts.length === 0
                        ? [localOptions[0]]
                        : opts.map((option: string) => ({
                              label: option,
                              value: option,
                          }));
            }
        } else {
            if (selectedValueSize === 0) {
                localSelectedValue = [];
            } else {
                const opts = options
                    .map((option: any) => option.options)
                    .flat()
                    .filter(
                        (option: string) =>
                            !option.includes(t("All selections"))
                    )
                    .filter(
                        (option: string) =>
                            !option.includes(actionType.option.label)
                    )
                    .filter((option: string) =>
                        selectedValue
                            .map((value: any) => value.label)
                            .includes(option)
                    )
                    .map((option: string) => ({
                        label: option,
                        value: option,
                    }));

                localSelectedValue =
                    selectedValueSize !== opts.length ? selectedValue : opts;
            }
        }

        const selectedGroups: string[] = localSelectedValue.map(
            (el: any) => el.label
        );
        const [, ...remainingGroups] = groups;

        const filteredGroups = selectedGroups.includes(t("All selections"))
            ? [...remainingGroups]
            : remainingGroups.filter((group) => {
                  return selectedGroups.includes(group);
              });

        setSelectedValueState(localSelectedValue);
        setSelectedGroups(filteredGroups);
    }

    const MultiValueRemove = () => {
        return (
            <components.MultiValueRemove>
                {selectedValueState.length > 0 &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState)?.includes(el.label)
                ).length > 0
                    ? false
                    : undefined}
            </components.MultiValueRemove>
        );
    };

    const Option = ({ getValue, setValue, ...props }: any) => {
        const { selectedGroups } = useSnapshot(store);

        const [
            isCurrentOptionInSelectedGroups,
            setIsCurrentOptionInSelectedGroups,
        ] = React.useState(false);
        const [isInputChecked, setIsInputChecked] = React.useState(false);

        React.useEffect(() => {
            setIsCurrentOptionInSelectedGroups(
                selectedGroups
                    .filter((option) => !option.includes(t("All selections")))
                    .includes(props.data.label)
            );
        }, [selectedGroups, props.data.label]);

        React.useEffect(() => {
            setIsInputChecked(
                isCurrentOptionInSelectedGroups ||
                    [t("All selections")].includes(
                        props.selectProps.value[0]?.label
                    )
            );
        }, [isCurrentOptionInSelectedGroups, props.selectProps.value]);

        return (
            <components.Option {...props}>
                <input type="checkbox" checked={isInputChecked} />{" "}
                <label>{props.value}</label>
            </components.Option>
        );
    };

    const ValueContainer = ({
        children,
        getValue,
        hasValue,
        ...props
    }: any) => {
        const { selectedGroups } = useSnapshot(store);

        const [selectedElement, setSelectedElement] = React.useState(
            t("All selections") as string
        );

        React.useEffect(() => {
            if (t("All selections") === props.selectProps.value[0]?.label) {
                setSelectedElement(t("All selections"));
            } else if (
                selectedGroups.length === 0 ||
                selectedValueState.length === 0
            ) {
                setSelectedElement(`${t("Select")}...`);
            } else {
                setSelectedElement(
                    t("Selected element", {
                        selectedItems: getValue().length,
                        count: getValue().length,
                    })
                );
            }
        }, [selectedGroups, getValue, props.selectProps.value]);

        return hasValue === false ? (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        ) : (
            <components.ValueContainer {...props}>
                {selectedElement}
                {React.Children.map(children, (child) => {
                    return child?.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    };

    React.useEffect(() => {
        fetchGroups();
    }, []);

    React.useEffect(() => {
        setOptions((prevState) => {
            const [, ...remainingGroups] = groups;

            if (remainingGroups.length > 0) {
                return [
                    {
                        options: [t("All selections")],
                        label: t("All selections"),
                    },
                    {
                        options: remainingGroups.map((group) => group),
                        label: t("Selections"),
                    },
                ];
            }

            return prevState;
        });
    }, [groups, t]);

    React.useEffect(() => {
        setLocalOptions(() => {
            return options[0].options.map((option: string) => ({
                label: option,
                value: option,
            }));
        });
        setGroupedOptions(() => {
            return options.map((outerOption: any) => {
                return {
                    label: t(capitalize(outerOption.label)),
                    options: outerOption.options.map((innerOption: string) => ({
                        label: innerOption,
                        value: innerOption,
                    })),
                };
            });
        });
    }, [options, t]);

    React.useEffect(() => {
        const [, ...remainingGroups] = groups;
        const newSelectedGroups = remainingGroups
            .filter((group) => selectedGroups.includes(group))
            .map((group) => ({
                label: group,
                value: group,
            }));

        if (newSelectedGroups.length !== remainingGroups.length) {
            setSelectedValueState(newSelectedGroups);
        } else {
            setSelectedValueState([localOptions[0]]);
        }
    }, [selectedGroups, groups, localOptions]);

    return (
        <Select
            options={groupedOptions}
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={handleSelectOnChangeEvent}
            onKeyDown={(e) => {
                if (selectedValueState.length > 0) e.preventDefault();
            }}
            isMulti={true}
            hideSelectedOptions={false}
            isClearable={isClearableProps}
            components={{
                Option,
                MultiValueRemove,
                ValueContainer,
            }}
            closeMenuOnSelect={false}
            styles={styles}
            theme={(theme: any) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "neutral10",
                },
            })}
        />
    );
}
