import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

export default function CustomSelect({
    options,
    placeholder,
    handleOnChangeEvent,
    selectedValue,
}: {
    options: string[];
    placeholder: string;
    handleOnChangeEvent: any;
    selectedValue: any;
}) {
    const { t } = useTranslation();

    const ValueContainer = ({
        children,
        getValue,
        hasValue,
        ...props
    }: any) => {
        if (!hasValue || getValue().length <= 2) {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
        return (
            <components.ValueContainer {...props}>
                {getValue().length > 2
                    ? t("Selected element", {
                          selectedItems: getValue().length,
                          count: getValue().length,
                      })
                    : children}

                {React.Children.map(children, (child) => {
                    return child.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    };

    return (
        <Select
            options={options}
            placeholder={placeholder}
            value={selectedValue}
            isMulti
            hideSelectedOptions={false}
            onChange={handleOnChangeEvent}
            noOptionsMessage={() => t("no option found")}
            className="mx-2 mb-2 mb-xl-0"
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    minWidth: 200,
                    minHeight: 40,
                    height: 40,
                    margin: 1,
                    borderRadius: "5px",
                    padding: "0px",
                }),
            }}
            components={{ ValueContainer }}
        />
    );
}
