import React from "react";
import { Row, Col } from "reactstrap";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import MiniCard from "@components/Common/MiniCard";

export default function Cards() {
    const { salesModeCards } = useSnapshot(store);

    return (
        <Row>
            {salesModeCards.modes?.map((title: string, index: number) => {
                return salesModeCards.amounts[index] ? (
                    <Col key={`${title}_${index}`}>
                        <MiniCard
                            title={title}
                            amount={salesModeCards.amounts[index]}
                        />
                    </Col>
                ) : null;
            })}
        </Row>
    );
}
