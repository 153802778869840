import React from "react";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store as vStore } from "@vStore";
import { store } from "../CategoriesColumn/store";

import { searchKeyByValue } from "@helpers/general";

type PropsType = {
    onClick: () => void;
} & ChildrenPropsType;

export function StyledButtonWrapper({ onClick, children }: PropsType) {
    const { t } = useTranslation();
    const { type } = useSnapshot(store);
    const { mainColor } = useSnapshot(vStore);

    return (
        <StyledButton
            className="my-2 mr-1 text-uppercase"
            rounded={true}
            style={{
                background:
                    (children as string)
                        .toLowerCase()
                        .includes(type.toLowerCase()) ||
                    t(searchKeyByValue(children as string))
                        .toLowerCase()
                        .includes(t(searchKeyByValue(type)).toLowerCase())
                        ? "#FFFFFF 0% 0% no-repeat padding-box"
                        : mainColor === null
                        ? "#000000 0% 0% no-repeat padding-box"
                        : `${mainColor} 0% 0% no-repeat padding-box`,
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #DBDBDB",
                borderRadius: "11px",
                font: "normal normal bold 13px/16px Poppins",
                color:
                    (children as string)
                        .toLowerCase()
                        .includes(type.toLowerCase()) ||
                    t(searchKeyByValue(children as string))
                        .toLowerCase()
                        .includes(t(searchKeyByValue(type)).toLowerCase())
                        ? "#000000"
                        : "#FFFFFF",
                letterSpacing: "0px",
            }}
            onClick={onClick}
        >
            {children}
        </StyledButton>
    );
}
