import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { StyledIconButton } from "@aureskonnect/react-ui";
import { Tooltip } from "@mui/material";
import { AnyAction } from "redux";
import { useSnapshot } from "valtio";

import { toggleLeftMenu, changeSidebarType } from "@store/actions";
import { store } from "@vStore";

import {
    PortalSvgIcon,
    MultiShopsSelectionSvgIcon,
} from "@components/Common/SvgIcons";
import { LanguageDropdown } from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";

import { SidebarLogo } from "./SidebarLogo";
import { ProfileMenu } from "./ProfileMenu";

export function Header() {
    const { t } = useTranslation();
    let { pathname } = useLocation();
    const { leftMenu, leftSideBarType } = useSelector(
        (state: any) => state.Layout
    );
    const { mainColor } = useSnapshot(store);

    const dispatch = useDispatch();

    const [search, setSearch] = React.useState<boolean>(false);
    const isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(
        navigator.userAgent
    );

    let matchSelectionRoute: boolean =
        pathname === "/my-selections" || pathname === "/my-selection-list";

    function toggleMenu(): void {
        dispatch(toggleLeftMenu((!leftMenu as unknown) as AnyAction));

        if (leftSideBarType === "default") {
            dispatch(changeSidebarType("condensed", isMobile));
        } else if (leftSideBarType === "condensed") {
            dispatch(changeSidebarType("default", isMobile));
        }
    }

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div
                        className="navbar-brand-box"
                        style={{ background: mainColor }}
                    >
                        <Link to="/dashboard" className="logo logo-light">
                            <SidebarLogo type={leftSideBarType} />
                        </Link>
                    </div>
                    <button
                        type="button"
                        onClick={toggleMenu}
                        className={classNames(
                            "btn btn-sm px-3 font-size-16 header-item waves-effect",
                            {
                                "d-none": matchSelectionRoute,
                            }
                        )}
                        id="vertical-menu-btn"
                    >
                        <i
                            className="fa fa-fw fa-bars"
                            style={{ color: mainColor }}
                        ></i>
                    </button>

                    <form className="app-search d-none">
                        <div className="position-relative">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={`${t("Search")} ...`}
                            />
                            <span className="bx bx-search-alt"></span>
                        </div>
                    </form>
                </div>
                <div className="d-flex">
                    <div className="dropdown d-none ml-2">
                        <button
                            onClick={() => {
                                setSearch(!search);
                            }}
                            type="button"
                            className="btn header-item noti-icon waves-effect"
                            id="page-header-search-dropdown"
                        >
                            <i className="mdi mdi-magnify"></i>
                        </button>
                        <div
                            className={
                                search
                                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                            }
                            aria-labelledby="page-header-search-dropdown"
                        >
                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search ..."
                                            aria-label="Recipient's username"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                            >
                                                <i className="mdi mdi-magnify"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <LanguageDropdown />
                    <Tooltip title={t("Portal")}>
                        <div>
                            <StyledIconButton
                                className="mt-3 mb-0 p-0 border-none__clz"
                                icon="PortalSvgIcon"
                                onClick={() => {
                                    window.location.href = process.env
                                        .REACT_APP_PORTAL_APP_URL as string;
                                }}
                            >
                                <PortalSvgIcon height={30} width={30} />
                            </StyledIconButton>
                        </div>
                    </Tooltip>
                    <Tooltip title={t("Multi-store display")}>
                        <Link to="/my-selections">
                            <StyledIconButton
                                className="mt-3 mb-0 p-0 border-none__clz"
                                icon="MultiShopsSelectionSvgIcon"
                            >
                                <MultiShopsSelectionSvgIcon
                                    height={30}
                                    width={30}
                                />
                            </StyledIconButton>
                        </Link>
                    </Tooltip>

                    <ProfileMenu />
                </div>
            </div>
        </header>
    );
}
