import React from "react";

export default function ThickHeadedSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8778">
                <g data-name="Groupe 8756">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="Groupe 8773" fill="#fff">
                    <path
                        data-name="Trac\xE9 8574"
                        d="M14 8.846v26.688a2.619 2.619 0 002.3 2.846h15.32a2.619 2.619 0 002.3-2.846V8.846A2.619 2.619 0 0031.62 6H16.3A2.619 2.619 0 0014 8.846zm18.386 0v26.688a.873.873 0 01-.766.949H16.3a.873.873 0 01-.766-.949V8.846A.873.873 0 0116.3 7.9h15.32a.873.873 0 01.766.946z"
                    />
                    <path
                        data-name="Trac\xE9 8575"
                        d="M21.661 33.783h4.6v1.532h-4.6z"
                    />
                    <path
                        data-name="Trac\xE9 8576"
                        d="M24.725 36.904v4.6h-1.532v-4.6z"
                    />
                </g>
            </g>
        </svg>
    );
}
