import React from "react";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "i18next";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "@vStore";

import { setAdvancedLegend } from "@pages/Statistics/Transactions/store";

import PageTitle from "@components/Common/PageTitle";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import { default as Breadcrumb } from "@components/Common/GlobalBreadcrumb";

import Header from "./Header";
import MainChart from "./MainChart";
import OrdersDetails from "./OrdersDetails";

export default function Transactions() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);

    moment.locale(i18n.language);

    React.useEffect(() => {
        setAdvancedLegend(t("General"));
    }, [t]);

    return (
        <React.Fragment>
            <PageTitle title={t("Transactions")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Transactions")}
                />
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader
                                className={classNames({
                                    "dark-bg__clz": mainColor === null,
                                })}
                                style={{ background: mainColor }}
                            >
                                <Header />
                            </CardHeader>
                            <CardBody>
                                <React.Suspense fallback={<SpinnerWrapper />}>
                                    <CustomErrorBoundary>
                                        <MainChart />
                                    </CustomErrorBoundary>
                                </React.Suspense>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <React.Suspense fallback={<SpinnerWrapper />}>
                            <CustomErrorBoundary>
                                <OrdersDetails />
                            </CustomErrorBoundary>
                        </React.Suspense>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}
