import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

export function TitleRow() {
    const { t } = useTranslation();

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            {t("Grouping and assigning objects")}
                        </CardTitle>
                        <CardSubtitle tag="h6" className="text-muted">
                            {t(
                                "Please group or assign similar items to get correct statistics"
                            )}
                        </CardSubtitle>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
