import React from "react";
import { Row, Col, Card, CardBody, Nav, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import faker from "faker";

import { getArrayOfRandomNumbers } from "@helpers/general";

import DonutChart from "../CommonCharts/DonutChart";
import ApexLineColumn from "../CommonCharts/ApexLineColumn";

export default function ServicesRow() {
    const { t } = useTranslation();

    const servicesSeries: string[] = [
        t("Midday service"),
        t("Evening service"),
    ];

    const top10OffersAndSales: string[] = Array(10)
        .fill(null)
        .map((e) => (e = faker.fake("{{random.word}}")));

    const top10OffersAndSalesChartSeries: SeriesDataType[] = [
        {
            name: top10OffersAndSales[0],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[1],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[2],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[3],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[4],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[5],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[6],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[7],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[8],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[9],
            data: getArrayOfRandomNumbers!(7),
        },
    ];

    const DonutChartServicesSerieData: SeriesDataType = {
        data: getArrayOfRandomNumbers!(2),
        labels: servicesSeries,
    };

    return (
        <Row className="services__clz">
            <Col md="4" sm="4">
                <DonutChart
                    title={t("Services")}
                    data={DonutChartServicesSerieData}
                />
            </Col>
            <Col md="8" sm="8">
                <Card>
                    <CardBody className="card-body__clz">
                        <Nav pills className="navtab-bg nav__clz">
                            <Link
                                style={{ paddingTop: "8px" }}
                                to="/turnover-detail"
                                className="btn btn-outline-dark cards btn-sm float-right d-none"
                            >
                                {t("See more")}{" "}
                                <i className="mdi mdi-arrow-right ml-1"></i>
                            </Link>
                        </Nav>
                        <CardBody className="pb-0 chart-order">
                            <TabPane className="p-2">
                                <div className="apex-charts">
                                    <ApexLineColumn
                                        data={top10OffersAndSalesChartSeries}
                                    />
                                </div>
                            </TabPane>
                        </CardBody>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
