import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CardBody, Card, Nav } from "reactstrap";

import ChartWrapper from "@pages/Dashboard/MainChart/ChartWrapper";

export default function RecapDataRow() {
    const { t } = useTranslation();

    return (
        <Card className="active order-card-mb order-xs-card-mb">
            <CardBody className="pl-0 pb-1">
                <Nav pills className="navtab-bg float-right d-none">
                    <Link
                        to="/turnover-detail"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <CardBody className="p-0">
                    <div className="p-3">
                        <ChartWrapper />
                    </div>
                </CardBody>
            </CardBody>
        </Card>
    );
}
