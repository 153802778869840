import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import {
    formatCurrency,
    generateComparedToStringFromAdvancedPeriod,
} from "@helpers/general";

import ApexRadial from "./ApexRadial";

type OrderRowType = {
    amount: number;
    percentage: number;
    chartPercentage: number;
};

export default function OrderRow({
    amount,
    percentage,
    chartPercentage,
}: OrderRowType) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);
    const { period, type, advancedDate } = useSelector((state: any) => state);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between mb-1">
                <div className="align-self-center">
                    <h3 className="mb-2 order-row-title__clz">{type}</h3>
                    <h4>
                        {type === t("Tickets")
                            ? Number(amount)
                            : formatCurrency(amount)}
                    </h4>
                </div>
                <ApexRadial
                    data={Math.abs(Number(chartPercentage.toFixed(2)))}
                />
            </div>

            <div className="mb-2 mt-5 orders-percentage-text__clz text-muted">
                <span
                    className={classnames({
                        "text-success mx-1 h5": percentage > 0,
                        "text-danger mx-1 h5": percentage < 0,
                        "text-muted mx-1 h5": percentage === 0,
                    })}
                >
                    {Math.abs(Number(percentage.toFixed(2)))}%{" "}
                    <i
                        className={classnames({
                            "mdi mdi-arrow-up": percentage > 0,
                            "mdi mdi-arrow-down": percentage < 0,
                            "mdi mdi-arrow-right": percentage === 0,
                        })}
                    ></i>
                </span>
                /
                {(advancedDate.startDate !== "" &&
                    advancedDate.endDate !== "") ||
                [
                    t("The last 7 days"),
                    t("The last 14 days"),
                    t("The last 30 days"),
                    t("The last 90 days"),
                    t("The last 180 days"),
                    t("The last 365 days"),
                ].includes(period) ? (
                    <label className="w-25">
                        <div className="text-muted text-lowercase ml-2">
                            <span
                                className={classnames("badge", {
                                    "badge-dark": mainColor === null,
                                    "text-white": mainColor !== null,
                                })}
                                style={{ background: mainColor }}
                            >
                                {generateComparedToStringFromAdvancedPeriod(
                                    period,
                                    advancedDate
                                )}
                            </span>
                        </div>
                    </label>
                ) : (
                    <label className="pb-0">
                        <div className="text-muted text-lowercase ml-2">
                            {generateComparedToStringFromAdvancedPeriod(
                                period,
                                advancedDate
                            )}
                        </div>
                    </label>
                )}
                <button type="button" className="d-none btn btn-link">
                    <u>{t("view order history")}</u>
                </button>
            </div>
        </React.Fragment>
    );
}
