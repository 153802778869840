import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-table";
import { useSnapshot } from "valtio";
import { StyledButton } from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import {
    store,
    setSalesSupportList,
} from "@pages/Statistics/Transactions/SalesSupport/store";
import { addSalesSupportSelectionItem } from "@pages/Statistics/Transactions/store";

import TitleRowButtons from "@pages/Statistics/Transactions/SegmentContent/TitleRowsButtons";

type PropsType = {
    setGlobalFilter: (filterValue: any) => void;
    selectedFlatRows: Row<object>[];
    navigation: any;
};

export function TopContentWrapper({
    setGlobalFilter,
    selectedFlatRows,
    navigation,
}: PropsType) {
    const { t } = useTranslation();
    const { salesSupportList } = useSnapshot(store);
    const { mainColor } = useSnapshot(vStore);

    function handleMultipleAddingButtonOnClickEvent() {
        selectedFlatRows
            .map((row: any) => row.original)
            .forEach((item: string) => {
                addSalesSupportSelectionItem(item);
            });

        setSalesSupportList(
            salesSupportList.filter((saleMode: any) => {
                return !selectedFlatRows
                    .map((row: any) => row.original)
                    .map((s) => s.toLowerCase())
                    .includes(saleMode.toLowerCase());
            })
        );
    }

    return (
        <div className="d-flex justify-content-between">
            <div className="app-search m-2 p-0">
                <div className="position-relative">
                    <input
                        type="text"
                        className="form-control w-xs-90"
                        placeholder={`${t("Search")} ...`}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                    <span className="bx bx-search-alt"></span>
                </div>
            </div>
            <div className="d-flex my-2 mx-3" style={{ gap: 10 }}>
                <StyledButton
                    rounded={true}
                    outline={false}
                    variant={mainColor === null ? "secondary" : ""}
                    className="white-space-nowrap"
                    style={{
                        background: mainColor,
                    }}
                    disabled={selectedFlatRows.length === 0}
                    onClick={handleMultipleAddingButtonOnClickEvent}
                >
                    {t("Add")}
                </StyledButton>
                <TitleRowButtons {...navigation} title={t("Sales support")} />
            </div>
        </div>
    );
}
