import * as React from "react";
export const ToTakeSvgIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={63.85}
        height={54.851}
        viewBox="0 0 342 342"
        {...props}
    >
        <defs>
            <filter
                id="Rectangle_13569"
                x={0}
                y={0}
                width={342}
                height={342}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.161} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_13577"
                    data-name="Rectangle 13577"
                    width={146.142}
                    height={169.314}
                    fill="none"
                />
            </clipPath>
        </defs>
        <g
            id="Groupe_1019"
            data-name="Groupe 1019"
            transform="translate(-7762 6583)"
        >
            <g
                transform="matrix(1, 0, 0, 1, 7762, -6583)"
                filter="url(#Rectangle_13569)"
            >
                <rect
                    id="Rectangle_13569-2"
                    data-name="Rectangle 13569"
                    width={324}
                    height={324}
                    rx={50}
                    transform="translate(9 6)"
                    fill="#f8f8f6"
                />
            </g>
            <g
                id="Groupe_1018"
                data-name="Groupe 1018"
                transform="translate(7865.929 -6515.285)"
            >
                <g
                    id="Groupe_1017"
                    data-name="Groupe 1017"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="Trac\xE9_1485"
                        data-name="Trac\xE9 1485"
                        d="M107.353,41.738c4.07.023,8.14.1,12.209.045,2.382-.036,3.46.876,4.244,3.284,4.823,14.814,10.322,29.421,14.755,44.346,4.1,13.808,8.358,27.577,7.121,42.529-.9,10.859.276,21.885.448,32.838.1,6.47-.225,6.755-6.5,6.756-44.483,0-88.966-.071-133.448.109-4.621.019-6.243-1.7-6.163-5.869C.151,159.05.209,152.32.51,145.6c.363-8.114-.869-16.136-.4-24.335C.98,105.935,5.433,91.6,9.715,77.134c2.871-9.7,6.027-19.336,7.86-29.3.882-4.787,3.226-6.7,8.024-6.032a34.137,34.137,0,0,0,5.626-.11l4.007,0H50.387l37.678.046,3.613,0,12.317,0,3.357,0"
                        transform="translate(0 -2.331)"
                        fill="#c07f50"
                    />
                    <path
                        id="Trac\xE9_1486"
                        data-name="Trac\xE9 1486"
                        d="M37.079,39.359l-4.006,0c-.467-13.617,4.024-25.136,14.8-33.742C53.721.953,60.465-1.44,67.943.923c4.909,1.551,9.929.3,14.783,1.3,15.756,3.255,22.686,14.6,25.876,29.255.558,2.563.417,5.279.6,7.924q-1.678,0-3.356,0a60.914,60.914,0,0,0-4.118-18.456c-2.691-7.02-12.456-15-21.035-14.323C89.029,16.17,94.5,26.592,93.527,39.41l-3.613,0c.373-9.184-2.582-17.2-7.467-24.974C77.36,6.34,69.427,4.827,63.262,11.912c-6.82,7.837-11.138,16.78-11.028,27.448H48.5c.4-14.175,6.006-25.7,17.725-34.159-6.874-2.481-18.762,2.376-22.6,9.44C39.469,22.3,35.737,30.19,37.079,39.359"
                        transform="translate(-1.848 0)"
                        fill="#181818"
                    />
                </g>
            </g>
        </g>
    </svg>
);
