import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import {
    usePagination,
    useTable,
    useRowSelect,
    useGlobalFilter,
} from "react-table";
import BTable from "react-bootstrap/Table";
import styled from "styled-components";
import { useSnapshot } from "valtio";

import { store as vStore } from "@vStore";
import store, {
    removeCustomerCityItem,
} from "@pages/Statistics/Transactions/store";

import { ITEMS_PER_PAGE } from "@constants/index";

import Pagination from "@components/Common/Table/Pagination";
import { ArrowLeftSvgIcon } from "@components/Common/SvgIcons";

const Styles = styled.div`
    table {
        th:first-child {
            width: 5%;
        }
    }
`;

type PropsType = {
    data: any[];
    previous: any;
};

export default function MySelection({ data, previous }: PropsType) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const advancedStore = useSnapshot(store);
    const [localData, setLocalData] = React.useState(data);

    function handleRemoveMultipleSelectedCustomersCities() {
        let localSelectedCustomerCity: any[] = [
            ...selectedFlatRows.map((row: any) => row.original),
        ];

        selectedFlatRows
            .map((row: any) => row.original)
            .forEach((item: string) => {
                removeCustomerCityItem(item);
            });

        setLocalData(
            localData.filter((customerCity: any) => {
                return !localSelectedCustomerCity
                    .map((s) => s.toLowerCase())
                    .includes(customerCity.toLowerCase());
            })
        );
    }

    const handleRemoveSelectedCustomerCity = React.useCallback(
        (customerCity: any) => {
            setLocalData(
                localData.filter((datum: string) => datum !== customerCity)
            );
            removeCustomerCityItem(customerCity);
        },
        [localData]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Designation"),
                accessor: (customerCity: any): string => customerCity,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (element: any) {
                    return (
                        <div>
                            <Button
                                type="button"
                                color={mainColor === null ? "secondary" : ""}
                                outline={true}
                                block={true}
                                style={{
                                    width: "150px",
                                    float: "right",
                                    borderColor: mainColor,
                                    color: mainColor,
                                }}
                                className="d-none d-sm-none d-md-block p-1"
                                onClick={() =>
                                    handleRemoveSelectedCustomerCity(element)
                                }
                            >
                                {t("Delete")}
                            </Button>
                            <button
                                className="border-0 d-block d-sm-block d-md-none p-1 bg-transparent__clz icon-color__clz"
                                onClick={() =>
                                    handleRemoveSelectedCustomerCity(element)
                                }
                            >
                                <i className="bx bxs-trash-alt font-size-24"></i>
                            </button>
                        </div>
                    );
                },
            },
        ],
        [t, handleRemoveSelectedCustomerCity, mainColor]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t(rest.title) };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: localData,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <ArrowLeftSvgIcon
                        style={{
                            cursor: "pointer",
                        }}
                        className="ml-2"
                        onClick={() => previous()}
                    />
                    <h5 className="ml-3 mb-0 white-space-nowrap">{`${t(
                        "My selection"
                    )} (${data.length})`}</h5>
                    <div className="app-search m-2 p-0">
                        <div className="position-relative">
                            <input
                                type="text"
                                className="form-control w-xs-90"
                                placeholder={`${t("Search")} ...`}
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                            />
                            <span className="bx bx-search-alt"></span>
                        </div>
                    </div>
                </div>
                <Button
                    type="button"
                    color={mainColor === null ? "secondary" : ""}
                    className="m-2 white-space-nowrap"
                    disabled={selectedFlatRows.length === 0}
                    onClick={handleRemoveMultipleSelectedCustomersCities}
                    style={{ borderColor: mainColor, color: mainColor }}
                >
                    {t("Delete")}
                </Button>
            </div>
            <Styles>
                <BTable responsive {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                className="white-space-nowrap"
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </BTable>
            </Styles>
            {advancedStore.customersCitiesSelection.length > ITEMS_PER_PAGE ? (
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                />
            ) : null}
        </React.Fragment>
    );
}
