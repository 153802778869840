import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import ErrorPage from "../ErrorPage";

export default function CustomErrorBoundary({ children }: ChildrenPropsType) {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            {children}
        </ErrorBoundary>
    );
}
