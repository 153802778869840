import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "i18next";

import Breadcrumb from "@components/Common/GlobalBreadcrumb";
import PageTitle from "@components/Common/PageTitle";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

import ArticlesRow from "./ArticlesRow";
import FamiliesRow from "./FamiliesRow";
import SubFamiliesRow from "./SubFamiliesRow";

export default function OutOfStock() {
    const { t } = useTranslation();

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Out of stock")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Out of stock")}
                />
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <ArticlesRow />
                </React.Suspense>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <FamiliesRow />
                </React.Suspense>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SubFamiliesRow />
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
