import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StatisticsContext } from "@pages/Statistics/context";

import TitleRow from "@components/Common/TitleRow";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import PercentageTop10FamiliesCol from "./PercentageTop10FamiliesCol";
import RecapCol from "./RecapCol";

export default function FamiliesRow() {
    const { t } = useTranslation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                }}
            >
                <TitleRow title={t("Families")}>{null}</TitleRow>
                <div
                    className="d-grid__clz 
                                grid-gap__clz 
                                grid-xl-statistics-products-details__clz 
                                grid-lg-statistics-template-details__clz 
                                grid-md-statistics-template-details__clz 
                                grid-sm-template-col__clz grid-xs-template-col__clz 
                                pr-0"
                >
                    <CustomErrorBoundary>
                        <PercentageTop10FamiliesCol />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <RecapCol />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
