import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardTitle } from "reactstrap";
import { useStep } from "react-hooks-helper";
import { useSnapshot } from "valtio";
import { StyledStepButton } from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import store, { getProducts } from "./store";

import { SettingsArticlesContext } from "./context";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import EditStep from "./EditStep";
import AddArticleEditStep from "./AddArticleEditStep";

import "@assets/widths.css";

export default function Articles() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const { groupName } = useSnapshot(store);

    const { index, navigation } = useStep({ steps: 5 });

    const [groups, setGroups] = React.useState<any[]>([]);

    getProducts();

    return (
        <SettingsArticlesContext.Provider
            value={{
                groups,
                setGroups,
            }}
        >
            <Card>
                <CardTitle
                    className="d-flex justify-content-center justify-content-md-start mb-0"
                    style={{
                        backgroundColor:
                            mainColor !== null ? mainColor : "black",
                    }}
                >
                    {index !== 2 && (
                        <div className="d-md-flex steps__clz mx-3 ml-md-3 mr-md-0">
                            <StyledStepButton
                                color={index === 0 ? "#fefefe" : "#6a7187"}
                                rounded
                                step="in progress"
                                stepDetail={t("Please select/create the group")}
                                stepNumber={1}
                                stepTitle="Step"
                                className={classnames({
                                    "my-2 active-menu__clz btn w-sm-100 w-md-unset":
                                        index === 0,
                                    "my-2 disable-menu__clz btn w-sm-100 w-md-unset":
                                        index !== 0,
                                })}
                                disabled={index === 0}
                                onClick={() => navigation.go(0)}
                            />
                            <StyledStepButton
                                color={
                                    [1, 3, 4].includes(index)
                                        ? "#fefefe"
                                        : "#6a7187"
                                }
                                rounded
                                step="in progress"
                                stepDetail={t("Please select the items")}
                                stepNumber={2}
                                stepTitle="Step"
                                className={classnames({
                                    "my-2 mx-md-3 active-menu__clz btn w-sm-100 w-md-unset": [
                                        1,
                                        3,
                                        4,
                                    ].includes(index),
                                    "my-2 mx-md-3 disable-menu__clz btn w-sm-100 w-md-unset":
                                        index !== 1 &&
                                        index !== 3 &&
                                        index !== 4,
                                })}
                                disabled={index === 0 || groupName === ""}
                            />
                        </div>
                    )}
                    {index === 2 && (
                        <h4
                            className="my-2 ml-2 font-weight-light"
                            style={{
                                color: "white",
                            }}
                        >
                            {t("My selection")}
                        </h4>
                    )}
                </CardTitle>

                {index === 0 && (
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <CustomErrorBoundary>
                            <FirstStep {...navigation} />
                        </CustomErrorBoundary>
                    </React.Suspense>
                )}
                {index === 1 && (
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <CustomErrorBoundary>
                            <SecondStep {...navigation} />
                        </CustomErrorBoundary>
                    </React.Suspense>
                )}
                {index === 2 && (
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <CustomErrorBoundary>
                            <EditStep {...navigation} />
                        </CustomErrorBoundary>
                    </React.Suspense>
                )}
                {index === 3 && <FourthStep {...navigation} />}
                {index === 4 && (
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <CustomErrorBoundary>
                            <AddArticleEditStep {...navigation} />
                        </CustomErrorBoundary>
                    </React.Suspense>
                )}
            </Card>
        </SettingsArticlesContext.Provider>
    );
}
