import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import { capitalize } from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/OldDownloadDropdown";

import SalesSupportCol from "./SalesSupportCol";
import StatisticsSalesSupportCol from "./StatisticsSalesSupportCol";
import ErrorPage from "@components/Common/ErrorPage";
import GeneralCustomSelectGroup from "@components/Common/GeneralCustomSelectGroup";

export default function SalesSupportRow() {
    const { t } = useTranslation();

    moment.locale(i18n.language);

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);

    const [salesSupportSelection, setSalesSupportSelection] = React.useState<
        string[]
    >([t("All sales supports")]);

    const [salesSupportListData, setSalesSupportListData] = React.useState<
        string[]
    >([]);

    const {
        resourceData: salesSupportList,
        error: salesSupportListError,
    } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/statistics/sales-support-list?${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSalesSupportList: string[] = [];

        salesSupportList.data?.forEach((el: string) => {
            localSalesSupportList.push(t(capitalize(el)));
        });

        setSalesSupportListData(localSalesSupportList);
    }, [salesSupportList, t]);

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                    percentageData,
                    setPercentageData,
                    histogramData,
                    setHistogramData,
                    categoriesData,
                    setCategoriesData,
                    salesSupportSelection,
                    setSalesSupportSelection,
                }}
            >
                <TitleRow title={t("Sub-families per sale support")}>
                    {salesSupportListError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <GeneralCustomSelectGroup
                                options={[
                                    t("All sales supports"),
                                    ...salesSupportListData,
                                ]}
                            />
                        </CustomErrorBoundary>
                    )}
                    <DownloadDropdown />
                </TitleRow>
                <div
                    className="
                d-grid__clz 
                grid-gap__clz 
                grid-xl-statistics-families-and-products-details__clz 
                grid-lg-statistics-template-details__clz 
                grid-md-statistics-template-details__clz
                grid-sm-template-col__clz grid-xs-template-col__clz 
                pr-0"
                >
                    <CustomErrorBoundary>
                        <SalesSupportCol />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <StatisticsSalesSupportCol />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
