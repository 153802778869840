import i18n from "i18next";
import { proxy } from "valtio";

import { searchKeyByValue } from "@helpers/general";

type StoreType = {
    type: string;
    titleRowWidth: number;
};

export const store = proxy<StoreType>({
    type: i18n.t(searchKeyByValue("TTC")),
    titleRowWidth: 0,
});

i18n.on("languageChanged", () => {
    setType("TTC");
});

export function setType(type: string) {
    store.type = type;
}

export function setTitleRowWidth(width: number) {
    store.titleRowWidth = width;
}
