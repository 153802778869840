import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/OldDownloadDropdown";

import ServicesCol from "./ServicesCol";
import StatisticsServicesCol from "./StatisticsServicesCol";
import GeneralCustomSelectGroup from "@components/Common/GeneralCustomSelectGroup";

export default function ServicesRow() {
    const { t } = useTranslation();
    moment.locale(i18n.language);

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);

    const [servicesSelection, setServicesSelection] = React.useState<string[]>([
        t("All services"),
    ]);

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                    percentageData,
                    setPercentageData,
                    histogramData,
                    setHistogramData,
                    categoriesData,
                    setCategoriesData,
                    servicesSelection,
                    setServicesSelection,
                }}
            >
                <TitleRow title={t("Sub-families per service")}>
                    <GeneralCustomSelectGroup
                        options={[
                            t("All services"),
                            t("Midday service"),
                            t("Evening service"),
                        ]}
                    />
                    <DownloadDropdown />
                </TitleRow>

                <div
                    className="
                d-grid__clz 
                grid-gap__clz 
                grid-xl-statistics-families-and-products-details__clz 
                grid-lg-statistics-template-details__clz 
                grid-md-statistics-template-details__clz
                grid-sm-template-col__clz grid-xs-template-col__clz 
                pr-0"
                >
                    <CustomErrorBoundary>
                        <ServicesCol />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <StatisticsServicesCol />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
