import React from "react";
import { useTranslation } from "react-i18next";

import { setTop10Percentage } from "@pages/Statistics/store";

import { useResource } from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getRoundedArrayNumbers,
    getStringsArrayUniqueValues,
    getTypeParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";
import { useSelector } from "react-redux";

export default function PercentageTop10ArticlesCol() {
    const { t } = useTranslation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const {
        resourceData: { data: top10ArticlesData },
        error,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        top10ArticlesData.forEach((item: any) => {
            localLabels.push(item.designation);
            localData.push(item.value);
        });
        setData(getRoundedArrayNumbers(localData.filter((item) => item !== 0)));
        setLabels(
            getStringsArrayUniqueValues(
                localLabels.length > 0
                    ? localLabels.map((item) => t(item))
                    : [t("No data")]
            )
        );

        setTop10Percentage(localData, "products");
    }, [top10ArticlesData, t]);

    const donutChartTop10ProductsData = {
        data,
        labels: labels.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Top 10 products")}
                        data={donutChartTop10ProductsData}
                    >
                        <LegendPopoverWrapper
                            title={t("Top 10 products")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
