import React from "react";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import { store } from "../store";

import {
    getAdvancedPeriodStringParam,
    getSelectionQueryStringFromSelectedGroups,
    getSelectionQueryStringFromSelectedShops,
} from "@helpers/general";

import { MyBestShopRowWrapper } from "./MyBestShopRowWrapper";

import "@assets/grid.css";

export function MyBestShop() {
    const { selectedShops, selectedGroups } = useSnapshot(store);
    const { period, advancedDate } = useSelector((state: any) => state);

    const selectionQueryString = getSelectionQueryStringFromSelectedShops(
        selectedShops
    );
    const groupsSelectionQueryString = getSelectionQueryStringFromSelectedGroups(
        selectedGroups
    );

    const url = `${
        process.env.REACT_APP_API_V1_URL
    }/statistics/client/global?${getAdvancedPeriodStringParam(
        period,
        advancedDate
    )}${selectionQueryString}${groupsSelectionQueryString}`;

    return (
        <Container fluid={true}>
            <MyBestShopRowWrapper url={url} />
        </Container>
    );
}
