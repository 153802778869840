import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { setTop10Percentage } from "@pages/Statistics/store";

import { top10FamiliesSelection } from "@pages/Statistics/NewProducts/signals";

import { useResource } from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getRoundedArrayNumbers,
    getStringsArrayUniqueValues,
    getTypeParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function Top10FamiliesCol() {
    const { t } = useTranslation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const {
        resourceData: { data: top10FamiliesData },
        error,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/percentage-families?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            top10FamiliesSelection.value !== ""
                ? `&families=${top10FamiliesSelection.value}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        top10FamiliesData?.forEach((item: any) => {
            localData.push(item.value);
            localLabels.push(item.designation);
        });

        setData(getRoundedArrayNumbers(localData.filter((item) => item !== 0)));
        setLabels(
            getStringsArrayUniqueValues(
                localLabels.length > 0
                    ? localLabels.map((item) => t(item))
                    : [t("No data")]
            )
        );

        setTop10Percentage(localData, "families");
    }, [top10FamiliesData, t]);

    const donutChartTop10FamiliesData = {
        data,
        labels: labels.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Top 10 families")}
                        data={donutChartTop10FamiliesData}
                    >
                        <LegendPopoverWrapper
                            title={t("Top 10 families")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
