import React from "react";
import { Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import { store } from "@pages/Statistics/Shops/store";

import { useResource } from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getSelectionQueryStringFromSelectedGroups,
    getSelectionQueryStringFromSelectedShops,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import { CustomFirstCol } from "./CustomFirstCol";
import { CustomCol } from "./CustomCol";

import "@assets/grid.css";

export function MyBestShop() {
    const { selectedShops, selectedGroups } = useSnapshot(store);
    const { period, advancedDate } = useSelector((state: any) => state);

    const [myBestShop, setMyBestShop] = React.useState<MyBestShopType[]>();
    const selectionQueryString = getSelectionQueryStringFromSelectedShops(
        selectedShops
    );
    const groupsSelectionQueryString = getSelectionQueryStringFromSelectedGroups(
        selectedGroups
    );
    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/shops/bestShop?${getAdvancedPeriodStringParam(
            period,
            advancedDate
        )}${selectionQueryString}${groupsSelectionQueryString}`
    );

    React.useEffect(() => {
        setMyBestShop(resourceData.data);
    }, [resourceData]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <Container fluid={true}>
                        <Row className="flex-row flex-nowrap overflow-auto">
                            {myBestShop?.map((shop, index) =>
                                index === 0 &&
                                shop.designation.toLowerCase() !==
                                    "turnover" ? (
                                    <CustomFirstCol
                                        key={shop.designation}
                                        shop={shop}
                                    />
                                ) : shop.tickets > 0 ? (
                                    <CustomCol
                                        key={shop.designation}
                                        shop={shop}
                                    />
                                ) : null
                            )}
                        </Row>
                    </Container>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
