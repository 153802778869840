import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { useDispatch } from "react-redux";

import { store } from "../store";
import { changeType } from "@store/actions";

import { searchKeyByValue } from "@helpers/general";
import { fetcherWithToken } from "@helpers/fetcherWithToken";

import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

type PropsType = {
    apiUrl: string;
};

export function DonutChartWrapper({ apiUrl }: PropsType) {
    const { t } = useTranslation();
    const { type } = useSnapshot(store);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(true);
    const [chartData, setChartData] = React.useState<SeriesDataType>({
        data: [],
        labels: [],
    });

    React.useEffect(() => {
        dispatch(changeType(type));
    }, [t, dispatch, type]);

    React.useEffect(() => {
        fetcherWithToken(apiUrl)
            .then(({ data }) => {
                setIsLoading(false);

                const localChartData = {
                    data: data.map((row: RowType) =>
                        Number(row[t(searchKeyByValue("Total"))])
                    ),
                    labels: data
                        .map(
                            (row: RowType) =>
                                row[t(searchKeyByValue(t("Shop")))]
                        )
                        .filter((el: any) => el !== undefined),
                };
                setChartData(localChartData);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(
                    `Error while getting my shops data, with message: ${error.message}`
                );
            });
    }, [apiUrl, type, t]);

    return (
        <CustomErrorBoundary>
            {isLoading === true ? (
                <SpinnerWrapper />
            ) : (
                <DonutChart data={chartData} title={t("Shops")}>
                    <LegendPopoverWrapper
                        title={t("Shops")}
                        labels={chartData.labels || []}
                        legendId="saleModes"
                    />
                </DonutChart>
            )}
        </CustomErrorBoundary>
    );
}
