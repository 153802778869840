import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { StatisticsContext } from "@pages/Statistics/context";

import { getCurrentLanguageItemTranslation } from "@helpers/general";

type LocalOptionsType = {
    label: string | number;
    value: string | number;
};

type GroupedOptionsType = {
    options: LocalOptionsType[];
};

type PropsType = {
    options: any[];
};

export default function GeneralCustomSelectGroup({ options }: PropsType) {
    const { t, i18n } = useTranslation();

    const {
        setSalesSupportSelection,
        setSalesModesSelection,
        setServicesSelection,
    } = React.useContext(StatisticsContext);

    let localOptions: LocalOptionsType[] = options.map((option: string) => ({
        label: option,
        value: option,
    }));

    const [selectedValueState, setSelectedValueState] = React.useState<{
        label: string | number;
        value: string | number;
    }>(localOptions[0]);

    let groupedOptions: GroupedOptionsType[] = [
        {
            options: localOptions,
        },
    ];

    function handleSelectOnChangeEvent(selectedValue: any, actionType: any) {
        if (selectedValue) {
            let selectedValueSize = Object.keys(selectedValue)?.length;
            let allItemExistInSelectedValue: boolean =
                selectedValue?.find(
                    (element: LocalOptionsType) =>
                        element.value === localOptions[0].value
                ) !== undefined;

            let allItemIndex = selectedValue.findIndex(
                (element: any) => element.value === localOptions[0].value
            );

            if (
                selectedValueSize > 1 &&
                allItemExistInSelectedValue &&
                allItemIndex === 0
            ) {
                setSelectedValueState(actionType.option);
            } else if (!allItemExistInSelectedValue) {
                setSelectedValueState(selectedValue);
            } else if (allItemIndex > 0) {
                selectedValue = selectedValue.filter((el: any) => {
                    return el === selectedValue[allItemIndex];
                });
                setSelectedValueState(selectedValue[0]);
            }
        } else {
            setSelectedValueState(localOptions[0]);
        }

        if (options.includes(t("All sales methods"))) {
            setSalesModesSelection!(() => {
                let newState: string[] = [];
                selectedValue?.forEach((element: LocalOptionsType) => {
                    newState.push(element.value as string);
                });
                return newState;
            });
        } else if (options.includes(t("All sales supports"))) {
            setSalesSupportSelection!(() => {
                let newState: string[] = [];
                selectedValue?.forEach((element: LocalOptionsType) => {
                    newState.push(element.value as string);
                });
                return newState;
            });
        } else if (options.includes(t("All services"))) {
            setServicesSelection!(() => {
                let newState: string[] = [];
                selectedValue?.forEach((element: LocalOptionsType) => {
                    newState.push(element.value as string);
                });
                return newState;
            });
        }
    }

    function MultiValueRemove() {
        return false;
    }

    const components: any = { MultiValueRemove };

    i18n.on("languageChanged", () => {
        setSelectedValueState((prevState: any) => {
            let newState: typeof prevState = [];

            if (
                options.includes("All sales methods") ||
                options.includes("Tous les modes de vente")
            ) {
                newState = getCurrentLanguageItemTranslation(
                    t("All sales methods")
                );
                setSalesModesSelection!([""]);
            } else if (
                options.includes("All sales supports") ||
                options.includes("Tous les supports de vente")
            ) {
                newState = getCurrentLanguageItemTranslation(
                    t("All sales supports")
                );
                setSalesSupportSelection!([""]);
            } else if (
                options.includes("All services") ||
                options.includes("Tous les services")
            ) {
                newState = getCurrentLanguageItemTranslation(t("All services"));
                setServicesSelection!([""]);
            }

            return newState.map((el: string) => ({
                label: el,
                value: el,
            }));
        });
    });

    return (
        <Select
            options={groupedOptions}
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={handleSelectOnChangeEvent}
            isMulti
            isClearable={false}
            components={
                localOptions[0].value === selectedValueState.value
                    ? components
                    : undefined
            }
            className="my-1"
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    minWidth: 240,
                    margin: 1,
                    borderRadius: "5px",
                    padding: "0px",
                }),
            }}
        />
    );
}
