import React from "react";

export default function CashBoxSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8765">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8482" fill="#fff">
                    <path
                        data-name="Trac\xE9 8619"
                        d="M39.508 31.264l-5.226-9.236V16.02a1.853 1.853 0 00-1.851-1.85h-6.786v-1.234h2.468a1.235 1.235 0 001.234-1.236V9.234A1.235 1.235 0 0028.113 8h-8.638a1.235 1.235 0 00-1.234 1.234V11.7a1.235 1.235 0 001.234 1.234h2.468v1.236h-6.786a1.853 1.853 0 00-1.851 1.85v6.007L8.08 31.264a.617.617 0 00-.08.3v6.17a1.853 1.853 0 001.851 1.851h27.886a1.853 1.853 0 001.851-1.851v-6.17a.617.617 0 00-.08-.3zM19.475 9.234h8.637V11.7h-8.637zm3.7 3.7h1.234v1.236h-1.232zm-8.892 9.873h6.735a.617.617 0 000-1.234H14.54V16.02a.618.618 0 01.617-.62h17.274a.618.618 0 01.617.617v5.553H26.57a.617.617 0 000 1.234h6.735l4.608 8.143H9.675zm23.454 15.547H9.851a.618.618 0 01-.617-.617v-5.553h29.12v5.553a.618.618 0 01-.617.617z"
                    />
                    <path
                        data-name="Trac\xE9 8620"
                        d="M34.899 33.418h-22.21a1.851 1.851 0 000 3.7h22.21a1.851 1.851 0 000-3.7zm0 2.468h-22.21a.617.617 0 010-1.234h22.21a.617.617 0 010 1.234z"
                    />
                    <path
                        data-name="Trac\xE9 8621"
                        d="M31.197 18.488a1.853 1.853 0 00-1.85-1.854h-11.1a1.851 1.851 0 100 3.7h11.1a1.853 1.853 0 001.85-1.846zm-13.573 0a.618.618 0 01.617-.617h11.1a.617.617 0 110 1.234h-11.1a.618.618 0 01-.616-.617z"
                    />
                    <path
                        data-name="Trac\xE9 8622"
                        d="M14.569 29.159a.623.623 0 00.592.8h17.265a.62.62 0 00.592-.8l-1.573-4.929a.623.623 0 00-.6-.436H16.736a.624.624 0 00-.6.436zm5.012-4.131l-.195 1.234h-2.6l.394-1.234zm3.6 0v1.234h-2.545l.195-1.234zm3.58 0l.195 1.234h-2.541v-1.234zm3.648 0l.394 1.234h-2.6l-.195-1.234zm-1.815 3.7l-.195-1.234h2.8l.394 1.234zm-12.589 0l.394-1.234h2.8l-.195 1.234zm4.44-1.234h2.736v1.234h-2.93zm3.97 0h2.736l.195 1.234h-2.93z"
                    />
                    <path
                        data-name="Trac\xE9 8623"
                        d="M23.794 21.568a.617.617 0 10.617.617.617.617 0 00-.617-.617z"
                    />
                </g>
            </g>
        </svg>
    );
}
