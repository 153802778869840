import React from "react";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getStringsArrayUniqueValues,
    getTypeParam,
    getRoundedArrayNumbers,
    getAdvancedPeriodStringParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function SalesSupportCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: salesSupportData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/sales-support?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        salesSupportData.data?.forEach((item: any) => {
            localLabels.push(t(item.designation));
            localData.push(item.value);
        });

        localData = getRoundedArrayNumbers(localData);
        setData(localData?.filter((item) => item !== 0));
        setLabels(
            getStringsArrayUniqueValues(localLabels).filter(
                (item) => item !== ""
            )
        );
        setPercentageData!(localData);
    }, [salesSupportData, setPercentageData, t]);

    const donutChartPurchaseSupportData: SeriesDataType = {
        data,
        labels:
            labels.length > 0
                ? labels.map((label) => t(label))
                : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Sales support")}
                        data={donutChartPurchaseSupportData}
                    >
                        <LegendPopoverWrapper
                            title={t("Sales support")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
