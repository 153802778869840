import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    usePagination,
    useTable,
    useRowSelect,
    useGlobalFilter,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { Button } from "reactstrap";
import { useSnapshot } from "valtio";
import { useStep } from "react-hooks-helper";
import {
    AddIcon,
    StyledButton,
    StyledIconButton,
} from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import store, {
    addSalesModeSelectionItem,
} from "@pages/Statistics/Transactions/store";

import { capitalize } from "@helpers/general";

import { useResource } from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import Pagination from "@components/Common/Table/Pagination";
import TitleRowButtons from "../SegmentContent/TitleRowsButtons";
import MySelection from "./MySelection";
import { Styles } from "../Styles";

export default function SalesMode({ title }: { title: string }) {
    const { t } = useTranslation();

    const advancedStore = useSnapshot(store);
    const { mainColor } = useSnapshot(vStore);
    const selections = useSelector((state: any) => state.selections);

    const { index, navigation } = useStep({ steps: 2 });

    const [salesModeList, setSalesModeList] = React.useState<any[]>([]);

    const { resourceData: salesMode, error: salesModeError } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/transactions/sales-mode${
            selections.designation && selections.designation !== "my_favorites"
                ? `?selected=${selections.designation}`
                : ""
        }`
    );

    function handleMultipleAddingButtonOnClickEvent() {
        selectedFlatRows
            .map((row: any) => row.original)
            .forEach((item: string) => {
                addSalesModeSelectionItem(item);
            });

        setSalesModeList(
            salesModeList.filter((saleMode: any) => {
                return !selectedFlatRows
                    .map((row: any) => row.original)
                    .map((s) => s.toLowerCase())
                    .includes(saleMode.toLowerCase());
            })
        );
    }

    const handleAddingSalesModeButtonOnClickEvent = React.useCallback(
        (salesMode: any) => {
            addSalesModeSelectionItem(salesMode);

            setSalesModeList(
                salesModeList.filter((datum: any) => {
                    return salesMode.toLowerCase() !== datum.toLowerCase();
                })
            );
        },
        [salesModeList]
    );

    const columns: AdvancedTableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("Designation"),
                accessor: (salesMode: any): string => t(capitalize(salesMode)),
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (salesMode: any) {
                    return (
                        <div>
                            <Button
                                type="button"
                                outline={true}
                                block={true}
                                color={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block p-1 white-space-nowrap"
                                style={{
                                    width: "150px",
                                    float: "right",
                                    color: mainColor,
                                    borderColor: mainColor,
                                }}
                                onClick={() =>
                                    handleAddingSalesModeButtonOnClickEvent(
                                        salesMode
                                    )
                                }
                            >
                                {t("Add")}
                            </Button>
                            <StyledIconButton
                                icon="AddIcon"
                                className="m-0 d-block d-sm-block d-md-none"
                                onClick={() =>
                                    handleAddingSalesModeButtonOnClickEvent(
                                        salesMode
                                    )
                                }
                            >
                                <AddIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingSalesModeButtonOnClickEvent, mainColor]
    );
    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t(rest.title) };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        selectedFlatRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: salesModeList,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    React.useEffect(() => {
        setSalesModeList(
            advancedStore.salesModeSelection.length > 0
                ? salesMode.data.filter((saleMode: any) => {
                      return !advancedStore.salesModeSelection
                          .map((s) => s.toLowerCase())
                          .includes(saleMode.toLowerCase());
                  })
                : salesMode.data
        );
    }, [salesMode, advancedStore]);

    return (
        <React.Fragment>
            {index === 0 && (
                <React.Fragment>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center app-search m-2 p-0">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control w-xs-90"
                                    placeholder={`${t("Search")} ...`}
                                    onChange={(e) =>
                                        setGlobalFilter(e.target.value)
                                    }
                                />
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </div>
                        <div className="d-flex my-2 mx-3" style={{ gap: 10 }}>
                            <StyledButton
                                rounded={true}
                                outline={false}
                                variant={mainColor === null ? "secondary" : ""}
                                className="white-space-nowrap"
                                disabled={selectedFlatRows.length === 0}
                                onClick={handleMultipleAddingButtonOnClickEvent}
                                style={{ background: mainColor }}
                            >
                                {t("Add")}
                            </StyledButton>
                            <TitleRowButtons {...navigation} title={title} />
                        </div>
                    </div>
                    {salesModeError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <Styles>
                                <BTable responsive {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (column) => {
                                                        return (
                                                            <th
                                                                {...column.getHeaderProps()}
                                                            >
                                                                {column.render(
                                                                    "Header"
                                                                )}
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td
                                                                className="white-space-nowrap align-middle"
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </BTable>
                            </Styles>
                            {salesModeList.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </CustomErrorBoundary>
                    )}
                </React.Fragment>
            )}
            {index === 1 && (
                <MySelection
                    {...navigation}
                    data={advancedStore.salesModeSelection}
                />
            )}
        </React.Fragment>
    );
}
