import React from "react";
import slugify from "@sindresorhus/slugify";
import ReactApexChart from "react-apexcharts";
import { uid } from "react-uid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { formatChartLabels } from "@helpers/general";

import fr from "apexcharts/dist/locales/fr.json";

type PropsType = {
    categories: string[];
    series: SerieType[];
};

export default function MixedChart({ categories, series }: PropsType) {
    const { t } = useTranslation();

    const period = useSelector((state: any) => state.period);
    const advancedDate = useSelector((state: any) => state.advancedDate);

    const options = {
        chart: {
            id: uid(`basic-bar__${slugify("tickets")}`),
            locales: [fr],
            defaultLocale: "fr",
            zoom: {
                enabled: false,
            },
            width: "100%",
            height: 359,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "100%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories,
            labels: {
                formatter: (value: any) =>
                    formatChartLabels(value, advancedDate, period),
            },
        },
        stroke: {
            curve: "smooth",
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8,
            },
        },
        yaxis: {
            labels: {
                formatter: function (value: any) {
                    return Math.round(value);
                },
            },
            tickAmount: 1,
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: "bottom",
        },
        fill: {
            opacity: 1,
        },
        noData: {
            text: t("No data"),
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
        },
    };

    return <ReactApexChart options={options} series={series} height="355" />;
}
