import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";

export default function ErrorPage() {
    const { t } = useTranslation();

    return (
        <Card className="error-page-card__clz">
            <CardBody>
                <h4 className="card-title mb-3">{t("There's an error")}</h4>
                <CardBody className="p-0">
                    <p className="text-uppercase">
                        {t("We can't retrieve data from our servers now")}.
                        <br />
                        {t("Please try later")}!
                    </p>
                </CardBody>
            </CardBody>
        </Card>
    );
}
