import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

type PropsType = {
    title: string;
    description: string;
    link: string;
    icon: React.ReactNode;
};

export function PortalCard({ title, description, link, icon }: PropsType) {
    const history = useHistory();

    return (
        <Card className="home-page-card__clz">
            <CardBody
                className="d-flex flex-column home-page-card-body__clz"
                onClick={() => history.push(link)}
            >
                <div className="d-flex flex-row justify-content-center home-page-content__clz">
                    <div className="d-flex flex-row home-page-svg-icon__clz">
                        {icon}
                    </div>
                    <div className="text-uppercase home-page-card-title__clz">
                        {title}
                    </div>
                </div>

                <div className="home-page-card-description__clz">
                    <Link to={link}>{description}</Link>
                </div>
            </CardBody>
        </Card>
    );
}
