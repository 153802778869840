import * as React from "react";

export const TeamworkSvgIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={62.171}
        height={62.171}
        {...props}
    >
        <path
            fill="#3fb777"
            d="M62.172 31.086a31.062 31.062 0 0 1-28.558 30.983q-1.251.1-2.528.1c-1 0-1.991-.047-2.967-.141a30.854 30.854 0 0 1-10.655-3 31.088 31.088 0 1 1 44.708-27.942Zm0 0"
            data-name="Trac\xE9 207"
        />
        <path
            fill="#bfd5de"
            d="M59.381 40.083v3.891a31.207 31.207 0 0 1-15.119 15.273 31 31 0 0 1-8.232 2.53h-.02v-21.7a8.812 8.812 0 0 1 8.093-8.784 8.63 8.63 0 0 1 .72-.029h5.74a8.8 8.8 0 0 1 8.817 8.813Zm0 0"
            data-name="Trac\xE9 208"
        />
        <path
            fill="#dde8ed"
            d="M59.381 40.083v3.891a31.207 31.207 0 0 1-15.119 15.273 30.838 30.838 0 0 1-3.751 1.466v-20.63a8.815 8.815 0 0 1 8.816-8.813h1.238a8.814 8.814 0 0 1 8.816 8.813Zm0 0"
            data-name="Trac\xE9 209"
        />
        <path
            fill="#d3a489"
            d="M39.068 21.546a2.923 2.923 0 1 1-2.457-2.887 2.926 2.926 0 0 1 2.457 2.887Zm0 0"
            data-name="Trac\xE9 210"
        />
        <path
            fill="#f74f4f"
            d="m50.061 34.45-2.366 3.8-2.366-3.8"
            data-name="Trac\xE9 211"
        />
        <path
            fill="#f74f4f"
            d="m50.878 48.967-3.183 4.018-3.185-4.018 3.185-11.709Zm0 0"
            data-name="Trac\xE9 212"
        />
        <path
            fill="#bf8e75"
            d="M39.068 21.549a2.926 2.926 0 0 1-2.457 2.887 10.936 10.936 0 0 1 0-5.774 2.926 2.926 0 0 1 2.457 2.887Zm0 0"
            data-name="Trac\xE9 213"
        />
        <path
            fill="#bf8e75"
            d="M37.189 21.548a1.045 1.045 0 1 1-1.045-1.045 1.046 1.046 0 0 1 1.045 1.045Zm0 0"
            data-name="Trac\xE9 214"
        />
        <path
            fill="#bfd5de"
            d="m47.694 34.878-3.969 2.206-1.207-5.615 2.466-1.452"
            data-name="Trac\xE9 215"
        />
        <path
            fill="#bfd5de"
            d="m47.694 34.878 3.968 2.206 1.207-5.615-2.466-1.452"
            data-name="Trac\xE9 216"
        />
        <path
            fill="#d3a489"
            d="M56.322 21.546a2.923 2.923 0 1 0 2.456-2.887 2.925 2.925 0 0 0-2.456 2.887Zm0 0"
            data-name="Trac\xE9 217"
        />
        <g fill="#bf8e75" data-name="Groupe 255">
            <path
                d="M56.321 21.549a2.925 2.925 0 0 0 2.456 2.887 10.923 10.923 0 0 0 0-5.774 2.925 2.925 0 0 0-2.456 2.887Zm0 0"
                data-name="Trac\xE9 218"
            />
            <path
                d="M60.296 21.548a1.045 1.045 0 1 1-1.045-1.045 1.046 1.046 0 0 1 1.045 1.045Zm0 0"
                data-name="Trac\xE9 219"
            />
            <path
                d="M58.577 19.995a10.882 10.882 0 0 1-9.132 10.741 10.758 10.758 0 0 1-1.746.14 10.881 10.881 0 0 1-10.886-10.881c0-2.844.988-7.113 2.773-9.051 1.989-2.159 4.942-1.831 8.109-1.831a10.758 10.758 0 0 1 1.746.141 10.882 10.882 0 0 1 9.136 10.741Zm0 0"
                data-name="Trac\xE9 220"
            />
            <path
                d="M50.84 27.875v3.86a3.145 3.145 0 0 1-6.29 0v-3.86Zm0 0"
                data-name="Trac\xE9 221"
            />
        </g>
        <path
            fill="#d3a489"
            d="M50.84 31.49v.244a2.274 2.274 0 1 1-4.4.41q.315.019.634.019a10.885 10.885 0 0 0 3.77-.674Zm0 0"
            data-name="Trac\xE9 222"
        />
        <path
            fill="#d3a489"
            d="M58.572 19.995a10.882 10.882 0 0 1-9.135 10.741 10.883 10.883 0 0 1 0-21.483 10.885 10.885 0 0 1 9.081 9.656c.04.358.054.72.054 1.086Zm0 0"
            data-name="Trac\xE9 223"
        />
        <path
            fill="#bf8e75"
            d="M47.695 25.355a5.5 5.5 0 0 1-2.653-.685.484.484 0 0 1 .466-.849 4.517 4.517 0 0 0 4.373 0 .484.484 0 0 1 .466.849 5.5 5.5 0 0 1-2.652.685Zm0 0"
            data-name="Trac\xE9 224"
        />
        <path
            fill="#3d4159"
            d="M45.377 19.361a1.234 1.234 0 1 1-1.234-1.234 1.234 1.234 0 0 1 1.234 1.234Zm0 0"
            data-name="Trac\xE9 225"
        />
        <path
            fill="#3d4159"
            d="M52.481 19.361a1.234 1.234 0 1 1-1.234-1.234 1.234 1.234 0 0 1 1.234 1.234Zm0 0"
            data-name="Trac\xE9 226"
        />
        <path
            fill="#fff"
            d="M45.126 19.144a.529.529 0 1 1-.529-.529.529.529 0 0 1 .529.529Zm0 0"
            data-name="Trac\xE9 227"
        />
        <path
            fill="#fff"
            d="M52.29 19.144a.529.529 0 1 1-.529-.529.529.529 0 0 1 .529.529Zm0 0"
            data-name="Trac\xE9 228"
        />
        <path
            fill="#bf8e75"
            d="M48.929 22.469H46.46a.532.532 0 0 1 0-1.065h2.469a.532.532 0 1 1 0 1.065Zm0 0"
            data-name="Trac\xE9 229"
        />
        <path
            fill="#bfd5de"
            d="M51.278 39.795h4.744v1.452h-4.744Zm0 0"
            data-name="Trac\xE9 230"
        />
        <path
            fill="#2a2f4f"
            d="M47.058 6.004a6.6 6.6 0 0 1 6.111 6.251l.031.629h1.9l1.021 6.791 2.474-.766-.332-6.655a6.6 6.6 0 0 0-6.612-6.268Zm0 0"
            data-name="Trac\xE9 231"
        />
        <path
            fill="#3d4159"
            d="M58.049 3.377a6.722 6.722 0 0 1-9.506 9.506Zm0 0"
            data-name="Trac\xE9 232"
        />
        <path
            fill="#3d4159"
            d="M48.548 12.883h-8.25l-1.022 6.792-2.474-.766.332-6.655a6.6 6.6 0 0 1 6.612-6.268l11.915.045 1.575 2.835"
            data-name="Trac\xE9 233"
        />
        <path
            fill="#2a2f4f"
            d="M48.331 6.004a6.6 6.6 0 0 0-6.111 6.251l-.031.629h-1.9l-1.022 6.791-2.474-.766.333-6.655a6.6 6.6 0 0 1 6.612-6.268Zm0 0"
            data-name="Trac\xE9 234"
        />
        <path
            fill="#3d4159"
            d="M24.442 40.344v21.115A31.141 31.141 0 0 1 2.608 43.566v-3.223a8.236 8.236 0 0 1 6.1-7.955c.129-.036.258-.067.39-.1a8.027 8.027 0 0 1 1.071-.157c.222-.018.447-.028.674-.028h5.363a8.246 8.246 0 0 1 1.747.185l.1.022c.1.021.189.044.283.07h.008a8.241 8.241 0 0 1 6.1 7.957Zm0 0"
            data-name="Trac\xE9 235"
        />
        <path
            fill="#66baed"
            d="m18.361 32.293-.02.093-2.627 12.216a27.078 27.078 0 0 1-.989.065q-.6.028-1.2.028-.618 0-1.228-.029a27.38 27.38 0 0 1-.963-.063L8.708 32.388l-.02-.094Zm0 0"
            data-name="Trac\xE9 236"
        />
        <path
            fill="#dde8ed"
            d="m15.735 35.08-2.211 3.546-2.211-3.546"
            data-name="Trac\xE9 237"
        />
        <path
            fill="#dde8ed"
            d="M15.305 44.635c-.192.012-1.377 6.278-1.779 6.278s-1.6-6.265-1.78-6.279l1.78-6.931Zm0 0"
            data-name="Trac\xE9 238"
        />
        <path
            fill="#2ba1cf"
            d="m13.525 35.48-3.707 2.06-1.128-5.246 2.3-1.357"
            data-name="Trac\xE9 239"
        />
        <path
            fill="#2ba1cf"
            d="m13.525 35.48 3.708 2.06 1.128-5.246-2.3-1.357"
            data-name="Trac\xE9 240"
        />
        <path
            fill="#2a2f4f"
            d="M18.498 39.373h1.176l-5.046 13.06-.993 2.572-1.1-2.736 1.014-3.481 4.784-16.4.028-.092 2.536.829Zm0 0"
            data-name="Trac\xE9 241"
        />
        <path
            fill="#2a2f4f"
            d="m14.631 52.433-.993 2.572-1.1-2.736-5.191-12.9h1.177l-2.4-6.251 2.537-.829.027.092 4.86 16.4Zm0 0"
            data-name="Trac\xE9 242"
        />
        <path
            fill="#eab599"
            d="M16.463 28.939v3.6a2.938 2.938 0 0 1-5.876 0v-3.6Zm0 0"
            data-name="Trac\xE9 243"
        />
        <path
            fill="#f6caae"
            d="M16.463 32.313v.229a2.937 2.937 0 0 1-2.069 2.806 2.938 2.938 0 0 1-2.045-2.424q.295.018.592.018a10.165 10.165 0 0 0 3.523-.629Zm0 0"
            data-name="Trac\xE9 244"
        />
        <path
            fill="#f6caae"
            d="M5.465 23.027a2.732 2.732 0 1 1-2.732-2.733 2.773 2.773 0 0 1 .438.035 2.733 2.733 0 0 1 2.294 2.698Zm0 0"
            data-name="Trac\xE9 245"
        />
        <path
            fill="#eab599"
            d="M5.464 23.027a2.732 2.732 0 0 1-2.294 2.7 10.208 10.208 0 0 1 0-5.395 2.733 2.733 0 0 1 2.294 2.695Zm0 0"
            data-name="Trac\xE9 246"
        />
        <path
            fill="#eab599"
            d="M3.709 23.027a.977.977 0 1 1-.977-.977.977.977 0 0 1 .977.977Zm0 0"
            data-name="Trac\xE9 247"
        />
        <path
            fill="#f6caae"
            d="M21.586 23.027a2.732 2.732 0 1 0 2.733-2.733 2.768 2.768 0 0 0-.438.035 2.733 2.733 0 0 0-2.295 2.698Zm0 0"
            data-name="Trac\xE9 248"
        />
        <path
            fill="#eab599"
            d="M21.586 23.027a2.733 2.733 0 0 0 2.295 2.7 10.221 10.221 0 0 0 0-5.395 2.733 2.733 0 0 0-2.295 2.695Zm0 0"
            data-name="Trac\xE9 249"
        />
        <path
            fill="#eab599"
            d="M23.692 17.703v3.874a10.167 10.167 0 0 1-8.535 10.035l-.05.008a9.986 9.986 0 0 1-1.582.123A10.166 10.166 0 0 1 3.359 21.577v-3.874A10.161 10.161 0 0 1 13.525 7.536a10.158 10.158 0 0 1 10.167 10.167Zm0 0"
            data-name="Trac\xE9 250"
        />
        <path
            fill="#eab599"
            d="M25.294 23.027a.976.976 0 1 1-.976-.977.976.976 0 0 1 .976.977Zm0 0"
            data-name="Trac\xE9 251"
        />
        <path
            fill="#f6caae"
            d="M23.69 17.703v3.871a10.167 10.167 0 0 1-8.535 10.035l-.05.008a10.167 10.167 0 0 1-8.582-10.043v-3.871a10.169 10.169 0 0 1 8.585-10.045 10.169 10.169 0 0 1 8.582 10.045Zm0 0"
            data-name="Trac\xE9 252"
        />
        <path
            fill="#3d4159"
            d="M11.36 20.982a1.153 1.153 0 1 1-1.154-1.154 1.154 1.154 0 0 1 1.154 1.154Zm0 0"
            data-name="Trac\xE9 253"
        />
        <path
            fill="#3d4159"
            d="M17.996 20.982a1.153 1.153 0 1 1-1.153-1.154 1.153 1.153 0 0 1 1.153 1.154Zm0 0"
            data-name="Trac\xE9 254"
        />
        <path
            fill="#fff"
            d="M11.125 20.779a.494.494 0 1 1-.494-.494.494.494 0 0 1 .494.494Zm0 0"
            data-name="Trac\xE9 255"
        />
        <path
            fill="#fff"
            d="M17.819 20.779a.494.494 0 1 1-.494-.494.494.494 0 0 1 .494.494Zm0 0"
            data-name="Trac\xE9 256"
        />
        <path
            fill="#eab599"
            d="M14.678 23.433h-2.306a.5.5 0 0 1 0-1h2.307a.5.5 0 1 1 0 1Zm0 0"
            data-name="Trac\xE9 257"
        />
        <path
            fill="#eab599"
            d="M13.525 26.581a5.14 5.14 0 0 1-2.478-.64.452.452 0 0 1 .436-.793 4.222 4.222 0 0 0 4.086 0 .452.452 0 0 1 .435.793 5.141 5.141 0 0 1-2.479.64Zm0 0"
            data-name="Trac\xE9 258"
        />
        <path
            fill="#e8c934"
            d="M23.692 14.189v3.966h-1.893v-3.106L8.141 12.511l-2.89-.537v6.181H3.359v-3.966a8.184 8.184 0 0 1 8.184-8.185h3.964c.108 0 .215 0 .323.007a8.183 8.183 0 0 1 7.862 8.178Zm0 0"
            data-name="Trac\xE9 259"
        />
        <path
            fill="#ffe152"
            d="M23.692 14.19v3.966h-1.894V15.05L8.141 12.512a7.837 7.837 0 0 1 15.551 1.678Zm0 0"
            data-name="Trac\xE9 260"
        />
        <path
            fill="#e8c934"
            d="M44.28 57.096v2.148a31.231 31.231 0 0 1-19.837 2.219q-1.291-.282-2.539-.667a30.913 30.913 0 0 1-4.441-1.757v-1.943a10.117 10.117 0 0 1 7.493-9.771 9.21 9.21 0 0 1 .477-.115 10.178 10.178 0 0 1 1.317-.194c.273-.023.548-.033.827-.033h6.587a10.125 10.125 0 0 1 2.145.227l.118.025c.117.027.233.056.348.088h.009a10.129 10.129 0 0 1 7.5 9.773Zm0 0"
            data-name="Trac\xE9 261"
        />
        <path
            fill="#ffe152"
            d="M44.28 57.096v2.148a31.231 31.231 0 0 1-19.837 2.219q-1.291-.282-2.539-.667v-3.7a10.118 10.118 0 0 1 7.493-9.771 9.21 9.21 0 0 1 .477-.115 10.178 10.178 0 0 1 1.317-.194c.273-.023.548-.033.827-.033h2.146a10.124 10.124 0 0 1 2.145.227l.118.025c.117.027.233.056.348.088h.009a10.128 10.128 0 0 1 7.5 9.773Zm0 0"
            data-name="Trac\xE9 262"
        />
        <path
            fill="#f2f0ec"
            d="m36.809 47.206-.026.114-3.165 14.749q-1.251.1-2.528.1c-1 0-1.991-.047-2.967-.141l-3.162-14.709-.026-.115Zm0 0"
            data-name="Trac\xE9 263"
        />
        <path
            fill="#d8d5d0"
            d="m30.87 51.118-4.553 2.531-1.386-6.443 2.829-1.666"
            data-name="Trac\xE9 264"
        />
        <path
            fill="#d8d5d0"
            d="m30.87 51.118 4.554 2.531 1.385-6.443-2.829-1.666"
            data-name="Trac\xE9 265"
        />
        <path
            fill="#f74f4f"
            d="M17.275 44.54V29.286a13.6 13.6 0 1 1 27.193 0v15.253"
            data-name="Trac\xE9 266"
        />
        <path
            fill="#dd3033"
            d="M22.868 29.881c0 5.4 3.159 11.637 4.894 14.658H17.275V29.285a13.587 13.587 0 0 1 13.5-13.6c-4.184.109-7.907 6.815-7.907 14.196Zm0 0"
            data-name="Trac\xE9 267"
        />
        <path
            fill="#eab599"
            d="M34.48 43.082v4.428a3.609 3.609 0 0 1-7.217 0v-4.428Zm0 0"
            data-name="Trac\xE9 268"
        />
        <path
            fill="#f6caae"
            d="M34.479 47.23v.28a2.609 2.609 0 1 1-5.053.47q.361.022.727.022a12.455 12.455 0 0 0 4.326-.772Zm0 0"
            data-name="Trac\xE9 269"
        />
        <path
            fill="#f6caae"
            d="M20.972 35.823a3.357 3.357 0 0 1-2.818 3.313 3.394 3.394 0 0 1-.538.043 3.356 3.356 0 1 1 0-6.711 3.4 3.4 0 0 1 .538.043 3.356 3.356 0 0 1 2.818 3.312Zm0 0"
            data-name="Trac\xE9 270"
        />
        <path
            fill="#eab599"
            d="M20.969 35.824a3.357 3.357 0 0 1-2.818 3.313 12.544 12.544 0 0 1 0-6.626 3.356 3.356 0 0 1 2.818 3.313Zm0 0"
            data-name="Trac\xE9 271"
        />
        <path
            fill="#eab599"
            d="M18.814 35.824a1.2 1.2 0 1 1-1.2-1.2 1.2 1.2 0 0 1 1.2 1.2Zm0 0"
            data-name="Trac\xE9 272"
        />
        <path
            fill="#eab599"
            d="M39.488 31.539v11.535a12.438 12.438 0 0 1-6.613 3.29l-.061.01a12.3 12.3 0 0 1-1.943.151A12.485 12.485 0 0 1 18.386 34.04v-4.755a12.559 12.559 0 0 1 .128-1.793 7.911 7.911 0 0 0 9.943-.64 7.069 7.069 0 0 0 1.876-2.934 8.239 8.239 0 0 0 2.577 5.143 9.214 9.214 0 0 0 6.578 2.478Zm0 0"
            data-name="Trac\xE9 273"
        />
        <path
            fill="#f6caae"
            d="M39.487 31.539v11.535a12.438 12.438 0 0 1-6.613 3.29l-.061.01a12.487 12.487 0 0 1-10.54-12.334v-4.755c0-.127 0-.252.007-.378a7.824 7.824 0 0 0 6.176-2.055 7.069 7.069 0 0 0 1.877-2.934 8.242 8.242 0 0 0 2.577 5.143 9.214 9.214 0 0 0 6.577 2.478Zm0 0"
            data-name="Trac\xE9 274"
        />
        <path
            fill="#eab599"
            d="M37.576 32.986a2.074 2.074 0 1 0-4.147 0"
            data-name="Trac\xE9 275"
        />
        <path
            fill="#eab599"
            d="M28.314 32.986a2.074 2.074 0 1 0-4.147 0"
            data-name="Trac\xE9 276"
        />
        <path
            fill="#3d4159"
            d="M27.657 33.869a1.416 1.416 0 1 1-1.416-1.416 1.416 1.416 0 0 1 1.416 1.416Zm0 0"
            data-name="Trac\xE9 277"
        />
        <path
            fill="#3d4159"
            d="M36.92 33.869a1.416 1.416 0 1 1-1.416-1.416 1.416 1.416 0 0 1 1.416 1.416Zm0 0"
            data-name="Trac\xE9 278"
        />
        <path
            fill="#eab599"
            d="M32.288 37.434h-2.833a.611.611 0 1 1 0-1.222h2.833a.611.611 0 0 1 0 1.222Zm0 0"
            data-name="Trac\xE9 279"
        />
        <path
            fill="#eab599"
            d="M30.871 41.303a6.312 6.312 0 0 1-3.044-.786.555.555 0 1 1 .535-.973 5.182 5.182 0 0 0 5.018 0 .555.555 0 1 1 .535.973 6.308 6.308 0 0 1-3.044.786Zm0 0"
            data-name="Trac\xE9 280"
        />
        <path
            fill="#fff"
            d="M27.368 33.619a.607.607 0 1 1-.607-.607.606.606 0 0 1 .607.607Zm0 0"
            data-name="Trac\xE9 281"
        />
        <path
            fill="#fff"
            d="M36.7 33.619a.607.607 0 1 1-.607-.607.606.606 0 0 1 .607.607Zm0 0"
            data-name="Trac\xE9 282"
        />
        <path
            fill="#eab599"
            d="M39.37 37.319a1.555 1.555 0 1 1-1.555-1.555 1.555 1.555 0 0 1 1.555 1.555Zm0 0"
            data-name="Trac\xE9 283"
        />
        <path
            fill="#eab599"
            d="M25.484 37.319a1.555 1.555 0 1 1-1.555-1.555 1.555 1.555 0 0 1 1.555 1.555Zm0 0"
            data-name="Trac\xE9 284"
        />
    </svg>
);
