import React from "react";

export default function CoinOperatedCashSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8766">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="atm (1)" fill="#fff">
                    <path
                        data-name="Trac\xE9 8616"
                        d="M24.206 17.587a5.662 5.662 0 105.662 5.662 5.669 5.669 0 00-5.662-5.662zm0 9.543a3.88 3.88 0 113.88-3.88 3.885 3.885 0 01-3.88 3.882zm0 0"
                    />
                    <path
                        data-name="Trac\xE9 8617"
                        d="M38.312 10.1A3.728 3.728 0 0035.658 9H12.754a3.754 3.754 0 00-3.753 3.754v3.817a3.754 3.754 0 003.754 3.754h1.971v16.287a2.8 2.8 0 002.8 2.8h13.36a2.8 2.8 0 002.8-2.8V20.325h1.972a3.754 3.754 0 003.753-3.754v-3.817a3.728 3.728 0 00-1.1-2.654zm-6.411 26.512a1.018 1.018 0 01-1.018 1.018H17.526a1.018 1.018 0 01-1.018-1.018V15.553h15.393zm5.726-20.04a1.972 1.972 0 01-1.972 1.972h-1.97v-2.99h1.016a.891.891 0 100-1.782H13.708a.891.891 0 000 1.782h1.018v2.99h-1.972a1.972 1.972 0 01-1.972-1.973v-3.817a1.972 1.972 0 011.972-1.972h22.9a1.972 1.972 0 011.972 1.972zm0 0"
                    />
                    <path
                        data-name="Trac\xE9 8618"
                        d="M28.976 32.858h-9.543a.891.891 0 000 1.782h9.543a.891.891 0 000-1.782zm0 0"
                    />
                </g>
            </g>
        </svg>
    );
}
