import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import { NavWrapper } from "./NavWrapper";
import { TabContentWrapper } from "./TabContentWrapper";

import "@assets/widths.css";
import "@assets/nav.css";

export function MainRow() {
    const { t } = useTranslation();

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>
                            {t("List of objects to be treated")}
                        </CardTitle>
                        <NavWrapper />
                        <TabContentWrapper />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
