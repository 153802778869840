import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/Employees/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/OldDownloadDropdown";

import Top10ProductsCol from "./Top10ProductsCol";
import StatisticsTop10ProductsCol from "./StatisticsTop10ProductsCol";
import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import CustomSelectFilter from "@components/Common/CustomSelectFilter";

export default function Top10ProductsRow() {
    const { t } = useTranslation();

    const { employeesQueryString } = useSnapshot(store);

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);

    const [top10ProductsSelection, setTop10ProductsSelection] = React.useState<
        string
    >("");
    const [products, setProducts] = React.useState<string[]>([]);

    const {
        resourceData: productsList,
        error: productsListError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/chef/product-list?${getAdvancedPeriodStringParam(
            period,
            advancedDate
        )}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            type === t("Average turnover") ? "" : `&type=${getTypeParam(type)}`
        }${employeesQueryString !== "" ? `&chef=${employeesQueryString}` : ""}`
    );

    React.useEffect(() => {
        setProducts([t("Top 10 products"), ...productsList.data]);
    }, [productsList, t]);

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                    percentageData,
                    setPercentageData,
                    histogramData,
                    setHistogramData,
                    categoriesData,
                    setCategoriesData,
                    top10ProductsSelection,
                    setTop10ProductsSelection,
                }}
            >
                <TitleRow title={t("Cooks per top 10 products")}>
                    {productsListError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <CustomSelectFilter options={products} />
                        </CustomErrorBoundary>
                    )}
                    <DownloadDropdown />
                </TitleRow>
                <div
                    className="d-grid__clz 
                        grid-gap__clz 
                        grid-xl-statistics-families-and-products-details__clz 
                        grid-lg-statistics-template-details__clz 
                        grid-md-statistics-template-details__clz 
                        grid-sm-template-col__clz 
                        grid-xs-template-col__clz 
                        pr-0"
                >
                    <CustomErrorBoundary>
                        <Top10ProductsCol />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <StatisticsTop10ProductsCol />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
