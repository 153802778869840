import React from "react";
import { Modal, Popover } from "reactstrap";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import DateRangePickerWrapper from "@components/Common/DateRangePickerWrapper";
import MobileDateRangePickerWrapper from "@components/Common/MobileDateRangePickerWrapper";
import { StatisticsOutOfStockStyledPopoverBody } from "./StatisticsOutOfStockStyledPopoverBody";
import { StyledPopoverBody } from "./StyledPopoverBody";

import "./index.css";

export function PeriodModalWrapper() {
    const { pathname } = useLocation();
    const period = useSelector((state: { period: string }) => state.period);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isPopoverOpened, setIsPopoverOpened] = React.useState(false);

    function toggleOpenedPopover() {
        setIsPopoverOpened((prevState) => !prevState);
    }

    function toggleOpeningModal() {
        setIsModalOpened((prevState) => !prevState);
    }

    return (
        <React.Fragment>
            <button
                id="date-range-picker-popover"
                type="button"
                className="font-size-16 text-dark bg-transparent border-0 mr-2 px-0"
                onClick={
                    isMobile === false
                        ? toggleOpenedPopover
                        : toggleOpeningModal
                }
            >
                <h5
                    style={{
                        textDecoration: "underline",
                    }}
                    className="white-space-nowrap"
                >
                    {period}
                </h5>
            </button>
            {isMobile === false ? (
                <Popover
                    placement="top"
                    isOpen={isPopoverOpened}
                    target="date-range-picker-popover"
                    toggle={toggleOpenedPopover}
                    trigger="legacy"
                >
                    {pathname === "/statistics-out-of-stock" ? (
                        <StatisticsOutOfStockStyledPopoverBody>
                            <DateRangePickerWrapper
                                toggleOpenedPopover={toggleOpenedPopover}
                            />
                        </StatisticsOutOfStockStyledPopoverBody>
                    ) : (
                        <StyledPopoverBody>
                            <DateRangePickerWrapper
                                toggleOpenedPopover={toggleOpenedPopover}
                            />
                        </StyledPopoverBody>
                    )}
                </Popover>
            ) : (
                <Modal
                    isOpen={isModalOpened}
                    toggle={toggleOpeningModal}
                    className="defined-range-modal__clz"
                    centered={true}
                    fade={false}
                >
                    <MobileDateRangePickerWrapper
                        toggleOpeningModal={toggleOpeningModal}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
}
