import React from "react";

export default function TakeOutSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8793">
                <g data-name="Groupe 8787">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="Groupe 8450">
                    <path
                        data-name="Trac\xE9 8592"
                        d="M35.999 36.329l-1.745-19.2a1.027 1.027 0 00-1.022-.929h-3.075v-2.05a6.15 6.15 0 10-12.3 0v2.05h-3.075a1.024 1.024 0 00-1.021.933l-1.745 19.2A4.1 4.1 0 0016.1 40.8h15.816A4.1 4.1 0 0036 36.329zM19.907 14.15a4.1 4.1 0 118.2 0v2.05h-8.2zm13.522 23.934a2.035 2.035 0 01-1.515.666H16.1a2.05 2.05 0 01-2.04-2.237l1.659-18.263h2.138v3.075a1.025 1.025 0 102.05 0V18.25h8.2v3.075a1.025 1.025 0 102.05 0V18.25h2.138l1.66 18.266a2.024 2.024 0 01-.526 1.568z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
}
