import { AnyAction } from "redux";

const INIT_STATE = "";

export default function groupDesignation(
    state = INIT_STATE,
    action: AnyAction
): string | null {
    if (action.type === "GROUP_DESIGNATION") {
        return action.payload;
    } else {
        return state !== undefined ? state : null;
    }
}
