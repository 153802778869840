import React from "react";

type SettingsSubFamiliesContextType = {
    groups: any[];
    setGroups: Function;
};

export const SettingsSubFamiliesContext = React.createContext<
    SettingsSubFamiliesContextType
>({
    groups: [],
    setGroups: () => {},
});
