import React from "react";

export default function DesktopSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            width="1em"
            height="1em"
            style={{
                msTransform: "rotate(360deg)",
                WebkitTransform: "rotate(360deg)",
            }}
            viewBox="0 0 36 36"
            transform="rotate(360)"
            {...props}
        >
            <path
                fill="#CCD6DD"
                d="M36 22a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h28a4 4 0 014 4v18z"
            />
            <path fill="#5DADEC" d="M4 4h28v18H4z" />
            <path fill="#CCD6DD" d="M13 26h10v6H13z" />
            <path fill="#9AAAB4" d="M13 26h10v2H13z" />
            <path
                fill="#E1E8ED"
                d="M36 33a3 3 0 00-3-3H3a3 3 0 100 6h30a3 3 0 003-3z"
            />
            <path
                fill="#F5F8FA"
                d="M3 32h2v2H3zm4 0h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2z"
            />
        </svg>
    );
}
