import React from "react";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import { Container, Row, Col, CardBody, Card, Button } from "reactstrap";
import PageTitle from "@components/Common/PageTitle";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import NProgressWrapper from "@components/Common/NProgressWrapper";
import byCompanySvg from "@assets/images/by_softavera.svg";

export default function LockScreen({ loading }: any) {
    const [fullname, setFullname] = React.useState("");
    const { t } = useTranslation();

    React.useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser")!);
            if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
                setFullname(`${obj.firstname} ${obj.lastname}`);
            }
        }
    }, []);

    return (
        <React.Fragment>
            <PageTitle title={t("Lock screen")} />
            <video
                autoPlay
                loop
                muted
                style={{
                    zIndex: 0,
                }}
                className={`${
                    isMobile ? "w-auto" : "w-100"
                } h-auto position-fixed float-left`}
            >
                <source src="background.mp4" type="video/mp4" />
                <source src="background.webm" type="videos/webm" />
            </video>

            <Container className="account-pages pt-sm-5 overflow-hidden">
                <NProgressWrapper isLoading={loading} />
                {isMobile && (
                    <Row className="mobile__clz d-md-none d-xl-none d-lg-none">
                        <div className="position-fixed">
                            <Card
                                className="w-100 overflow-hidden align-items-center"
                                style={{
                                    marginTop: "60%",
                                    marginLeft: "25%",
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                                    WebkitBoxShadow:
                                        "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                                    MozBoxShadow:
                                        "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                                }}
                            >
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <AvForm className="form-horizontal">
                                            <div className="user-thumb text-center mb-4">
                                                <i
                                                    className="bx bx-user-circle mr-3"
                                                    style={{ fontSize: "40px" }}
                                                />
                                                <h5 className="font-size-15 mt-3">
                                                    {fullname}
                                                </h5>
                                            </div>

                                            <div className="form-group">
                                                <AvField
                                                    name="password"
                                                    label={t("Password")}
                                                    type="password"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: t(
                                                                "This field is required"
                                                            ),
                                                        },
                                                    }}
                                                    placeholder={t(
                                                        "Enter password"
                                                    )}
                                                />
                                            </div>

                                            <div className="d-flex justify-content-center mt-4">
                                                <Button
                                                    className="text-uppercase px-5 btn btn-sm btn-dark"
                                                    type="submit"
                                                    style={{
                                                        boxShadow:
                                                            "0px 15px 20px 7px #bfbdbd",
                                                    }}
                                                >
                                                    {t("Unlock")}
                                                </Button>
                                            </div>
                                        </AvForm>
                                        <div className="mt-4 text-center">
                                            <Link
                                                to="/login"
                                                className="text-muted"
                                            >
                                                {t("Sign In")}
                                            </Link>
                                        </div>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <img
                                                src={byCompanySvg}
                                                alt="Logo"
                                                height="20"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                )}
                {!isMobile && (
                    <Row className="desktop__clz justify-content-md-center">
                        <Col md="8" lg="6" xl="5">
                            <Card
                                className="overflow-hidden"
                                style={{ marginTop: "30%" }}
                            >
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <AvForm className="form-horizontal">
                                            <div className="user-thumb text-center mb-4">
                                                <i
                                                    className="bx bx-user-circle mr-3"
                                                    style={{ fontSize: "40px" }}
                                                />
                                                <h5 className="font-size-15 mt-3">
                                                    {fullname}
                                                </h5>
                                            </div>

                                            <div className="form-group">
                                                <AvField
                                                    name="password"
                                                    label={t("Password")}
                                                    type="password"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: t(
                                                                "This field is required"
                                                            ),
                                                        },
                                                    }}
                                                    placeholder={t(
                                                        "Enter password"
                                                    )}
                                                />
                                            </div>

                                            <div className="d-flex justify-content-center mt-4">
                                                <Button
                                                    className="text-uppercase px-5 btn btn-sm btn-dark"
                                                    type="submit"
                                                    style={{
                                                        boxShadow:
                                                            "0px 15px 20px 7px #bfbdbd",
                                                    }}
                                                >
                                                    {t("Unlock")}
                                                </Button>
                                            </div>
                                        </AvForm>
                                        <div className="mt-4 text-center">
                                            <Link
                                                to="/login"
                                                className="text-muted"
                                            >
                                                {t("Sign In")}
                                            </Link>
                                        </div>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <img
                                                src={byCompanySvg}
                                                alt="Logo"
                                                height="20"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment>
    );
}
