import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import TitleRow from "@components/Common/TitleRow";
import { MyBestShop } from "../MyBestShop";

export function MyBestShopCol() {
    const { t } = useTranslation();
    return (
        <Col md="12">
            <TitleRow title={t("Your best shop").toUpperCase()}>
                {null}
            </TitleRow>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <MyBestShop />
                </CustomErrorBoundary>
            </React.Suspense>
        </Col>
    );
}
