import React from "react";
import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";

import MySelectionsContext from "@pages/MySelections/context";

import store, { setCheckedFranchises } from "@pages/MySelections/store";

import { ITEMS_PER_PAGE } from "@constants/index";

import { TableRow } from "../TableRow";
import Pagination from "@components/Common/Pagination";

import "./index.css";

export default function FranchisesTable() {
    const { t } = useTranslation();
    const {
        searchedFranchises,
        isFranchiseChosen,
        setExpandedRows,
        selectedSelectionValue,
    } = React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    const [totalItems, setTotalItems] = React.useState<number>(0);

    const [currentPage, setCurrentPage] = React.useState<number>(1);

    const [filtered, setFiltered] = React.useState<FranchisesDataType[]>([]);
    const [filteredData, setFilteredData] = React.useState<
        FranchisesDataType[]
    >([]);

    React.useEffect(() => {
        setCheckedFranchises([]);
    }, []);

    React.useEffect(() => {
        let localTable: any[] = [];
        filteredData.forEach((el: any) =>
            localTable.push({
                id: `${el.id}|${el
                    .shops!.map((shop: FranchisesDataType) => shop.id)
                    .join(",")}`,
                expanded: false,
            })
        );

        setExpandedRows!(localTable);

        if (
            (MySelectionsStore.mySavedSelectionSelectAction.action ===
                "clear" ||
                MySelectionsStore.mySavedSelectionSelectAction.action ===
                    undefined ||
                (MySelectionsStore.mySavedSelectionSelectAction.action ===
                    "remove-value" &&
                    selectedSelectionValue === null)) &&
            MySelectionsStore.searchedElementInputValue.length === 0 &&
            !isFranchiseChosen
        ) {
            setFilteredData(MySelectionsStore.allFranchises);
            setTotalItems(MySelectionsStore.allFranchises.length);
        } else if (MySelectionsStore.searchedElementInputValue.length !== 0) {
            setFilteredData(searchedFranchises);
            setTotalItems(searchedFranchises!.length);
        } else {
            setFilteredData(MySelectionsStore.filteredFranchises);
            setTotalItems(MySelectionsStore.filteredFranchises.length);
        }
    }, [
        setExpandedRows,
        MySelectionsStore.filteredFranchises,
        searchedFranchises,
        MySelectionsStore.searchedElementInputValue,
        filtered,
        MySelectionsStore.mySavedSelectionSelectAction,
        isFranchiseChosen,
        filteredData,
        selectedSelectionValue,
        MySelectionsStore.allFranchises,
    ]);

    React.useEffect(() => {
        setFiltered(
            filteredData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            )
        );

        if (
            MySelectionsStore.searchedElementInputValue.length !== 0 &&
            searchedFranchises!.length <= ITEMS_PER_PAGE
        ) {
            setCurrentPage(1);
        }

        if (totalItems % ITEMS_PER_PAGE !== 0) {
            if (Math.trunc(totalItems / ITEMS_PER_PAGE) + 1 < currentPage) {
                setCurrentPage(1);
            }
        } else {
            if (totalItems / ITEMS_PER_PAGE < currentPage) {
                setCurrentPage(1);
            }
        }
    }, [
        filteredData,
        currentPage,
        MySelectionsStore.searchedElementInputValue,
        searchedFranchises,
        totalItems,
    ]);

    return (
        <React.Fragment>
            <div className="mt-3 table-responsive fixed-table-head__clz">
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("Franchise")}</th>
                            <th>{t("Shop")}</th>
                            <th>{t("Kind")}</th>
                            <th>{t("Address")}</th>
                            <th>{t("Zip code")}</th>
                            <th>{t("City")}</th>
                            <th>{t("Region")}</th>
                            <th>{t("Country")}</th>
                            <th className="text-center">
                                {t("See the details")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered.map((franchise: FranchisesDataType) => (
                            <TableRow
                                key={uid(franchise.id)}
                                franchise={franchise}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
            {totalItems > ITEMS_PER_PAGE && (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page: any) => setCurrentPage(page)}
                />
            )}
        </React.Fragment>
    );
}
