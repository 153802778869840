import i18next from "i18next";
import { signal } from "@preact/signals-react";

export const top10FamiliesData = signal<any[]>([]);
export const top10SubFamiliesData = signal<any[]>([]);
export const categories = signal<string[]>([]);
export const top10FamiliesSelection = signal<string>("");
export const families = signal<string[]>([i18next.t("Top 10 families")]);
export const top10SubFamiliesSelection = signal<string>("");
export const top10ProductsSelection = signal<string>("");

export function setTop10FamiliesData(families: any[]) {
    top10FamiliesData.value = families;
}

export function setTop10SubFamiliesData(subFamilies: any[]) {
    top10SubFamiliesData.value = subFamilies;
}

export function setCategories(newCategories: string[]) {
    categories.value = newCategories;
}

export function setTop10FamiliesSelection(selection: string) {
    top10FamiliesSelection.value = selection;
}

export function setTop10SubFamiliesSelection(selection: string) {
    top10SubFamiliesSelection.value = selection;
}

export function setTop10ProductsSelection(selection: string) {
    top10ProductsSelection.value = selection;
}

export function setFamilies(newFamilies: string[]) {
    families.value = newFamilies;
}
