import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import "@assets/scss/dashboard.scss";

type ApexRadialPropsType = {
    data: number;
};

export default function ApexRadial({ data }: ApexRadialPropsType) {
    const { t } = useTranslation();

    const options = {
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: "13px",
                        color: void 0,
                        offsetY: 60,
                    },
                    value: {
                        offsetY: 22,
                        fontSize: "16px",
                        color: void 0,
                        formatter: function (e: any) {
                            return e + "%";
                        },
                    },
                },
            },
        },
        colors: ["#292929"],
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                shadeIntensity: 0.15,
                inverseColors: !1,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91],
            },
        },
        stroke: {
            dashArray: 4,
        },
        labels: [`/${t("CMD Total")}`],
    };

    const series = [data];

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height="150"
        />
    );
}
