import React from "react";
import { useTranslation } from "react-i18next";
import BTable from "react-bootstrap/Table";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useFilters,
} from "react-table";

import { ITEMS_PER_PAGE } from "@constants/index";

import Pagination from "@components/Common/Table/Pagination";
import GlobalFilter from "./GlobalFilter";

type PropsType = { columns: AdvancedTableColumnsType[]; data: any[] };

export default function Table({ columns, data }: PropsType) {
    const { t } = useTranslation();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: data,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            <div className="row px-3 justify-content-between">
                <h3 className="mt-2">{t("Details")}</h3>
                <div className="float-right w-xs-100">
                    <div className="app-search pt-0">
                        <div className="position-relative">
                            <GlobalFilter
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                            />
                            <span className="bx bx-search-alt"></span>
                        </div>
                    </div>
                </div>
            </div>
            <BTable responsive {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => {
                                return (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {data.length === 0 ? (
                        <tr>
                            <td className="text-center" colSpan={8}>
                                {t("No data")}
                            </td>
                        </tr>
                    ) : (
                        page.map((row: any) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td
                                                className="white-space-nowrap"
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </BTable>
            {data.length > ITEMS_PER_PAGE ? (
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                />
            ) : null}
        </React.Fragment>
    );
}
