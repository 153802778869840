import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "@vStore";
import { store as myShopsStore } from "@pages/Statistics/Shops/MyShopsCol/MyShopsRowCardWrapper/store";

import {
    findObjectWithLongestShopKey,
    formatCurrency,
    searchKeyByValue,
} from "@helpers/general";

type PropsType = {
    selectedRows: any[];
};

export function TotalTableWrapper({ selectedRows }: PropsType) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);
    const { titleRowWidth } = useSnapshot(myShopsStore);

    const shopWithTheLongestLastOrderDate = findObjectWithLongestShopKey(
        selectedRows,
        t("Last order")
    );

    const ticketsSum = selectedRows
        .map((row) => {
            return Number(row[t(searchKeyByValue("Tickets"))]);
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const vatSum = selectedRows
        .map((row) => {
            return Number(row[t(searchKeyByValue("TVA"))]);
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const exTaxSum = selectedRows
        .map((row) => {
            return Number(row[t(searchKeyByValue("HT"))]);
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const inclTaxSum = selectedRows
        .map((row) => {
            return Number(row[t(searchKeyByValue("TTC"))]);
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const avgBasketSum = formatCurrency(inclTaxSum / ticketsSum);

    return (
        <table
            id="total-table-wrapper"
            className={classNames("table-responsive rounded p-2 text-white", {
                "bg-dark": mainColor === null,
            })}
            style={{ background: mainColor }}
        >
            <tr
                className="d-flex overflow-hidden"
                style={{ flex: "1 0 auto", minWidth: "200px" }}
            >
                <td
                    className="d-flex align-items-center"
                    style={{
                        flex: "150 0 auto",
                        minWidth: "10px",
                        width: `${titleRowWidth}px`,
                        justifyContent: "center",
                        boxSizing: "border-box",
                    }}
                >
                    {t("Total")} ({selectedRows.length})
                </td>
                <td
                    className="d-flex align-items-center"
                    style={{
                        flex: "150 0 auto",
                        minWidth: "10px",
                        width: "150px",
                        justifyContent: "center",
                        boxSizing: "border-box",
                    }}
                >
                    {ticketsSum}
                </td>
                <td
                    className="d-flex align-items-center"
                    style={{
                        flex: "150 0 auto",
                        minWidth: "10px",
                        width: "150px",
                        justifyContent: "end",
                        boxSizing: "border-box",
                    }}
                >
                    {avgBasketSum}
                </td>
                <td
                    className="d-flex align-items-center"
                    style={{
                        flex: "150 0 auto",
                        minWidth: "10px",
                        width: "150px",
                        justifyContent: "end",
                        boxSizing: "border-box",
                    }}
                >
                    {formatCurrency(vatSum)}
                </td>
                <td
                    className="d-flex align-items-center"
                    style={{
                        flex: "150 0 auto",
                        minWidth: "10px",
                        width: "150px",
                        justifyContent: "end",
                        boxSizing: "border-box",
                    }}
                >
                    {formatCurrency(exTaxSum)}
                </td>
                <td
                    className="d-flex align-items-center"
                    style={{
                        flex: "150 0 auto",
                        minWidth: "10px",
                        width: "150px",
                        justifyContent: "end",
                        boxSizing: "border-box",
                    }}
                >
                    {formatCurrency(inclTaxSum)}
                </td>
                <td style={{ visibility: "hidden" }}>
                    {shopWithTheLongestLastOrderDate[t("Last order")]}
                </td>
            </tr>
        </table>
    );
}
