import * as React from "react";
import { SVGProps } from "react";

export const LogOutSvgIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={800}
        height={800}
        viewBox="0 0 1000 1000"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M591.61 280.48C693.9 317.86 766.91 416 766.91 531.26c0 147.41-119.5 266.91-266.91 266.91s-266.91-119.5-266.91-266.91c0-115.22 73-213.4 175.3-250.78"
            style={{
                fill: "none",
                stroke: "#020202",
                strokeLinecap: "round",
                strokeMiterlimit: 10,
                strokeWidth: 22,
            }}
        />
        <rect
            height={160.61}
            rx={35.92}
            width={71.84}
            x={464.08}
            y={201.83}
            style={{
                fill: "#020202",
            }}
        />
    </svg>
);
