import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import RecapTop10Employees from "./RecapTop10Employees";
import PercentageTop10Employees from "./PercentageTop10Employees";

import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/OldDownloadDropdown";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

export default function Top10EmployeesRow() {
    const { t } = useTranslation();

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);

    return (
        <StatisticsContext.Provider
            value={{
                period,
                type,
                advancedDate,
                selections,
                percentageData,
                setPercentageData,
                histogramData,
                setHistogramData,
                categoriesData,
                setCategoriesData,
            }}
        >
            <TitleRow title={t("Top 10 cashiers")}>
                <DownloadDropdown />
            </TitleRow>

            <div
                className="d-grid__clz 
                        grid-gap__clz 
                        grid-xl-statistics-families-and-products-details__clz 
                        grid-lg-statistics-template-details__clz 
                        grid-md-statistics-template-details__clz 
                        grid-sm-template-col__clz 
                        grid-xs-template-col__clz 
                        pr-0"
            >
                <CustomErrorBoundary>
                    <PercentageTop10Employees />
                </CustomErrorBoundary>
                <CustomErrorBoundary>
                    <RecapTop10Employees />
                </CustomErrorBoundary>
            </div>
        </StatisticsContext.Provider>
    );
}
