import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ArrowRightSvgIcon } from "@components/Common/SvgIcons";

export default function SecondStep() {
    const { t } = useTranslation();
    let history = useHistory();

    return (
        <Card className="mb-0" style={{ height: "100%" }}>
            <div
                className="d-flex justify-content-start ml-5"
                style={{ height: "100%" }}
            >
                <div className="mt-4" style={{ minWidth: "50%" }}>
                    <CardTitle
                        className="mt-4 px-4 text-uppercase"
                        style={{
                            color: "#141414",
                            fontSize: 30,
                        }}
                    >
                        {t("Activate your account")}
                    </CardTitle>
                    <CardBody className="px-0">
                        <div>
                            <p
                                style={{ color: "#000000" }}
                                className="ml-4 font-weight-normal"
                            >
                                {t("To activate your account please")}
                            </p>
                            <p className="ml-5">
                                <p className="d-flex align-items-center font-weight-light">
                                    <span className="mr-2 registration-second-step-step-number__clz">
                                        1
                                    </span>
                                    <p
                                        className="mb-0"
                                        style={{ color: "#3A3A3A" }}
                                    >
                                        {t(
                                            "Connect to your administration interface"
                                        )}
                                    </p>
                                </p>
                                <p className="d-flex align-items-center font-weight-light">
                                    <span className="mr-2 registration-second-step-step-number__clz">
                                        2
                                    </span>
                                    <p
                                        className="mb-0"
                                        style={{ color: "#3A3A3A" }}
                                    >
                                        {t(
                                            "Authorize online consultation of your data"
                                        )}
                                    </p>
                                </p>
                            </p>
                        </div>
                    </CardBody>
                </div>
            </div>
            <hr
                className="mx-4 my-0"
                style={{
                    color: "#C4C4C4",
                    border: "1px solid #C4C4C4",
                }}
            />
            <div className="d-flex justify-content-end mx-3 p-2">
                <Button
                    type="button"
                    color="dark"
                    className="px-4"
                    onClick={() => history.push("/login")}
                >
                    <div className="d-flex align-items-center">
                        <div style={{ fontSize: 14 }}>{t("Next")}</div>
                        <ArrowRightSvgIcon style={{ filter: "invert(1)" }} />
                    </div>
                </Button>
            </div>
        </Card>
    );
}
