import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Button, CardBody, Card } from "reactstrap";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "@vStore";

import { getAuthUserShopsNumber } from "@helpers/general";

import PageTitle from "@components/Common/PageTitle";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import FranchisesTable from "./FranchisesTable";

export default function MySelectionsList() {
    const { t } = useTranslation();
    const history = useHistory();

    const { mainColor } = useSnapshot(store);
    const user = useSelector(
        (state: {
            Login: {
                user: UserType;
            };
        }) => state.Login.user
    );

    function handleModifyButtonClickEvent() {
        history.push("my-selections");
    }

    const selections = useSelector(
        (state: { selections: SelectionType }) => state.selections
    );

    const designation = selections ? selections.designation : undefined;

    const [shopsNumber, setShopsNumber] = React.useState<number>(0);

    React.useEffect(() => {
        let localShopsNumber: number = 0;

        if (selections.selection?.length > 0) {
            selections.selection?.forEach((selection: any) => {
                localShopsNumber += selection.shops_id.length;
            });
        } else {
            localShopsNumber = getAuthUserShopsNumber(user);
        }

        setShopsNumber(localShopsNumber);
    }, [selections, user]);

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            <CustomErrorBoundary>
                <React.Fragment>
                    <PageTitle title={t("My selection")} />
                    <div
                        className="page-content"
                        style={{ paddingBottom: "5px" }}
                    >
                        <Row>
                            <Col className="my-3">
                                <div className="float-left">
                                    <span className="font-weight-bold text-uppercase">
                                        <h5> {t("My selection")}: </h5>
                                        {designation !== undefined &&
                                        designation !== "" &&
                                        !designation.includes(
                                            "temp_selection_"
                                        ) &&
                                        designation !== "my_favorites" ? (
                                            designation
                                        ) : (
                                            <h6>
                                                {shopsNumber}{" "}
                                                {t("Shop", {
                                                    count: shopsNumber,
                                                })}
                                            </h6>
                                        )}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="px-3">
                                    <CardBody className="mb-5 mb-sm-2 mb-md-5 mb-lg-4 mb-xl-0">
                                        <Row>
                                            <Col>
                                                <div className="p-2 d-flex justify-content-center float-right ">
                                                    <Button
                                                        type="button"
                                                        color={
                                                            mainColor === null
                                                                ? "dark"
                                                                : ""
                                                        }
                                                        style={{
                                                            width: "140px",
                                                            background: mainColor,
                                                        }}
                                                        onClick={() => {
                                                            history.goBack();
                                                        }}
                                                        className={classNames(
                                                            "font-size-sm p-2 text-centre",
                                                            {
                                                                "text-white":
                                                                    mainColor !==
                                                                    null,
                                                            }
                                                        )}
                                                    >
                                                        {t("Return")}
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        style={{
                                                            width: "140px",
                                                        }}
                                                        className="p-2 text-centre ml-2"
                                                        onClick={
                                                            handleModifyButtonClickEvent
                                                        }
                                                    >
                                                        {t("Edit")}
                                                    </Button>
                                                </div>
                                                <React.Suspense
                                                    fallback={
                                                        <SpinnerWrapper />
                                                    }
                                                >
                                                    <CustomErrorBoundary>
                                                        <FranchisesTable />
                                                    </CustomErrorBoundary>
                                                </React.Suspense>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
            </CustomErrorBoundary>
        </React.Suspense>
    );
}
