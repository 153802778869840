import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getRegulationModesQueryString,
    getTypeParam,
} from "@helpers/general";
import getRegulationsDataHelper from "@pages/Dashboard/StatisticsCharts/RegulationsRow/getRegulationDataHelper";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function PercentageCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        selections,
        advancedDate,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: regulationData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/regulations/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getRegulationModesQueryString(
                statisticsStore.regulationModesCards.modes
            ).length > 0
                ? `&regulation=${getRegulationModesQueryString(
                      statisticsStore.regulationModesCards.modes
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        const { amounts, labels } = getRegulationsDataHelper(
            regulationData.data
        );
        setData(amounts);
        setLabels(labels);
        setPercentageData!(amounts);
    }, [regulationData, setPercentageData]);

    const donutChartRegulationModesData: SeriesDataType = {
        data: data,
        labels: labels.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={`${t("Payment type", { count: 2 })}`}
                        data={donutChartRegulationModesData}
                    >
                        <LegendPopoverWrapper
                            title={`${t("Payment type", { count: 2 })}`}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
