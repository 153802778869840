import React from "react";
import { useTranslation } from "react-i18next";
import { StyledSelectInput } from "@aureskonnect/react-ui";

import { setAdvancedLegend } from "../store";

type PropsType = { options: any[] };

export default function SelectFilter({ options }: PropsType) {
    const { t } = useTranslation();

    let localOptions: LocalOptionsType[] = options.map((option: string) => ({
        label: option,
        value: option,
    }));

    const [selectedValueState, setSelectedValueState] = React.useState<
        LocalOptionsType[]
    >([localOptions[0]]);

    function handleSelectOnChangeEvent(selectedValue: any) {
        setSelectedValueState(selectedValue);

        setAdvancedLegend(selectedValue.label);
    }

    return (
        <StyledSelectInput
            id="transactions-select"
            name="transactions-select"
            options={localOptions}
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={handleSelectOnChangeEvent}
            noOptionsMessage={() => t("no option found")}
            className="w-100 mb-2 mb-md-0"
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    minWidth: 200,
                    margin: 1,
                    borderRadius: "5px",
                    padding: "0px",
                    "&:hover": {
                        boxShadow: "unset",
                        borderColor: "unset",
                    },
                }),
                container: (provided: any) => ({
                    ...provided,
                    display: "flex",
                    zIndex: "999",
                }),
            }}
        />
    );
}
