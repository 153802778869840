import React from "react";
import { usePagination, useTable, useRowSelect } from "react-table";
import BTable from "react-bootstrap/Table";
import styled from "styled-components";

import { ITEMS_PER_PAGE } from "@constants/index";

import Pagination from "./Pagination";

type PropsType = {
    columns: AdvancedTableColumnsType[];
    data: any[];
    setSelectedSelection: React.Dispatch<React.SetStateAction<SelectionType>>;
};

const Styles = styled.div`
    table {
        th:first-child {
            width: 5%;
        }
    }
`;

export function Table({ columns, data, setSelectedSelection }: PropsType) {
    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return <input type="checkbox" ref={resolvedRef} {...rest} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllPageRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    React.useEffect(() => {
        let localSelectedSelection: SelectionType = {
            designation: "",
            selection: [],
        };

        if (selectedFlatRows.length > 1) {
            const user: Record<string, any> = JSON.parse(
                localStorage.getItem("authUser")!
            );
            localSelectedSelection.designation = `temp_selection_${user?.id}`;
            localSelectedSelection.selection = selectedFlatRows
                .map((row: any) => row.original)
                .map((row: any) =>
                    row.selection.map((el: any) => Object.assign({}, el))
                )
                .map((proxy) => Object.assign({}, proxy))
                .map((el) => ({
                    ...el[0],
                }));
        } else {
            localSelectedSelection = selectedFlatRows.map(
                (row: any) => row.original
            )[0];
        }

        setSelectedSelection(localSelectedSelection);
    }, [selectedFlatRows, setSelectedSelection]);

    return (
        <React.Fragment>
            <Styles>
                <BTable responsive {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                className="white-space-nowrap"
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </BTable>
            </Styles>
            {data.length > ITEMS_PER_PAGE ? (
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                />
            ) : null}
        </React.Fragment>
    );
}
