import {
    ADD_SALE_MODE,
    CLEAR_SALES_MODES,
    INIT_SALES_MODES,
    SELECT_SALES_MODES,
} from "./actions-types";

export const selectSalesMode = (
    salesMode: string
): {
    type: string;
    payload: string;
} => {
    return {
        type: SELECT_SALES_MODES,
        payload: salesMode,
    };
};

export const addSaleMode = (
    saleMode: string
): {
    type: string;
    payload: string;
} => {
    return {
        type: ADD_SALE_MODE,
        payload: saleMode,
    };
};

export const clearSalesMode = (): {
    type: string;
    payload: [];
} => {
    return {
        type: CLEAR_SALES_MODES,
        payload: [],
    };
};

export function initSalesModes(
    salesModes: string[]
): { type: string; payload: string[] } {
    return {
        type: INIT_SALES_MODES,
        payload: salesModes,
    };
}
