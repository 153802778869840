import React from "react";
import { Row, Container, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PageTitle from "@components/Common/PageTitle";
import { PortalLogoSvgIcon } from "@components/Common/SvgIcons";
import { ReportingSvgIcon } from "@components/Common/SvgIcons";
import Header from "./Header";

import "./index.css";

import ResetPasswordModal from "./ResetPasswordModal";
import { PortalCard } from "./PortalCard";

export default function Home() {
    const { t } = useTranslation();

    const { user } = useSelector((state: any) => state.Login);

    const [firstname, setFirstname] = React.useState<string>("");
    const [isFirstConnection, setIsFirstConnection] = React.useState<boolean>(
        true
    );

    React.useEffect(() => {
        if (user === null) {
            let user = JSON.parse(localStorage.getItem("authUser")!);
            setFirstname(user.firstname);
            setIsFirstConnection(user.isFirstConnection);
        } else {
            setFirstname(user!.firstname);
            setIsFirstConnection(user!.isFirstConnection);
        }
    }, [user]);

    return (
        <React.Fragment>
            <PageTitle title={t("Home")} />
            <Container
                fluid
                style={{
                    padding: "0px",
                }}
                className="header-container__clz"
            >
                <Header />
                <video autoPlay loop muted>
                    <source src="background.mp4" type="video/mp4" />
                </video>
                <Row
                    style={{
                        paddingRight: "90px",
                        paddingLeft: "90px",
                    }}
                >
                    <div className="d-flex flex-column mb-4">
                        <PortalLogoSvgIcon />
                        <div
                            className="text-uppercase home-title__clz"
                            style={{ color: "white" }}
                        >
                            {t("Hello")} {firstname} !
                        </div>
                    </div>
                </Row>
            </Container>

            <Container fluid style={{ paddingBottom: "60px" }}>
                <Row style={{ marginLeft: "50px", marginRight: "50px" }}>
                    <Col xs="3">
                        <PortalCard
                            title={t("Reporting")}
                            description={t(
                                "Allows you to track your activities"
                            )}
                            link="/dashboard"
                            icon={<ReportingSvgIcon />}
                        />
                    </Col>
                </Row>
            </Container>
            {isFirstConnection ? <ResetPasswordModal /> : null}
        </React.Fragment>
    );
}
