import { createContext } from "react";

type SettingsArticlesContextType = {
    groups: any[];
    setGroups: Function;
};

export const SettingsArticlesContext = createContext<
    SettingsArticlesContextType
>({
    groups: [],
    setGroups: () => {},
});
