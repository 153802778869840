import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { setRegulationType } from "@store/actions";

import Dropdown from "./Dropdown";
import DropdownIndicator from "./DropdownIndicator";
import { FilterSvgIcon } from "../SvgIcons";

type SelectWrapperPropsType = {
    options: string[];
};

export default function SelectWrapper({ options }: SelectWrapperPropsType) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isSelectOpen, setIsSelectOpen] = React.useState<boolean>(false);

    const localOptions = options.map((opt: string | number) => ({
        label: opt,
        value: opt,
    }));

    const [selectedValue, setSelectedValue] = React.useState<{
        label: string | number;
        value: string | number;
    }>(localOptions[0]);

    const selectStyles = {
        control: (provided: any) => ({
            ...provided,
            minWidth: 240,
            margin: 8,
            borderRadius: "5px",
            padding: "0 10px",
            display: "none",
        }),
        menu: () => ({
            boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
        }),
        container: () => ({
            background: "white",
        }),
    };

    function toggleOpen() {
        setIsSelectOpen(!isSelectOpen);
    }

    function onSelectChange(value: any) {
        toggleOpen();
        setSelectedValue(value);
        dispatch(setRegulationType(value.label));
    }

    return (
        <Dropdown
            isOpen={isSelectOpen}
            onClose={toggleOpen}
            target={
                <div
                    className="btn-floating dropdown-toggle my-2"
                    style={{ color: "white" }}
                    onClick={toggleOpen}
                >
                    <FilterSvgIcon style={{ filter: "invert(1)" }} />
                </div>
            }
        >
            <Select
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={onSelectChange}
                options={localOptions}
                placeholder={`${t("Search")}...`}
                styles={selectStyles}
                tabSelectsValue={false}
                value={selectedValue}
            />
        </Dropdown>
    );
}
