import React from "react";
import { Card, CardBody } from "reactstrap";
import { DynamicTable } from "@aures/custom-react-table";
import { useSnapshot } from "valtio";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import { store } from "@pages/Statistics/Shops/store";
import { store as categoriesStore, setTitleRowWidth } from "./store";

import {
    findObjectWithLongestShopKey,
    getAdvancedPeriodStringParam,
    getLimitQueryStringFromLimit,
    getSelectionQueryStringFromSelectedGroups,
    getSelectionQueryStringFromSelectedShops,
    searchKeyByValue,
} from "@helpers/general";

import { TotalRowWrapper } from "@pages/Statistics/Shops/TotalRowWrapper";
import { DonutChartWrapper } from "./DonutChartWrapper";

export function CategoriesColumn() {
    const { t } = useTranslation();
    const { selectedShops, selectedGroups, limitCategories } = useSnapshot(
        store
    );
    const { period, advancedDate } = useSelector((state: any) => state);
    const { type, titleRowWidth } = useSnapshot(categoriesStore);

    const selectionQueryString = getSelectionQueryStringFromSelectedShops(
        selectedShops
    );
    const groupsSelectionQueryString = getSelectionQueryStringFromSelectedGroups(
        selectedGroups
    );
    const typeSelectionQueryString = type !== undefined ? `&type=${type}` : "";
    const tableId = "categoriesTable";
    const limitQueryString = getLimitQueryStringFromLimit(limitCategories);
    const apiUrl = `${
        process.env.REACT_APP_API_V1_URL
    }/statistics/shops/families?${getAdvancedPeriodStringParam(
        period,
        advancedDate
    )}${selectionQueryString}${groupsSelectionQueryString}${limitQueryString}${typeSelectionQueryString}`;
    const token = localStorage.getItem("jwt");
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [key, setKey] = React.useState(uuid());
    const [, setSelectedRows] = React.useState<any[]>([]);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [data, setData] = React.useState<{ data: any[] }>({ data: [] });

    const tableContainer = document.querySelector(
        `#${tableId} .MuiTableContainer-root`
    );
    const totalRowWrapper = document.querySelector(
        `#${tableId} #totalRowWrapper`
    );

    if (totalRowWrapper !== null && tableContainer !== null) {
        ((totalRowWrapper as HTMLElement)
            .parentElement as HTMLElement).style.width = `${tableContainer?.scrollWidth}px`;
    }

    React.useEffect(() => {
        setKey(uuid());
    }, [
        type,
        advancedDate,
        groupsSelectionQueryString,
        period,
        selectionQueryString,
        i18nextLng,
    ]);

    React.useEffect(() => {
        const shopWithLongestName = findObjectWithLongestShopKey(
            data.data,
            t(searchKeyByValue("Catégories"))
        );
        // size of a character width in pixel based on font and styling
        const estimatedCharacterWidth = 12;
        // a minimum width to avoid very small cells
        const minWidth = 150;

        // the width based on content length and character width
        const calculatedWidth = Math.max(
            shopWithLongestName[t(searchKeyByValue("Catégories"))]?.length *
                estimatedCharacterWidth,
            minWidth
        );
        setTitleRowWidth(calculatedWidth);
    }, [data, t]);

    return (
        <div key={key} className="grid-container">
            <Card id="categoriesTable">
                <CardBody className="card-body__clz">
                    <DynamicTable
                        url={apiUrl}
                        requestHeader={{
                            "Accept-Language": i18nextLng,
                            Authorization: `Bearer ${token}`,
                        }}
                        setData={setData}
                        canSort={true}
                        canResize={true}
                        colsSize={[
                            {
                                item: t(searchKeyByValue("Catégories")),
                                width: `${titleRowWidth}px`,
                            },
                        ]}
                        canSelect={false}
                        setSelectedRows={setSelectedRows}
                        customSelect={true}
                        showGlobalFilter={false}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={8}
                        minHeight="300px"
                        maxHeight="450px"
                        defaultPaginationValues={[10]}
                        customClasses={{
                            thead: "custom-table-head-text-size__clz",
                        }}
                        isTableHasFooter={true}
                        tableFooter={
                            <TotalRowWrapper
                                selectedRows={data.data}
                                tableId={tableId}
                                type={type}
                            />
                        }
                    />
                </CardBody>
            </Card>
            <DonutChartWrapper apiUrl={apiUrl} />
        </div>
    );
}
