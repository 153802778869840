import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import { uid } from "react-uid";
import classNames from "classnames";

import { store as vStore } from "@vStore";
import { store, setSelectedShops, setSelectedGroups } from "../store";
import { capitalize } from "@helpers/general";

import { GroupedShopsCustomSelect } from "../GroupedShopsCustomSelect";
import { GroupedGroupsCustomSelect } from "../GroupedGroupsCustomSelect";
import { PeriodModalWrapper } from "@components/Common/GlobalBreadcrumb/PeriodModalWrapper";

export function GlobalBreadcrumb() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const { selectedShops, selectedGroups } = useSnapshot(store);

    function handleRemoveShopFromSelectedShops(shop: MyShopsType) {
        const newSelectedShops = selectedShops.filter(
            (selectedShop) =>
                selectedShop.shopId !== shop.shopId &&
                selectedShop.franchiseId !== shop.franchiseId &&
                selectedShop.shopName !== shop.shopName
        );
        setSelectedShops(newSelectedShops);
    }

    function handleRemoveGroupFromSelectedGroups(group: string) {
        const newSelectedGroups = selectedGroups.filter(
            (selectedGroupElt) => selectedGroupElt !== group
        );
        setSelectedGroups(newSelectedGroups);
    }

    return (
        <div>
            <div
                className="d-xl-flex align-items-center px-4 py-1"
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #00000029",
                    border: "1px solid #F2F2F2",
                }}
            >
                <div className="d-md-flex align-items-center">
                    <h4 className="mr-3">{capitalize(t("Customers"))}</h4>
                    <div className="d-sm-flex">
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ gap: "10px" }}
                        >
                            <PeriodModalWrapper />
                            <GroupedShopsCustomSelect />
                            <GroupedGroupsCustomSelect />
                        </div>
                    </div>
                </div>
            </div>

            <Container
                fluid={true}
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #00000029",
                    border: "1px solid #F2F2F2",
                }}
            >
                <Row className="p-2 mb-0 flex-row flex-nowrap overflow-auto">
                    {selectedShops?.map((shop: any) => (
                        <div
                            key={uid(`${shop.franchiseId}__${shop.shopId}`)}
                            className={classNames(
                                "d-flex mr-1 mb-1 p-2 badge",
                                {
                                    "badge-dark": mainColor === null,
                                    "text-white": mainColor !== null,
                                }
                            )}
                            style={{ background: mainColor }}
                        >
                            <span>{shop.shopName}</span>
                            <span
                                className="uppercase-text ml-1"
                                onClick={() =>
                                    handleRemoveShopFromSelectedShops(shop)
                                }
                                style={{ cursor: "pointer" }}
                            >
                                X
                            </span>
                        </div>
                    ))}
                    {selectedGroups.map((group) => (
                        <div
                            key={uid(group)}
                            className={classNames("mr-1 mb-1 p-2 badge", {
                                "badge-dark": mainColor === null,
                                "text-white": mainColor !== null,
                            })}
                            style={{ background: mainColor }}
                        >
                            {group}
                            <span
                                className="uppercase-text ml-1"
                                onClick={() =>
                                    handleRemoveGroupFromSelectedGroups(group)
                                }
                                style={{ cursor: "pointer" }}
                            >
                                X
                            </span>
                        </div>
                    ))}
                </Row>
            </Container>
        </div>
    );
}
