import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

import { setType } from "./ProductsColumn/store";

import { TitleRow } from "../TitleRow";
import { ProductsColumn } from "./ProductsColumn";
import { StyledButtonWrapper } from "./StyledButtonWrapper";

export function ProductsCol() {
    const { t } = useTranslation();

    return (
        <Col md="12">
            <TitleRow title={t("Items").toUpperCase()}>
                <StyledButtonWrapper onClick={() => setType("TTC")}>
                    {t("Turnover incl-tax")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("HT")}>
                    {t("Turnover ex-tax")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("Tickets")}>
                    {t("Tickets")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("Panier moyen")}>
                    {t("Average basket")}
                </StyledButtonWrapper>
            </TitleRow>
            <ProductsColumn />
        </Col>
    );
}
