import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { useResource } from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomSelectEmployeesFilter from "@components/Common/CustomSelectEmployeesFilter";

export default function SelectFilter() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);
    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [employees, setEmployees] = React.useState<any[]>([
        {
            label: t("All employees"),
            options: [t("All employees")],
        },
    ]);

    const { resourceData: employeesData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/chef/chef-list?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let shops: any[] = [];
        let localShops: string[] = [];
        let array: any[] = [];

        shops = employeesData.data?.forEach((employee: any) => {
            localShops.push(employee.shop_name);
        });

        shops = [...new Set(localShops)];

        array.push({
            label: t("All employees"),
            options: [t("All employees")],
        });

        shops?.forEach((shop: any) => {
            let localOptions: string[] = [];

            employeesData.data?.forEach((employee: any) => {
                if (employee.shop_name === shop) {
                    localOptions.push(employee);
                }
            });

            array.push({
                label: shop,
                options: localOptions,
            });
        });

        setEmployees(array);
    }, [employeesData, t]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card
                        className={classNames({
                            "dark-bg__clz": mainColor === null,
                        })}
                        style={{ background: mainColor }}
                    >
                        <CardBody className="py-1 px-3">
                            {error ? (
                                <ErrorPage />
                            ) : (
                                <div className="d-flex align-items-center">
                                    <CardTitle
                                        tag="h4"
                                        className="card-title mb-0 mr-3 text-white"
                                    >
                                        {t("Select your employees")}
                                    </CardTitle>
                                    <CustomSelectEmployeesFilter
                                        options={employees}
                                    />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}
