import React from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

import { amounts, products, setAmounts, setProducts } from "./signals";

import { useResource } from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import MiniCard from "@components/Common/MiniCard";

export default function Cards() {
    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    const {
        resourceData: { data: productsData },
        error,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/global?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localProducts: string[] = [];
        let localAmounts: number[] = [];

        productsData?.forEach(
            (mode: { designation: string; value: number }) => {
                localProducts.push(mode.designation);
                localAmounts.push(mode.value);
            }
        );

        setProducts(localProducts);
        setAmounts(localAmounts);
    }, [productsData]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <Row>
                    {products.map((title: string, index: number) => {
                        return (
                            <Col key={`${title}_${index}`}>
                                <MiniCard
                                    title={title}
                                    amount={amounts[index]}
                                />
                            </Col>
                        );
                    })}
                </Row>
            )}
        </React.Fragment>
    );
}
