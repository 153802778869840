import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useResource } from "@hooks/useResource";

import { getTypeParam, getAdvancedPeriodStringParam } from "@helpers/general";

import getRegulationsDataHelper from "./getRegulationDataHelper";

import DonutChart from "../DonutChart";
import ErrorPage from "@components/Common/ErrorPage";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

export default function RegulationsRow({
    type,
    period,
    designation,
}: StatisticsChartsRowsPropsType) {
    const { t } = useTranslation();
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/regulations?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            designation && designation !== "my_favorites"
                ? `&selected=${designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        const { amounts, labels } = getRegulationsDataHelper(resourceData.data);

        setData(amounts);
        setLabels(labels);
    }, [resourceData]);

    const regulationInitData: SeriesDataType = {
        data,
        labels: labels?.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            {error ? (
                <ErrorPage />
            ) : (
                <DonutChart
                    title={t("Payment types")}
                    data={regulationInitData}
                    type={type}
                />
            )}
        </React.Suspense>
    );
}
