import * as actions from "./actionTypes";

const initialState: LoginReducerInitStateType = {
    error: false,
    loading: false,
    user: null,
};

export default function login(
    state = initialState,
    action: LoginReducerActionType
) {
    switch (action.type) {
        case actions.LOGIN_USER:
            return {
                ...state,
                loading: true,
            };
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case actions.LOGOUT_USER:
            return { ...state };
        case actions.LOGOUT_USER_SUCCESS:
            return { ...state };
        case actions.API_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
}
