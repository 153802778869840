import React from "react";
import { useTranslation } from "react-i18next";

import PageTitle from "@components/Common/PageTitle";
import Breadcrumb from "@components/Common/Breadcrumb";

import { MainRow } from "./components/MainRow";
import { TitleRow } from "./components/TitleRow";

import "./styles.css";

export default function Settings() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PageTitle title={t("Settings")} />
            <div className="page-content">
                <Breadcrumb
                    title={t("Setting")}
                    breadcrumbItem={t("Statistics")}
                />
                <TitleRow />
                <MainRow />
            </div>
        </React.Fragment>
    );
}
