import React from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { useSnapshot } from "valtio";
import {
    AngleSmallLeftIcon,
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    TrashIcon,
} from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import store, { setGroupedFamilies } from "./store";

import { ITEMS_PER_PAGE } from "@constants/index";

import { NoImagePlaceholderIcon } from "../components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";

export default function FourthStep({ go }: any) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const { groupedFamilies, groupName } = useSnapshot(store);

    const [selectedFamilies, setSelectedFamilies] = React.useState<any[]>([]);

    const handleRemoveSelectedFamily = React.useCallback(
        (family: any) => {
            let localSelectedFamilies: any[] = [...selectedFamilies, family];
            setSelectedFamilies(localSelectedFamilies);
            let localFamilies: any[] = groupedFamilies.filter((family: any) => {
                return !localSelectedFamilies
                    .map((selectedFamily: any) =>
                        `${selectedFamily.designation}|${selectedFamily.franchise_schema}|${selectedFamily.shop_id}`.toLowerCase()
                    )
                    .includes(
                        `${family.designation}|${family.franchise_schema}|${family.shop_id}`.toLowerCase()
                    );
            });

            setGroupedFamilies(localFamilies);
        },
        [selectedFamilies, groupedFamilies]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Family"),
                accessor: "designation",
            },
            {
                Header: t("Picture"),
                accessor: function (family: any) {
                    return (
                        <div>
                            {family.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                family.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (family: any) {
                    return (
                        <div>
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleRemoveSelectedFamily(family)
                                }
                            >
                                {t("Delete")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none m-0"
                                icon="TrashIcon"
                                onClick={() =>
                                    handleRemoveSelectedFamily(family)
                                }
                            >
                                <TrashIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleRemoveSelectedFamily, mainColor]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: groupedFamilies,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect
    );

    function handlePreviousStepAction() {
        go(1);
    }

    async function handleSaveButtonClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/families/save-group`;
        let savedData = {
            designation: groupName,
            groupment: groupedFamilies.map((s: any) => s.designation),
        };

        try {
            NProgress.start();

            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        go(0);
                        setGroupedFamilies([]);

                        return swal({
                            icon: "success",
                            content: <p>{t(data.message)}!</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    })
            );
        } catch (e) {
            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
                buttons: false,
                timer: 2000,
            });
        }
    }

    React.useEffect(() => {
        setPageSize(5);
    }, [setPageSize]);

    return (
        <React.Fragment>
            <div className="d-md-flex justify-content-md-between">
                <div className="d-md-flex align-items-md-center my-2">
                    <div className="d-flex align-items-center">
                        <StyledIconButton
                            icon="AngleSmallLeftIcon"
                            className="m-0 p-0"
                            onClick={handlePreviousStepAction}
                        >
                            <AngleSmallLeftIcon height={35} width={35} />
                        </StyledIconButton>

                        <span className="mb-0 ml-2 mr-0 font-size-14 h5 white-space-nowrap">
                            {groupName}
                        </span>
                    </div>
                    <span
                        className="divider mt-0 mx-4 d-none d-md-block"
                        style={{
                            backgroundColor: "#808080a6",
                            height: "2.25rem",
                            width: "2px",
                        }}
                    />
                    <StyledSearchInput
                        onChange={(e) => {
                            setGlobalFilter(e.target.value);
                        }}
                        placeholder={`${t("Search")} ...`}
                        className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                    />
                </div>
                <StyledButton
                    rounded={true}
                    variant="success"
                    className="mr-0 ml-2 mt-0 my-md-2 w-xs-95 mx-md-2 w-sm-95 w-md-unset"
                    onClick={handleSaveButtonClickEvent}
                    disabled={pageCount === 0}
                >
                    {t("Save")}
                </StyledButton>
            </div>
            {page.length > 0 ? (
                <React.Fragment>
                    <BTable responsive {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="white-space-nowrap"
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className="white-space-nowrap align-middle"
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </BTable>
                    {groupedFamilies.length > ITEMS_PER_PAGE ? (
                        <Pagination
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            pageIndex={pageIndex}
                        />
                    ) : null}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
}
