import React from "react";
import {
    AvForm,
    AvGroup,
    AvInput,
    AvFeedback,
} from "availity-reactstrap-validation";
import { Button, FormGroup, Row, Col, Label } from "reactstrap";
import swal from "@sweetalert/with-react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import frZipCodes from "fr-zip-codes";
import { mutate } from "swr";
import styled from "styled-components";
import IntlTelInput from "react-intl-tel-input";

import store from "./store";

import { fetcherWithToken } from "@helpers/fetcherWithToken";
import { isEmailValid } from "@helpers/general";

import { ArrowRightSvgIcon } from "@components/Common/SvgIcons";

import CountriesSelect from "./CountriesSelect";
import SelectType from "./SelectType";
import CitiesSelect from "./CitiesSelect";

import "flag-icon-css/css/flag-icons.min.css";
import "react-intl-tel-input/dist/main.css";

import "@assets/widths.css";
import "./index.css";

type AccountInfoType = {
    service: string;
    job: string;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 10% auto;
    gap: 0px 0px;
    background: white;
`;

export default function FirstStep({ next }: any) {
    const { t } = useTranslation();
    const { type, country, city } = useSnapshot(store);

    const [zip, setZip] = React.useState<string>("");
    const [isInvalidEmail, setIsInvalidEmail] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");
    const [verifyEmailError, setVerifyEmailError] = React.useState<boolean>(
        false
    );
    const [siret, setSiret] = React.useState<string>("");
    const [verifySiretMessage, setVerifySiretMessage] = React.useState<string>(
        ""
    );

    const [verifySiretError, setVerifySiretError] = React.useState<boolean>(
        false
    );
    const [shopId, setShopId] = React.useState<string>("");
    const [phoneNumber, setPhoneNumber] = React.useState<string>("");

    const [verifyShopIdMessage, setVerifyShopIdMessage] = React.useState<
        string
    >("");
    const [verifyShopIdError, setVerifyShopIdError] = React.useState<boolean>(
        false
    );

    let cities = frZipCodes.find(zip);

    const [accountInfo] = React.useState<AccountInfoType>({
        service: t("Administration"),
        job: t("Administrator (responsible)"),
    });

    function handleZipInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setZip(e.target.value);
    }

    function sendVerificationEmailRequest(email: string) {
        fetcherWithToken(
            `${
                process.env.REACT_APP_API_V1_URL
            }/register/verifyMail?mail=${email.toLowerCase()}`
        )
            .then((value) => {
                setVerifyEmailError(value.error);
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }

    function handleEmailInputOnBlurEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        sendVerificationEmailRequest(e.target.value);
        setEmail(e.target.value);
        setIsInvalidEmail(false);
        setIsInvalidEmail(!isEmailValid(e.target.value));
    }

    function handleSiretInputOnBlurEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        if (e.target.value !== "") {
            fetcherWithToken(
                `${process.env.REACT_APP_API_V1_URL}/register/verifySiret?siret=${e.target.value}`
            )
                .then((value) => {
                    setVerifySiretError(value.error);
                    setVerifySiretMessage(value.message);
                })
                .catch((error) => {
                    console.log(error.message);
                });
            setSiret(e.target.value);
        }
    }

    function handleShopIdInputOnBlurEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        fetcherWithToken(
            `${process.env.REACT_APP_API_V1_URL}/register/verifyId?idCesar=${e.target.value}`
        )
            .then((value) => {
                setVerifyShopIdError(value.error);
                setVerifyShopIdMessage(value.message);
            })
            .catch((error) => {
                console.log(error.message);
            });

        setShopId(e.target.value);
    }

    async function handleSubmit(event: any, errors: any, values: any) {
        let apiUrl = `${process.env.REACT_APP_API_V1_URL}/register`;

        let savedData = {
            email: values.email.toLowerCase(),
            name: values.name,
            firstName: values.firstName,
            address: {
                country: country,
                city: cities.length > 1 ? city : values.city,
                street: "",
                zip: values.zip,
                address: values.address,
            },
            phoneNumber,
            shopId: values.shopId,
            type: type,
            infoUser: {
                society: values.society,
                commercialName: values.commercialName,
                siret: values.siret,
                job: values.job,
            },
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        next();
                    })
            );
        } catch (e) {
            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
                buttons: false,
                timer: 2000,
            });
        }
    }

    return (
        <Container>
            <div
                className="ml-5 mt-4 text-uppercase"
                style={{
                    color: "#141414",
                    fontSize: "30px",
                    fontWeight: 600,
                }}
            >
                {t("Enter your information")}
            </div>
            <AvForm
                className="registration-form__clz mt-3"
                onSubmit={handleSubmit}
                model={accountInfo}
            >
                <Row className="d-md-flex justify-content-md-between mx-3 mb-2 p-3">
                    <Col md={5}>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label
                                for="email"
                                className="mb-0 custom-label__clz"
                            >
                                {t("Email")}
                            </Label>
                            <AvInput
                                name="email"
                                type="email"
                                id="email"
                                className="registration-input__clz custom-input__clz"
                                required
                                placeholder={t("Enter")}
                                value={email}
                                onBlur={handleEmailInputOnBlurEvent}
                            />

                            {isInvalidEmail && (
                                <AvFeedback className="custom-error-message__clz">
                                    {t(
                                        "Please enter a valid email in the format name@example.com"
                                    )}
                                </AvFeedback>
                            )}
                            {verifyEmailError ? (
                                <div
                                    className="custom-error-message__clz"
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("This email address is already in use")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="society" className="mb-0">
                                {t("Society")}
                            </Label>
                            <AvInput
                                name="society"
                                id="society"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                required
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="commercialName" className="mb-0">
                                {t("Commercial name")}
                            </Label>
                            <AvInput
                                name="commercialName"
                                id="commercialName"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                required
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="siret" className="mb-0">
                                SIRET
                            </Label>
                            <AvInput
                                name="siret"
                                id="siret"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                value={siret}
                                onBlur={handleSiretInputOnBlurEvent}
                            />
                            {verifySiretError ? (
                                <div
                                    className="custom-error-message__clz"
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t(verifySiretMessage)}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="address" className="mb-0">
                                {t("Address")}
                            </Label>
                            <AvInput
                                name="address"
                                id="address"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                required
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="zip" className="mb-0">
                                {t("Zip code")}
                            </Label>
                            <AvInput
                                name="zip"
                                id="zip"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                required
                                value={zip}
                                onChange={handleZipInputOnChangeEvent}
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="city" className="mb-0">
                                {t("City")}
                            </Label>
                            {cities.length > 1 ? (
                                <CitiesSelect
                                    options={cities.map(
                                        (el: any) => el.townName
                                    )}
                                />
                            ) : (
                                <AvInput
                                    name="city"
                                    id="city"
                                    className="registration-input__clz"
                                    placeholder={t("Enter")}
                                    required
                                    value={
                                        cities.length === 1
                                            ? cities[0].townName
                                            : ""
                                    }
                                />
                            )}
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="country" className="mb-0">
                                {t("Country")}
                            </Label>
                            <CountriesSelect />
                        </AvGroup>
                    </Col>
                    <Col className="ml-md-5 pl-md-5" md={6}>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="name" className="mb-0">
                                {t("Name")}
                            </Label>
                            <AvInput
                                name="name"
                                id="name"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                required
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="firstName" className="mb-0">
                                {t("Firstname")}
                            </Label>
                            <AvInput
                                name="firstName"
                                id="firstName"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                required
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="phoneNumber" className="mb-0">
                                {t("Phone number")}
                            </Label>
                            <IntlTelInput
                                inputClassName="form-control registration-input__clz"
                                fieldName="phoneNumber"
                                fieldId="phoneNumber"
                                onPhoneNumberChange={(status, value) => {
                                    setPhoneNumber(value);
                                }}
                                placeholder={t("Enter")}
                                autoPlaceholder={false}
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="type" className="mb-0">
                                {t("You are?")}
                            </Label>

                            <SelectType />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz">
                            <Label for="shopId" className="mb-0">
                                {t("Username")}
                            </Label>
                            <AvInput
                                name="shopId"
                                id="shopId"
                                className="registration-input__clz"
                                placeholder={t("Enter")}
                                required
                                value={shopId}
                                onBlur={handleShopIdInputOnBlurEvent}
                            />
                            {verifyShopIdError ? (
                                <div
                                    className="custom-error-message__clz"
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t(verifyShopIdMessage)}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz d-none">
                            <Label for="service" className="mb-0">
                                {t("Service")}
                            </Label>
                            <AvInput
                                name="service"
                                id="service"
                                className="custom-registration-input__clz"
                                placeholder={t("Enter")}
                                required
                                disabled
                            />
                        </AvGroup>
                        <AvGroup className="registration-form-group-control__clz d-none">
                            <Label for="job" className="mb-0">
                                {t("Job")}
                            </Label>
                            <AvInput
                                name="job"
                                id="job"
                                className="custom-registration-input__clz"
                                placeholder={t("Enter")}
                                required
                                disabled
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <FormGroup className="d-flex justify-content-end mx-5">
                    <Button
                        type="submit"
                        color="dark"
                        className="px-4"
                        disabled={[
                            verifyEmailError,
                            verifySiretError,
                            verifyShopIdError,
                            isInvalidEmail,
                            phoneNumber.length === 0,
                        ].includes(true)}
                    >
                        <div className="d-flex align-items-center">
                            <div style={{ fontSize: 14 }}>{t("Next")}</div>
                            <ArrowRightSvgIcon
                                style={{ filter: "invert(1)" }}
                            />
                        </div>
                    </Button>
                </FormGroup>
            </AvForm>
        </Container>
    );
}
