import React from "react";
import { StyledH1 } from "@aureskonnect/react-ui";

import "./styles.css";

type SidebarLogoPropsType = { type: string };

export function SidebarLogo({ type }: SidebarLogoPropsType) {
    return (
        <StyledH1 className="m-2 text-white d-flex justify-content-center brand__clz">
            {type === "default" ? "Reporting" : "Rp"}
        </StyledH1>
    );
}
