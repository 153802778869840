import React from "react";
import { CardBody, Card, Nav, Button } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    setSegmentQueryString,
    setAdvancedDate,
    setAdvancedTypeParam,
    setAdvancedLegend,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import "@assets/apex-chart.css";

export default function StatisticsTop10FamiliesCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setCategoriesData,
        setHistogramData,
    } = React.useContext(StatisticsContext);

    const [top10FamiliesData, setTop10FamiliesData] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<string[]>([]);

    const {
        resourceData: statisticsFamiliesData,
        error: statisticsFamiliesDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/families/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsFamiliesData.data);
        setTop10FamiliesData(
            getStatisticsData(
                statisticsFamiliesData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsFamiliesData,
        categories,
        period,
        advancedDate,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        setAdvancedLegend(t("Families"));

        let queryString = encodeURI(
            JSON.stringify({
                designation: "temp",
                value: [
                    {
                        designation: "families",
                        value: top10FamiliesData.map((family) => family.name),
                    },
                ],
            })
        );

        setSegmentQueryString(queryString);

        setAdvancedTypeParam(
            type === t("Incl-tax")
                ? t("Turnover incl-tax")
                : t("Turnover ex-tax")
        );

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsFamiliesDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={top10FamiliesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
