import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store } from "@vStore";
import {
    store as headerStore,
    toggleNav,
} from "@pages/Statistics/Transactions/Header/store";

export function NavWrapper() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);
    const { activeTab } = useSnapshot(headerStore);

    return (
        <Nav
            tabs
            className={classnames("flex-md-column tab-width__clz w-sm-100", {
                "bg-dark": mainColor === null,
            })}
            style={{
                borderTopLeftRadius: "0.4rem",
                background: mainColor,
            }}
        >
            <NavItem
                className={classnames({
                    "mm-active advanced-nav-item-active__clz": activeTab === 1,
                })}
            >
                <NavLink
                    className={classnames("border-none__clz nav-link__clz", {
                        "active nav-link-active__clz": activeTab === 1,
                        "text-white": mainColor !== null,
                    })}
                    onClick={() => {
                        toggleNav(1);
                    }}
                >
                    {t("New filter")}
                </NavLink>
            </NavItem>
            <NavItem
                className={classnames({
                    "mm-active advanced-nav-item-active__clz": activeTab === 2,
                })}
            >
                <NavLink
                    className={classnames("border-none__clz nav-link__clz", {
                        "active nav-link-active__clz": activeTab === 2,
                        "text-white": mainColor !== null,
                    })}
                    onClick={() => {
                        toggleNav(2);
                    }}
                >
                    {t("Saved filters")}
                </NavLink>
            </NavItem>
        </Nav>
    );
}
