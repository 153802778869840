import React from "react";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import { getTypeParam, getAdvancedPeriodStringParam } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function Top10EmployeesCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: employeesData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/cashier/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        employeesData.data?.forEach((item: any) => {
            localLabels.push(item.designation);
            localData.push(item.value);
        });

        setData(localData);
        setLabels(localLabels);
        setPercentageData!(localData);
    }, [employeesData, setPercentageData]);

    const donutChartTop10EmployeesData: SeriesDataType = {
        data,
        labels: labels.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Top 10 employees")}
                        data={donutChartTop10EmployeesData}
                    >
                        <LegendPopoverWrapper
                            title={t("Top 10 employees")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
