import React from "react";

import { FiltersWrapper } from "@pages/Statistics/Shops/TopContentWrapper/FiltersWrapper";
import { ContainerWrapper } from "@pages/Statistics/Shops/TopContentWrapper/ContainerWrapper";

export function TopContentWrapper() {
    return (
        <React.Fragment>
            <FiltersWrapper />
            <ContainerWrapper />
        </React.Fragment>
    );
}
