import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import * as serviceWorker from "./serviceWorker";
import "@i18n";
import "./wdyr";

import { App } from "./App";

import { store } from "./store";

let persistor = persistStore(store);

function Main() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

ReactDOM.render(<Main />, document.getElementById("root"));
serviceWorker.unregister();
