import React from "react";
import { Card, CardBody } from "reactstrap";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getProductsData,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function RecapCol() {
    const { period, type, advancedDate, selections } = React.useContext(
        StatisticsContext
    );

    const [subFamiliesData, setSubFamiliesData] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);
    const [productsData, setProductsData] = React.useState<any[]>([]);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/out-of-stock/sub-families/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
    }, [period, advancedDate]);

    React.useEffect(() => {
        setSubFamiliesData(
            getStatisticsData(
                resourceData.data,
                categories,
                period,
                advancedDate
            )
        );
        setProductsData(
            getProductsData(resourceData.data, categories, period, advancedDate)
        );
    }, [resourceData, setSubFamiliesData, categories, period, advancedDate]);

    return (
        <Card>
            <CardBody className="card-body__clz">
                <div className="apex-charts apex-chart__clz">
                    {error ? (
                        <ErrorPage />
                    ) : (
                        <ChartWrapper
                            series={subFamiliesData}
                            categories={categories}
                            productsData={productsData}
                            chartId="subFamilies"
                        />
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
