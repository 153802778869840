export const selectGroup = (
    selectedGroup: any | undefined
): {
    type: string;
    payload: any | undefined;
} => {
    return {
        type: "GROUPS",
        payload: selectedGroup,
    };
};
