import React from "react";
import { useLocation, withRouter } from "react-router-dom";

import Footer from "./VerticalLayout/Footer";

type PropsType = {
    children?: React.ReactNode;
};

export default withRouter(function NonAuthLayout({ children }: PropsType) {
    const { pathname } = useLocation();

    return (
        <React.Fragment>
            {children}
            {["/registration", "/home"].includes(pathname) ? (
                <div className="ml-0 main-content">
                    <Footer />
                </div>
            ) : null}
        </React.Fragment>
    );
});
