import TitleRow from "@components/Common/TitleRow";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

import { setType } from "./SalesModeColumn/store";

import { SalesModeColumn } from "./SalesModeColumn";
import { StyledButtonWrapper } from "./StyledButtonWrapper";

export function SalesModeCol() {
    const { t } = useTranslation();

    return (
        <Col md="12">
            <TitleRow title={t("Sales mode").toUpperCase()}>
                <StyledButtonWrapper onClick={() => setType("TTC")}>
                    {t("Turnover incl-tax")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("HT")}>
                    {t("Turnover ex-tax")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("Tickets")}>
                    {t("Tickets")}
                </StyledButtonWrapper>
                <StyledButtonWrapper onClick={() => setType("Panier moyen")}>
                    {t("Average basket")}
                </StyledButtonWrapper>
            </TitleRow>
            <SalesModeColumn />
        </Col>
    );
}
