import React from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default function useFingerprint() {
    const [fingerprint, setFingerprint] = React.useState("");

    React.useEffect(() => {
        FingerprintJS.load()
            .then((fp) => fp.get())
            .then((result) => setFingerprint(result.visitorId));
    }, []);

    return { fingerprint };
}
