import React from "react";

export default function DeliverooSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8784">
                <g data-name="Groupe 8762">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <path
                    d="M30.476 9L29.1 22.1l-2.35-11.049-7.411 1.559 2.327 11.049L11 25.923l1.9 8.812 18.776 4 4.293-9.6L38 9.791 30.476 9zm-5.942 19.024a1.363 1.363 0 01-1.446.136c-.542-.181-.768-.813-.587-1.627.158-.587.859-.678 1.243-.678a.886.886 0 01.407.09c.249.113.678.361.768.723a1.308 1.308 0 01-.384 1.356zm5.445.587c-.294.5-1.017.565-1.762.2a1.072 1.072 0 01-.429-1.211 1.067 1.067 0 01.249-.565 1.183 1.183 0 01.791-.52 1.372 1.372 0 011.288.678 1.367 1.367 0 01-.136 1.423z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
