import React from "react";

import Menu from "./Menu";
import Blanket from "./Blanket";

type PropsType = {
    isOpen: boolean;
    target: any;
    onClose: Function;
} & ChildrenPropsType;

export default function Dropdown({
    children,
    isOpen,
    target,
    onClose,
}: PropsType) {
    return (
        <div className="dropdonw__clz btn btn-sm float-right">
            {target}
            {isOpen ? <Menu>{children}</Menu> : null}
            {isOpen ? <Blanket onClick={onClose} /> : null}
        </div>
    );
}
