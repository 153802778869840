import React from "react";
import { useTranslation } from "react-i18next";

export function NoDataWrapper() {
    const { t } = useTranslation();

    return (
        <div
            className="d-flex justify-content-center align-items-center apexcharts-legend apexcharts-align-center position-bottom"
            style={{
                inset: "auto 0px 15px 20px",
                position: "absolute",
                maxHeight: "200px",
            }}
        >
            <div
                className="apexcharts-legend-series"
                style={{ margin: "2px 5px" }}
            >
                <span
                    className="mx-1"
                    style={{
                        height: "12px",
                        width: "12px",
                        background: "rgb(0, 143, 251)",
                        color: "rgb(0, 143, 251)",
                        borderRadius: "50%",
                        display: "inline-block",
                    }}
                />
                <span
                    className="apexcharts-legend-text"
                    style={{
                        color: "rgb(55, 61, 63)",
                        fontSize: "14px",
                        fontWeight: 400,
                        fontFamily: "Helvetica, Arial, sans-serif",
                    }}
                >
                    {t("No data")}
                </span>
            </div>
        </div>
    );
}
