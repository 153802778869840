import React from "react";
import { CardBody, Card, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsTop10ArticlesCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const [top10ArticlesData, setTop10ArticlesData] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);

    const {
        resourceData: statisticsArticlesData,
        error: statisticsArticlesError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setTop10ArticlesData(
            getStatisticsData(
                statisticsArticlesData.data,
                categories,
                period,
                advancedDate
            )
        );
        setHistogramData!(statisticsArticlesData.data);
    }, [
        statisticsArticlesData,
        categories,
        period,
        advancedDate,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        setAdvancedLegend(t("Products"));

        let queryString = encodeURI(
            JSON.stringify({
                designation: "temp",
                value: [
                    {
                        designation: "articles",
                        value: top10ArticlesData.map((product) => product.name),
                    },
                ],
            })
        );

        setSegmentQueryString(queryString);

        setAdvancedTypeParam(type);

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsArticlesError ? (
                        <ErrorPage />
                    ) : (
                        <ChartWrapper
                            series={top10ArticlesData}
                            categories={categories}
                        />
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
