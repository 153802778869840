import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { useSnapshot } from "valtio";

import { setSelectedShops, store } from "@pages/Statistics/Shops/store";
import { fetchShops } from "@pages/Statistics/Shops/store";

import { capitalize } from "@helpers/general";

type LocalOptionsType = {
    label: string | number;
    value: string | number;
};

type GroupedOptionsType = {
    options: LocalOptionsType[];
};

export function GroupedShopsCustomSelect() {
    const { t } = useTranslation();

    const styles = {
        control: (provided: any) => ({
            ...provided,
            minWidth: 240,
            minHeight: 40,
            height: 40,
            margin: 1,
            borderRadius: "5px",
            padding: "0px",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            width: "100%",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }),
        option: (provided: any) => {
            return {
                ...provided,
                color: "#495057",
            };
        },
        menu: (provided: any) => {
            return {
                ...provided,
                zIndex: 99999999,
            };
        },
    };

    const { shops, selectedShops } = useSnapshot(store);

    const [localOptions, setLocalOptions] = React.useState<any[]>([
        { label: t("All shops"), value: t("All shops") },
    ]);
    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        localOptions[0],
    ]);

    const isClearableProps =
        selectedValueState &&
        localOptions.filter((el: any) =>
            Object.values(selectedValueState).includes(el.label)
        ).length === 0;

    const [options, setOptions] = React.useState<SelectFilterType[]>([
        {
            options: [t("All shops")],
            label: t("All shops"),
        },
    ]);

    const [groupedOptions, setGroupedOptions] = React.useState<
        GroupedOptionsType[]
    >([{ options: [] }]);

    function handleSelectOnChangeEvent(selectedValue: any, actionType: any) {
        let allItemExistInSelectedValue: boolean =
            selectedValue?.find((element: LocalOptionsType) => {
                return element.value === localOptions[0].value;
            }) !== undefined;

        let selectedValueSize = selectedValue?.length;

        let localSelectedValue = [...selectedValueState];

        if (allItemExistInSelectedValue === true) {
            if ([t("All shops")].includes(actionType.option.label)) {
                localSelectedValue = [localOptions[0]];
            } else {
                let opts: string[] = [];
                options.forEach((element: any) => {
                    if (![t("All shops")].includes(element.label)) {
                        opts.push(...element.options);
                    }
                });

                opts = opts.filter(
                    (opt: string) => opt !== actionType.option.label
                );
                localSelectedValue =
                    opts.length === 0
                        ? [localOptions[0]]
                        : opts.map((option: string) => ({
                              label: option,
                              value: option,
                          }));
            }
        } else {
            if (selectedValueSize === 0) {
                localSelectedValue = [];
            } else {
                const opts = options
                    .map((option: any) => option.options)
                    .flat()
                    .filter((option: string) => !option.includes(t("All")))
                    .filter(
                        (option: any) =>
                            !option.includes(actionType.option.label)
                    )
                    .filter((option: any) =>
                        selectedValue
                            .map((value: any) => value.label)
                            .includes(option)
                    )
                    .map((option: string) => ({
                        label: option,
                        value: option,
                    }));
                localSelectedValue =
                    selectedValueSize !== opts.length ? selectedValue : opts;
            }
        }

        const selectedShops: string[] = localSelectedValue.map((el: any) =>
            t(capitalize(el?.label))
        );
        const [, ...remainingShops] = shops;

        const filteredShops = selectedShops.includes(t("All shops"))
            ? [...(remainingShops as MyShopsType[])]
            : (remainingShops as MyShopsType[]).filter((shop) => {
                  return selectedShops.includes(shop.shopName);
              });

        setSelectedValueState(localSelectedValue);
        setSelectedShops(filteredShops);
    }

    const MultiValueRemove = () => {
        return (
            <components.MultiValueRemove>
                {selectedValueState.length > 0 &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState)?.includes(el.label)
                ).length > 0
                    ? false
                    : undefined}
            </components.MultiValueRemove>
        );
    };

    const Option = ({ getValue, setValue, ...props }: any) => {
        const { selectedShops } = useSnapshot(store);

        const [
            isCurrentOptionInSelectedShops,
            setIsCurrentOptionInSelectedShops,
        ] = React.useState(false);
        const [isInputChecked, setIsInputChecked] = React.useState(false);

        React.useEffect(() => {
            setIsCurrentOptionInSelectedShops(
                selectedShops
                    .map((shop) => shop.shopName)
                    .filter((option) => !option?.includes(t("All shops")))
                    .includes(props.data.label)
            );
        }, [selectedShops, props.data.label]);

        React.useEffect(() => {
            setIsInputChecked(
                isCurrentOptionInSelectedShops ||
                    [t("All shops")].includes(props.selectProps.value[0]?.label)
            );
        }, [isCurrentOptionInSelectedShops, props.selectProps.value]);

        return (
            <components.Option {...props}>
                <input type="checkbox" checked={isInputChecked} />{" "}
                <label>{props.value}</label>
            </components.Option>
        );
    };

    const ValueContainer = ({
        children,
        getValue,
        hasValue,
        ...props
    }: any) => {
        const { selectedShops } = useSnapshot(store);

        const [selectedElement, setSelectedElement] = React.useState(
            t("All shops") as string
        );

        React.useEffect(() => {
            if (t("All shops") === props.selectProps.value[0]?.label) {
                setSelectedElement(t("All shops"));
            } else if (
                selectedShops.length === 0 ||
                selectedValueState.length === 0
            ) {
                setSelectedElement(`${t("Select")}...`);
            } else {
                setSelectedElement(
                    t("Selected element", {
                        selectedItems: getValue().length,
                        count: getValue().length,
                    })
                );
            }
        }, [selectedShops, getValue, props.selectProps.value]);

        return hasValue === false ? (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        ) : (
            <components.ValueContainer {...props}>
                {selectedElement}
                {React.Children.map(children, (child) => {
                    return child?.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    };

    React.useEffect(() => {
        fetchShops();
    }, []);

    React.useEffect(() => {
        setOptions((prevState) => {
            const [, ...remainingShops] = shops;

            if (remainingShops.length > 0) {
                return [
                    {
                        options: [t("All shops")],
                        label: t("All shops"),
                    },
                    {
                        options: (remainingShops as MyShopsType[]).map(
                            (shop) => shop.shopName
                        ),
                        label: t("Shops"),
                    },
                ];
            }

            return prevState;
        });
    }, [shops, t]);

    React.useEffect(() => {
        setLocalOptions(() => {
            return options[0].options.map((option: string) => ({
                label: option,
                value: option,
            }));
        });
        setGroupedOptions(() => {
            return options.map((outerOption: any) => {
                return {
                    label: t(capitalize(outerOption.label)),
                    options: outerOption.options.map((innerOption: string) => ({
                        label: innerOption,
                        value: innerOption,
                    })),
                };
            });
        });
    }, [options, t]);

    React.useEffect(() => {
        const [, ...remainingShops] = shops;
        const newSelectedShops = (remainingShops as MyShopsType[])
            .map((shop) => shop.shopName)
            .filter((shop) =>
                selectedShops
                    .map((selectedShop) => selectedShop.shopName)
                    .includes(shop)
            )
            .map((shop) => ({
                label: shop,
                value: shop,
            }));

        if (newSelectedShops.length !== remainingShops.length) {
            setSelectedValueState(newSelectedShops);
        } else {
            setSelectedValueState([localOptions[0]]);
        }
    }, [selectedShops, shops, localOptions]);

    return (
        <Select
            options={groupedOptions}
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={handleSelectOnChangeEvent}
            onKeyDown={(e) => {
                if (selectedValueState.length > 0) e.preventDefault();
            }}
            isMulti={true}
            hideSelectedOptions={false}
            isClearable={isClearableProps}
            components={{
                Option,
                MultiValueRemove,
                ValueContainer,
            }}
            closeMenuOnSelect={false}
            styles={styles}
            theme={(theme: any) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "neutral10",
                },
            })}
        />
    );
}
