import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getAuthUserShopsNumber } from "@helpers/general";

export default function ShopsNumberLink() {
    const { t } = useTranslation();

    const user = useSelector(
        (state: {
            Login: {
                user: UserType;
            };
        }) => state.Login.user
    );
    const selections = useSelector(
        (state: { selections: SelectionType }) => state.selections
    );

    const [shopsNumber, setShopsNumber] = React.useState<number>(0);

    React.useEffect(() => {
        let localeShopsNumber: number = 0;

        if (selections.selection?.length > 0) {
            selections.selection?.forEach((selection: any) => {
                localeShopsNumber += selection.shops_id.length;
            });
        } else {
            localeShopsNumber = getAuthUserShopsNumber(user);
        }

        setShopsNumber(localeShopsNumber);
    }, [selections, user]);

    return (
        <div className="dropdown">
            <Link
                className="pl-0 px-sm-2 py-0 btn btn-secondary bg-transparent border-0 white-space-nowrap"
                to="/my-selection-list"
            >
                <h5
                    className="mb-0 mb-sm-2"
                    style={{
                        textDecoration: "underline",
                    }}
                >
                    {`${t("Shops")} ( ${shopsNumber} )`}
                </h5>
            </Link>
        </div>
    );
}
