import React from "react";
import classNames from "classnames";
import { Card, CardBody, Media } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { capitalize, formatCurrency } from "@helpers/general";

import IconWrapper from "./IconWrapper";

type MiniCardPropsType = {
    icon?: React.ReactNode;
    title: string;
    items?: string[];
    amount: number | string;
    changeMargin?: boolean;
    changeColor?: boolean;
};

export default function MiniCard(props: MiniCardPropsType) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);
    const type = useSelector((state: any) => state.type);

    function getUntranslatedTitle(title: string) {
        const titles: string[] = [
            "Bank card",
            "Fidelity",
            "Restaurant coupon",
            "Cash box",
            "Coin acceptor",
            "Softpay",
            "Check",
        ];

        if (titles.includes(t(title))) {
            return t(title);
        } else {
            return title;
        }
    }

    return (
        <Card
            className={classNames({
                "mini-stats-wid mb-0": props.changeMargin,
                "mini-stats-wid": !props.changeMargin,
                "dark-bg__clz": mainColor === null,
            })}
            style={{ background: mainColor }}
        >
            <CardBody
                className={classNames({
                    "bg-secondary rounded": props.changeColor,
                })}
            >
                <Media>
                    <Media
                        body
                        className="text-left"
                        style={{ width: "max-content" }}
                    >
                        <p className="text-white font-weight-medium">
                            {t(capitalize(props.title)) === "Click_&_collect"
                                ? "Click & collect"
                                : t(capitalize(props.title))}
                        </p>
                        <h4 className="text-white mb-0">
                            {[
                                t("Tickets"),
                                t("Quantity sold"),
                                t("Item number"),
                            ].includes(type)
                                ? props.amount
                                : formatCurrency(Number(props.amount))}
                        </h4>
                    </Media>

                    <IconWrapper
                        title={getUntranslatedTitle(t(capitalize(props.title)))}
                    />
                </Media>
            </CardBody>
        </Card>
    );
}
