import React from "react";
import { useDispatch } from "react-redux";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";
import { useTranslation } from "react-i18next";

import { clearPeriod, changeType } from "@store/actions";

export function LanguageDropdown() {
    let { pathname } = useLocation();
    const { i18n } = useTranslation();

    const [menu, setMenu] = React.useState<boolean>(false);
    const [language, setLanguage] = React.useState<string>(
        i18n.language === "fr" ? "Français" : "English"
    );
    const dispatch = useDispatch();

    let matchRegulationModesRoute: boolean = pathname === "/regulation-modes";
    let matchEmployeesRoutes: boolean = pathname.includes("employees");
    let matchStatisticsTurnoverRoute: boolean =
        pathname === "/statistics-turnover";

    function changeLanguage(currentLanguage: string) {
        i18n.changeLanguage(currentLanguage === "en" ? "en" : "fr");

        dispatch(clearPeriod());

        if (matchRegulationModesRoute || matchEmployeesRoutes) {
            dispatch(changeType(i18n.t("Turnover")));
        } else if (matchStatisticsTurnoverRoute) {
            dispatch(changeType(i18n.t("Incl-tax")));
        } else {
            dispatch(changeType(i18n.t("Turnover incl-tax")));
        }

        if (currentLanguage === "en") {
            setLanguage("English");
        } else if (currentLanguage === "fr") {
            setLanguage("Français");
        }
    }

    function toggleDropdown() {
        setMenu((prevState) => !prevState);
    }

    return (
        <Dropdown
            isOpen={menu}
            toggle={toggleDropdown}
            className="mt-1 p-0 mb-0 d-inline-block"
        >
            <DropdownToggle
                className="btn header-item waves-effect"
                tag="button"
            >
                <span className="align-middle">
                    {language === "Français" ? (
                        <img
                            style={{ width: "35px" }}
                            src="https://flagpedia.net/data/flags/h80/fr.webp"
                            alt="France flag"
                        />
                    ) : (
                        <img
                            style={{ width: "40px" }}
                            src="https://flagpedia.net/data/flags/h80/us.webp"
                            alt="US flag"
                        />
                    )}
                </span>
            </DropdownToggle>
            <DropdownMenu className="language-switch" right>
                <DropdownItem
                    tag="a"
                    onClick={() => {
                        NProgress.start();
                        changeLanguage(language === "English" ? "fr" : "en");
                        NProgress.done();
                    }}
                    className={`notify-item ${
                        language === "English" ? "active d-none" : ""
                    }`}
                >
                    <span className="align-middle">
                        <img
                            style={{ width: "35px" }}
                            src="https://flagpedia.net/data/flags/h80/us.webp"
                            alt="US flag"
                        />
                    </span>
                </DropdownItem>
                <DropdownItem
                    tag="a"
                    onClick={() => changeLanguage("fr")}
                    className={`notify-item ${
                        language === "Français" ? "active d-none" : ""
                    }`}
                >
                    <span className="align-middle">
                        <img
                            style={{ width: "35px" }}
                            src="https://flagpedia.net/data/flags/h80/fr.webp"
                            alt="France flag"
                        />
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
