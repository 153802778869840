import i18n from "i18next";
import { AnyAction } from "redux";

import {
    ADD_SALE_SUPPORT,
    SELECT_SALES_SUPPORT,
    CLEAR_SALES_SUPPORT,
    EMPTY,
    INIT_SALES_SUPPORT,
} from "./actions-types";

import { capitalize } from "@helpers/general";

const INIT_STATE: string[] = [];

export default function salesSupports(
    state = INIT_STATE,
    action: AnyAction
): string[] | null {
    switch (action.type) {
        case ADD_SALE_SUPPORT:
            let newState: string[] = [...state];
            newState.push(action.payload);
            return newState;
        case SELECT_SALES_SUPPORT:
            let newSelectedState: string[] = [...state];
            newSelectedState = newSelectedState.filter(
                (el: string) => el !== EMPTY
            );
            let storedSalesSupport = JSON.parse(
                localStorage.getItem("salesSupports")!
            );

            if (
                newSelectedState
                    .map((el: string) => i18n.t(capitalize(el)))
                    .includes(action.payload)
            ) {
                if (action.payload === i18n.t("All sales supports")) {
                    newSelectedState = [];
                } else {
                    if (action.payload === i18n.t("Shop")) {
                        newSelectedState = newSelectedState
                            .filter((element: string) => {
                                return ![
                                    "Shop",
                                    "Box",
                                    "Thick headed",
                                ].includes(capitalize(element));
                            })
                            .filter((element: string) => {
                                return !["All sales supports"].includes(
                                    capitalize(element)
                                );
                            });
                    } else if (action.payload === i18n.t("Click_&_collect")) {
                        newSelectedState = newSelectedState
                            .filter(
                                (element: string) =>
                                    ![
                                        "Click_&_collect",
                                        "Application",
                                        "Web",
                                    ].includes(capitalize(element))
                            )
                            .filter(
                                (element: string) =>
                                    !["All sales supports"].includes(
                                        capitalize(element)
                                    )
                            );
                    } else if (action.payload === i18n.t("Platform")) {
                        newSelectedState = newSelectedState
                            .filter(
                                (element: string) =>
                                    ![
                                        "Platform",
                                        "UBER",
                                        "DELIVERO",
                                        "JUSTEAT",
                                        "WEBEXTERNE",
                                    ].includes(capitalize(element))
                            )
                            .filter(
                                (element: string) =>
                                    !["All sales supports"].includes(
                                        capitalize(element)
                                    )
                            );
                    } else {
                        let selectedSalesSupport = storedSalesSupport.filter(
                            (el: string) =>
                                i18n.t(capitalize(el)) === action.payload
                        )[0];

                        let isShopChildrenSelected = [
                            "Box",
                            "Thick headed",
                        ].includes(selectedSalesSupport);

                        let isClickAndCollectChildrenSelected = [
                            "Application",
                            "Web",
                        ].includes(selectedSalesSupport);

                        let isPlatformChildrenSelected = [
                            "UBER",
                            "DELIVERO",
                            "JUSTEAT",
                            "WEBEXTERNE",
                        ].includes(selectedSalesSupport);

                        newSelectedState = newSelectedState
                            .filter(
                                (element: string) =>
                                    i18n.t(capitalize(element)) !==
                                    action.payload
                            )
                            .filter(
                                (element: string) =>
                                    i18n.t(capitalize(element)) !==
                                    i18n.t("All sales supports")
                            );

                        if (isShopChildrenSelected) {
                            newSelectedState = newSelectedState.filter(
                                (el: string) => el !== "shop"
                            );
                        }

                        if (isClickAndCollectChildrenSelected) {
                            newSelectedState = newSelectedState.filter(
                                (el: string) => el !== "click_&_collect"
                            );
                        }

                        if (isPlatformChildrenSelected) {
                            newSelectedState = newSelectedState.filter(
                                (el: string) => el !== "platform"
                            );
                        }
                    }
                }
            } else if (action.payload === i18n.t("All sales supports")) {
                newSelectedState = storedSalesSupport;
            } else if (action.payload === i18n.t("Shop")) {
                newSelectedState = [
                    ...newSelectedState,
                    ...storedSalesSupport.filter(
                        (item: string) =>
                            capitalize(item) === "Shop" ||
                            capitalize(item) === "Box" ||
                            capitalize(item) === "Thick headed"
                    ),
                ];
            } else if (action.payload === i18n.t("Click_&_collect")) {
                newSelectedState = [
                    ...newSelectedState,
                    ...storedSalesSupport.filter(
                        (item: string) =>
                            capitalize(item) === "Click_&_collect" ||
                            capitalize(item) === "Application" ||
                            capitalize(item) === "Web"
                    ),
                ];
            } else if (action.payload === i18n.t("Platform")) {
                newSelectedState = [
                    ...newSelectedState,
                    ...storedSalesSupport.filter(
                        (item: string) =>
                            capitalize(item) === "Platform" ||
                            capitalize(item) === "UBER" ||
                            capitalize(item) === "DELIVERO" ||
                            capitalize(item) === "JUSTEAT" ||
                            capitalize(item) === "WEBEXTERNE"
                    ),
                ];
            } else {
                let selectedSalesSupport = storedSalesSupport.filter(
                    (el: string) => i18n.t(capitalize(el)) === action.payload
                )[0];

                newSelectedState.push(selectedSalesSupport);

                let shopChildren = storedSalesSupport.filter((el: string) =>
                    ["Box", "Thick headed"].includes(el)
                );

                let isShopChildrenSelected =
                    newSelectedState.filter((element: string) =>
                        ["Box", "Thick headed"].includes(capitalize(element))
                    ).length >= shopChildren.length;

                let clickAndCollectChildren = storedSalesSupport.filter(
                    (el: string) => ["Application", "Web"].includes(el)
                );

                let isClickAndCollectChildrenSelected =
                    newSelectedState.filter((element: string) =>
                        ["Application", "Web"].includes(capitalize(element))
                    ).length >= clickAndCollectChildren.length;

                let platformChildren = storedSalesSupport.filter((el: string) =>
                    ["UBER", "DELIVERO", "JUSTEAT", "WEBEXTERNE"].includes(el)
                );

                let isPlatformChildrenSelected =
                    newSelectedState.filter((element: string) =>
                        ["UBER", "DELIVERO", "JUSTEAT", "WEBEXTERNE"].includes(
                            capitalize(element)
                        )
                    ).length >= platformChildren.length;

                if (
                    isShopChildrenSelected &&
                    !newSelectedState.includes("shop")
                ) {
                    newSelectedState.push("shop");
                }

                if (
                    isClickAndCollectChildrenSelected &&
                    !newSelectedState.includes("click_&_collect")
                ) {
                    newSelectedState.push("click_&_collect");
                }

                if (
                    isPlatformChildrenSelected &&
                    !newSelectedState.includes("platform")
                ) {
                    newSelectedState.push("platform");
                }
            }

            if (newSelectedState.length === storedSalesSupport.length - 1) {
                newSelectedState.push("All sales supports");
            }

            if (newSelectedState.length === 0) {
                newSelectedState.push(EMPTY);
            }

            return newSelectedState;
        case CLEAR_SALES_SUPPORT:
            return [];
        case INIT_SALES_SUPPORT:
            let localState: string[] = action.payload;
            if (localState.length === 0) {
                localState.push(EMPTY);
            }
            return localState;
        default:
            return state !== undefined ? state : null;
    }
}
