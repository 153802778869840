import { ADD_SELECTIONS } from "./actions-types";

export const selectShops = (
    selections: SelectionType
): {
    type: string;
    payload: SelectionType;
} => {
    return {
        type: ADD_SELECTIONS,
        payload: selections,
    };
};
