import i18n from "i18next";

import { CHANGE_TYPE, CLEAR_TYPE } from "./actionsType";

export const changeType = (
    type: string
): {
    type: string;
    payload: any;
} => {
    return {
        type: CHANGE_TYPE,
        payload: type,
    };
};

export const clearType = (): {
    type: string;
    payload: any;
} => {
    return {
        type: CLEAR_TYPE,
        payload: i18n.t("Turnover"),
    };
};

export const initInclTaxType = (): {
    type: string;
    payload: any;
} => {
    return {
        type: CLEAR_TYPE,
        payload: i18n.t("Turnover incl-tax"),
    };
};

export const initInclTaxTypeInTurnoverPage = (): {
    type: string;
    payload: any;
} => {
    return {
        type: CLEAR_TYPE,
        payload: i18n.t("Incl-tax"),
    };
};

export const initInclQuantityType = (): {
    type: string;
    payload: any;
} => {
    return {
        type: CLEAR_TYPE,
        payload: i18n.t("Quantity"),
    };
};