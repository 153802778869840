import React from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";

export default function Footer() {
    const { t } = useTranslation();
    let matchMySelectionsRoute = useRouteMatch("/my-selections");
    let matchMySelectionsListRoute = useRouteMatch("/my-selection-list");
    let matchRegistrationRoute = useRouteMatch("/registration");
    let matchHomeRoute = useRouteMatch("/home");

    return (
        <footer
            className={classNames("footer", {
                "my-selection-footer":
                    matchMySelectionsRoute?.isExact ||
                    matchMySelectionsListRoute?.isExact ||
                    matchRegistrationRoute?.isExact ||
                    matchHomeRoute?.isExact,
                "fixed-bottom":
                    matchHomeRoute?.isExact || matchRegistrationRoute?.isExact,
            })}
            style={{ marginTop: "auto" }}
        >
            <Row className="d-flex col-sm justify-content-center align-items-center justify-content-sm-start pb-md-row">
                <div className="mt-4 mx-1 mb-md-4">
                    © {new Date().getFullYear()} AuresKonnect
                </div>
                <span>|</span>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Terms of use")}</u>
                </Link>
                <span>|</span>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Confidentiality rule")}</u>
                </Link>
            </Row>
        </footer>
    );
}
