import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import { mutate } from "swr";
import BTable from "react-bootstrap/Table";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { useSnapshot } from "valtio";
import {
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    TrashIcon,
    AngleSmallLeftIcon,
} from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import { store, setGroupedRegulations } from "./store";

import { useResource } from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import Pagination from "@components/Common/StatisticsPagination";

export default function EditStep({ go }: any) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const { groupName } = useSnapshot(store);

    const [
        selectedRegulationsList,
        setSelectedRegulationsList,
    ] = React.useState<any[]>([]);
    const [
        selectedRegulationModes,
        setSelectedRegulationModes,
    ] = React.useState<any[]>([]);

    const {
        resourceData: { data },
        error,
    } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/regulations/group?group-name=${groupName}`
    );

    const handleDeleteRegulationModeButtonOnClickEvent = React.useCallback(
        async (regulationMode: any) => {
            swal({
                text: t(
                    "Are you sure you want to delete this regulation mode?"
                ),
                icon: "warning",
                buttons: [t("Cancel"), t("Delete")],
                dangerMode: true,
            }).then(async (willDelete: boolean) => {
                if (willDelete === true) {
                    let localSelectedRegulationModes: any[] = [
                        ...selectedRegulationModes,
                        regulationMode,
                    ];
                    setSelectedRegulationModes(localSelectedRegulationModes);
                    let localRegulationModes: any[] = selectedRegulationsList.filter(
                        (regulationMode: any) => {
                            return !localSelectedRegulationModes
                                .map(
                                    (s) =>
                                        `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`
                                )
                                .includes(
                                    `${regulationMode.reference}|${regulationMode.designation}|${regulationMode.family}|${regulationMode.subFamily}`
                                );
                        }
                    );
                    setSelectedRegulationsList(localRegulationModes);

                    const apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/regulations/regulation`;
                    const savedData = {
                        designation: groupName,
                        groupment: localRegulationModes.map(
                            (mode) => mode.designation
                        ),
                    };

                    NProgress.start();

                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            NProgress.done();
                            if (data.error === true) {
                                throw new Error(
                                    "Error while deleting an regulationMode in an regulationModes group!"
                                );
                            }

                            go(0);
                            return swal({
                                icon: "success",
                                content: <p>{t(data.message)}!</p>,
                                buttons: false,
                                timer: 2000,
                            });
                        })
                        .catch((_) => {
                            NProgress.done();
                            return swal({
                                icon: "error",
                                content: <p>{t("There's an error")!}</p>,
                                buttons: false,
                                timer: 2000,
                            });
                        });
                }
            });
        },
        [selectedRegulationModes, selectedRegulationsList, t, go, groupName]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Payment method"),
                accessor: "designation",
            },
            {
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (regulationMode: any) {
                    return (
                        <div>
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleDeleteRegulationModeButtonOnClickEvent(
                                        regulationMode
                                    )
                                }
                            >
                                {t("Delete")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none m-0"
                                icon="TrashIcon"
                                onClick={() =>
                                    handleDeleteRegulationModeButtonOnClickEvent(
                                        regulationMode
                                    )
                                }
                            >
                                <TrashIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleDeleteRegulationModeButtonOnClickEvent, mainColor]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: selectedRegulationsList,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllPageRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    async function handleMultipleRemoveRegulationModesButtonOnClickEvent() {
        swal({
            text: t(
                "Are you sure you want to delete this selected element from the group?"
            ),
            icon: "warning",
            buttons: [t("Cancel"), t("Delete")],
            dangerMode: true,
        }).then(async (willDelete: boolean) => {
            if (willDelete === true) {
                let localSelectedRegulationModes: any[] = selectedFlatRows.map(
                    (row: any) => row.original
                );
                let localSelectedRegulationsList = selectedRegulationsList.map(
                    (mode: any) => mode.designation
                );

                let regulationModes: any[] = [];

                localSelectedRegulationsList.forEach(
                    (regulationMode: string) => {
                        if (
                            !localSelectedRegulationModes
                                .map((s: any) => s.designation)
                                .includes(regulationMode)
                        ) {
                            regulationModes.push(regulationMode);
                        }
                    }
                );

                setSelectedRegulationsList(
                    selectedRegulationsList.filter((regulationMode: any) => {
                        return !localSelectedRegulationModes
                            .map((s) => `${s.designation}`.toLowerCase())
                            .includes(
                                `${regulationMode.designation}`.toLowerCase()
                            );
                    })
                );

                const apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/regulations/regulation`;
                const savedData = {
                    designation: groupName,
                    groupment: regulationModes,
                };

                NProgress.start();

                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            NProgress.done();

                            if (data.error) {
                                throw Error(
                                    "Error while removing multiple regulationModes in an regulationModes group!"
                                );
                            }

                            mutate(
                                `${process.env.REACT_APP_API_V1_URL}/settings/products/group?group-name=${groupName}`
                            );

                            go(0);
                            return swal({
                                icon: "success",
                                content: <p>{t(data.message)}!</p>,
                                buttons: false,
                                timer: 2000,
                            });
                        })
                        .catch((_) => {
                            NProgress.done();
                            return swal({
                                icon: "error",
                                content: <p>{t("There's an error")!}</p>,
                                buttons: false,
                                timer: 2000,
                            });
                        })
                );
            }
        });
    }

    function handlePreviousStepAction() {
        go(0);
        setGroupedRegulations([]);
    }

    function handleEditRegulationModesGroup() {
        setGroupedRegulations(selectedRegulationsList);
        go(4);
    }

    React.useEffect(() => {
        setSelectedRegulationsList(data);
    }, [data]);

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div className="d-md-flex justify-content-md-between align-items-md-center">
                        <div className="d-md-flex align-items-md-center mt-2 mt-md-2 mb-2">
                            <div className="d-flex align-items-center">
                                <StyledIconButton
                                    icon="AngleSmallLeftIcon"
                                    className="m-0 p-0"
                                    onClick={handlePreviousStepAction}
                                >
                                    <AngleSmallLeftIcon
                                        height={35}
                                        width={35}
                                    />
                                </StyledIconButton>
                                <span className="mx-2 mb-0 font-size-14 white-space-nowrap h5">
                                    {groupName}
                                </span>
                            </div>
                            <span
                                className="divider mt-0 mx-4 d-none d-md-block"
                                style={{
                                    backgroundColor: "#808080a6",
                                    height: "2.25rem",
                                    width: "2px",
                                }}
                            />
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                            />
                        </div>
                        <div className="d-md-flex mt-2 mx-2 mb-2">
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                disabled={selectedFlatRows.length === 0}
                                onClick={
                                    handleMultipleRemoveRegulationModesButtonOnClickEvent
                                }
                            >
                                {t("Delete payment modes")}
                            </StyledButton>
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                onClick={handleEditRegulationModesGroup}
                            >
                                {t("Add payment methods")}
                            </StyledButton>
                        </div>
                    </div>
                    {page.length > 0 ? (
                        <React.Fragment>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {selectedRegulationsList.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
