import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";
import moment from "moment";

import store, {
    setFamiliesCsv,
    setFamiliesExcel,
    setTop10FamiliesCsv,
    setTop10FamiliesExcel,
} from "@pages/Statistics/store";

import { useResource } from "@hooks/useResource";

import {
    capitalize,
    formatCurrency,
    formatExcelData,
    getAdvancedPeriodStringParam,
    getTypeParam,
} from "@helpers/general";

import StatisticsTop10FamiliesCol from "./StatisticsTop10FamiliesCol";
import Top10FamiliesCol from "./Top10FamiliesCol";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import { TitleRowWrapper } from "./TitleRowWrapper";

export default function Top10FamiliesRow() {
    const { t } = useTranslation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    const {
        top10: {
            percentage: top10Percentage,
            categories: top10Categories,
            histogram: top10Histogram,
        },
        all: { percentage, categories, histogram },
    } = useSnapshot(store.families);

    const {
        resourceData: { data },
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/all/families?${getAdvancedPeriodStringParam(
            period,
            advancedDate
        )}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${type === t("Average turnover") ? "" : `&type=${getTypeParam(type)}`}`
    );

    React.useEffect(() => {
        let array: any[] = [];

        const headers: any[] = [t("Categories")];

        top10Histogram.forEach((datum: any) => {
            headers.push(t(capitalize(datum.designation.toLowerCase())));
        });

        let averageLine: any[] = [];

        top10Categories.forEach((category: any) => {
            let localArray: any[] = [];
            let localCategory = category;

            top10Histogram.forEach((item: any, index: number) => {
                item.value.forEach((datum: any) => {
                    let localDesignation = String(datum.designation);

                    if (
                        advancedDate.startDate !== "" &&
                        advancedDate.endDate !== ""
                    ) {
                        let daysBetweenDates = moment
                            .unix(Number(advancedDate.endDate))
                            .diff(
                                moment.unix(Number(advancedDate.startDate)),
                                "days"
                            );
                        if (daysBetweenDates === 0) {
                            localDesignation = String(datum.designation);
                        } else if (daysBetweenDates <= 31) {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("ddd DD-MM-YYYY")
                            );
                        } else {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("MMM YYYY")
                            );
                        }
                    } else if (
                        [
                            t("Current week"),
                            t("The last 7 days"),
                            t("The last 14 days"),
                            t("The last 30 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                        localCategory = capitalize(
                            moment
                                .unix(Number(category))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else if (period === t("Current month")) {
                        localDesignation = capitalize(
                            moment.unix(Number(datum.designation)).format("DD")
                        );
                    } else if (period === t("Current year")) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    } else if (
                        [
                            t("The last 90 days"),
                            t("The last 180 days"),
                            t("The last 365 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }

                    if (localDesignation === localCategory) {
                        localArray.push(
                            type !== t("Tickets")
                                ? datum.value
                                : `${datum.value} ${t("Tickets")}`
                        );
                    }
                });

                if (!localArray[index]) {
                    localArray[index] = 0;
                }
            });

            array.push([localCategory, ...localArray]);
        });

        top10Percentage.forEach((datum: any) => {
            const average = array.length === 0 ? 0 : datum / array.length;
            averageLine.push(
                type !== t("Tickets")
                    ? formatCurrency(average)
                    : average.toFixed(2)
            );
        });

        const formattedPercentageData = top10Percentage.map((element: any) => {
            return type !== t("Tickets") ? formatCurrency(element) : element;
        });

        setTop10FamiliesCsv([
            headers,
            ...array,
            [t("Total"), ...formattedPercentageData],
            [t("Average"), ...averageLine],
        ]);

        setTop10FamiliesExcel([
            formatExcelData(
                headers,
                array,
                formattedPercentageData,
                averageLine,
                type
            ),
        ]);
    }, [
        t,
        top10Histogram,
        top10Categories,
        top10Percentage,
        advancedDate.startDate,
        advancedDate.endDate,
        period,
        type,
    ]);

    React.useEffect(() => {
        let localProducts: number[] = [];

        data.forEach((item: any) => {
            localProducts.push(item.value);
        });

        let array: any[] = [];

        let headers: any[] = [t("Categories")];

        histogram.forEach((datum: any) => {
            headers.push(t(capitalize(datum.designation.toLowerCase())));
        });

        categories.forEach((category: any) => {
            let localArray: any[] = [];
            let localCategory = category;

            histogram.forEach((item: any, index: number) => {
                item.value.forEach((datum: any) => {
                    let localDesignation = String(datum.designation);

                    if (
                        advancedDate.startDate !== "" &&
                        advancedDate.endDate !== ""
                    ) {
                        let daysBetweenDates = moment
                            .unix(Number(advancedDate.endDate))
                            .diff(
                                moment.unix(Number(advancedDate.startDate)),
                                "days"
                            );
                        if (daysBetweenDates === 0) {
                            localDesignation = String(datum.designation);
                        } else if (daysBetweenDates <= 31) {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("ddd DD-MM-YYYY")
                            );
                        } else {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("MMM YYYY")
                            );
                        }
                    } else if (
                        [
                            t("Current week"),
                            t("The last 7 days"),
                            t("The last 14 days"),
                            t("The last 30 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                        localCategory = capitalize(
                            moment
                                .unix(Number(category))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else if (period === t("Current month")) {
                        localDesignation = capitalize(
                            moment.unix(Number(datum.designation)).format("DD")
                        );
                    } else if (period === t("Current year")) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    } else if (
                        [
                            t("The last 90 days"),
                            t("The last 180 days"),
                            t("The last 365 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }

                    if (localDesignation === localCategory) {
                        localArray.push(
                            type !== t("Tickets")
                                ? datum.value
                                : `${datum.value} ${t("Tickets")}`
                        );
                    }
                });
                if (!localArray[index]) {
                    localArray[index] = 0;
                }
            });

            array.push([localCategory, ...localArray]);
        });

        const averageLine: any[] = [];

        (percentage.length > 0 ? percentage : localProducts).forEach(
            (datum: number) => {
                averageLine.push(
                    type !== t("Tickets")
                        ? formatCurrency(datum / array.length)
                        : (datum / array.length).toFixed(2)
                );
            }
        );

        const formattedPercentageData = (percentage.length > 0
            ? percentage
            : localProducts
        ).map((element: number) => {
            return type !== t("Tickets") ? formatCurrency(element) : element;
        });

        const localCsvProducts = [
            headers,
            ...array,
            [t("Total"), ...formattedPercentageData],
            [t("Average"), ...averageLine],
        ];

        setFamiliesCsv(localCsvProducts);

        setFamiliesExcel([
            formatExcelData(
                headers,
                array,
                formattedPercentageData,
                averageLine,
                type
            ),
        ]);
    }, [
        data,
        t,
        percentage,
        histogram,
        categories,
        advancedDate.startDate,
        advancedDate.endDate,
        period,
        type,
    ]);

    return (
        <React.Fragment>
            <TitleRowWrapper />
            <div
                className="d-grid__clz 
                                grid-gap__clz 
                                grid-xl-statistics-products-details__clz 
                                grid-lg-statistics-template-details__clz 
                                grid-md-statistics-template-details__clz 
                                grid-sm-template-col__clz grid-xs-template-col__clz 
                                pr-0"
            >
                <CustomErrorBoundary>
                    <Top10FamiliesCol />
                </CustomErrorBoundary>
                <CustomErrorBoundary>
                    <StatisticsTop10FamiliesCol />
                </CustomErrorBoundary>
            </div>
        </React.Fragment>
    );
}
