import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, CardBody } from "reactstrap";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "@vStore";

import { TeamworkSvgIcon } from "@components/Common/SvgIcons";

type PropsType = { shop: ClientsMyBestShopType };

export function CustomCol({ shop }: PropsType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    return (
        <Col md="3" className="px-0">
            <Card
                className={classNames(
                    "card-block__clz  my-best-shop-card__clz mr-2",
                    {
                        "dark-bg__clz": mainColor === null,
                    }
                )}
                style={{ background: mainColor }}
            >
                <CardBody
                    className="text-white d-grid__clz py-2 px-3 mb-2"
                    style={{
                        gridTemplateColumns: "0.2fr 0.8fr",
                        gap: "20px",
                    }}
                >
                    <div className="d-flex justify-content-center align-items-center">
                        <TeamworkSvgIcon />
                    </div>
                    <div className="d-grid__clz ml-3">
                        <span
                            className="d-flex flex-column"
                            style={{
                                font: "normal normal bold 17px/22px Poppins",
                                letterSpacing: "0px",
                            }}
                        >
                            {t(shop.designation)}
                        </span>
                        <table
                            style={{
                                font: "normal normal  13px/18px Poppins",
                            }}
                        >
                            {shop.info.map((info, index) => (
                                <tr>
                                    <td align="left">{info}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}
