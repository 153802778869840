import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    capitalize,
} from "@helpers/general";

import DonutChart from "../DonutChart";
import ErrorPage from "@components/Common/ErrorPage";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

export default function SalesModeRow({
    type,
    period,
    designation,
}: StatisticsChartsRowsPropsType) {
    const { t } = useTranslation();
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );

    const [series, setSeries] = React.useState<number[]>([]);
    const [salesModes, setSalesModes] = React.useState<string[]>([]);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/sales-mode?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            designation && designation !== "my_favorites"
                ? `&selected=${designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        resourceData.data?.forEach((item: any) => {
            localLabels.push(item.designation);
            localData.push(item.value);
        });

        setSeries(localData.filter((item) => item !== 0));
        setSalesModes(localLabels.map((item) => t(capitalize(item))));
    }, [resourceData, t]);

    const salesModesInitData: SeriesDataType = {
        data: series,
        labels: salesModes?.length > 0 ? salesModes : [t("No data")],
    };

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            {error ? (
                <ErrorPage />
            ) : (
                <DonutChart
                    title={t("Sales mode")}
                    data={salesModesInitData}
                    type={type}
                />
            )}
        </React.Suspense>
    );
}
