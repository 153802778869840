import React from "react";

export default function FamiliesSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8257">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="box (5)">
                    <g data-name="Groupe 8307">
                        <g data-name="Groupe 8306">
                            <path
                                data-name="Trac\xE9 8564"
                                d="M18.343 29.374l-3.128-1.329a.781.781 0 10-.61 1.439l3.128 1.329a.781.781 0 10.61-1.439z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g data-name="Groupe 8309">
                        <g data-name="Groupe 8308">
                            <path
                                data-name="Trac\xE9 8565"
                                d="M36.009 16.436a.8.8 0 00-.039-.239.7.7 0 00-.073-.117c-.017-.028-.03-.053-.048-.078a.751.751 0 00-.325-.252s-.006-.006-.011-.008L23 11.052a.765.765 0 00-.582.011l-10.944 4.69c-.011 0-.016.017-.027.022a.757.757 0 00-.274.23.816.816 0 00-.059.084.637.637 0 00-.052.075.784.784 0 00-.062.307v14.075a.783.783 0 00.474.719l10.946 4.692a.778.778 0 00.306.063.793.793 0 00.274-.051l.028-.011 12.48-4.68a.782.782 0 00.507-.732V16.472c.005-.014-.006-.023-.006-.036zM21.946 34.05l-9.383-4.02V17.659l9.383 4.02zm.8-13.73l-8.978-3.848 8.979-3.847 10.265 3.847-2.448.917-4.388-1.965a.769.769 0 00-.686.027l-3.067 1.3a.784.784 0 00.008 1.446l2.845 1.174zm5.454-.375l1.564-.586v3.811l-1.564.586zm-.779-1.378l-2.674-1.1 1.107-.471 2.627 1.176zm7.034 11.436l-10.946 4.1v-12.4l3.128-1.173v4.359a.781.781 0 00.782.782.8.8 0 00.275-.05l3.128-1.173a.783.783 0 00.507-.732v-4.944l3.128-1.173z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
