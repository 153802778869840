import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import moment from "moment";

import PageTitle from "@components/Common/PageTitle";
import Breadcrumb from "@components/Common/GlobalBreadcrumb";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import CardSpinnerWrapper from "@components/Common/CardSpinnerWrapper";

import Top10CooksRow from "./Top10CooksRow";
import SelectFilter from "./SelectFilter";
import Top10FamilisRow from "./Top10FamiliesRow";
import Top10ProductsRow from "./Top10ProductsRow";
import Cards from "./Cards";

export default function Cook() {
    const { t } = useTranslation();

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Cook")} />
            <div className="page-content">
                <Breadcrumb section={t("Statistics")} pageTitle={t("Cook")} />
                <React.Suspense fallback={<CardSpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <SelectFilter />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <Cards />
                    </CustomErrorBoundary>
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <Top10CooksRow />
                    </CustomErrorBoundary>
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <Top10FamilisRow />
                    </CustomErrorBoundary>
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <Top10ProductsRow />
                    </CustomErrorBoundary>
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
