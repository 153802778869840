import i18n from "i18next";

export default function getRegulationsDataHelper(
    data: any[]
): { amounts: number[]; labels: string[] } {
    let labels: string[] = [];
    let amounts: number[] = [];

    data?.forEach((item: any) => {
        amounts.push(item.value);
        labels.push(i18n.t(item.designation));
    });
    return { amounts, labels };
}
