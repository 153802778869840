import React from "react";
import { CardBody, Card, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import SmoothCurveLine from "@pages/Statistics/CommonCharts/SmoothCurveLine";

export default function StatisticsServicesCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [servicesData, setServicesData] = React.useState<SeriesDataType[]>(
        []
    );
    const {
        resourceData: statisticsServicesData,
        error: statisticsServicesDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/services/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setServicesData(
            getStatisticsData(
                statisticsServicesData.data,
                categories,
                period,
                advancedDate
            )
        );
        setHistogramData!(statisticsServicesData.data);
    }, [
        statisticsServicesData,
        categories,
        period,
        advancedDate,
        setHistogramData,
    ]);

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right">
                    <Link
                        to="/coming-soon"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <div className="apex-charts">
                    {statisticsServicesDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <SmoothCurveLine
                                series={servicesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
