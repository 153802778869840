import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

import {
    setRegulationModesCards,
    setSalesSupportCards,
} from "@pages/Statistics/store";

import { capitalize } from "@helpers/general";
import subscribeToStoreChange from "@helpers/subscribeToStoreChange";

type LocalOptionsType = {
    label: string | number;
    value: string | number;
};

type GroupedOptionsType = {
    options: LocalOptionsType[];
};

type PropsType = {
    amounts: number[];
    options: any;
    allModes: string[];
};

export default function GroupedCustomSelect({
    amounts,
    options,
    allModes,
}: PropsType) {
    const { t } = useTranslation();

    let localOptions: LocalOptionsType[] = options[0]?.options?.map(
        (option: string) => ({
            label: option,
            value: option,
        })
    );

    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        localOptions[0],
    ]);

    const [optionsNumber, setOptionsNumber] = React.useState<number>(0);

    let groupedOptions: GroupedOptionsType[] = options.map(
        (outerOption: any) => {
            return {
                label: t(capitalize(outerOption.label)),
                options: outerOption.options.map((innerOption: string) => ({
                    label: innerOption,
                    value: innerOption,
                })),
            };
        }
    );

    function handleSelectOnChangeEvent(selectedValue: any, actionType: any) {
        let allItemExistInSelectedValue: boolean =
            selectedValue?.find((element: LocalOptionsType) => {
                return element.value === localOptions[0].value;
            }) !== undefined;

        let selectedValueSize = selectedValue.length;

        if (allItemExistInSelectedValue) {
            if (
                [t("All sales supports"), t("All regulation methods")].includes(
                    actionType.option.label
                )
            ) {
                setSelectedValueState([localOptions[0]]);
            } else {
                let opts: any[] = [];
                options.forEach((element: any) => {
                    if (
                        ![
                            t("All sales supports"),
                            t("All regulation methods"),
                        ].includes(element.label)
                    ) {
                        opts.push(...element.options);
                    }
                });

                opts = opts.filter(
                    (opt: string) => opt !== actionType.option.label
                );
                setSelectedValueState(
                    opts.length === 0
                        ? [localOptions[0]]
                        : opts.map((option: string) => ({
                              label: option,
                              value: option,
                          }))
                );
            }
        } else {
            if (selectedValueSize === 0) {
                setSelectedValueState([localOptions[0]]);
            } else {
                let opts: any[] = options
                    .map((option: any) => option.options)
                    .flat()
                    .filter((option: string) => !option.includes(t("All")))
                    .filter(
                        (option: any) =>
                            !option.includes(actionType.option.label)
                    )
                    .filter((option: any) =>
                        selectedValue
                            .map((value: any) => value.label)
                            .includes(option)
                    )
                    .map((option: string) => ({
                        label: option,
                        value: option,
                    }));

                setSelectedValueState(
                    selectedValueSize !== opts.length ? selectedValue : opts
                );
            }
        }
    }

    const MultiValueRemove = () => {
        return (
            <components.MultiValueRemove>
                {selectedValueState &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState)?.includes(el.label)
                ).length > 0
                    ? false
                    : undefined}
            </components.MultiValueRemove>
        );
    };

    const Option = ({ getValue, setValue, ...props }: any) => {
        let isCurrentOptionSelected =
            selectedValueState
                .map((option: any) => option.label)
                .filter((option: any) => !option.includes(t("All")))
                .filter((opt: string) => opt.includes(props.data.label))
                .length > 0;

        return (
            <components.Option {...props}>
                <input
                    type="checkbox"
                    disabled={
                        (getValue().filter((el: any) => {
                            return [
                                t("All sales supports"),
                                t("All regulation methods"),
                            ].includes(el.label);
                        }).length !== 0 ||
                            optionsNumber === getValue().length) &&
                        [
                            t("All sales supports"),
                            t("All regulation methods"),
                        ].includes(props.data.label)
                    }
                    checked={
                        [
                            t("All sales supports"),
                            t("All regulation methods"),
                        ].includes(props.selectProps.value[0]?.label) ||
                        (isCurrentOptionSelected && props.isSelected) ||
                        props.selectProps.value.length === optionsNumber
                    }
                    readOnly
                />{" "}
                <label>{props.value}</label>
            </components.Option>
        );
    };

    const ValueContainer = ({ children, getValue, ...props }: any) => {
        return (
            <components.ValueContainer {...props}>
                {t("All sales supports") ===
                    props.selectProps.value[0]?.label ||
                (props.selectProps.value.length === optionsNumber &&
                    options
                        .map((element: any) => element.label)
                        .includes(t("All sales supports")))
                    ? t("All sales supports")
                    : t("All regulation methods") ===
                          props.selectProps.value[0]?.label ||
                      (props.selectProps.value.length === optionsNumber &&
                          options
                              .map((element: any) => element.label)
                              .includes(t("All regulation methods")))
                    ? t("All regulation methods")
                    : t("Selected element", {
                          selectedItems: getValue().length,
                          count: getValue().length,
                      })}

                {React.Children.map(children, (child) => {
                    return child.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    };

    React.useEffect(() => {
        let selectedModes: string[] = selectedValueState?.map((el: any) =>
            t(capitalize(el?.label))
        );

        let localModesAmounts: number[] = [];

        selectedModes.forEach((element: string) => {
            allModes?.forEach((item: string) => {
                if (element === item) {
                    localModesAmounts.push(amounts[allModes.indexOf(item)]);
                }
            });
        });

        let obj: CardsModesType = {
            modes: selectedModes,
            amounts: localModesAmounts,
        };

        if (
            options
                .map((element: any) => element.label)
                .includes(t("All sales supports"))
        ) {
            setSalesSupportCards(obj);
        } else {
            setRegulationModesCards(obj);
        }
    }, [selectedValueState, amounts, options, allModes, t]);

    React.useEffect(() => {
        let localOptionsNumber: number = 0;
        options.forEach((element: any) => {
            if (
                ![
                    t("All sales supports"),
                    t("All regulation methods"),
                ].includes(element.label)
            ) {
                localOptionsNumber += element.options.length;
            }
        });
        setOptionsNumber(localOptionsNumber);
    }, [options, t]);

    subscribeToStoreChange(
        () => setSelectedValueState([localOptions[0]]),
        "period"
    );

    return (
        <Select
            options={groupedOptions}
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={handleSelectOnChangeEvent}
            isMulti
            hideSelectedOptions={false}
            isClearable={
                selectedValueState &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState).includes(el.label)
                ).length === 0
            }
            components={{
                Option,
                MultiValueRemove,
                ValueContainer,
            }}
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    minWidth: 240,
                    minHeight: 40,
                    height: 40,
                    margin: 1,
                    borderRadius: "5px",
                    padding: "0px",
                }),
                valueContainer: (provided: any) => ({
                    ...provided,
                    width: "100%",
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }),
                option: (provided: any) => {
                    return {
                        ...provided,
                        color: "#495057",
                    };
                },
            }}
            theme={(theme: any) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "neutral10",
                },
            })}
        />
    );
}
