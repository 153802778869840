import React from "react";
import {
    ModalFooter,
    ModalBody,
    Input,
    Modal,
    ModalHeader,
    Alert,
} from "reactstrap";
import { useStep } from "react-hooks-helper";
import { useTranslation } from "react-i18next";
import NProgress from "nprogress";
import classnames from "classnames";
import swal from "@sweetalert/with-react";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store as vStore } from "@vStore";

import { isEmailValid } from "@helpers/general";

import { FORGOT_PASSWORD_CODE_LENGTH } from "@constants/index";

import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";

import PasswordStrengthMeter from "./PasswordStrengthMeter";

import "./index.css";

export default function ForgotPasswordModal({
    isModalOpened,
    toggleOpeningForgotPasswordModal,
}: any) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);

    const [email, setEmail] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>(
        ""
    );
    const [forgotPasswordCode, setForgotPasswordCode] = React.useState<string>(
        ""
    );
    const [isInvalidEmail, setIsInvalidEmail] = React.useState<boolean>(false);
    const [isInvalidCode, setIsInvalidCode] = React.useState<boolean>(false);

    const [isVerifiedEmailError, setIsVerifiedEmailError] = React.useState<
        boolean
    >(false);
    const [verifiedEmailMessage, setVerifiedEmailMessage] = React.useState<
        string
    >("");
    const [isUnverifiedCode, setIsUnverifiedCode] = React.useState<boolean>(
        false
    );
    const [isPasswordNotUpdated, setIsPasswordNotUpdated] = React.useState<
        boolean
    >(false);

    const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] = React.useState<
        boolean
    >(false);
    const [
        isConfirmedPasswordDisplayed,
        setIsConfirmedPasswordDisplayed,
    ] = React.useState<boolean>(false);

    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(
        false
    );

    const [isPasswordConfirmed, setIsPasswordConfirmed] = React.useState<
        boolean
    >(false);

    const [isFocusInInput, setIsFocusInInput] = React.useState<boolean>(false);

    const {
        index,
        navigation: { next, go },
    } = useStep({ steps: 3 });

    function handleEmailInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setEmail(e.target.value);
        setIsInvalidEmail(false);
        setIsInvalidEmail(!isEmailValid(e.target.value));
    }

    function handleEmailInputOnPasteEvent(
        e: React.ClipboardEvent<HTMLInputElement>
    ) {
        setEmail(e.clipboardData.getData("Text"));
        setIsInvalidEmail(false);
        setIsInvalidEmail(!isEmailValid(e.clipboardData.getData("Text")));
    }

    async function sendEmailVerificationRequest(email: string) {
        NProgress.start();

        await fetch(`${process.env.REACT_APP_API_V1_URL}/auth/verify-email`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email.toLowerCase(),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();
                if (data.error) {
                    setIsVerifiedEmailError(true);
                    setVerifiedEmailMessage(data.message);
                } else {
                    sendResetCodeRequest(email);
                    next();
                }
            })
            .catch((e: any) => {
                console.log(e.message);
                NProgress.done();
            });
    }

    async function sendResetCodeRequest(email: string) {
        NProgress.start();

        await fetch(
            `${process.env.REACT_APP_API_V1_URL}/auth/send-reset-code`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email.toLowerCase(),
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();
            })
            .catch((e: any) => {
                NProgress.done();
                console.log(e.message);
            });
    }

    function handleCodeInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setForgotPasswordCode(e.target.value);
        setIsInvalidCode(false);
        validateCode(e.target.value);
    }

    function validateCode(codeToValide: string) {
        if (String(codeToValide).length !== FORGOT_PASSWORD_CODE_LENGTH) {
            setIsInvalidCode(true);
        }
    }

    async function sendCodeVerificationRequest(
        email: string,
        forgotPasswordCode: string
    ) {
        NProgress.start();

        await fetch(`${process.env.REACT_APP_API_V1_URL}/auth/verify-code`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email.toLowerCase(),
                validationCode: forgotPasswordCode,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.isValid) {
                    setIsUnverifiedCode(false);
                    next();
                } else {
                    setIsUnverifiedCode(true);
                }
            })
            .catch((e: any) => {
                console.log(e.message);
                NProgress.done();
            });
    }

    function checkNewPassword(e: React.ChangeEvent<HTMLInputElement>) {
        setNewPassword(e.target.value);

        if (e.target.value === "") {
            setIsPasswordCorrect(false);
        } else if (
            e.target.value.match(/[0-9]/g) !== null &&
            e.target.value.length > 7 &&
            e.target.value.match(/[a-z]/g) !== null &&
            e.target.value.match(/[A-Z]/g) !== null &&
            e.target.value.match(/[^A-Za-z0-9]/g) !== null
        ) {
            setIsPasswordCorrect(true);
        } else {
            setIsPasswordCorrect(false);
        }

        if (confirmNewPassword !== e.target.value) {
            setIsPasswordConfirmed(false);
        } else if (e.target.value !== "") {
            setIsPasswordConfirmed(true);
            setNewPassword(e.target.value);
        }
    }

    function checkConfirmedPasswords(e: React.ChangeEvent<HTMLInputElement>) {
        setConfirmNewPassword(e.target.value);

        if (newPassword === e.target.value) {
            setIsPasswordConfirmed(true);
            setNewPassword(newPassword);
        } else {
            setIsPasswordConfirmed(false);
        }
    }

    async function sendUpdatePasswordRequest(
        email: string,
        newPassword: string,
        forgotPasswordCode: string
    ) {
        NProgress.start();

        await fetch(
            `${process.env.REACT_APP_API_V1_URL}/auth/forgot-password`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    password: newPassword,
                    email: email.toLowerCase(),
                    validationCode: forgotPasswordCode,
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.error) {
                    setIsPasswordNotUpdated(false);

                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")!}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    setIsPasswordNotUpdated(true);
                    toggleOpeningForgotPasswordModal();

                    go(0);

                    return swal({
                        icon: "success",
                        content: <p>{t(data.message)}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            })
            .catch(() => {
                NProgress.done();

                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")!}</p>,
                    buttons: false,
                    timer: 2000,
                });
            });
    }

    return (
        <Modal onClosed={() => go(0)} isOpen={isModalOpened} centered>
            <ModalHeader
                toggle={() => {
                    setNewPassword("");
                    setConfirmNewPassword("");
                    setIsPasswordConfirmed(false);
                    setIsPasswordCorrect(false);
                    toggleOpeningForgotPasswordModal();
                }}
            >
                {t("Forgot your password?")}
            </ModalHeader>
            {index === 0 && (
                <React.Fragment>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t("Please enter your email")}
                        </p>
                        <Input
                            type="email"
                            placeholder={t("Enter email")}
                            onChange={handleEmailInputOnChangeEvent}
                            onPaste={handleEmailInputOnPasteEvent}
                            onFocus={() => {
                                setIsFocusInInput(true);
                            }}
                        />
                        {isInvalidEmail ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t(
                                    "Please enter a valid username in the format name@example.com"
                                )}
                            </Alert>
                        ) : null}
                        {isFocusInInput ? null : isVerifiedEmailError ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t(verifiedEmailMessage)}
                            </Alert>
                        ) : null}
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <StyledButton
                            outline={true}
                            rounded={true}
                            variant={mainColor === null ? "primary" : ""}
                            onClick={toggleOpeningForgotPasswordModal}
                            className="w-25"
                            style={{
                                color: mainColor,
                                borderColor: mainColor,
                            }}
                        >
                            {t("Return")}
                        </StyledButton>
                        <StyledButton
                            rounded={true}
                            variant={mainColor === null ? "primary" : ""}
                            className="w-25"
                            style={{
                                background: mainColor,
                            }}
                            disabled={isInvalidEmail}
                            onClick={() => {
                                setIsFocusInInput(false);
                                setIsInvalidEmail(!isEmailValid(email));
                                sendEmailVerificationRequest(email);
                            }}
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </React.Fragment>
            )}
            {index === 1 && (
                <React.Fragment>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t(
                                "Enter the 6-digit verification code sent to your email address"
                            )}
                        </p>
                        <Input
                            type="number"
                            placeholder={t("6 digit code")}
                            onChange={handleCodeInputOnChangeEvent}
                        />
                        {isInvalidCode ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("The code must be 6 digit length")}
                            </Alert>
                        ) : null}
                        {isUnverifiedCode ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("Sorry, we can't find your code")}.
                            </Alert>
                        ) : null}
                        <StyledButton
                            //@ts-ignore
                            variant="link"
                            className="pl-0 text-muted"
                            style={{
                                textDecoration: "underline",
                                color: "#495057",
                                background: "transparent",
                            }}
                            onClick={() => {
                                sendResetCodeRequest(email);
                            }}
                        >
                            {t("Resend code")}
                        </StyledButton>
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <StyledButton
                            outline={true}
                            rounded={true}
                            variant={mainColor === null ? "primary" : ""}
                            className="w-25"
                            style={{
                                color: mainColor,
                                borderColor: mainColor,
                            }}
                            onClick={toggleOpeningForgotPasswordModal}
                        >
                            {t("Return")}
                        </StyledButton>
                        <StyledButton
                            rounded={true}
                            variant={mainColor === null ? "primary" : ""}
                            className="w-25"
                            style={{
                                background: mainColor,
                            }}
                            disabled={isInvalidCode}
                            onClick={() => {
                                sendCodeVerificationRequest(
                                    email,
                                    forgotPasswordCode
                                );
                            }}
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </React.Fragment>
            )}
            {index === 2 && (
                <React.Fragment>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t("Please enter your new password")}
                        </p>
                        <div className="new-password-toggle__clz">
                            {isNewPasswordDisplayed ? (
                                <HidePasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            ) : (
                                <ShowPasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            )}
                        </div>
                        <input
                            type={isNewPasswordDisplayed ? "text" : "password"}
                            className={`form-control ${classnames({
                                "border-danger":
                                    !isPasswordCorrect &&
                                    newPassword !== undefined &&
                                    newPassword !== "",
                                "border-success": isPasswordCorrect,
                            })}`}
                            placeholder={t("New password")}
                            onChange={checkNewPassword}
                        />
                        <PasswordStrengthMeter password={newPassword} />
                        <div className="confirmed-password-toggle__clz">
                            {isConfirmedPasswordDisplayed ? (
                                <HidePasswordSvgIcon
                                    onClick={() =>
                                        setIsConfirmedPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            ) : (
                                <ShowPasswordSvgIcon
                                    onClick={() =>
                                        setIsConfirmedPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            )}
                        </div>
                        <input
                            type={
                                isConfirmedPasswordDisplayed
                                    ? "text"
                                    : "password"
                            }
                            className={`form-control mt-3 ${classnames({
                                "border-danger":
                                    !isPasswordConfirmed &&
                                    confirmNewPassword !== "" &&
                                    confirmNewPassword !== undefined,
                                "border-success": isPasswordConfirmed,
                            })}`}
                            placeholder={t("Confirm password")}
                            onChange={checkConfirmedPasswords}
                            disabled={!isPasswordCorrect}
                        />

                        {!isPasswordConfirmed &&
                        confirmNewPassword !== "" &&
                        confirmNewPassword !== undefined ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("Sorry, your passwords not confirmed")}.
                            </Alert>
                        ) : null}
                        {isPasswordConfirmed && isPasswordNotUpdated ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("There's an error")}.
                            </Alert>
                        ) : null}
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <StyledButton
                            rounded={true}
                            variant={mainColor === null ? "primary" : ""}
                            className="w-25"
                            style={{
                                background: mainColor,
                            }}
                            onClick={() => {
                                setNewPassword("");
                                setConfirmNewPassword("");
                                setIsPasswordConfirmed(false);
                                setIsPasswordCorrect(false);
                                sendUpdatePasswordRequest(
                                    email,
                                    newPassword,
                                    forgotPasswordCode
                                );
                            }}
                            disabled={
                                !isPasswordConfirmed ||
                                newPassword === "" ||
                                confirmNewPassword === ""
                            }
                        >
                            {t("Validate")}
                        </StyledButton>
                    </ModalFooter>
                </React.Fragment>
            )}
        </Modal>
    );
}
