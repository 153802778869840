import { AnyAction } from "redux";

import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE } from "./actionTypes";

export function editProfile(user: UserType): AnyAction {
    return {
        type: EDIT_PROFILE,
        payload: { user },
    };
}

export function profileSuccess(message: string): AnyAction {
    return {
        type: PROFILE_SUCCESS,
        payload: message,
    };
}

export function profileError(error: boolean): AnyAction {
    return {
        type: PROFILE_ERROR,
        payload: error,
    };
}
