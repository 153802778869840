import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Media, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { useResource } from "@hooks/useResource";

import {
    formatCurrency,
    getTypeParam,
    getAdvancedPeriodStringParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

type GlobalDataType = {
    title: string;
    iconClass: string;
};

export default function GlobalDataRow({ title, iconClass }: GlobalDataType) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);
    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [amount, setAmount] = React.useState<number>(0);

    const { resourceData: globalData, error: globalDataError } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/global?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        if (globalData.data) {
            let localData: number = 0;

            globalData.data?.forEach((item: any) => {
                if (item.designation === getTypeParam(type)) {
                    localData = item.value;
                }
            });

            setAmount(localData);
        }
    }, [globalData, type]);

    return (
        <Row>
            <Col>
                <Card className="mini-stats-wid dark-bg__clz">
                    {globalDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <CardBody style={{ background: mainColor }}>
                                <Media>
                                    <Media body>
                                        <p className="text-white font-weight-medium">
                                            {type === t("Incl-tax")
                                                ? t("Turnover incl-tax")
                                                : type === t("Ex-tax")
                                                ? t("Turnover ex-tax")
                                                : title}
                                        </p>
                                        <h4 className="text-white mb-0">
                                            {title === t("Tickets")
                                                ? amount
                                                : formatCurrency(amount)}
                                        </h4>
                                    </Media>
                                    <div className="mini-stat-icon avatar-sm rounded-circle  align-self-center">
                                        <span className="avatar-title bg-dark">
                                            <i
                                                className={`bx ${iconClass}  font-size-24 `}
                                            ></i>
                                        </span>
                                    </div>
                                </Media>
                            </CardBody>
                        </CustomErrorBoundary>
                    )}
                </Card>
            </Col>
        </Row>
    );
}
