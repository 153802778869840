import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import store, {
    setTop10ArticlesCsv,
    setTop10ArticlesExcel,
    setArticlesCsv,
    setArticlesExcel,
} from "@pages/Statistics/store";

import { useResource } from "@hooks/useResource";

import {
    formatCurrency,
    formatExcelData,
    getTypeParam,
    getAdvancedPeriodStringParam,
    capitalize,
} from "@helpers/general";

export function useTop10ArticlesData() {
    const { t } = useTranslation();
    const { period, type, advancedDate } = useSelector((state: any) => state);

    const {
        top10: {
            percentage: top10Percentage,
            categories: top10Categories,
            histogram: top10Histogram,
        },
    } = useSnapshot(store.products);

    useEffect(() => {
        let array: any[] = [];

        const headers: any[] = [t("Categories")];

        top10Histogram.forEach((datum: any) => {
            headers.push(t(capitalize(datum.designation.toLowerCase())));
        });

        let averageLine: any[] = [];

        top10Categories.forEach((category: any) => {
            let localArray: any[] = [];
            let localCategory = category;

            top10Histogram.forEach((item: any, index: number) => {
                item.value.forEach((datum: any) => {
                    let localDesignation = String(datum.designation);

                    if (
                        advancedDate.startDate !== "" &&
                        advancedDate.endDate !== ""
                    ) {
                        let daysBetweenDates = moment
                            .unix(Number(advancedDate.endDate))
                            .diff(
                                moment.unix(Number(advancedDate.startDate)),
                                "days"
                            );
                        if (daysBetweenDates === 0) {
                            localDesignation = String(datum.designation);
                        } else if (daysBetweenDates <= 31) {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("ddd DD-MM-YYYY")
                            );
                        } else {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("MMM YYYY")
                            );
                        }
                    } else if (
                        [
                            t("Current week"),
                            t("The last 7 days"),
                            t("The last 14 days"),
                            t("The last 30 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                        localCategory = capitalize(
                            moment
                                .unix(Number(category))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else if (period === t("Current month")) {
                        localDesignation = capitalize(
                            moment.unix(Number(datum.designation)).format("DD")
                        );
                    } else if (period === t("Current year")) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    } else if (
                        [
                            t("The last 90 days"),
                            t("The last 180 days"),
                            t("The last 365 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }

                    if (localDesignation === localCategory) {
                        localArray.push(
                            type !== t("Tickets")
                                ? datum.value
                                : `${datum.value} ${t("Tickets")}`
                        );
                    }
                });

                if (!localArray[index]) {
                    localArray[index] = 0;
                }
            });

            array.push([localCategory, ...localArray]);
        });

        top10Percentage.forEach((datum: any) => {
            averageLine.push(
                type !== t("Tickets")
                    ? formatCurrency(datum / array.length)
                    : (datum / array.length).toFixed(2)
            );
        });

        const formattedPercentageData = top10Percentage.map((element: any) => {
            return type !== t("Tickets") ? formatCurrency(element) : element;
        });

        const top10CsvData = [
            headers,
            ...array,
            [t("Total"), ...formattedPercentageData],
            [t("Average"), ...averageLine],
        ];

        setTop10ArticlesCsv(top10CsvData);

        const top10ExcelData = [
            formatExcelData(
                headers,
                array,
                formattedPercentageData,
                averageLine,
                type
            ),
        ];

        setTop10ArticlesExcel(top10ExcelData);
    }, [
        t,
        top10Histogram,
        top10Categories,
        top10Percentage,
        advancedDate.startDate,
        advancedDate.endDate,
        period,
        type,
    ]);
}

export function useTopAllArticlesData() {
    const { t } = useTranslation();
    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );
    const {
        all: { percentage, categories, histogram },
    } = useSnapshot(store.products);

    const {
        resourceData: { data: products },
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/all/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    useEffect(() => {
        let localProducts: number[] = [];

        products.forEach((item: any) => {
            localProducts.push(item.value);
        });

        let array: any[] = [];

        let headers: any[] = [t("Categories")];

        histogram.forEach((datum: any) => {
            headers.push(t(capitalize(datum.designation.toLowerCase())));
        });

        categories.forEach((category: any) => {
            let localArray: any[] = [];
            let localCategory = category;

            histogram.forEach((item: any, index: number) => {
                item.value.forEach((datum: any) => {
                    let localDesignation = String(datum.designation);

                    if (
                        advancedDate.startDate !== "" &&
                        advancedDate.endDate !== ""
                    ) {
                        let daysBetweenDates = moment
                            .unix(Number(advancedDate.endDate))
                            .diff(
                                moment.unix(Number(advancedDate.startDate)),
                                "days"
                            );
                        if (daysBetweenDates === 0) {
                            localDesignation = String(datum.designation);
                        } else if (daysBetweenDates <= 31) {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("ddd DD-MM-YYYY")
                            );
                        } else {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("MMM YYYY")
                            );
                        }
                    } else if (
                        [
                            t("Current week"),
                            t("The last 7 days"),
                            t("The last 14 days"),
                            t("The last 30 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                        localCategory = capitalize(
                            moment
                                .unix(Number(category))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else if (period === t("Current month")) {
                        localDesignation = capitalize(
                            moment.unix(Number(datum.designation)).format("DD")
                        );
                    } else if (period === t("Current year")) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    } else if (
                        [
                            t("The last 90 days"),
                            t("The last 180 days"),
                            t("The last 365 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }

                    if (localDesignation === localCategory) {
                        localArray.push(
                            type !== t("Tickets")
                                ? datum.value
                                : `${datum.value} ${t("Tickets")}`
                        );
                    }
                });

                if (!localArray[index]) {
                    localArray[index] = 0;
                }
            });

            array.push([localCategory, ...localArray]);
        });

        const averageLine: any[] = [];

        (percentage.length > 0 ? percentage : localProducts).forEach(
            (datum: number) => {
                const average = array.length === 0 ? 0 : datum / array.length;
                averageLine.push(
                    type !== t("Tickets")
                        ? formatCurrency(average)
                        : average.toFixed(2)
                );
            }
        );

        const formattedPercentageData = (percentage.length > 0
            ? percentage
            : localProducts
        ).map((element: number) => {
            return type !== t("Tickets") ? formatCurrency(element) : element;
        });

        const localCsvProducts = [
            headers,
            ...array,
            [t("Total"), ...formattedPercentageData],
            [t("Average"), ...averageLine],
        ];

        setArticlesCsv(localCsvProducts);

        const excelData = [
            formatExcelData(
                headers,
                array,
                formattedPercentageData,
                averageLine,
                type
            ),
        ];

        setArticlesExcel(excelData);
    }, [
        products,
        t,
        percentage,
        histogram,
        categories,
        advancedDate.startDate,
        advancedDate.endDate,
        period,
        type,
    ]);
}
