import { proxy } from "valtio";
import i18n from "i18next";
import moment from "moment";

import { getLastXDaysInTimestamp } from "@helpers/general";

type AdvancedDateType = {
    startDate: string;
    endDate: string;
};

type AdvancedStoreType = {
    advancedDate: AdvancedDateType;
    comparisonAdvancedDate: AdvancedDateType;
    type: string;
    advancedLegend: string;
    chartType: string;
    salesModeSelection: string[];
    salesSupportSelection: string[];
    regulationModesSelection: string[];
    productsSelection: any[];
    customersCitiesSelection: string[];
    avgBasketRange: number[];
    selectedSegments: string[];
    segmentQueryString: string;
    productsNumber: number;
    maxAvgBasket: number;
    segmentName: string;
    allProducts: any[];
    segments: string[];
    advancedSelection: SelectionType;
    preAdvancedSelection: SelectionType;
    comparisonFlag: boolean;
    transactionsAmountRange: number[];
};

const store = proxy<AdvancedStoreType>({
    advancedDate: {
        startDate: getLastXDaysInTimestamp(7)[0],
        endDate: getLastXDaysInTimestamp(7)[6],
    },
    comparisonAdvancedDate: {
        startDate: String(moment().unix()),
        endDate: String(moment().unix()),
    },
    type: i18n.t("Turnover incl-tax"),
    advancedLegend: i18n.t("General"),
    chartType: "line",
    salesModeSelection: [],
    salesSupportSelection: [],
    regulationModesSelection: [],
    productsSelection: [],
    customersCitiesSelection: [],
    avgBasketRange: [],
    selectedSegments: [],
    segmentQueryString: "",
    productsNumber: 0,
    maxAvgBasket: 0,
    segmentName: "",
    allProducts: [],
    segments: [],
    advancedSelection: {
        designation: "",
        selection: [],
    },
    preAdvancedSelection: {
        designation: "",
        selection: [],
    },
    comparisonFlag: false,
    transactionsAmountRange: [],
});

export function clearStore() {
    store.salesModeSelection = [];
    store.salesSupportSelection = [];
    store.regulationModesSelection = [];
    store.productsSelection = [];
    store.customersCitiesSelection = [];
    store.avgBasketRange = [];
    store.productsNumber = 0;
    store.segmentName = "";
    store.transactionsAmountRange = [];
}

export function setAdvancedSelection(selection: SelectionType) {
    store.advancedSelection = selection;
}
export function setComparisonFlag(comparisonFlag: boolean) {
    store.comparisonFlag = comparisonFlag;
}

export function setPreAdvancedSelection(selection: SelectionType) {
    store.preAdvancedSelection = selection;
}

export function setAllProducts(allProducts: any[]) {
    store.allProducts = allProducts;
}

export function setSegmentName(segmentName: string) {
    store.segmentName = segmentName;
}

export function setProductsNumber(productsNumber: number) {
    store.productsNumber = productsNumber;
}

export function setMaxAvgBasket(maxAvgBasket: number) {
    store.maxAvgBasket = maxAvgBasket;
}

export function addSalesModeSelectionItem(salesMode: string) {
    store.salesModeSelection.push(salesMode);
}

export function removeSalesModeItem(salesMode: string) {
    store.salesModeSelection = store.salesModeSelection.filter(
        (datum: string) => datum !== salesMode
    );
}

export function addSalesSupportSelectionItem(salesSupport: string) {
    store.salesSupportSelection.push(salesSupport);
}

export function removeSalesSupportItem(salesSupport: string) {
    store.salesSupportSelection = store.salesSupportSelection.filter(
        (datum: string) => datum !== salesSupport
    );
}

export function addRegulationModesSelectionItem(regulationMode: string) {
    store.regulationModesSelection.push(regulationMode);
}

export function removeRegulationModeItem(regulationMode: string) {
    store.regulationModesSelection = store.regulationModesSelection.filter(
        (datum: string) => datum !== regulationMode
    );
}

export function addProductsSelectionItem(product: any) {
    store.productsSelection.push(product);
}

export function removeProductItem(product: any) {
    store.productsSelection = store.productsSelection.filter((datum: any) => {
        return (
            `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`.toLowerCase() !==
            `${datum.reference}|${datum.designation}|${datum.families}|${datum.sub_families}`.toLowerCase()
        );
    });
}

export function addCustomersCitiesSelectionItem(customerCity: string) {
    store.customersCitiesSelection.push(customerCity);
}

export function removeCustomerCityItem(customerCity: string) {
    store.customersCitiesSelection = store.customersCitiesSelection.filter(
        (datum: string) => datum !== customerCity
    );
}

export function setAvgBasketRange(range: number[]) {
    store.avgBasketRange = range;
}

export function setAdvancedDate(advancedDate: AdvancedDateType) {
    store.advancedDate = advancedDate;
}

export function setComparedAdvancedDate(
    comparisonAdvancedDate: AdvancedDateType
) {
    store.comparisonAdvancedDate = comparisonAdvancedDate;
}

export function setAdvancedTypeParam(type: string) {
    store.type = type;
}

export function setAdvancedLegend(legend: string) {
    store.advancedLegend = legend;
}

export function setChartType(chartType: string) {
    store.chartType = chartType;
}

export function setSelectedSegments(segment: string) {
    if (store.selectedSegments.includes(segment)) {
        store.selectedSegments = store.selectedSegments.filter(
            (datum) => datum !== segment
        );
    } else {
        store.selectedSegments.push(segment);
    }
}

export function clearSelectedSegments() {
    store.selectedSegments = [];
}

export function setSegmentQueryString(segmentQueryString: string) {
    store.segmentQueryString = segmentQueryString;
}

export function setSegments(segments: string[]) {
    store.segments = segments;
}

export function setTransactionsAmountRange(range: number[]) {
    store.transactionsAmountRange = range;
}

export default store;
