import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";

import { MyBestShopSvgIcon } from "@components/Common/SvgIcons";

type PropsType = { shop: ClientsMyBestShopType };

export function CustomFirstCol({ shop }: PropsType) {
    const { t } = useTranslation();

    return (
        <Col md="3" className="px-0">
            <Card
                className="card-block__clz my-best-shop-card__clz mr-2"
                style={{
                    background:
                        "transparent linear-gradient(180deg, #EAC600 0%, #FFB103 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <CardBody
                    className="text-white d-grid__clz py-2 px-3"
                    style={{
                        gridTemplateColumns: "0.1fr 0.9fr",
                    }}
                >
                    <div className="d-flex justify-content-center align-items-center">
                        <MyBestShopSvgIcon />
                    </div>
                    <div className="d-grid__clz ml-3">
                        <span
                            className="d-flex flex-column"
                            style={{
                                font: "normal normal bold 17px/22px Poppins",
                                letterSpacing: "0px",
                            }}
                        >
                            <span className="mb-2">{t("Best shop")}</span>

                            <span>{shop?.designation}</span>
                        </span>
                        <table
                            style={{
                                font: "normal normal  13px/18px Poppins",
                            }}
                        >
                            {shop.info.map((info) => (
                                <tr>{info}</tr>
                            ))}
                        </table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}
