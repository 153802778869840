import React from "react";
import ReactExport from "react-data-export";

import { capitalize } from "@helpers/general";

type PropsType = {
    filename: string;
    data: any[];
} & ChildrenPropsType;

export default function ExportCsvToExcel({
    filename,
    data,
    children,
}: PropsType) {
    return (
        <ReactExport.ExcelFile
            filename={capitalize(filename)}
            element={children}
        >
            <ReactExport.ExcelFile.ExcelSheet
                dataSet={data}
                name={capitalize(filename)}
            />
        </ReactExport.ExcelFile>
    );
}
