import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { StyledSlider } from "@aureskonnect/react-ui";

const { createSliderWithTooltip } = StyledSlider;
const Range = createSliderWithTooltip(StyledSlider.Range);

export const StyledRange = styled(Range)`
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 80%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    margin-top: ${isMobile ? "30px" : "70px"};
    margin-bottom: ${isMobile ? "30px" : "0px"};
    margin-left: ${isMobile ? "25px" : "80px"};
    .rc-slider-handle {
        position: absolute;
        width: 14px;
        height: 14px;
        right: auto;
        transform: translateX(-50%);
        margin-top: -5px;
        cursor: grab;
        border-radius: 50%;
        border: 2px solid #96dbfa;
        background-color: #fff;
        touch-action: pan-x;
        &:hover {
            border-color: #57c5f7;
        }
    }
    .rc-slider-handle-dragging {
        border-color: #57c5f7;
        box-shadow: 0 0 0 5px #96dbfa;
    }
    .rc-slider-rail {
        position: absolute;
        width: 100%;
        background-color: #e9e9e9;
        height: 4px;
        border-radius: 6px;
    }
    .rc-slider-track {
        position: absolute;
        left: 15%;
        right: auto;
        width: 50%;
        height: 4px;
        border-radius: 6px;
        background-color: #abe2fb;
    }
    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 4px;
        background: transparent;
    }
    .rc-slider-mark {
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        font-size: 12px;
    }
    .rc-slider-tooltip {
        position: absolute;
        visibility: visible;
        box-sizing: border-box;
    }

    .rc-slider-tooltip-placement-top {
        padding: 4px 0 8px;
    }

    .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
    }

    .rc-slider-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-top-color: transparent;
        border-style: solid;
    }

    .rc-slider-tooltip-inner {
        padding: 6px 2px;
        min-width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 1;
        color: #fff;
        text-align: center;
        text-decoration: none;
        background-color: #6c6c6c;
        border-radius: 6px;
        box-shadow: 0 0 4px #d9d9d9;
    }
`;
