import React from "react";
import { useTranslation } from "react-i18next";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "@vStore";
import { store as productsStore } from "../store";

import {
    formatCurrency,
    getTableThead,
    searchKeyByValue,
} from "@helpers/general";

type PropsType = {
    selectedRows: any[];
    tableId: string;
    type: string;
};

export function TotalRowWrapper({ selectedRows, tableId, type }: PropsType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const { titleRowWidth } = useSnapshot(productsStore);

    let totals: Record<string, any> = {};

    for (const row of selectedRows) {
        const rowCopy = { ...row };
        delete rowCopy[t(searchKeyByValue("TTC"))];
        delete rowCopy[t(searchKeyByValue("designation"))];
        delete rowCopy[t(searchKeyByValue("HT"))];
        delete rowCopy[t(searchKeyByValue("Tickets"))];
        delete rowCopy[t(searchKeyByValue("Panier moyen"))];
        delete rowCopy[t(searchKeyByValue("Boutique"))];

        // eslint-disable-next-line
        Object.keys(rowCopy).forEach((index) => {
            totals = {
                ...totals,
                [index]:
                    totals[index] !== undefined
                        ? Number(totals[index]) +
                          Number(rowCopy[index] === "_" ? 0 : rowCopy[index])
                        : Number(rowCopy[index] === "_" ? 0 : rowCopy[index]),
            };
        });
    }

    const keys = getTableThead(tableId);

    return (
        <table
            className={classNames("table-responsive rounded p-2 text-white", {
                "bg-dark": mainColor === null,
            })}
            style={{ background: mainColor }}
        >
            <tr
                id="totalRowWrapper"
                className="overflow-hidden"
                style={{ display: "flex", flex: "1 0 auto", minWidth: "200px" }}
            >
                <td
                    className="d-flex align-items-center"
                    style={{
                        flex: "150 0 auto",
                        minWidth: "10px",
                        width: `${titleRowWidth}px`,
                        justifyContent: "start",
                        boxSizing: "border-box",
                    }}
                >
                    {t("Total")} ({selectedRows.length})
                </td>

                {keys.map((item, index) =>
                    totals[item] !== undefined ? (
                        <td
                            key={uid(`${item}_${totals[item]}__${index}`)}
                            className="d-flex align-items-center"
                            style={{
                                flex: "150 0 auto",
                                minWidth: "10px",
                                width: "150px",
                                justifyContent: "center",
                                boxSizing: "border-box",
                            }}
                        >
                            {type === t(searchKeyByValue("Tickets"))
                                ? totals[item]
                                : type === t(searchKeyByValue("Panier moyen"))
                                ? Math.round(
                                      (totals[item] / selectedRows?.length) *
                                          100
                                  ) / 100
                                : formatCurrency(totals[item])}
                        </td>
                    ) : null
                )}
            </tr>
        </table>
    );
}
