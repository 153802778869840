import React from "react";
import { Col, Row, TabContent, TabPane } from "reactstrap";
import { useSnapshot } from "valtio";

import { store } from "../store";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import Articles from "@pages/Settings/Articles";
import Families from "@pages/Settings/Families";
import { RegulationsGroups } from "@pages/Settings/Regulations";
import SubFamilies from "@pages/Settings/SubFamilies";

export function TabContentWrapper() {
    const { activeTab } = useSnapshot(store);

    return (
        <TabContent
            activeTab={activeTab}
            className="mt-3"
            style={{
                boxShadow: "5px 15px 16px 0px #e0e0e0",
            }}
        >
            <TabPane tabId={1}>
                <Row>
                    <Col sm="12">
                        <React.Suspense fallback={<SpinnerWrapper />}>
                            <RegulationsGroups />
                        </React.Suspense>
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId={2}>
                <Row>
                    <Col sm="12">
                        <Families />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId={3}>
                <Row>
                    <Col sm="12">
                        <SubFamilies />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId={4}>
                <Row>
                    <Col sm="12">
                        <Articles />
                    </Col>
                </Row>
            </TabPane>
        </TabContent>
    );
}
