import React from "react";

export default function ArrowDownSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            width="1em"
            height="1em"
            style={{
                msTransform: "rotate(360deg)",
                WebkitTransform: "rotate(360deg)",
            }}
            viewBox="0 0 24 24"
            transform="rotate(360)"
            {...props}
        >
            <path
                d="M16.293 9.293L12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"
                fill="#fff"
            />
        </svg>
    );
}
