import React from "react";

export default function AndroindSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            width=".86em"
            height="1em"
            style={{
                msTransform: "rotate(360deg)",
                WebkitTransform: "rotate(360deg)",
            }}
            viewBox="0 0 256 301"
            transform="rotate(360)"
            {...props}
        >
            <path
                d="M78.389.858a8.824 8.824 0 00-4.19 1.089c-4.218 2.338-5.765 7.757-3.428 11.972l9.523 17.196C57.35 45.31 42.147 69.558 42.147 97.233v5.142c-4.407-5.07-10.884-8.271-18.121-8.271C10.766 94.104 0 104.87 0 118.129v74.009c0 13.258 10.767 24.025 24.026 24.025 7.237 0 13.714-3.202 18.12-8.272v10.367c0 13.977 11.437 25.413 25.414 25.413h6.448v32.923c0 13.26 10.767 24.026 24.026 24.026s24.025-10.767 24.025-24.026v-32.923h11.156v32.923c0 13.26 10.767 24.026 24.026 24.026s24.025-10.767 24.025-24.026v-32.923h6.449c13.976 0 25.413-11.436 25.413-25.413v-10.367c4.406 5.07 10.884 8.272 18.121 8.272 13.259 0 24.025-10.767 24.025-24.025v-74.009c0-13.259-10.766-24.025-24.025-24.025-7.237 0-13.715 3.201-18.121 8.271v-4.517c.002-.26 0-.468 0-.625 0-27.67-15.238-51.894-38.174-66.09l9.55-17.224c2.336-4.215.79-9.634-3.428-11.972a8.824 8.824 0 00-4.19-1.089c-3.115-.03-6.172 1.612-7.782 4.517l-9.986 18.04c-9.764-3.603-20.388-5.578-31.48-5.578-11.082 0-21.726 1.954-31.482 5.55L86.171 5.375C84.56 2.47 81.504.83 78.389.858z"
                fill="#FFF"
            />
            <path
                d="M24.026 100.362c-9.894 0-17.767 7.873-17.767 17.767v74.009c0 9.894 7.873 17.767 17.767 17.767 9.894 0 17.768-7.873 17.768-17.767v-74.009c0-9.894-7.874-17.767-17.768-17.767zm207.224 0c-9.895 0-17.768 7.873-17.768 17.767v74.009c0 9.894 7.873 17.767 17.768 17.767 9.894 0 17.767-7.873 17.767-17.767v-74.009c0-9.894-7.873-17.767-17.767-17.767zM98.034 184.818c-9.894 0-17.768 7.873-17.768 17.768v74.008c0 9.894 7.874 17.767 17.768 17.767 9.894 0 17.767-7.873 17.767-17.767v-74.008c0-9.895-7.873-17.768-17.767-17.768zm59.207 0c-9.895 0-17.768 7.873-17.768 17.768v74.008c0 9.894 7.873 17.767 17.768 17.767 9.894 0 17.767-7.873 17.767-17.767v-74.008c0-9.895-7.873-17.768-17.767-17.768z"
                fill="#A4C639"
            />
            <path
                d="M78.443 7.117a2.47 2.47 0 00-1.197.326c-1.267.702-1.683 2.158-.98 3.428l12.517 22.611c-24.08 12.53-40.346 36.341-40.378 63.669H206.87c-.033-27.328-16.298-51.14-40.378-63.669l12.516-22.61c.704-1.27.287-2.727-.98-3.429a2.467 2.467 0 00-1.197-.326c-.9-.01-1.751.44-2.231 1.306l-12.68 22.855c-10.372-4.6-22.006-7.183-34.283-7.183-12.277 0-23.91 2.582-34.283 7.183L80.674 8.423a2.486 2.486 0 00-2.23-1.306zm-30.038 96.292v114.85a19.113 19.113 0 0019.155 19.154h120.155a19.113 19.113 0 0019.155-19.155V103.41H48.405z"
                fill="#A4C639"
            />
            <path
                d="M91.068 54.923c-3.617 0-6.612 2.994-6.612 6.611 0 3.618 2.995 6.612 6.612 6.612 3.618 0 6.612-2.994 6.612-6.612 0-3.617-2.994-6.611-6.612-6.611zm73.138 0c-3.618 0-6.612 2.994-6.612 6.611 0 3.618 2.994 6.612 6.612 6.612 3.617 0 6.612-2.994 6.612-6.612 0-3.617-2.995-6.611-6.612-6.611z"
                fill="#FFF"
            />
        </svg>
    );
}
