import React from "react";
import { Card, CardBody, Spinner } from "reactstrap";

export default function CardSpinnerWrapper() {
    return (
        <Card>
            <CardBody>
                <div className="text-center">
                    <Spinner type="grow" className="mr-2" color="primary" />
                    <Spinner type="grow" className="mr-2" color="secondary" />
                    <Spinner type="grow" className="mr-2" color="success" />
                    <Spinner type="grow" className="mr-2" color="danger" />
                </div>
            </CardBody>
        </Card>
    );
}
