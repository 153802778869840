import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
    formatChartLabels,
    formatCurrency,
    getColorsArray,
} from "@helpers/general";

type SmoothCurveLineType = {
    series: SeriesDataType[];
    categories?: any;
};

export default function SmoothCurveLine({
    series,
    categories,
}: SmoothCurveLineType) {
    const { t } = useTranslation();
    const type = useSelector((state: { type: string }) => state.type);
    const period = useSelector((state: any) => state.period);
    const advancedDate = useSelector((state: any) => state.advancedDate);

    const options = {
        responsive: [
            {
                breakpoint: 992,
                options: {
                    legend: {
                        show: false,
                    },
                },
            },
        ],
        legend: {
            show: true,
        },
        colors: getColorsArray(series.length),
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },
        xaxis: {
            categories,
            labels: {
                formatter: (value: any) =>
                    formatChartLabels(value, advancedDate, period),
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        yaxis: {
            tickAmount: 1,
            labels: {
                formatter: function (value: any) {
                    return [t("Tickets"), t("Quantity sold")].includes(type)
                        ? Math.round(value)
                        : formatCurrency(value, 0);
                },
            },
        },
    };

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="360"
        />
    );
}
