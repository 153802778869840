import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";

import PageTitle from "@components/Common/PageTitle";
import { TopContentWrapper } from "@pages/Statistics/Shops/TopContentWrapper";
import { MyShopsCol } from "@pages/Statistics/Shops/MyShopsCol";
import { SalesModeCol } from "@pages/Statistics/Shops/SalesModeCol";
import { SalesSupportCol } from "@pages/Statistics/Shops/SalesSupportCol";
import { RegulationModesCol } from "@pages/Statistics/Shops/RegulationModesCol";
import { CategoriesCol } from "@pages/Statistics/Shops/CategoriesCol";
import { ProductsCol } from "@pages/Statistics/Shops/ProductsCol";
import { MyBestShopCol } from "@pages/Statistics/Shops/MyBestShopCol";

import "./index.css";

export function Shops() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PageTitle title={t("Shops")} />
            <div className="page-content">
                <TopContentWrapper />
                <Row>
                    <MyBestShopCol />
                    <MyShopsCol />
                    <SalesModeCol />
                    <SalesSupportCol />
                    <RegulationModesCol />
                    <ProductsCol />
                    <CategoriesCol />
                </Row>
            </div>
        </React.Fragment>
    );
}
