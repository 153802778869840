import React from "react";

import { PeriodModalWrapper } from "@components/Common/GlobalBreadcrumb/PeriodModalWrapper";
import { SelectsWrapper } from "@pages/Statistics/Shops/TopContentWrapper/FiltersWrapper/MainContentWrapper/SelectsWrapper";

export function MainContentWrapper() {
    return (
        <div className="d-sm-flex">
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "10px" }}
            >
                <PeriodModalWrapper />
                <SelectsWrapper />
            </div>
        </div>
    );
}
