import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { useSnapshot } from "valtio";
import { StyledButton, StyledSearchInput } from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import store, {
    setFilteredProducts,
    setGlobalFilterValue,
    setGroupedArticles,
} from "@pages/Settings/Articles/store";

import { ITEMS_PER_PAGE } from "@constants/index";

import CustomSelect from "@pages/Settings/Articles/CustomSelect";
import { NoImagePlaceholderIcon } from "@pages/Settings/components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";
import { ActionsButtonsWrapper } from "@pages/Settings/Articles/SecondStep/ActionsButtonsWrapper";

import "@assets/widths.css";

export default function SecondStep({ go }: any) {
    console.log("SecondStep");
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const {
        groupedArticles,
        groupName,
        products,
        families,
        subFamilies,
        globalFilterValue,
        filteredProducts,
    } = useSnapshot(store);

    const [familiesSelectedValue, setFamiliesSelectedValue] = React.useState<
        any[]
    >([]);
    const [selectedArticles, setSelectedArticles] = React.useState<any[]>([]);
    const [
        subFamiliesSelectedValue,
        setSubFamiliesSelectedValue,
    ] = React.useState<any[]>([]);

    const columns = React.useMemo(
        () => [
            {
                Header: t("Product"),
                accessor: "designation",
            },
            {
                Header: t("Picture"),
                accessor: function (article: any) {
                    return (
                        <div>
                            {article.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                article.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: t("Families"),
                accessor: "family",
                disableGlobalFilter: true,
            },
            {
                Header: t("Sub-families"),
                accessor: "subFamily",
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (article: any) {
                    return (
                        <ActionsButtonsWrapper
                            article={article}
                            selectedArticles={selectedArticles}
                            setSelectedArticles={setSelectedArticles}
                        />
                    );
                },
            },
        ],
        [t, selectedArticles]
    );

    const localFamilies: any[] = families.map((family: string) => ({
        label: family,
        value: family,
    }));

    const localSubFamilies: any[] = subFamilies.map((subFamily: string) => ({
        label: subFamily,
        value: subFamily,
    }));

    function handleFamiliesSelectOnChangeEvent(
        familiesSelectedValue: any,
        action: any
    ) {
        setFamiliesSelectedValue(familiesSelectedValue);
        setSubFamiliesSelectedValue([]);
        let filteredArticlesByFamilies: any[] = [];
        if (action.action === "clear") {
            setFilteredProducts(
                filteredProducts.filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                })
            );
        } else if (familiesSelectedValue) {
            familiesSelectedValue!.forEach((item: any) => {
                let localArticles = filteredProducts.filter(function (
                    article: any
                ) {
                    return article.family === item.value;
                });
                filteredArticlesByFamilies = [
                    ...filteredArticlesByFamilies,
                    ...localArticles,
                ].filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                });
            });
            setFilteredProducts(filteredArticlesByFamilies);
        }
    }

    function handleSubFamiliesSelectOnChangeEvent(
        subFamiliesSelectedValue: any,
        action: any
    ) {
        setFamiliesSelectedValue([]);
        setSubFamiliesSelectedValue(subFamiliesSelectedValue);
        let filteredArticlesBySubFamilies: any[] = [];

        if (action.action === "clear") {
            setFilteredProducts(
                filteredProducts.filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                })
            );
        } else if (subFamiliesSelectedValue) {
            subFamiliesSelectedValue!.forEach((item: any) => {
                let localArticles = filteredProducts.filter(function (
                    article: any
                ) {
                    return article.subFamily === item.value;
                });
                filteredArticlesBySubFamilies = [
                    ...filteredArticlesBySubFamilies,
                    ...localArticles,
                ].filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                });
            });
            setFilteredProducts(filteredArticlesBySubFamilies);
        }
    }

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredProducts as any[],
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    function handleSelectionListButtonOnClickEvent() {
        setGroupedArticles([...selectedArticles, ...groupedArticles]);
        go(3);
    }

    function handleMultipleAddingRegulationModesButtonOnClickEvent() {
        let localSelectedArticles: any[] = [
            ...selectedArticles,
            ...selectedFlatRows.map((row: any) => row.original),
        ];
        setSelectedArticles(localSelectedArticles);
        let localArticles: any[] = filteredProducts.filter((article) => {
            return !localSelectedArticles
                .map((s) => s.designation.toLowerCase())
                .includes(article.designation.toLowerCase());
        });
        setFilteredProducts(localArticles);
    }

    function handleGlobalFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
        setGlobalFilterValue(e.target.value);
    }

    React.useEffect(() => {
        setGlobalFilter(globalFilterValue);
        // eslint-disable-next-line
    }, [globalFilterValue]);

    React.useEffect(() => {
        let localArticles: any[] = products.filter((article: any) => {
            return !groupedArticles
                .map((articleGroup: any) =>
                    `${articleGroup.reference}|${articleGroup.designation}|${articleGroup.family}|${articleGroup.subFamily}`.toLowerCase()
                )
                .includes(
                    `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                );
        });

        if (
            (familiesSelectedValue === null ||
                familiesSelectedValue!.length === 0) &&
            (subFamiliesSelectedValue === null ||
                subFamiliesSelectedValue!.length === 0)
        ) {
            setFilteredProducts(localArticles);
        }
    }, [
        products,
        familiesSelectedValue,
        groupedArticles,
        subFamiliesSelectedValue,
    ]);

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="d-xl-flex justify-content-xl-between">
                <div className="d-md-flex align-items-md-center my-2">
                    <span className="mb-0 ml-2 mr-0 h5 white-space-nowrap">
                        {groupName}
                    </span>
                    <span
                        className="divider mt-0 mx-4 d-none d-md-block"
                        style={{
                            backgroundColor: "#808080a6",
                            height: "2.25rem",
                            width: "2px",
                        }}
                    />
                    <StyledSearchInput
                        onChange={handleGlobalFilterChange}
                        placeholder={`${t("Search")} ...`}
                        className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                    />
                    <CustomSelect
                        options={localFamilies}
                        placeholder={t("Families")}
                        handleOnChangeEvent={handleFamiliesSelectOnChangeEvent}
                        selectedValue={familiesSelectedValue}
                    />
                    <CustomSelect
                        options={localSubFamilies}
                        placeholder={t("Sub-families")}
                        handleOnChangeEvent={
                            handleSubFamiliesSelectOnChangeEvent
                        }
                        selectedValue={subFamiliesSelectedValue}
                    />
                </div>
                <div className="d-md-flex mt-2 mx-2 mb-2">
                    <StyledButton
                        rounded={true}
                        variant={mainColor === null ? "secondary" : ""}
                        className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                        style={{
                            background: mainColor,
                        }}
                        disabled={selectedFlatRows.length === 0}
                        onClick={
                            handleMultipleAddingRegulationModesButtonOnClickEvent
                        }
                    >
                        {t("Add payment modes")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        variant={mainColor === null ? "secondary" : ""}
                        className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                        style={{
                            background: mainColor,
                        }}
                        disabled={
                            selectedArticles.length + groupedArticles.length ===
                            0
                        }
                        onClick={handleSelectionListButtonOnClickEvent}
                    >
                        {`${t("My selection")} (${
                            selectedArticles.length + groupedArticles.length
                        })`}
                    </StyledButton>
                </div>
            </div>
            <BTable responsive={true} {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className="white-space-nowrap"
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className="white-space-nowrap align-middle"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </BTable>
            {filteredProducts.length > ITEMS_PER_PAGE ? (
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                />
            ) : null}
        </React.Fragment>
    );
}
