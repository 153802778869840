import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { families, setFamilies } from "@pages/Statistics/NewProducts/signals";

import { useResource } from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import CustomSelectFilter from "@pages/Statistics/NewProducts/CustomSelectFilter";
import { DownloadDropdown } from "@pages/Statistics/NewProducts/DownloadDropdown";
import ErrorPage from "@components/Common/ErrorPage";
import TitleRow from "@components/Common/TitleRow";

export function TitleRowWrapper() {
    const { t } = useTranslation();

    const { period, type, advancedDate, selections } = useSelector(
        (state: any) => state
    );

    const {
        resourceData: { data: familiesList },
        error: familiesListError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/families-list?${getAdvancedPeriodStringParam(
            period,
            advancedDate
        )}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${type === t("Average turnover") ? "" : `&type=${getTypeParam(type)}`}`
    );

    React.useEffect(() => {
        setFamilies([t("Top 10 families"), ...familiesList]);
    }, [familiesList, t]);

    return (
        <TitleRow title={t("Articles per top 10 families")}>
            {familiesListError ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <CustomSelectFilter options={families.value} />
                </CustomErrorBoundary>
            )}
            <DownloadDropdown />
        </TitleRow>
    );
}
