import { proxy } from "valtio";

type EmployeeStoreType = {
    employeesQueryString: string;
};

const store = proxy<EmployeeStoreType>({
    employeesQueryString: "",
});


export function setEmployeesQueryString(string: string) {
    store.employeesQueryString = string;
}

export function clearStore() {
    store.employeesQueryString = "";
}

export default store;
