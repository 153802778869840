import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import OrderRow from "./OrderRow";

type OrdersWrapperPropsType = {
    title: string;
    totalAmount: number;
    totalPercentage: number;
    chartPercentage: number;
};

export default function OrdersWrapper({
    title,
    totalAmount,
    totalPercentage,
    chartPercentage,
}: OrdersWrapperPropsType) {
    const { mainColor } = useSnapshot(store);

    return (
        <Card className="order-card-mb order-xs-card-mb">
            <CardTitle
                className={classNames("rounded-top", {
                    "dark-bg__clz": mainColor === null,
                })}
                style={{ background: mainColor }}
            >
                <h4 className="card-title my-2 mx-3 text-white">{title}</h4>{" "}
            </CardTitle>
            <CardBody className="py-0">
                <OrderRow
                    amount={totalAmount}
                    percentage={totalPercentage}
                    chartPercentage={chartPercentage}
                />
            </CardBody>
        </Card>
    );
}
