import React from "react";
import {
    AddIcon,
    StyledButton,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store as vStore } from "@vStore";
import store, { setFilteredProducts } from "@pages/Settings/Articles/store";

export function ActionsButtonsWrapper({
    article,
    selectedArticles,
    setSelectedArticles,
}: any) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(vStore);

    const { filteredProducts } = useSnapshot(store);

    function handleAddingArticleButtonOnClickEvent(article: any) {
        const localSelectedArticles: any[] = [...selectedArticles, article];
        setSelectedArticles(() => localSelectedArticles);

        const localArticles: any[] = filteredProducts.filter((article: any) => {
            return !localSelectedArticles
                .map((selectedArticle) =>
                    `${selectedArticle.reference}|${selectedArticle.designation}|${selectedArticle.family}|${selectedArticle.subFamily}`.toLowerCase()
                )
                .includes(
                    `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                );
        });
        setFilteredProducts(localArticles);
    }

    return (
        <React.Fragment>
            <StyledButton
                rounded={true}
                outline={true}
                variant={mainColor === null ? "secondary" : ""}
                className="d-none d-sm-none d-md-block white-space-nowrap"
                style={{
                    color: mainColor,
                    borderColor: mainColor,
                    float: "right",
                }}
                onClick={() => handleAddingArticleButtonOnClickEvent(article)}
            >
                {t("Add")}
            </StyledButton>
            <StyledIconButton
                icon="PlusIcon"
                className="d-block d-sm-block d-md-none m-0"
                onClick={() => handleAddingArticleButtonOnClickEvent(article)}
            >
                <AddIcon height={20} width={20} />
            </StyledIconButton>
        </React.Fragment>
    );
}
