import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@helpers/general";

export default function ApexLineColumn(props: any) {
    const { t } = useTranslation();

    const options = {
        chart: {
            width: "10%",
            height: 359,
            type: "bar",
            stacked: false,
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "45%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: [
                t("Monday"),
                t("Tuesday"),
                t("Wednesday"),
                t("Thursday"),
                t("Friday"),
                t("Saturday"),
                t("Sunday"),
            ],
        },
        yaxis: {
            title: {
                text: "Euro",
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return formatCurrency(val);
                },
            },
        },
    };

    return (
        <ReactApexChart
            options={options}
            series={props.data || []}
            type="bar"
            height={360}
        />
    );
}
