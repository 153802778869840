import React from "react";

export default function AllSalesSupportSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8775">
                <g data-name="Groupe 8753">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="shopping-cart (1)" fill="#fff">
                    <path
                        data-name="Trac\xE9 8541"
                        d="M18.632 28.336h17.534a.967.967 0 00.93-.7L40.963 14.1a.967.967 0 00-.93-1.232H16.4l-.689-3.111A.967.967 0 0014.767 9h-5.8a.967.967 0 100 1.934h5.025l3.491 15.708a2.9 2.9 0 001.152 5.558h17.531a.967.967 0 000-1.934H18.635a.967.967 0 010-1.933zM38.751 14.8l-3.315 11.6H19.41l-2.578-11.6zm0 0"
                    />
                    <path
                        data-name="Trac\xE9 8542"
                        d="M17.668 35.103a2.9 2.9 0 102.9-2.9 2.9 2.9 0 00-2.9 2.9zm2.9-.967a.967.967 0 11-.967.967.968.968 0 01.967-.966zm0 0"
                    />
                    <path
                        data-name="Trac\xE9 8543"
                        d="M31.332 35.103a2.9 2.9 0 102.9-2.9 2.9 2.9 0 00-2.9 2.9zm2.9-.967a.967.967 0 11-.967.967.968.968 0 01.967-.966zm0 0"
                    />
                </g>
            </g>
        </svg>
    );
}
