import {
    PutEffect,
    takeEvery,
    fork,
    put,
    all,
    call,
    CallEffect,
} from "redux-saga/effects";
import { AnyAction } from "redux";
import jwt_decode from "jwt-decode";
import { push, replace } from "connected-react-router";
import i18next from "i18next";
import { cache } from "swr";

import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";

import { loginSuccess, apiError } from "./actions";
import { changeType } from "@store/dashboard/actions";
import { clearPeriod } from "@store/statistics/actions";

import { getAdvancedPeriodDate } from "@store/advanced-date/actions";
import { resetRegulation } from "@store/regulations/actions";

import {
    postJwtLogin,
    postFakeLogin,
    isTokenExpired,
    getFavorites,
    getFranchises,
} from "@helpers/backend_helper";

import { cleanFavorites, cleanCardsModes, selectShops } from "@store/actions";
import { clearSalesMode } from "@store/sales-modes/actions";
import { clearSalesSupport } from "@store/sales-support/actions";
import { clearRegulationModes } from "@store/regulation-modes/actions";
import { convertStringDataToNumber } from "@helpers/general";

function* loginUser({
    payload: { user },
}: AnyAction): Generator<
    CallEffect<any> | PutEffect<AnyAction>,
    void,
    unknown
> {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const apiUrl = `${process.env.REACT_APP_API_V1_URL}/auth/login`;

            // @see https://github.com/redux-saga/redux-saga/issues/2015#issuecomment-586476693
            const response: any = yield call(postJwtLogin, apiUrl, {
                email: user.email,
                password: user.password,
            }) as any;

            if (response.error) {
                yield put(apiError(true));
            } else {
                let decodedJwt: any = jwt_decode(
                    typeof response.data === "string"
                        ? response.data
                        : response.data.accessToken
                );

                if (isTokenExpired(decodedJwt.exp)) {
                    yield put(apiError(true));
                }
                let user: UserType = {
                    id: decodedJwt.id,
                    firstname: decodedJwt.firstname,
                    lastname: decodedJwt.lastname,
                    consult: decodedJwt.consult,
                    email: decodedJwt.email,
                    exp_client: decodedJwt.exp,
                    username: decodedJwt.username,
                    phone: decodedJwt.phone,
                    isFirstConnection: decodedJwt.isFirstConnection,
                    shops: decodedJwt.shops,
                };

                localStorage.setItem("authUser", JSON.stringify(user));

                yield put(loginSuccess(user));

                if (response.data !== undefined) {
                    localStorage.setItem(
                        "jwt",
                        typeof response.data === "string"
                            ? response.data
                            : response.data.accessToken
                    );

                    const favorites = (yield call(
                        getFavorites,
                        `${process.env.REACT_APP_API_V1_URL}/selections/favorites`
                    )) as any;

                    if (favorites.data.length > 0) {
                        if (favorites.data[0].selection.length > 0) {
                            yield put(selectShops(favorites.data[0]));
                        } else {
                            const { data: franchises } = (yield call(
                                getFranchises,
                                `${process.env.REACT_APP_API_V1_URL}/selections/franchises-shops-list`
                            )) as any;

                            let allFranchises: any[] = [];

                            franchises.forEach((el: any, index: number) => {
                                let shopsIds: any[] = [];
                                el.shops?.forEach((element: any) => {
                                    shopsIds.push(element.id);
                                });
                                allFranchises[index] = {
                                    franchise_id: el.id,
                                    shops_id: shopsIds,
                                };
                            });

                            yield put(
                                selectShops(
                                    convertStringDataToNumber({
                                        designation: "",
                                        selection: allFranchises,
                                    })
                                )
                            );
                        }
                    }
                }

                yield put(replace("/dashboard"));
            }
        } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
            const response: any = yield call(
                postFakeLogin,
                "/post-fake-login",
                {
                    email: user.email,
                    password: user.password,
                }
            );

            delete response.password;

            localStorage.setItem("authUser", JSON.stringify(response));
            localStorage.setItem("jwt", btoa(JSON.stringify(response)));
            yield put(loginSuccess(response));
        }
    } catch (error) {
        yield put(apiError(true));
    }
}

function* logoutUser() {
    try {
        yield localStorage.clear();

        yield put(apiError(false));

        yield put(
            selectShops({
                designation: "",
                selection: [],
            })
        );

        yield put(cleanFavorites());

        yield put(changeType(i18next.t("Turnover")));
        yield put(clearPeriod());
        yield put(
            getAdvancedPeriodDate({
                startDate: "",
                endDate: "",
            })
        );
        yield put(resetRegulation());
        yield put(clearSalesMode());
        yield put(clearSalesSupport());
        yield put(clearRegulationModes());
        yield put(cleanCardsModes());
        yield cache.clear();
        yield put(push("/login"));
    } catch (error) {
        yield put(apiError(true));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
