import React from "react";

export default function VerifiedSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={164}
            viewBox="0 0 512 512"
            width={164}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="m459.669 82.906-196-81.377a19.999 19.999 0 0 0-15.338 0l-196 81.377A20 20 0 0 0 40 101.377v98.925c0 136.213 82.329 258.74 208.442 310.215a20.004 20.004 0 0 0 15.116 0C389.669 459.043 472 336.516 472 200.302v-98.925a20 20 0 0 0-12.331-18.471zM432 200.302c0 115.795-68 222.392-176 269.974C150.886 423.965 80 319.235 80 200.302v-85.573l176-73.074 176 73.074zm-198.106 67.414 85.964-85.963c7.81-7.81 20.473-7.811 28.284 0s7.81 20.474-.001 28.284L248.036 310.142c-7.812 7.812-20.475 7.809-28.284 0l-55.894-55.894c-7.811-7.811-7.811-20.474 0-28.284s20.474-7.811 28.284 0z" />
        </svg>
    );
}
