import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

type PropsType = {
    data: any[];
};

export function ExportExcelButton({ data }: PropsType) {
    const { t } = useTranslation();

    function handleExportToExcel() {
        const workSheet = XLSX.utils.json_to_sheet(data);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "clients");
        const excelBuffer = XLSX.write(workBook, {
            bookType: "xlsx",
            type: "array",
        });
        saveAs(
            new Blob([excelBuffer], { type: "application/octet-stream" }),
            "Clients.xlsx"
        );
    }

    return (
        <StyledButton
            className="mr-1"
            outline={true}
            rounded={true}
            variant="light"
            onClick={handleExportToExcel}
        >
            {t("Export")}
        </StyledButton>
    );
}
