import { proxy } from "valtio";
import i18n from "i18next";

type RegistrationStoreType = {
    type: string;
    country: string;
    city: string;
};

const store = proxy<RegistrationStoreType>({
    type: i18n.t("Shop Admin"),
    country: "FR",
    city: "",
});

export function setType(type: string) {
    store.type = type;
}

export function setCountry(country: string) {
    store.country = country;
}

export function setCity(city: string) {
    store.city = city;
}

export default store;
