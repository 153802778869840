import React from "react";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    capitalize,
    getAdvancedPeriodStringParam,
    getStringsArrayUniqueValues,
    getTypeParam,
} from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function SalesModeCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: salesModeData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-mode/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSeries: number[] = [];
        let localSalesModes: string[] = [];

        salesModeData.data?.forEach((item: any) => {
            localSalesModes.push(item.designation);
            localSeries.push(item.value);
        });

        setData(localSeries.filter((item) => item !== 0));
        setLabels(
            getStringsArrayUniqueValues(
                localSalesModes.length > 0
                    ? localSalesModes.map((item) => t(capitalize(item)))
                    : [t("No data")]
            )
        );
        setPercentageData!(localSeries);
    }, [salesModeData, t, setPercentageData]);

    const donutChartPurchaseModeData: SeriesDataType = {
        data: data,
        labels: labels.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Sales mode")}
                        data={donutChartPurchaseModeData}
                    >
                        <LegendPopoverWrapper
                            title={t("Sales mode")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
