import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "i18next";
import "moment/locale/fr";

import { useResource } from "@hooks/useResource";

import { changeType } from "@store/dashboard/actions";

import ErrorPage from "@components/Common/ErrorPage";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import Report from "./Report";
import ChartCard from "./ChartCard";

import {
    getTypeParam,
    formatCurrency,
    getAdvancedPeriodStringParam,
} from "@helpers/general";

export default function MainChart() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { advancedDate, type, period, selections } = useSelector(
        (state: any) => state
    );

    const [customActiveChartTab, setCustomActiveChartTab] = React.useState<
        number
    >(1);
    const [turnover, setTurnover] = React.useState<number>(0);

    const [tickets, setTickets] = React.useState<number>(0);
    const [avgBasket, setAvgBasket] = React.useState<number>(0);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/global?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    moment.locale(i18n.language);

    React.useEffect(() => {
        let localTurnover: number = 0;
        let localTickets: number = 0;
        let localAvgBasket: number = 0;

        if (resourceData.data) {
            resourceData.data.forEach((item: any) => {
                if (item.designation === "turnover") {
                    localTurnover = item.value;
                }

                if (item.designation === "tickets") {
                    localTickets = item.value;
                }
                if (item.designation === "average_basket") {
                    localAvgBasket = item.value;
                }
            });
        }

        setTurnover(localTurnover);
        setTickets(localTickets);
        setAvgBasket(localAvgBasket);
    }, [resourceData]);

    const reports: ReportType[] = [
        {
            title: t("Turnover"),
            iconClass: "bx-euro",
            description: formatCurrency(turnover),
            toggle: 1,
        },
        {
            title: t("Tickets"),
            iconClass: "bx-purchase-tag-alt",
            description: tickets,
            toggle: 2,
        },
        {
            title: t("Average basket"),
            iconClass: "bx-archive-in",
            description: formatCurrency(avgBasket),
            toggle: 3,
        },
    ];

    function toggleChartTabs(tab: number, title: string): void {
        if (customActiveChartTab !== tab) {
            setCustomActiveChartTab(tab);
        }
        dispatch(changeType(title));
    }

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            {error ? (
                <ErrorPage />
            ) : (
                <div className="grid-card">
                    {reports.map((report: ReportType, key: number | string) => (
                        <Report
                            type={type}
                            key={key}
                            id={key}
                            report={report}
                            toggleTabs={toggleChartTabs}
                        />
                    ))}
                </div>
            )}
            <div className=" grid-chart-card ">
                <CustomErrorBoundary>
                    <ChartCard type={type} />
                </CustomErrorBoundary>
            </div>
        </React.Suspense>
    );
}
