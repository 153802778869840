import { proxy } from "valtio";

import { clearStore } from "@pages/Statistics/Transactions/store";

type StoreType = {
    activeTab: number;
    isModalOpened: boolean;
};

export const store = proxy<StoreType>({
    activeTab: 1,
    isModalOpened: false,
});

export function setActiveTab(tab: number) {
    store.activeTab = tab;
}

export function setIsModalOpened(opened: boolean) {
    store.isModalOpened = opened;
}

export function toggleNewSegmentModal() {
    setIsModalOpened(!store.isModalOpened);
    clearStore();
}

export function toggleNav(tab: number) {
    if (store.activeTab !== tab) {
        setActiveTab(tab);
    }
}
