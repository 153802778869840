import React from "react";
import { CardBody, Card, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getPhysicalRegulations,
    getNumericRegulations,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsRegulationCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const regulationType = useSelector(
        (state: RegulationTypeType) => state.regulationType
    );
    const [categories, setCategories] = React.useState<string[]>([]);
    const [regulationData, setRegulationData] = React.useState<
        SeriesDataType[]
    >([]);

    const {
        resourceData: statisticsRegulationData,
        error: statisticsRegulationDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/regulations/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setRegulationData(
            getStatisticsData(
                regulationType === t("All regulation methods")
                    ? statisticsRegulationData.data
                    : regulationType === t("Physical Payment Method")
                    ? getPhysicalRegulations(statisticsRegulationData.data)
                    : getNumericRegulations(statisticsRegulationData.data),
                categories,
                period,
                advancedDate
            )
        );

        if (regulationType === t("All regulation methods")) {
            setHistogramData!(statisticsRegulationData.data);
        } else if (regulationType === t("Physical Payment Method")) {
            setHistogramData!(
                getPhysicalRegulations(statisticsRegulationData.data)
            );
        } else {
            setHistogramData!(
                getNumericRegulations(statisticsRegulationData.data)
            );
        }
    }, [
        regulationType,
        statisticsRegulationData,
        categories,
        period,
        advancedDate,
        setHistogramData,
        t,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        setAdvancedLegend(t("Payment methods"));
        setAdvancedTypeParam(t("Turnover incl-tax"));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    const chartWrapperGeneralRegulationsData: SeriesDataType[] = statisticsRegulationData
        ? regulationData
        : [];

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsRegulationDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={chartWrapperGeneralRegulationsData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
