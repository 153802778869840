import React from "react";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import OrdersWrapper from "./OrdersWrapper";
import RecapDataRow from "./RecapDataRow";
import GlobalDataRow from "./GlobalDataRow";

export default function StatisticsChart({ title, iconClass }: any) {
    return (
        <div className="grid-container">
            <div className="d-grid__clz">
                <div className="grid-global-card">
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <CustomErrorBoundary>
                            <GlobalDataRow
                                title={title}
                                iconClass={iconClass}
                            />
                        </CustomErrorBoundary>
                    </React.Suspense>
                </div>
                <div className="grid-chart-card">
                    <React.Suspense fallback={<SpinnerWrapper />}>
                        <CustomErrorBoundary>
                            <RecapDataRow />
                        </CustomErrorBoundary>
                    </React.Suspense>
                </div>
            </div>
            <div className="grid-order grid-xs-order mb-2 mb-sm-0">
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <OrdersWrapper />
                    </CustomErrorBoundary>
                </React.Suspense>
            </div>
        </div>
    );
}
