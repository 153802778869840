import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

export default function PeriodBadgeWrapper() {
    const period = useSelector((state: any) => state.period);
    const advancedDate = useSelector((state: any) => state.advancedDate);

    return (
        <React.Fragment>
            {advancedDate.startDate && advancedDate.endDate
                ? `${moment
                      .unix(Number(advancedDate.startDate))
                      .format("DD/MM/YYYY")} - ${moment
                      .unix(Number(advancedDate.endDate))
                      .format("DD/MM/YYYY")}`
                : period}
        </React.Fragment>
    );
}
