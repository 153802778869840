import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { mutate } from "swr";
import { add, getUnixTime } from "date-fns";
import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";

import { useResource } from "@hooks/useResource";

export default function CookiesModal({ fingerprint }: any) {
    const { t } = useTranslation();

    const [isCookiesModalOpened, setIsCookiesModalOpened] = React.useState<
        boolean
    >(false);

    const { resourceData: cookies } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/cookies-verification?designation=${fingerprint}`
    );

    async function handleAcceptButtonClickEvent() {
        setIsCookiesModalOpened(false);

        let apiUrl = `${process.env.REACT_APP_API_V1_URL}/cookies`;
        let savedData = {
            designation: fingerprint,
            value: getUnixTime(
                add(new Date(), {
                    years: 1,
                })
            ),
        };

        try {
            NProgress.start();

            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response: any) => response.json())
                    .then((data: any) => {
                        NProgress.done();

                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            NProgress.done();

            setIsCookiesModalOpened(true);
            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
                buttons: false,
                timer: 2000,
            });
        }
    }

    React.useEffect(() => {
        setIsCookiesModalOpened(!cookies.verified);
    }, [cookies]);

    return (
        <Modal isOpen={isCookiesModalOpened} centered>
            <ModalBody className="px-4">
                <h1 className="font-weight-light mt-2">{t("Cookies")}</h1>
                <p className="my-2">
                    {t(
                        "Our website uses cookies to provide you with a personalized experience. If you choose to accept these cookies, we (or our third party partners) may collect and share data about the actions you take on our site"
                    )}
                </p>
                <h6 className="my-2 font-weight-bold">
                    {t("Functional cookies")}
                </h6>
                <p>
                    {t(
                        "These cookies are necessary for the basic functionality of the site and are always enabled. These cookies allow you to remember when you browse the site during the same session. Functional cookies help you log on to the site and make purchases securely, while helping you troubleshoot security concerns and ensure that you are in compliance with regulations"
                    )}
                </p>
                <h6 className="font-weight-bold">{t("Analyzes")}</h6>
                <p>
                    {t(
                        "Analytical solutions are used to understand how visitors interact with this website, in order to improve the experience of the site or to address potential problems with the site"
                    )}
                </p>
            </ModalBody>
            <ModalFooter className="justify-content-center mt-3">
                <StyledButton
                    rounded
                    outline={false}
                    variant="primary"
                    onClick={handleAcceptButtonClickEvent}
                    className="w-25"
                >
                    {t("Accept")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
