import React from "react";

type LegendBadgePropsType = {
    label: string;
    color: string;
};

export default function LegendBadge({ label, color }: LegendBadgePropsType) {
    return (
        <div className="d-flex d-inline">
            <div
                className="mx-1 legend-badge__clz "
                style={{
                    width: "15px",
                    height: "15px",
                    background: `${color}`,
                    borderRadius: "50%",
                }}
            />
            {label}
        </div>
    );
}
