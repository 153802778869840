import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Container, Row } from "reactstrap";
import { useSnapshot } from "valtio";

import { store  } from "@vStore";

import {
    decryptWord,
    generatePassword,
    getUserAccountStatus,
} from "@helpers/general";

import { ACTIVE_STATUS } from "@constants/index";

import NProgressWrapper from "@components/Common/NProgressWrapper";
import PageTitle from "@components/Common/PageTitle";

import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import { PortalLogoSvgIcon } from "@components/Common/SvgIcons";

import "./index.css";

export function ChangePassword() {
    const { t } = useTranslation();

    const history = useHistory();

    const { mainColor } = useSnapshot(store);

    const [inputEmail, setInputEmail] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [inputPassword, setInputPassword] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const [loading, setLoading] = React.useState<boolean>(false);

    const [passwordMessageError, setPasswordMessageError] = React.useState<
        string
    >(t("This field is required"));
    const [errorMessage, setErrorMessage] = React.useState<string>(
        t("This field is required")
    );

    const [isInvalidPassword, setIsInvalidPassword] = React.useState<boolean>(
        false
    );
    const [isPasswordNotConfirmed, setIsPasswordNotConfirmed] = React.useState<
        boolean
    >(false);
    const [isPasswordDisplayed, setIsPasswordDisplayed] = React.useState<
        boolean
    >(false);

    const [, setIsFocusInInputs] = React.useState<boolean>(false);

    async function handleValidSubmit(values: ValidSubmitValues) {
        setIsFocusInInputs(false);
        setLoading(true);

        const {
            email: localEmail,
            password: localPassword,
            confirmPassword,
            newPassword,
        } = values;

        const localIsPasswordNotConfirmed = confirmPassword !== newPassword;

        if (localIsPasswordNotConfirmed === true) {
            setIsFocusInInputs(false);
            setLoading(false);
            setIsPasswordNotConfirmed(true);
        } else {
            getUserAccountStatus(localEmail, localPassword)
                .then(async (response) => {
                    if (response.error === true) {
                        decryptWord(response.data.email, setEmail);
                        decryptWord(response.data.pwd, setPassword);

                        if (email === "" && password === "") {
                            setErrorMessage(
                                t(
                                    "Account not found. Verify your email address."
                                )
                            );
                            setInputEmail("");
                        } else {
                            if (
                                localEmail === email &&
                                localPassword !== password
                            ) {
                                setIsInvalidPassword(true);
                                setInputPassword("");
                            } else {
                                if (
                                    localEmail !== email &&
                                    localPassword === password
                                ) {
                                    setErrorMessage(
                                        t(
                                            "Account not found. Verify your email address."
                                        )
                                    );
                                    setInputEmail("");
                                }
                            }
                        }
                    } else {
                        if (
                            response.data.email !== "" &&
                            response.error === false
                        ) {
                            if (response.data.etataccount === ACTIVE_STATUS) {
                                generatePassword(
                                    values.email,
                                    values.password,
                                    values.newPassword
                                )
                                    .then((data) => {
                                        if (data.error === true) {
                                            throw new Error(
                                                "Error while 'generating password' !"
                                            );
                                        }

                                        swal({
                                            icon: "success",
                                            content: (
                                                <p>
                                                    {
                                                        t(
                                                            "Password updated successfully"
                                                        )!
                                                    }
                                                </p>
                                            ),
                                            buttons: false,
                                            timer: 2000,
                                        }).then(() => {
                                            setLoading(false);

                                            setTimeout(() => {
                                                // redirect to login after 1 sec
                                                history.push("/login");
                                            }, 1000);
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(
                                            `Error while "generating new password, with message: ${
                                                (error as Error).message
                                            }`
                                        );
                                        toast.error(
                                            `${t("There's an error")!}`,
                                            {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                                autoClose: 2000,
                                                theme: "colored",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                            }
                                        );
                                    });
                            }
                        } else {
                            return swal({
                                icon: "error",
                                content: <p>{t("There's an error")!}</p>,
                                buttons: false,
                                timer: 2000,
                            });
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(
                        `Error while 'getting user account status, with message: ${error.message}`
                    );
                    setLoading(false);
                    return false;
                });
        }
    }

    React.useEffect(() => {
        if (inputPassword.length === 0 && isInvalidPassword === false) {
            setPasswordMessageError(t("This field is required"));
        } else {
            setPasswordMessageError(t("the current password is not correct"));
            setIsInvalidPassword(false);
        }
    }, [inputPassword, isInvalidPassword, t]);

    return (
        <React.Fragment>
            <PageTitle title={t("Change password")} />
            <video
                autoPlay={true}
                loop={true}
                muted={true}
                style={{
                    zIndex: 0,
                }}
                className="video position-fixed float-right"
            >
                <source src="background.mp4" type="video/mp4" />
            </video>
            <Container fluid={true} className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />

                <Row>
                    <div
                        className="white-section"
                        style={{
                            height: "100vh",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PortalLogoSvgIcon
                                fill="black"
                                className="etk-logo__clz"
                            />
                        </div>
                    </div>
                    <Card
                        className="overflow-hidden login-card w-sm-80 w-md-60 w-lg-50 w-xl-40 w-xxl-30"
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            WebkitBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            MozBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            borderRadius: "2%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            padding: "10px",
                            margin: "0px",
                        }}
                    >
                        <p
                            className="mb-2 mt-4 ml-4"
                            style={{
                                fontSize: "33px",
                                fontWeight: 500,
                            }}
                        >
                            {t("Change password")}
                        </p>
                        <CardBody className="ml-1 py-0">
                            <div>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(
                                        event: any,
                                        values: ValidSubmitValues
                                    ) => {
                                        event.preventDefault();
                                        handleValidSubmit(values);
                                    }}
                                >
                                    <div className="mb-3">
                                        <StyledLabel htmlFor="email">
                                            {t("Email")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="email"
                                            name="email"
                                            type="email"
                                            className="form-control__clz"
                                            onChange={(
                                                event: React.ChangeEvent<
                                                    HTMLInputElement
                                                >
                                            ) => {
                                                setInputEmail(
                                                    event.target.value
                                                );
                                            }}
                                            value={inputEmail}
                                            placeholder={t("Email")}
                                            validate={{
                                                email: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "Invalid email address"
                                                    ),
                                                },
                                                required: {
                                                    value: true,
                                                    errorMessage: errorMessage,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="password-toggle__clz">
                                        {isPasswordDisplayed ? (
                                            <HidePasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <ShowPasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="password">
                                            {t("Current password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="password"
                                            name="password"
                                            value={inputPassword}
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: passwordMessageError,
                                                },
                                            }}
                                            onChange={(
                                                event: React.ChangeEvent<
                                                    HTMLInputElement
                                                >
                                            ) => {
                                                setInputPassword(
                                                    event.target.value
                                                );
                                            }}
                                            placeholder={t("Current password")}
                                            className="form-control__clz"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="password">
                                            {t("Nouveau mot de passe")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="newPassword"
                                            name="newPassword"
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                            placeholder={t(
                                                "Nouveau mot de passe"
                                            )}
                                            className="form-control__clz"
                                        />
                                    </div>

                                    <div className="pb-5">
                                        <StyledLabel htmlFor="confirmPassword">
                                            {t("Confirm the new password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={() => {
                                                setIsPasswordNotConfirmed(
                                                    false
                                                );
                                            }}
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                            placeholder={t(
                                                "Confirm the new password"
                                            )}
                                            className="form-control__clz"
                                        />

                                        {isPasswordNotConfirmed ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Sorry, your passwords not confirmed"
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="mt-5 d-flex justify-content-center">
                                        <StyledButton
                                            rounded={true}
                                            variant={mainColor === null ? "primary" : ""}
                                            style={{
                                                background: mainColor,
                                            }}
                                            type="submit"
                                        >
                                            {t("Login")}
                                        </StyledButton>
                                    </div>
                                </AvForm>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );
}
