import React from "react";
import { CardBody, Card, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import "@assets/apex-chart.css";

export default function StatisticsSalesModeCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [salesModesData, setSalesModesData] = React.useState<
        SeriesDataType[]
    >([]);

    const {
        resourceData: statisticsSalesModeData,
        error: statisticsSalesModeDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-mode/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setSalesModesData(
            getStatisticsData(
                statisticsSalesModeData.data,
                categories,
                period,
                advancedDate
            )
        );
        setHistogramData!(statisticsSalesModeData.data);
    }, [
        statisticsSalesModeData,
        categories,
        period,
        advancedDate,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        setAdvancedLegend(t("Sales mode"));
        setAdvancedTypeParam(t("Average basket"));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsSalesModeDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={salesModesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
