import React from "react";
import { Row, Col, Card, CardBody, Nav, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import faker from "faker";

import { getArrayOfRandomNumbers } from "@helpers/general";

import ChartWrapper from "../CommonCharts/ChartWrapper";
import DonutChart from "../CommonCharts/DonutChart";

export default function Top10OffersAndSalesRow() {
    const { t } = useTranslation();

    const top10OffersAndSales: string[] = Array(10)
        .fill(null)
        .map(() => faker.fake("{{random.word}}"));

    const top10OffersAndSalesChartSeries: SeriesDataType[] = [
        {
            name: top10OffersAndSales[0],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[1],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[2],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[3],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[4],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[5],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[6],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[7],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[8],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10OffersAndSales[9],
            data: getArrayOfRandomNumbers!(7),
        },
    ];

    const DonutChartModesData: SeriesDataType = {
        data: getArrayOfRandomNumbers!(3),
        labels: [],
    };

    return (
        <Row className="top-10-items-row__clz">
            <Col md="8" sm="8">
                <Card>
                    <CardBody className="card-body__clz">
                        <Nav pills className="navtab-bg nav__clz">
                            <Link
                                to="/#/turnover-detail"
                                className="btn btn-outline-dark cards btn-sm float-right d-none"
                            >
                                {t("See more")}{" "}
                                <i className="mdi mdi-arrow-right ml-1"></i>
                            </Link>
                        </Nav>
                        <div className="title">
                            <label>
                                <h4 className="card-title mb-4">
                                    {t("Top 10")}
                                </h4>
                            </label>
                        </div>
                        <CardBody className="pb-2 chart-order">
                            <TabPane className="p-2">
                                <div className="apex-charts">
                                    <ChartWrapper
                                        series={top10OffersAndSalesChartSeries}
                                    />
                                </div>
                            </TabPane>
                        </CardBody>
                    </CardBody>
                </Card>
            </Col>
            <Col md="4" sm="4">
                <DonutChart
                    title={`${t("Add. Offers and sale")} / %`}
                    data={DonutChartModesData}
                />
            </Col>
        </Row>
    );
}
