import React from "react";

export default function ArrowRightSvgIcon(props: any) {
    return (
        <svg
            data-name="Layer 1"
            width={15}
            height={15}
            viewBox="0 0 20 25"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            color="white"
        >
            <path
                data-name="&lt;Path&gt;"
                fill="none"
                stroke="#1d1d1b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7.66 3.41l8.68 8.69-8.48 8.49"
            />
        </svg>
    );
}
