import {
    ADD_SALE_SUPPORT,
    SELECT_SALES_SUPPORT,
    CLEAR_SALES_SUPPORT,
    INIT_SALES_SUPPORT,
} from "./actions-types";

export const selectSalesSupport = (
    salesSupport: string
): {
    type: string;
    payload: string;
} => {
    return {
        type: SELECT_SALES_SUPPORT,
        payload: salesSupport,
    };
};

export const addSaleSupport = (
    salesSupport: string
): {
    type: string;
    payload: string;
} => {
    return {
        type: ADD_SALE_SUPPORT,
        payload: salesSupport,
    };
};

export const clearSalesSupport = (): {
    type: string;
    payload: [];
} => {
    return {
        type: CLEAR_SALES_SUPPORT,
        payload: [],
    };
};

export function initSalesSupport(
    salesSupport: string[]
): {
    type: string;
    payload: string[];
} {
    return {
        type: INIT_SALES_SUPPORT,
        payload: salesSupport,
    };
}
