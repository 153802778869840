import { initialize, event, set, pageview } from "react-ga";

const TRACKING_ID = process.env.REACT_APP_GA!;

function init() {
    initialize(TRACKING_ID);
}

function sendEvent(payload: any) {
    event(payload);
}

function sendPageview(path: any) {
    set({ page: path });
    pageview(path);
}

export { init, sendEvent, sendPageview };
