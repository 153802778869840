import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

import { setEmployeesQueryString } from "@pages/Statistics/Employees/store";

import { capitalize } from "@helpers/general";
import subscribeToStoreChange from "@helpers/subscribeToStoreChange";

type LocalOptionsType = {
    label: string | number;
    value: string | number;
};

type GroupedOptionsType = {
    options: LocalOptionsType[];
};

type PropsType = {
    options: GroupedOptionsType[];
};

export default function CustomSelectEmployeesFilter({ options }: PropsType) {
    const { t } = useTranslation();

    let localOptions: LocalOptionsType[] = options[0]!.options?.map(
        (option: any) => ({
            label: option,
            value: option,
        })
    );

    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        localOptions[0],
    ]);

    const [optionsNumber, setOptionsNumber] = React.useState<number>(0);

    let groupedOptions: GroupedOptionsType[] = options.map(
        (outerOption: any) => {
            return {
                label: t(capitalize(outerOption.label)),
                options: outerOption.options.map((innerOption: any) => {
                    if (innerOption === t("All employees")) {
                        return {
                            label: innerOption,
                            value: innerOption,
                        };
                    } else {
                        return {
                            label: innerOption!.designation,
                            value: innerOption!.designation,
                            _id: `${innerOption.code!}|${innerOption.shop_id!}|${innerOption.schema!}`,
                        };
                    }
                }),
            };
        }
    );

    function handleSelectOnChangeEvent(selectedValue: any, actionType: any) {
        let allItemExistInSelectedValue: boolean =
            selectedValue?.find((element: LocalOptionsType) => {
                return element.value === localOptions[0].value;
            }) !== undefined;

        let selectedValueSize = selectedValueState.length;

        if (allItemExistInSelectedValue) {
            if ([t("All employees")].includes(actionType.option.label)) {
                setSelectedValueState([localOptions[0]]);
            } else {
                let opts: any[] = [];
                options.forEach((element: any) => {
                    if (![t("All employees")].includes(element.label)) {
                        opts.push(...element.options);
                    }
                });

                opts = opts.filter(
                    (opt: any) =>
                        actionType.option._id !==
                        `${opt.code!}|${opt.shop_id!}|${opt.schema!}`
                );

                setSelectedValueState(
                    opts.length === 0
                        ? [localOptions[0]]
                        : opts.map((option: any) => {
                              return {
                                  label: option!.designation,
                                  value: option!.designation,
                                  _id: `${option.code!}|${option.shop_id!}|${option.schema!}`,
                              };
                          })
                );
            }
        } else {
            if (selectedValueSize === 1) {
                setSelectedValueState([localOptions[0]]);
            } else {
                setSelectedValueState((prevState: any) => {
                    let existInPrevState: boolean =
                        prevState.filter(
                            (option: any) =>
                                option._id === actionType.option._id
                        ).length > 0;

                    if (existInPrevState) {
                        return prevState.filter(
                            (option: any) =>
                                option._id !== actionType.option._id
                        );
                    } else {
                        return [...prevState, actionType.option];
                    }
                });
            }
        }
    }

    const MultiValueRemove = () => {
        return (
            <components.MultiValueRemove>
                {selectedValueState &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState)?.includes(el.label)
                ).length > 0
                    ? false
                    : undefined}
            </components.MultiValueRemove>
        );
    };

    const Option = ({ getValue, setValue, ...props }: any) => {
        let isCurrentOptionSelected =
            selectedValueState
                .filter((option: any) => !option.label.includes(t("All")))
                .map((option: any) => option._id)
                .filter((id: string) => id === props.data._id).length > 0;

        return (
            <components.Option {...props}>
                <input
                    type="checkbox"
                    disabled={
                        (getValue().filter((el: any) => {
                            return [t("All employees")].includes(el.label);
                        }).length !== 0 ||
                            optionsNumber === getValue().length) &&
                        [t("All employees")].includes(props.data.label)
                    }
                    checked={
                        [t("All employees")].includes(
                            props.selectProps.value[0]?.label
                        ) ||
                        (isCurrentOptionSelected && props.isSelected) ||
                        optionsNumber === selectedValueState.length
                    }
                    readOnly
                    className="mr-1"
                />
                <label>{props.value}</label>
            </components.Option>
        );
    };

    const ValueContainer = ({ children, getValue, ...props }: any) => {
        return (
            <components.ValueContainer {...props}>
                {[t("All employees")].includes(
                    props.selectProps.value[0]?.label
                )
                    ? t("Selected element", {
                          selectedItems: optionsNumber,
                          count: optionsNumber,
                      })
                    : t("Selected element", {
                          selectedItems: getValue().length,
                          count: getValue().length,
                      })}

                {React.Children.map(children, (child) => {
                    return child.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    };

    React.useEffect(() => {
        let localOptionsNumber: number = 0;
        options.forEach((element: any) => {
            if (![t("All employees")].includes(element.label)) {
                localOptionsNumber += element.options.length;
            }
        });
        setOptionsNumber(localOptionsNumber);
    }, [options, t]);

    React.useEffect(() => {
        let allItemExistInSelectedValue: boolean =
            selectedValueState.filter((el: any) => {
                return t("All employees").includes(el.label!);
            }).length > 0 || optionsNumber === selectedValueState.length;

        setEmployeesQueryString(
            !allItemExistInSelectedValue
                ? selectedValueState
                      .filter((el: any) => {
                          return (
                              el !== undefined &&
                              !t("All employees").includes(el.label!)
                          );
                      })
                      .map((value: any) => {
                          return value._id;
                      })
                      .join(",")
                : ""
        );
    }, [t, selectedValueState, optionsNumber]);

    subscribeToStoreChange(
        () => setSelectedValueState([localOptions[0]]),
        "period"
    );

    return (
        <Select
            options={groupedOptions}
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={handleSelectOnChangeEvent}
            isMulti
            hideSelectedOptions={false}
            isClearable={
                selectedValueState &&
                localOptions.filter((el: any) =>
                    Object.values(selectedValueState).includes(el.label)
                ).length === 0
            }
            components={{
                Option,
                MultiValueRemove,
                ValueContainer,
            }}
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    minWidth: 240,
                    minHeight: 40,
                    height: 40,
                    margin: 1,
                    borderRadius: "5px",
                    padding: "0px",
                }),
                valueContainer: (provided: any) => ({
                    ...provided,
                    width: "100%",
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }),
                option: (provided: any) => {
                    return {
                        ...provided,
                        color: "#495057",
                    };
                },
            }}
            theme={(theme: any) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "neutral10",
                },
            })}
        />
    );
}
