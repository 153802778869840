import { AnyAction } from "redux";

import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE } from "./actionTypes";

type ProfileStateType = {
    error: boolean;
    success: string;
};

const initialState = {
    error: false,
    success: "",
};

export default function profile(
    state = initialState,
    action: AnyAction
): ProfileStateType {
    switch (action.type) {
        case EDIT_PROFILE:
            return { ...state };
        case PROFILE_SUCCESS:
            return { ...state, success: action.payload };
        case PROFILE_ERROR:
            return { ...state, error: action.payload };
        default:
            return { ...state };
    }
}
