import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import moment from "moment";

import PageTitle from "@components/Common/PageTitle";
import Breadcrumb from "@components/Common/GlobalBreadcrumb";
import CardSpinnerWrapper from "@components/Common/CardSpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

import SelectFilter from "./SelectFilter";
import Cards from "./Cards";
import DeliveredOrdersRow from "./DeliveredOrdersRow";

export default function DeliveryMan() {
    const { t } = useTranslation();

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Delivery man")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Delivery man")}
                />
                <React.Suspense fallback={<CardSpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <SelectFilter />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <Cards />
                    </CustomErrorBoundary>
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <DeliveredOrdersRow />
                    </CustomErrorBoundary>
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
