import i18n from "i18next";
import { AnyAction } from "redux";

import {
    ADD_SALE_MODE,
    CLEAR_SALES_MODES,
    SELECT_SALES_MODES,
    INIT_SALES_MODES,
    EMPTY,
} from "./actions-types";

import { capitalize } from "@helpers/general";

const INIT_STATE: string[] = [];

export default function salesModes(
    state = INIT_STATE,
    action: AnyAction
): string[] | null {
    switch (action.type) {
        case ADD_SALE_MODE:
            let newState: string[] = [...state];
            newState.push(action.payload);
            return newState;
        case SELECT_SALES_MODES:
            let newSelectedState: string[] = [...state];
            let storedSalesModes: string[] = JSON.parse(
                localStorage.getItem("salesModes")!
            ).map((salesMode: string) => i18n.t(capitalize(salesMode)));

            newSelectedState = newSelectedState.filter(
                (el: string) => el !== EMPTY
            );

            if (newSelectedState.includes(action.payload)) {
                if (action.payload === i18n.t("All sales methods")) {
                    newSelectedState = [];
                } else {
                    newSelectedState = newSelectedState
                        .filter((element: string) => element !== action.payload)
                        .filter(
                            (element: string) =>
                                element !== i18n.t("All sales methods")
                        );
                }
            } else if (action.payload === i18n.t("All sales methods")) {
                return storedSalesModes;
            } else {
                newSelectedState = newSelectedState.filter(
                    (element: string) => element !== i18n.t("All sales methods")
                );

                newSelectedState.push(action.payload);

                if (newSelectedState.length === storedSalesModes.length - 1) {
                    newSelectedState.push(i18n.t("All sales methods"));
                }
            }

            if (newSelectedState.length === 0) {
                newSelectedState.push(EMPTY);
            }

            return newSelectedState;
        case CLEAR_SALES_MODES:
            return [];
        case INIT_SALES_MODES:
            let localState: string[] = action.payload;
            if (localState.length === 0) {
                localState.push(EMPTY);
            }
            return localState;
        default:
            return state !== undefined ? state : null;
    }
}
