import React from "react";
import { useTranslation } from "react-i18next";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import BTable from "react-bootstrap/Table";
import classNames from "classnames";
import {
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    EditIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store } from "@vStore";
import { setGroupName, setGroupedRegulations } from "./store";

import { useResource } from "@hooks/useResource";

import { SettingsArticlesContext } from "./context";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import Pagination from "@components/Common/StatisticsPagination";

import "@assets/widths.css";

export default function FirstStep({ go }: any) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);

    const [regulationsGroups, setRegulationsGroups] = React.useState<any>([]);

    const { setGroups } = React.useContext(SettingsArticlesContext);

    const {
        resourceData: { data: groupsData },
        error,
    } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/regulations/groups`
    );

    React.useEffect(() => {
        setRegulationsGroups(groupsData.map((group: string) => t(group)));
        setGroupedRegulations([]);
        setGroups([...groupsData]);
    }, [groupsData, setGroups, t]);

    const handleEditGroupButtonOnClickEvent = React.useCallback(
        (groupName: string) => {
            setGroupName(groupName);
            go(2);
        },
        [go]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Payment methods"),
                accessor: (data: any) => data,
            },
            {
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (group: any) {
                    return (
                        <div className="d-flex justify-content-end">
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="px-2 mr-2 py-1 d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleEditGroupButtonOnClickEvent(group)
                                }
                            >
                                {t("Edit")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none"
                                icon="EditIcon"
                                onClick={() =>
                                    handleEditGroupButtonOnClickEvent(group)
                                }
                            >
                                <EditIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleEditGroupButtonOnClickEvent, mainColor]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: regulationsGroups,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div
                        className={classNames({
                            "d-md-flex justify-content-md-between":
                                regulationsGroups.length > 0,
                            "d-flex justify-content-end":
                                regulationsGroups.length === 0,
                        })}
                    >
                        {regulationsGroups.length !== 0 ? (
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="w-xs-90 m-2 p-0"
                            />
                        ) : null}
                    </div>
                    {regulationsGroups.length !== 0 ? (
                        <React.Fragment>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {regulationsGroups.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
