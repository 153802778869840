import React from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { addFavorite, removeFavorite } from "@store/favorites/actions";

import FavoritesSvgIcon from "@components/Common/SvgIcons/FavoritesSvgIcon";

type FavoriteButtonWrapperPropsType = {
    franchise_id: string | number;
    favorite: FranchisesDataType;
};

export default function FavoriteButtonWrapper({
    franchise_id,
    favorite,
}: FavoriteButtonWrapperPropsType) {
    const favorites = useSelector(
        (state: { favorites: any[] }) => state.favorites
    );
    const [favoritesSvgProps, setFavoritesSvgProps] = React.useState({
        fill: "transparent",
        stroke: "rgb(34, 34, 34)",
    });

    const dispatch = useDispatch();

    function handleFavoriteButtonClickEvent(favorite: FranchisesDataType) {
        let isFavorite: boolean =
            favorites!.filter(function (favoriteEl: any) {
                return (
                    franchise_id === favoriteEl.franchise_id &&
                    favorite.id === favoriteEl.shop_id
                );
            }).length > 0;

        if (isFavorite) {
            dispatch(removeFavorite(franchise_id, favorite.id));

            setFavoritesSvgProps({
                fill: "transparent",
                stroke: "rgb(34, 34, 34)",
            });
        } else {
            dispatch(addFavorite({ franchise_id, shop_id: favorite.id }));

            setFavoritesSvgProps({
                fill: "rgb(255, 56, 92)",
                stroke: "rgb(255, 56, 92)",
            });
        }
    }

    React.useEffect(() => {
        if (favorites) {
            let isFavorite: boolean =
                favorites!.filter(function (favoriteEl: any) {
                    return (
                        franchise_id === favoriteEl.franchise_id &&
                        favorite.id === favoriteEl.shop_id
                    );
                }).length > 0;

            if (isFavorite) {
                setFavoritesSvgProps({
                    fill: "rgb(255, 56, 92)",
                    stroke: "rgb(255, 56, 92)",
                });
            }
        }
    }, [favorites, favorite, franchise_id]);

    return (
        <Button
            onClick={() => handleFavoriteButtonClickEvent(favorite)}
            color="link"
            className="favorites__clz ml-1 mt-xl-0 mt-1"
        >
            <FavoritesSvgIcon
                style={{
                    height: "20px",
                    width: "20px",
                }}
                {...favoritesSvgProps}
            />
        </Button>
    );
}
