import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Puff } from "svg-loaders-react";
import { ErrorBoundary } from "react-error-boundary";
import swal from "@sweetalert/with-react";

import { changeType } from "@store/actions";

import DownloadButton from "@components/Common/DownloadButton";

import { PeriodModalWrapper } from "./PeriodModalWrapper";
import TypeDropdownWrapper from "./TypeDropdownWrapper";
import ShopsNumberLink from "./ShopsNumberLink";

type GlobalBreadcrumbPropsType = {
    section: string;
    pageTitle: string;
};

export default function GlobalBreadcrumb({
    section,
    pageTitle,
}: GlobalBreadcrumbPropsType) {
    const { t } = useTranslation();
    let { pathname } = useLocation();
    const type = useSelector((state: { type: string }) => state.type);

    const [isTypeDropdownOpened, setIsTypeDropdownOpened] = React.useState<
        boolean
    >(false);

    let items: string[] =
        t("Turnover") === pageTitle
            ? [t("Incl-tax"), t("Ex-tax")]
            : t("Payment methods") === pageTitle
            ? [t("Turnover"), t("Tickets"), t("Average basket")]
            : t("Cashier") === pageTitle
            ? [
                  t("Turnover"),
                  t("Tickets"),
                  t("Average turnover"),
                  t("Average basket"),
              ]
            : t("Cook") === pageTitle
            ? [t("Turnover"), t("Quantity sold")]
            : t("Delivery man") === pageTitle
            ? [t("Turnover"), t("Tickets")]
            : t("Out of stock") === pageTitle
            ? [t("Quantity"), t("Duration")]
            : [
                  t("Turnover incl-tax"),
                  t("Turnover ex-tax"),
                  t("Tickets"),
                  t("Average basket"),
              ];

    if ([t("Families"), t("Sub-families")].includes(pageTitle)) {
        items.push(t("Quantity sold"));
    }

    if (t("Items") === pageTitle) {
        items.push(t("Item number"));
    }

    let matchDashboardRoute: boolean = pathname === "/dashboard";
    let matchStatisticsTicketsRoute: boolean =
        pathname === "/statistics-tickets";
    let matchStatisticsAvgBasketRoute: boolean =
        pathname === "/statistics-average-basket";
    let matchStatisticsTransactionsRoute: boolean =
        pathname === "/transactions";

    return (
        <React.Fragment>
            <div className="d-xl-flex align-items-center justify-content-between align-items-center page-title-box">
                <div className="d-md-flex align-items-center">
                    <h4 className="mr-3">{pageTitle}</h4>
                    <div className="d-sm-flex">
                        <div className="d-flex">
                            <PeriodModalWrapper />

                            {!matchStatisticsTransactionsRoute &&
                                !matchDashboardRoute &&
                                !matchStatisticsAvgBasketRoute &&
                                !matchStatisticsTicketsRoute && (
                                    <TypeDropdownWrapper
                                        selectedItem={type}
                                        items={items}
                                        isDropdownOpened={isTypeDropdownOpened}
                                        setIsDropdownOpened={
                                            setIsTypeDropdownOpened
                                        }
                                        changePeriodAction={changeType}
                                    />
                                )}
                        </div>
                        <div className="d-flex">
                            <ShopsNumberLink />
                            {matchDashboardRoute ? (
                                <React.Suspense
                                    fallback={
                                        <Puff
                                            stroke="#495057"
                                            width="20px"
                                            height="20px"
                                        />
                                    }
                                >
                                    <ErrorBoundary
                                        FallbackComponent={() =>
                                            swal({
                                                icon: "error",
                                                content: (
                                                    <p>
                                                        {t("There's an error")}!
                                                    </p>
                                                ),
                                                buttons: false,
                                                timer: 2000,
                                            })
                                        }
                                        onReset={() => {
                                            // reset the state of your app so the error doesn't happen again
                                        }}
                                    >
                                        <DownloadButton />
                                    </ErrorBoundary>
                                </React.Suspense>
                            ) : null}
                        </div>
                    </div>
                </div>

                {pageTitle !== t("Dashboard") && (
                    <Breadcrumb className="d-none d-sm-flex">
                        <BreadcrumbItem active>
                            <Link to="#">{section}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to="#">{pageTitle}</Link>
                        </BreadcrumbItem>
                    </Breadcrumb>
                )}
            </div>
        </React.Fragment>
    );
}
