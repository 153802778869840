import React from "react";
import { Card, CardBody } from "reactstrap";
import { DynamicTable } from "@aures/custom-react-table";
import { useSnapshot } from "valtio";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import { store } from "@pages/Statistics/Shops/store";
import {
    store as myShopsStore,
    setTitleRowWidth,
} from "@pages/Statistics/Shops/MyShopsCol/MyShopsRowCardWrapper/store";

import {
    findObjectWithLongestShopKey,
    getAdvancedPeriodStringParam,
    getSelectionQueryStringFromSelectedGroups,
    getSelectionQueryStringFromSelectedShops,
} from "@helpers/general";

import { TotalTableWrapper } from "./TotalTableWrapper";
import { DonutChartWrapper } from "./DonutChartWrapper";

export function MyShopsRowCardWrapper() {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const { type, titleRowWidth } = useSnapshot(myShopsStore);
    const { selectedShops, selectedGroups } = useSnapshot(store);
    const { period, advancedDate } = useSelector((state: any) => state);

    const selectionQueryString = getSelectionQueryStringFromSelectedShops(
        selectedShops
    );
    const groupsSelectionQueryString = getSelectionQueryStringFromSelectedGroups(
        selectedGroups
    );
    const token = localStorage.getItem("jwt");
    const apiUrl = `${
        process.env.REACT_APP_API_V1_URL
    }/statistics/shops/myShops?${getAdvancedPeriodStringParam(
        period,
        advancedDate
    )}${selectionQueryString}${groupsSelectionQueryString}`;

    const [key, setKey] = React.useState(uuid());
    const [, setSelectedRows] = React.useState<any[]>([]);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [data, setData] = React.useState<{ data: any[] }>({ data: [] });

    React.useEffect(() => {
        setDataIsUpdated(i18nextLng === "fr" || i18nextLng === "en");
    }, [i18nextLng]);

    React.useEffect(() => {
        setKey(uuid());
    }, [
        type,
        advancedDate,
        groupsSelectionQueryString,
        period,
        selectionQueryString,
    ]);

    React.useEffect(() => {
        const shopWithLongestName = findObjectWithLongestShopKey(
            data.data,
            t("Shop")
        );
        // size of a character width in pixel based on font and styling
        const estimatedCharacterWidth = 12;
        // a minimum width to avoid very small cells
        const minWidth = 150;

        // the width based on content length and character width
        const calculatedWidth = Math.max(
            shopWithLongestName[t("Boutique")]?.length *
                estimatedCharacterWidth,
            minWidth
        );

        setTitleRowWidth(calculatedWidth);
    }, [data, t]);

    return (
        <div key={key} className="grid-container">
            <Card id="shopsTable">
                <CardBody className="card-body__clz">
                    <DynamicTable
                        url={apiUrl}
                        requestHeader={{
                            "Accept-Language": i18nextLng,
                            Authorization: `Bearer ${token}`,
                        }}
                        setData={setData}
                        colsSize={[
                            {
                                item: t("Shop"),
                                width: `${titleRowWidth}px`,
                            },
                        ]}
                        canSort={true}
                        canResize={true}
                        canSelect={false}
                        setSelectedRows={setSelectedRows}
                        customSelect={true}
                        showGlobalFilter={false}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={8}
                        minHeight="300px"
                        maxHeight="450px"
                        defaultPaginationValues={[10]}
                        customClasses={{
                            thead: "custom-table-head-text-size__clz",
                        }}
                        isTableHasFooter={true}
                        tableFooter={
                            <TotalTableWrapper selectedRows={data.data} />
                        }
                    />
                </CardBody>
            </Card>

            <DonutChartWrapper apiUrl={apiUrl} />
        </div>
    );
}
