import React from "react";
import { Button, Card, CardBody, Nav } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";
import store from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getTypeParam,
    getSalesModesQueryString,
    getUntranslatedString,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsTop10ArticlesCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [top10ArticlesData, setTop10ArticlesData] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);

    const {
        resourceData: statisticsArticlesData,
        error: statisticsArticlesError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-mode/products?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesModesQueryString(statisticsStore.salesModeCards.modes)
                .length > 0
                ? `&sales-mode=${getSalesModesQueryString(
                      statisticsStore.salesModeCards.modes.map(
                          getUntranslatedString
                      )
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsArticlesData.data);
        setTop10ArticlesData(
            getStatisticsData(
                statisticsArticlesData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsArticlesData,
        categories,
        period,
        advancedDate,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "sales_mode",
                    value: [
                        ...statisticsStore.salesModeCards.modes
                            .filter(
                                (mode: string) =>
                                    mode !== t("All sales methods")
                            )
                            .map(getUntranslatedString),
                    ],
                },
                {
                    designation: "articles",
                    value: top10ArticlesData.map((product) => product.name),
                },
            ],
        };

        setAdvancedLegend(t("Products"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsArticlesError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={top10ArticlesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
