import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import slugify from "@sindresorhus/slugify";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { getColorsArray } from "@helpers/general";

import LegendBadge from "./LegendBadge";

type LegendPopoverWrapperPropsType = {
    title: string;
    labels: string[];
    legendId?: string;
};

export default function LegendPopoverWrapper({
    title,
    labels,
    legendId,
}: LegendPopoverWrapperPropsType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    const [isPopoverOpened, setIsPopoverOpened] = React.useState(false);

    const buttonId =
        legendId === undefined
            ? `button_${slugify(title)}`
            : `button_${slugify(title)}${legendId}`;

    function toggleOpenedPopover() {
        setIsPopoverOpened((prevState) => !prevState);
    }

    return (
        <React.Fragment>
            {labels.length > 0 ? (
                <div className="d-flex justify-content-center">
                    <Button
                        id={buttonId}
                        type="button"
                        color={mainColor === null ? "secondary" : ""}
                        outline={true}
                        onClick={toggleOpenedPopover}
                        className="see-legend-button__clz px-2 py-1"
                        style={{
                            borderColor: mainColor,
                            color: mainColor,
                        }}
                    >
                        {t("See legend")}
                    </Button>
                    <Popover
                        placement="top"
                        isOpen={isPopoverOpened}
                        target={buttonId}
                        toggle={toggleOpenedPopover}
                    >
                        <PopoverHeader className="d-flex justify-content-between">
                            <p className="text-uppercase font-size-14 mb-0 ">
                                {title}
                            </p>
                            <button
                                type="button"
                                style={{ lineHeight: "0" }}
                                className="close ml-1"
                                onClick={toggleOpenedPopover}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </PopoverHeader>
                        <PopoverBody>
                            {labels.map((label: string, index: number) => {
                                return [undefined, null].includes(
                                    label as any
                                ) === false ? (
                                    <LegendBadge
                                        key={`legend-badge__${slugify(
                                            label
                                        )}${index}`}
                                        label={label}
                                        color={
                                            getColorsArray(labels.length)[index]
                                        }
                                    />
                                ) : null;
                            })}
                        </PopoverBody>
                    </Popover>
                </div>
            ) : null}
        </React.Fragment>
    );
}
