import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { useSelector } from "react-redux";

import store from "@pages/Statistics/Transactions/store";

import {
    getAdvancedPeriodStringParam,
    getAdvancedLegendParam,
    getStatisticsLineChartData,
    getStatisticsData,
    getFormattedCategoriesByPeriod,
    formatChartLabels,
} from "@helpers/general";
import { fetcherWithToken } from "@helpers/fetcherWithToken";

import MixedChartWrapper from "./MixedChartWrapper";
import DownloadButton from "./DownloadButton";

import "./index.css";

export default function MainChart() {
    const { t } = useTranslation();

    const {
        advancedLegend,
        selectedSegments,
        segmentQueryString,
    } = useSnapshot(store);

    const advancedDate = useSelector((state: any) => state.advancedDate);
    const period = useSelector((state: any) => state.period);
    const selections = useSelector((state: any) => state.selections);

    const [chartData, setChartData] = React.useState<SerieType[]>([]);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [excelFileCategories, setExcelFileCategories] = React.useState<
        string[]
    >([]);

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
    }, [advancedDate, period]);

    React.useEffect(() => {
        if (selectedSegments.length > 0) {
            selectedSegments.forEach(async (segment: string) => {
                const apiResult = await fetcherWithToken(
                    `${
                        process.env.REACT_APP_API_V1_URL
                    }/statistics/transactions/recap?${getAdvancedPeriodStringParam(
                        period,
                        advancedDate
                    )}${
                        selections.designation &&
                        selections.designation !== "my_favorites"
                            ? `&selected=${selections.designation}`
                            : ""
                    }${
                        advancedLegend !== t("General")
                            ? `&legend=${getAdvancedLegendParam(
                                  advancedLegend
                              )}`
                            : ""
                    }${segment !== "" ? `&segment=${segment}` : ""}`
                );
                if (apiResult.error) {
                    throw Error(t("There's an error"));
                } else {
                    setChartData(
                        advancedLegend !== t("General")
                            ? (getStatisticsData(
                                  apiResult.data,
                                  categories,
                                  period,
                                  advancedDate
                              ) as SerieType[])
                            : [
                                  {
                                      name: segment,
                                      data: getStatisticsLineChartData(
                                          apiResult.data,
                                          categories,
                                          period,
                                          advancedDate
                                      ),
                                  },
                              ]
                    );
                }
            });
        } else {
            const getDefaultData = async function () {
                const apiResult = await fetcherWithToken(
                    `${
                        process.env.REACT_APP_API_V1_URL
                    }/statistics/transactions/recap?${getAdvancedPeriodStringParam(
                        period,
                        advancedDate
                    )}${
                        selections.designation &&
                        selections.designation !== "my_favorites"
                            ? `&selected=${selections.designation}`
                            : ""
                    }${
                        advancedLegend !== t("General")
                            ? `&legend=${getAdvancedLegendParam(
                                  advancedLegend
                              )}`
                            : ""
                    }${
                        segmentQueryString !== ""
                            ? `&segmentObject=${segmentQueryString}`
                            : ""
                    }`
                );

                if (apiResult.error) {
                    throw Error(t("There's an error"));
                } else {
                    setChartData(
                        advancedLegend !== t("General")
                            ? (getStatisticsData(
                                  apiResult.data,
                                  categories,
                                  period,
                                  advancedDate
                              ) as SerieType[])
                            : [
                                  {
                                      name: t("Tickets"),
                                      data: getStatisticsLineChartData(
                                          apiResult.data,
                                          categories,
                                          period,
                                          advancedDate
                                      ),
                                  },
                              ]
                    );
                }
            };
            getDefaultData();
        }
    }, [
        selectedSegments,
        categories,
        period,
        advancedDate,
        selections,
        advancedLegend,
        segmentQueryString,
        t,
    ]);

    React.useEffect(() => {
        let localExcelFileCategories: string[] = [];
        categories.forEach((category: string) => {
            localExcelFileCategories.push(
                formatChartLabels(category, advancedDate, period)
            );
        });
        setExcelFileCategories(localExcelFileCategories);
    }, [categories, advancedDate, period]);

    return (
        <React.Fragment>
            <DownloadButton
                chartData={chartData}
                categories={excelFileCategories}
            />
            <MixedChartWrapper categories={categories} series={chartData} />
        </React.Fragment>
    );
}
