import React from "react";

export default function CheckSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8768">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8475">
                    <g data-name="Groupe 8474">
                        <path
                            data-name="Trac\xE9 8607"
                            d="M38.125 16.374h-7.5a.938.938 0 000 1.875h7.5v15h-26.25v-15h3.75a.938.938 0 000-1.875h-3.75A1.877 1.877 0 0010 18.249v15a1.877 1.877 0 001.875 1.875h26.25A1.877 1.877 0 0040 33.249v-15a1.877 1.877 0 00-1.875-1.875z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8477">
                    <g data-name="Groupe 8476">
                        <path
                            data-name="Trac\xE9 8608"
                            d="M33.438 21.999h-5.625a.938.938 0 000 1.875h5.625a.938.938 0 000-1.875z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8479">
                    <g data-name="Groupe 8478">
                        <path
                            data-name="Trac\xE9 8609"
                            d="M33.438 27.624h-15a.937.937 0 100 1.875h15a.938.938 0 100-1.875z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8481">
                    <g data-name="Groupe 8480">
                        <path
                            data-name="Trac\xE9 8610"
                            d="M33.136 9.925l-2.651-2.651a.938.938 0 00-1.326 0L17.226 19.205a.951.951 0 00-.229.368l-1.326 3.977a.937.937 0 00.891 1.234.9.9 0 00.3-.049l3.977-1.326a.921.921 0 00.368-.225l9.279-9.279 2.649-2.649a.938.938 0 00.001-1.331zM20.036 21.7l-1.989.664.664-1.989 8.462-8.462 1.326 1.326zm9.787-9.787l-1.328-1.325 1.326-1.326 1.325 1.326z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
