import React from "react";
import { Input, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { setLimitArticles, setLimitCategories } from "../store";

import { capitalize } from "@helpers/general";
import classNames from "classnames";

type PropsType = {
    title: string;
} & ChildrenPropsType;

export function TitleRow({ title, children }: PropsType) {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const { mainColor } = useSnapshot(store);

    let childrenArray = React.Children.toArray(children);

    let newChildren: React.ReactNode;

    let filename = `${t("Statistics")}_${t(
        capitalize(pathname.replace("/", ""))
    )}_${title.replaceAll(" ", "-")}`;

    if (childrenArray.length === 1) {
        newChildren = React.cloneElement(childrenArray[0] as any, {
            filename,
        });
    } else if (childrenArray.length > 1) {
        let lastElement = childrenArray[childrenArray.length - 1];

        newChildren = [React.cloneElement(childrenArray[0] as any)]
            .concat(childrenArray.slice(1, -1) as any)
            .concat(
                React.cloneElement(lastElement as any, {
                    filename,
                }) as any
            );
    }

    return (
        <Row
            className={classNames(
                "justify-content-center justify-content-sm-between rounded mb-2 align-items-center mx-0 ",
                { "dark-bg__clz": mainColor === null }
            )}
            style={{ background: mainColor }}
        >
            <div className="d-flex">
                <h4 className="card-title my-2 mx-3 text-white">{title}</h4>
                <Input
                    id="limitFilter"
                    name="limitFilter"
                    type="select"
                    onChange={(event) => {
                        if (title === t("Categories").toUpperCase()) {
                            setLimitCategories(event.target.value);
                        } else if (title === t("Items").toUpperCase()) {
                            setLimitArticles(event.target.value);
                        }
                    }}
                >
                    <option value="5">{t("Top 5")}</option>
                    <option value="10">{t("Top 10")}</option>
                    <option value="20">{t("Top 20")}</option>
                    <option value="all">{t("All")}</option>
                </Input>
            </div>
            <div className="d-flex">{newChildren}</div>
        </Row>
    );
}
