import React from "react";

import { GroupedShopsCustomSelect } from "@pages/Statistics/Shops/GroupedShopsCustomSelect";
import { GroupedGroupsCustomSelect } from "@pages/Statistics/Shops/GroupedGroupsCustomSelect";

export function SelectsWrapper() {
    return (
        <React.Fragment>
            <GroupedShopsCustomSelect />
            <GroupedGroupsCustomSelect />
        </React.Fragment>
    );
}
