import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem } from "reactstrap";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import moment from "moment";

import { StatisticsContext } from "@pages/Statistics/context";

import { capitalize, formatCurrency } from "@helpers/general";

type PropsType = {
    filename: string;
};

export function DownloadTop10CsvDropdownItem({ filename }: PropsType) {
    const { t } = useTranslation();

    const {
        percentageData,
        histogramData,
        categoriesData: categories,
    } = React.useContext(StatisticsContext);

    const { period, type, advancedDate } = useSelector((state: any) => state);

    const [csvData, setCsvData] = React.useState<any[][]>([]);

    const [, setHeaders] = React.useState<any[]>();

    React.useEffect(() => {
        let array: any[] = [];

        let headers: any[] = [t("Categories")];

        histogramData?.forEach((datum: any) => {
            headers.push(t(capitalize(datum.designation.toLowerCase())));
        });

        let averageLine: any[] = [];

        categories?.forEach((category: any) => {
            let localArray: any[] = [];
            let localCategory = category;

            histogramData?.forEach((item: any, index: number) => {
                item.value.forEach((datum: any) => {
                    let localDesignation = String(datum.designation);

                    if (
                        advancedDate.startDate !== "" &&
                        advancedDate.endDate !== ""
                    ) {
                        let daysBetweenDates = moment
                            .unix(Number(advancedDate.endDate))
                            .diff(
                                moment.unix(Number(advancedDate.startDate)),
                                "days"
                            );
                        if (daysBetweenDates === 0) {
                            localDesignation = String(datum.designation);
                        } else if (daysBetweenDates <= 31) {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("ddd DD-MM-YYYY")
                            );
                        } else {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("MMM YYYY")
                            );
                        }
                    } else if (
                        [
                            t("Current week"),
                            t("The last 7 days"),
                            t("The last 14 days"),
                            t("The last 30 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                        localCategory = capitalize(
                            moment
                                .unix(Number(category))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else if (period === t("Current month")) {
                        localDesignation = capitalize(
                            moment.unix(Number(datum.designation)).format("DD")
                        );
                    } else if (period === t("Current year")) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    } else if (
                        [
                            t("The last 90 days"),
                            t("The last 180 days"),
                            t("The last 365 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }

                    if (localDesignation === localCategory) {
                        localArray.push(
                            type !== t("Tickets")
                                ? datum.value
                                : `${datum.value} ${t("Tickets")}`
                        );
                    }
                });
                if (!localArray[index]) {
                    localArray[index] =
                        type !== t("Tickets") ? 0 : `0 ${t("Tickets")}`;
                }
            });

            array.push([localCategory, ...localArray]);
        });

        percentageData?.forEach((datum: any) => {
            averageLine.push(
                type !== t("Tickets")
                    ? formatCurrency(datum / array.length)
                    : (datum / array.length).toFixed(2)
            );
        });

        setHeaders(() => headers);

        const localPercentage = (percentageData as any[]).map((el) =>
            type !== t("Tickets") ? formatCurrency(el) : el
        );

        const localCsvData = [
            headers,
            ...array,
            [t("Total"), ...localPercentage],
            [t("Average"), ...averageLine],
        ];

        setCsvData(() => localCsvData);
    }, [
        t,
        histogramData,
        categories,
        percentageData,
        advancedDate.startDate,
        advancedDate.endDate,
        period,
        type,
    ]);

    return (
        <DropdownItem>
            <CSVLink className="btn p-0" data={csvData} filename={filename}>
                {t("Download top 10 in CSV format")}
            </CSVLink>
        </DropdownItem>
    );
}
