import React from "react";
import { Card, CardBody, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import { useResource } from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getAdvancedSeeMorePeriod,
    getFamiliesFilterQueryString,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getTypeParam,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsSalesSupportCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
        salesSupportSelection,
    } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [top10FamiliesData, setTop10FamiliesData] = React.useState<any[]>([]);

    const {
        resourceData: statisticsFamiliesSalesSupportData,
        error: statisticsFamiliesSalesSupportDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/families/sales-support?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getFamiliesFilterQueryString(salesSupportSelection!) !== ""
                ? `&sales-support=${getFamiliesFilterQueryString(
                      salesSupportSelection!
                  ).toUpperCase()}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsFamiliesSalesSupportData.data);
        setTop10FamiliesData(
            getStatisticsData(
                statisticsFamiliesSalesSupportData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsFamiliesSalesSupportData,
        categories,
        period,
        advancedDate,
        t,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "families",
                    value: top10FamiliesData.map((family) => family.name),
                },
                {
                    designation: "sales_support",
                    value: salesSupportSelection!
                        .filter(
                            (mode: string) => mode !== t("All sales supports")
                        )
                        .map((item: string) => {
                            return getUntranslatedString(item).toUpperCase();
                        }),
                },
            ],
        };

        setAdvancedLegend(t("Families"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm">
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <CardBody className="pb-0 chart-order chart-order-sm chart-order-md chart-order-lg chart-order-xl">
                    <div className="apex-charts apex-chart__clz">
                        {statisticsFamiliesSalesSupportDataError ? (
                            <ErrorPage />
                        ) : (
                            <CustomErrorBoundary>
                                <ChartWrapper
                                    series={top10FamiliesData}
                                    categories={categories}
                                />
                            </CustomErrorBoundary>
                        )}
                    </div>
                </CardBody>
            </CardBody>
        </Card>
    );
}
