import React from "react";

import { Card, CardBody, Media } from "reactstrap";
import classNames from "classnames";

export default function MiniCard({
    itemIcon,
    title,
    margin,
    color,
    padding,
}: any) {
    const [icon, setIcon] = React.useState<boolean>(false);

    return (
        <Card
            className={classNames({
                "mini-stats-wid mb-2 ": margin,
                "mini-stats-wid mb-1": !margin,
            })}
            style={{
                width: margin ? "315px" : "326px",
                height: "67px",
                marginLeft: margin && "5px",
            }}
        >
            <CardBody
                style={{ width: "325px", height: "21px" }}
                className={classNames({
                    "bg-secondary rounded": color,
                })}
            >
                <Media style={{ width: "276px", height: "34px" }}>
                    <Media body>
                        <p
                            className={classNames({
                                "pt-2 text-white font-weight-medium":
                                    color && !padding,
                                "text-muted font-weight-medium pt-2  ":
                                    !color && !padding,
                                "pt-0 text-white font-weight-medium":
                                    color && padding,
                                "text-muted font-weight-medium pt-0  ":
                                    !color && padding,
                            })}
                            style={{ width: "162px", textAlign: "left" }}
                        >
                            {title}
                        </p>
                    </Media>
                    <div
                        className={
                            "mini-stat-icon  align-self-center mr-2 rounded-circle bg-primary "
                        }
                        style={{ height: "34px", width: "34px" }}
                        onClick={() => {
                            setIcon(!icon);
                        }}
                    >
                        <span
                            className="font-weight-bolder rounded-circle border-dark avatar-title"
                            style={{
                                backgroundColor: icon ? "#ced4da" : "#1dffc9",
                                color: icon ? "#adb5bd" : "black",
                                border: "2px solid #eff2f7",
                            }}
                        >
                            <i className="glyphicon glyphicon-ok font-size-24"></i>
                        </span>
                    </div>
                    <div
                        style={{
                            height: "34px",
                            width: "34px",
                        }}
                        className={
                            "mini-stat-icon  align-self-center rounded-circle "
                        }
                    >
                        <span className="font-weight-bolder rounded-circle border-dark bg-dark avatar-title">
                            {itemIcon}
                        </span>
                    </div>
                </Media>
            </CardBody>
        </Card>
    );
}
