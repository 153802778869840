import React from "react";

export default function AdditionalSaleSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8798">
                <g data-name="credit-card (2)" fill="#fff">
                    <path
                        data-name="Trac\xE9 8558"
                        d="M38.291 25.723l-2.151-12.2a3.056 3.056 0 00-3.54-2.476l-18.446 3.254a3.056 3.056 0 00-2.476 3.534l.158.9a3.057 3.057 0 00-1.836 2.8v12.08a3.056 3.056 0 003.052 3.052h18.315a3.056 3.056 0 003.052-3.052v-4.11l1.4-.246a3.052 3.052 0 002.476-3.536zm-1.87 1.326a1.379 1.379 0 01-.9.571l-1.106.2v-6.285a3.056 3.056 0 00-3.052-3.052H13.482l-.164-.932a1.388 1.388 0 011.126-1.607l18.449-3.253a1.387 1.387 0 011.607 1.121l2.151 12.2a1.379 1.379 0 01-.23 1.037zm-5.054 7.954H13.052a1.389 1.389 0 01-1.387-1.388v-5.917h21.089v5.917a1.389 1.389 0 01-1.387 1.387zM13.052 20.145h18.315a1.389 1.389 0 011.387 1.39v.724H11.665v-.724a1.389 1.389 0 011.387-1.39zm19.7 3.777v2.113H11.665v-2.114z"
                    />
                    <path
                        data-name="Trac\xE9 8559"
                        d="M18.011 31.654h-3.777a.833.833 0 100 1.665h3.777a.833.833 0 100-1.665z"
                    />
                </g>
                <g data-name="Groupe 8257">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                    <g fill="#fff">
                        <path
                            data-name="Trac\xE9 8641"
                            d="M30.414 17.169l-3.749-.545-1.678-3.4a.854.854 0 00-1.473 0l-1.678 3.4-3.749.545a.821.821 0 00-.454 1.4l2.712 2.646-.643 3.735a.822.822 0 001.192.866l3.355-1.763 3.353 1.762a.822.822 0 001.192-.866l-.639-3.734 2.713-2.646a.821.821 0 00-.454-1.4zm-3.712 3.172a.822.822 0 00-.237.727l.433 2.52-2.264-1.19a.817.817 0 00-.764 0l-2.268 1.189.433-2.52a.822.822 0 00-.237-.727l-1.828-1.785 2.53-.368a.82.82 0 00.618-.449l1.133-2.293 1.133 2.293a.82.82 0 00.618.449l2.53.368z"
                        />
                        <path
                            data-name="Trac\xE9 8642"
                            d="M41.351 34.571l-5.622-8.029a13.143 13.143 0 10-22.958 0l-5.622 8.029a.82.82 0 00.815 1.279l6.057-1.068 1.066 6.06a.821.821 0 00.63.659.842.842 0 00.178.019.819.819 0 00.672-.351l5.63-8.04a12.006 12.006 0 004.1 0l5.63 8.041a.819.819 0 00.672.351.842.842 0 00.178-.019.821.821 0 00.63-.659l1.067-6.056 6.057 1.068a.82.82 0 00.815-1.279zM16.361 38.6l-.866-4.911a.819.819 0 00-.951-.666l-4.913.865 4.11-5.869a13.173 13.173 0 006.723 4.723zM12.75 20.16a11.5 11.5 0 1111.5 11.5 11.5 11.5 0 01-11.5-11.5zm21.206 12.863a.819.819 0 00-.951.666l-.866 4.911-4.1-5.858a13.173 13.173 0 006.723-4.722l4.11 5.869z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
