import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import {
    store as headerStore,
    toggleNewSegmentModal,
} from "@pages/Statistics/Transactions/Header/store";
import { store } from "@vStore";

import { clearStore } from "@pages/Statistics/Transactions/store";

import CustomModalFooter from "./CustomModalFooter";
import { NavWrapper } from "./NavWrapper";
import { TabContentWrapper } from "./TabContentWrapper";

export default function ModalWrapper() {
    const { t } = useTranslation();

    const { activeTab, isModalOpened } = useSnapshot(headerStore);
    const { mainColor } = useSnapshot(store);

    React.useEffect(() => {
        if (activeTab === 2) {
            clearStore();
        }
    }, [activeTab]);

    return (
        <Modal
            isOpen={isModalOpened}
            toggle={toggleNewSegmentModal}
            centered={true}
            className="segment-modal__clz px-3"
            style={{ maxWidth: "1600px" }}
            backdrop="static"
        >
            <ModalBody className="p-0 d-md-flex">
                <NavWrapper />
                <TabContentWrapper />
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <StyledButton
                    outline={true}
                    rounded={true}
                    variant={mainColor === null ? "primary" : ""}
                    onClick={toggleNewSegmentModal}
                    style={{
                        marginRight: "0.5rem",
                        borderColor: mainColor,
                        color: mainColor,
                    }}
                >
                    {t("Close")}
                </StyledButton>
                {activeTab === 1 ? (
                    <CustomModalFooter
                        toggleNewSegmentModal={toggleNewSegmentModal}
                    />
                ) : null}
            </ModalFooter>
        </Modal>
    );
}
