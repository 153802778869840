import { signal } from "@preact/signals-react";

let { value: products } = signal<string[]>([]);
let { value: amounts } = signal<number[]>([]);

function setProducts(newProducts: string[]) {
    products = newProducts;
}
function setAmounts(newAmounts: number[]) {
    amounts = newAmounts;
}

export { products, amounts, setProducts, setAmounts };
