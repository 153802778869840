import * as React from "react";
import { SVGProps } from "react";
export const KioskSvgIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={63.85}
        height={54.851}
        viewBox="0 0 342 342"
        {...props}
    >
        <defs>
            <filter
                id="Rectangle_13564"
                x={0}
                y={0}
                width={342}
                height={342}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.161} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_13573"
                    data-name="Rectangle 13573"
                    width={164.504}
                    height={230.388}
                    fill="none"
                />
            </clipPath>
        </defs>
        <g
            id="Groupe_1024"
            data-name="Groupe 1024"
            transform="translate(-6066 6583)"
        >
            <g
                transform="matrix(1, 0, 0, 1, 6066, -6583)"
                filter="url(#Rectangle_13564)"
            >
                <rect
                    id="Rectangle_13564-2"
                    data-name="Rectangle 13564"
                    width={324}
                    height={324}
                    rx={50}
                    transform="translate(9 6)"
                    fill="#f8f8f6"
                />
            </g>
            <g
                id="Groupe_1009"
                data-name="Groupe 1009"
                transform="translate(6154.748 -6530.194)"
            >
                <g
                    id="Groupe_1008"
                    data-name="Groupe 1008"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="Trac\xE9_1417"
                        data-name="Trac\xE9 1417"
                        d="M138.612,119.208c0,14.773.086,29.547-.07,44.318-.032,3.041.794,4.767,3.631,6.126,6.4,3.068,12.661,6.452,18.89,9.868,4.589,2.516,4.6,5.473-.035,7.858-27.331,14.06-54.716,28.014-82.047,42.074a7.5,7.5,0,0,1-7.5-.149Q37.585,211.759,3.658,194.282c-1.732-.9-3.793-1.713-3.651-4.205.137-2.4,2.141-3.119,3.937-3.991,14.863-7.207,29.69-14.494,44.61-21.581,2.893-1.374,4.143-2.893,4.1-6.32-.21-17.924-.121-35.852-.07-53.779a16.812,16.812,0,0,0-1.422-6.711q-11-26.257-21.856-52.573c-2.68-6.459-2.571-6.716,3.331-10.114C54,22.712,76.515,12.683,98.384,1.385c4.919-2.541,6.839-1.712,9.289,3.531q14.888,31.859,29.74,63.734a15.2,15.2,0,0,1,1.215,6.737q-.047,21.911-.016,43.821m-9.079,6.662c0-16.435-.031-32.871.033-49.307a15.96,15.96,0,0,0-1.582-7.13c-8.828-18.662-17.639-37.333-26.206-56.115-1.468-3.218-2.634-3.685-5.729-2.084Q76.255,21.481,56.22,31.252c-3.106,1.507-2.918,2.846-1.786,5.612,8,19.549,15.764,39.2,23.712,58.771a23.564,23.564,0,0,1,1.78,9.145c-.059,32.041.015,64.082-.1,96.123-.013,3.754.776,4.226,4.026,2.479q21.179-11.388,42.539-22.438c2.391-1.235,3.244-2.545,3.22-5.27-.15-16.6-.075-33.2-.075-49.8M70.528,205.091a9.309,9.309,0,0,0,.213-1.409q.018-50.055,0-100.11a9.384,9.384,0,0,0-.453-3.409Q58.2,70.064,46.014,40.006c-.359-.888-.393-2-1.718-2.114-3.4-.288-6.557,4.2-5.207,7.455,6.965,16.77,13.883,33.56,20.964,50.281a21.381,21.381,0,0,1,1.679,8.641q-.075,44.826-.03,89.651c0,8.515,0,8.515,8.826,11.171M14,190.185a10.923,10.923,0,0,0,1.457,1.254c19.234,9.925,38.493,19.8,57.692,29.793,2.141,1.114,3.615.345,5.411-.573q34.642-17.729,69.309-35.411c.888-.455,2.386-.477,2.472-2.375-3.814-1.458-7.127-4.224-11.92-5.361,1.576,5.231-1.38,7.155-5.126,9.063-13.7,6.974-27.951,13.165-40.68,21.629-12.59,8.372-23.578,8.359-35.623.138-2.891-1.974-4.609-3.6-4.517-7.325.24-9.683.081-19.376.081-29.709L14,190.185"
                        fill="#0d0d0d"
                    />
                    <path
                        id="Trac\xE9_1418"
                        data-name="Trac\xE9 1418"
                        d="M129.533,125.87c0,16.6-.075,33.2.074,49.8.025,2.725-.828,4.035-3.219,5.27q-21.368,11.032-42.54,22.438c-3.249,1.747-4.038,1.275-4.025-2.479.117-32.041.044-64.082.1-96.123a23.579,23.579,0,0,0-1.78-9.145C70.2,76.062,62.437,56.413,54.434,36.864c-1.132-2.766-1.321-4.1,1.785-5.612q20.053-9.725,39.83-20.018c3.095-1.6,4.261-1.134,5.729,2.084,8.567,18.782,17.378,37.453,26.205,56.115a15.947,15.947,0,0,1,1.583,7.13c-.065,16.436-.033,32.872-.033,49.307M96.325,20.877a9.047,9.047,0,0,0-2.362.626c-8.952,4.676-17.856,9.444-26.81,14.117-3.12,1.629-2.98,4.026-1.885,6.739q9.292,23.016,18.588,46.03c1.545,3.848,3.988,4.465,7.457,2.523q12.786-7.16,25.615-14.241c2.889-1.6,3.874-3.6,2.526-6.944C113.4,54.685,107.512,39.575,101.6,24.475a4.979,4.979,0,0,0-5.277-3.6m-1.42,82.8a2.419,2.419,0,0,0,.994-.26c8.329-5.073,16.673-10.123,24.938-15.3a3.887,3.887,0,0,0,1.266-5.665c-1.38-2.3-3.612-2.159-5.653-.953-5.267,3.11-10.466,6.336-15.678,9.536-2.817,1.729-5.664,3.42-8.39,5.283a4.038,4.038,0,0,0-1.448,5.272c.629,1.528,1.821,2.309,3.971,2.087"
                        fill="#f7f7f4"
                    />
                    <path
                        id="Trac\xE9_1419"
                        data-name="Trac\xE9 1419"
                        d="M70.527,205.091c-8.823-2.656-8.825-2.656-8.826-11.172q0-44.825.031-89.65a21.381,21.381,0,0,0-1.679-8.641c-7.082-16.721-14-33.511-20.964-50.281-1.351-3.253,1.8-7.743,5.207-7.455,1.324.112,1.358,1.226,1.717,2.114Q58.16,70.08,70.283,100.163a9.362,9.362,0,0,1,.453,3.409q.032,50.055,0,100.11a9.332,9.332,0,0,1-.214,1.409"
                        fill="#f7f7f4"
                    />
                    <path
                        id="Trac\xE9_1420"
                        data-name="Trac\xE9 1420"
                        d="M14,190.185,52.56,171.308c0,10.333.159,20.026-.081,29.709-.092,3.724,1.626,5.351,4.517,7.325,12.045,8.221,23.033,8.234,35.623-.138,12.729-8.464,26.984-14.655,40.68-21.629,3.746-1.908,6.7-3.832,5.126-9.063,4.793,1.137,8.106,3.9,11.92,5.361-.086,1.9-1.584,1.92-2.472,2.375Q113.244,203,78.564,220.659c-1.8.918-3.27,1.687-5.411.573-19.2-9.992-38.458-19.868-57.692-29.793A10.923,10.923,0,0,1,14,190.185"
                        fill="#f7f7f4"
                    />
                    <path
                        id="Trac\xE9_1421"
                        data-name="Trac\xE9 1421"
                        d="M96.325,20.876a4.98,4.98,0,0,1,5.277,3.6c5.91,15.1,11.8,30.21,17.852,45.252,1.348,3.348.363,5.346-2.526,6.944Q104.1,83.765,91.313,90.912c-3.469,1.942-5.912,1.325-7.457-2.523q-9.249-23.033-18.588-46.03c-1.1-2.713-1.235-5.11,1.885-6.739,8.954-4.673,17.858-9.441,26.81-14.117a9.016,9.016,0,0,1,2.362-.627m14.066,48.839c-.1-.278-.272-.743-.452-1.2-4.647-11.853-9.334-23.69-13.911-35.57-1.057-2.741-2.636-2.405-4.65-1.329C86.27,34.341,81.181,37.111,76,39.7c-2.259,1.131-2.669,2.42-1.709,4.746,4.858,11.769,9.6,23.587,14.334,35.406.8,1.994,1.553,2.977,3.9,1.574,5.252-3.141,10.674-6,16.016-8.989.985-.551,2.192-.992,1.846-2.726"
                        fill="#c07f50"
                    />
                    <path
                        id="Trac\xE9_1422"
                        data-name="Trac\xE9 1422"
                        d="M94.9,103.68c-2.15.222-3.342-.559-3.971-2.087a4.038,4.038,0,0,1,1.448-5.272c2.726-1.863,5.573-3.554,8.39-5.283,5.212-3.2,10.411-6.426,15.678-9.536,2.041-1.206,4.273-1.346,5.653.953a3.887,3.887,0,0,1-1.266,5.665c-8.265,5.177-16.609,10.227-24.938,15.3a2.419,2.419,0,0,1-.994.26"
                        fill="#c07f50"
                    />
                    <path
                        id="Trac\xE9_1423"
                        data-name="Trac\xE9 1423"
                        d="M110.391,69.715c.346,1.734-.861,2.175-1.846,2.726-5.342,2.992-10.764,5.848-16.016,8.989-2.346,1.4-3.1.42-3.9-1.574C83.895,68.037,79.154,56.219,74.3,44.45c-.96-2.326-.55-3.615,1.709-4.746,5.176-2.593,10.265-5.363,15.373-8.092,2.014-1.076,3.593-1.412,4.65,1.329,4.577,11.88,9.264,23.717,13.911,35.57.18.461.348.926.452,1.2"
                        fill="#f7f7f4"
                    />
                </g>
            </g>
        </g>
    </svg>
);
